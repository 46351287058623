import React, { useRef, useEffect, useState } from "react";
import {
  MagnifyingGlassIcon,
  ArrowDownTrayIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { Breadcrumbs } from "@material-tailwind/react";
import { EyeIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { PiEyeThin } from "react-icons/pi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { LuClipboardEdit } from "react-icons/lu";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import axios from "axios";
import InputField from "../../../components/InputField";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "sonner";
import { Avatar } from "@material-tailwind/react";
import { useUserQuery } from "../../../hooks/useUserQuery";
import { BeatLoader, ClipLoader } from "react-spinners";
import { useDialog, useModal } from "../../../hooks/useModal";
import Pagination from "../../../components/Pagination";
// import { register } from "module";
const TABLE_HEAD = ["User Profile", "Full Names", "Email Address", "Role", ""];
const api = process.env.REACT_APP_BACKEND_API_URL;
export default function SystemUsers() {
  const [TABLE_ROWS, setDataTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  // const [userData, setUserData] = useState({
  //   name: "",
  //   email: "",
  //   password: "password123",
  // });

  // const handleForm = (e) => {
  //   setUserData({ ...userData, [e.target.name]: e.target.value });
  // };
  const schema = yup
    .object()
    .shape({
      name: yup.string().required(),
      email: yup.string().email().required(),
      userId: yup.string().nullable(),
    })
    .required();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,

    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // const [loading, setLoading] = useState(true);
  const createUser = async (data) => {
    setOpen(!open);
    setCreating(true);
    console.log(data);
    setLoading(true);
    if (data.userId) {
      axios
        .patch(`${api}/api/v1/users/${data.userId}`, { ...data })
        .then((response) => {
          console.log("Response:", response.data);
          setOpen(!open);
          setCreating(false);
          document.getElementById("userform").close();
          dialog.showModal("Success", "User Edited successfully");
          reset();
          search();
        })
        .catch((error) => {
          let msg = "User not created";
          if (error.response.status === 409) {
            // toast.error("Unsuccessfull", { description: "User exists" });
            msg = "User already exists";
          }
          console.error("Error:", error);
          setCreating(false);
          dialog.showModal("Error", "User not edited successfully");
        })
        .finally(() => {});
    } else {
      try {
        axios
          .post(`${api}/api/v1/users`, {
            ...data,
            password: "password123",
            passwordConfirm: "password123",
            role: "user",
          })
          .then((response) => {
            console.log("Response:", response.data);
            setOpen(!open);
            setCreating(false);
            document.getElementById("userform").close();
            dialog.showModal("Success", "User created successfully");

            reset();
            search();
          })
          .catch((error) => {
            let msg = "User not created";
            if (error.response.status === 409) {
              // toast.error("Unsuccessfull", { description: "User exists" });
              msg = "User already exists";
            }
            console.error("Error:", error);
            setCreating(false);
            toast.error("Unsuccessfull", { description: msg });
          });
      } catch (error) {
        console.log(error);
        setCreating(false);
      } finally {
        // set
        setLoading(false);
      }
    }
  };

  const search = async () => {
    try {
      let response = await axios.get(`${api}/api/v1/users?role=user`);
      response = response.data.data;

      // Filter the response data based on the query
      let filteredData = response.filter((item) => {
        // Check if name, id, or project of the item matches the query
        return (
          item.name.toLowerCase().includes(query.toLowerCase()) ||
          item._id.toLowerCase().includes(query.toLowerCase()) ||
          item.role.toLowerCase().includes(query.toLowerCase()) ||
          item.email.toLowerCase().includes(query.toLowerCase())
        );
      });
      filteredData = filteredData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      if (filteredData) {
        console.log("filteredData");
        console.log(filteredData);
        setDataTable(filteredData);
        setLoading(false);
      } else if (query === "") {
        setLoading(false);
        console.log(response);
        setDataTable(response);
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    search();
  }, [query]);

  const modal = useModal();
  const dialog = useDialog();
  const Delete = (id) => {
    modal.showModal("Deleting user", "Please wait...");
    try {
      axios.delete(`${api}/api/v1/users/${id}`).then((res) => {
        modal.closeModal();
        dialog.showModal("Success", "User deleted successfully");
        // toast.success("User deleted successfully");
        search();
      });
    } catch (error) {
      modal.closeModal();

      console.log(error);
    }
  };

  useEffect(() => {
    if (open === true) {
      document.getElementById("userform").showModal();
    }
  }, [open]);

  const [creating, setCreating] = useState(false);
  const [modalTitle, setModalTitle] = useState("Create New User");

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = TABLE_ROWS.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => {
    console.log("Mike" + pageNumber);

    setCurrentPage(pageNumber);
  };

  return (
    <div className="container h-[100vh]  flex-col flex  mx-auto">
      <div className="mt-44 mb-6 mx-10">
        <label className="text-[#e9e9e9] text-[21px]">
          {" "}
          View all System Users
        </label>
        <Breadcrumbs className="text-[#e9e9e9]" separator="-">
          <a href="#" className="opacity-60">
            Dashboard
          </a>
          <a href="#">Users</a>
        </Breadcrumbs>
      </div>

      <Card className="bg-[#102232] shadow-[60%] py-8 mx-11  border border-[#5455551a]">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none bg-transparent my-1 text-[#e9e9e9]"
        >
          <div className="mb-2 flex items-center justify-between mx-10 gap-8">
            <div>
              <label className="input input-bordered bg-transparent border border-[#454545] flex items-center gap-2">
                <input
                  type="text"
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  className="grow"
                  placeholder="Search"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 opacity-70"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </label>
            </div>
            <div className="flex  flex-col  bg-[#2b2d77]  rounded-lg sm:flex-row">
              <Button
                className="flex items-center gap-3 text-[#e9e9e9]"
                onClick={() => {
                  setValue("email", "");
                  setValue("name", "");
                  setValue("userId", "");
                  setModalTitle("Create New User");
                  setOpen(!open);
                }}
              >
                <UserPlusIcon strokeWidth={2} className="h-6 w-5 " /> Add new
                user
              </Button>
            </div>
          </div>
        </CardHeader>

        <CardBody className="text-white mx-10">
          <table className="mt-4 w-full min-w-max table-auto ">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className="cursor-pointer one bg-blue-gray-50/50 py-4 transition-colors text-start bg-[#162e42]"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {head}{" "}
                      {index !== TABLE_HEAD.length - 1 && (
                        <ChevronUpDownIcon
                          strokeWidth={2}
                          className="h-4 w-4"
                        />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading
                ? Array.from({ length: 3 }).map((_, index) => (
                    <tr className="py-2">
                      <td>
                        <div className="skeleton h-[56px] w-[56px]"></div>
                      </td>
                      <td>
                        <div className="skeleton h-4 w-11/12"></div>
                      </td>
                      <td>
                        <div className="skeleton h-4 w-10/12"></div>
                      </td>
                      <td>
                        <div className="skeleton h-4 w-12"></div>
                      </td>{" "}
                      <td>
                        <div className="skeleton h-4 w-full"></div>
                      </td>
                    </tr>
                  ))
                : currentPosts.map(({ name, role, email, _id: id }, index) => {
                    const isLast = index === TABLE_ROWS.length - 1;
                    const classes = isLast ? "py-2" : "py-2 border-none";

                    return (
                      <tr key={id} className="text-[#bbbbbb]">
                        <td className={classes}>
                          <div className="flex items-center gap-2">
                            <div className="w-14 flex items-center justify-center rounded">
                              <Avatar
                                src="https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
                                alt="avatar"
                                variant="square"
                              />
                            </div>
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                className="font-normal text-[#bbbbbb]"
                              >
                                {name}
                              </Typography>
                              <Typography
                                variant="small"
                                className="font-normal text-[#bbbbbb]"
                              >
                                {email}
                              </Typography>
                            </div>
                          </div>
                        </td>

                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {name}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {email}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {role}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex">
                            <Tooltip content="View user">
                              <IconButton
                                className="flex justify-center items-center"
                                onClick={() => {
                                  setValue("email", email);
                                  setValue("name", name);
                                  setValue("userId", id);
                                  setModalTitle("Edit User");
                                  setOpen(!open);
                                }}
                                variant="text"
                              >
                                <LuClipboardEdit size={20} color="#ccc" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip content="Delete user">
                              <IconButton
                                className="flex justify-center items-center"
                                onClick={() => {
                                  Delete(id);
                                }}
                                variant="text"
                              >
                                <RiDeleteBin6Line size={20} color="red" />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </CardBody>

        <CardFooter className="flex py-1 px-3 sm:px-10 lg:px-12  mx-1 ">
         
         <Pagination
         currentPage={currentPage}
         postsPerPage={postsPerPage}
         totalPosts={TABLE_ROWS.length}
         paginate={paginate}
  />
       
      </CardFooter>
      </Card>
      {/* <dialog id="my_modal_5" className="modal  modal-bottom sm:modal-middle">
        <div className="modal-box bg-[#081c2f]">
          <h3 className="font-bold text-lg">Deleting user </h3>
          <p className="py-4">
            Please wait...
            <ClipLoader color="#081c2f" />
          </p>
        </div>
      </dialog> */}
      <dialog id="userform" className="modal">
        <div className="modal-box bg-[#112232] text-[#bbbbbb] grid grid-cols-1 gap-8 w-1/4 max-w-5xl">
          <label className="text-[#bbbbbb] text-xl">{modalTitle}</label>
          <form>
            <div className="flex flex-col gap-4">
              <InputField
                label="Full Names"
                type="text"
                message={errors.name?.message}
                isErrored={errors.name}
                register={register}
                name="name"
                placeholder="type the user's full names here"
              />
              <InputField
                label="Email Address"
                type="email"
                disabled={getValues("userId")?.length > 0}
                message={errors.email?.message}
                isErrored={errors.email}
                name="email"
                register={register}
                placeholder="type the user's email address here..."
              />
            </div>
            <div className="modal-action">
              <div className="flex mt-10 gap-2">
                <div className="modal-action mt-0">
                  <form method="dialog">
                    {/* if there is a button in form, it will close the modal */}
                    <button
                      onClick={(e) => {
                        // document.getElementById("userform").showModal();
                        setOpen(!open);
                        search();
                      }}
                      className="btn h-full text-white focus:border-none outline-none px-10 hover:border-none border-none rounded-lg hover:scale-70 hover:bg-[#2b2d77]  bg-transparent"
                    >
                      Close
                    </button>
                  </form>
                </div>
                <div></div>
                <button
                  onClick={handleSubmit(createUser)}
                  className="text-white px-10 focus:border-none outline-none flex justify-center items-center border-none rounded-xl hover:border-none hover:bg-[#2b2d77]  bg-[#2b2d77]"
                >
                  {creating ? <BeatLoader size={10} color="#fff" /> : "Save"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </dialog>
    </div>
  );
}
