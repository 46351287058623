import React, { useEffect, useRef, useState } from "react";
// import { set } from "react-hook-form";
import {
  BiChevronDown,
  BiChevronUp,
  BiInfoCircle,
  BiSearch,
} from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
const Combobox = ({
  label,
  type,
  name,
  placeholder,
  onChange,
  value,
  className,
  id,
  inputPlaceholder,
  isErrored,
  message,
  register,
  toolTip,
  setValue,
  onSelect,
  clearErrors,
}) => {
  const [hasError, setHasError] = useState(isErrored);
  // useStartTyping(() => alert("You started typing!"));

  useEffect(() => {
    setHasError(isErrored);
  }, [isErrored]);

  const [isTooTipShown, setIsTooTipShown] = useState(false);

  // helper for the popover close
  const handleClick = () => {
    const elem = document.activeElement;
    if (elem) {
      setIsTooTipShown(!isTooTipShown);
      elem?.blur();
    }
  };
  const [matchedSearch, setMatchedSearch] = React.useState(toolTip);

  const onHandleSearch = (e) => {
    setMatchedSearch(
      toolTip.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };
  const inputRef = React.useRef(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);
  return (
    <div
      className={`flex  items-center justify-between px-3 py-[15px] bg-[#081c2f] cursor-pointer  rounded-2xl border ${
        hasError ? "border-[red]" : "border-[#585d61]"
      }`}
    >
      <div className={`flex flex-col w-11/12`}>
        <label className="text-xs mb-1 text-white">{label}</label>
        {register === undefined ? (
          <input
            id={id}
            value={value}
            disabled
            className={
              "mt-1 text-xs text-[#a6adbb] bg-transparent placeholder-[#475663] active:border-transparent focus:outline-none"
            }
            // {...register(name, { required: true })}
            type={type}
            // name={name}
            placeholder={placeholder}
          />
        ) : (
          <input
            value={value}
            {...register(name)}
            id={id}
            className={
              "mt-1 text-xs text-[#a6adbb] bg-transparent placeholder-[#475663] active:border-transparent focus:outline-none"
            }
            // {...register(name, { required: true })}
            type={type}
            disabled
            // name={name}
            placeholder={placeholder}
            // onChange={(e) => {
            //   onChange(e);
            //   setHasError(false);
            // }}
          />
        )}

        {hasError && (
          <div className="text-[red] first-letter:capitalize  text-xs mt-1">
            {message || "Required"}
          </div>
        )}
      </div>

      {toolTip && (
        <div className="W-[30px]">
          <div
            onBlur={() => setIsTooTipShown(false)}
            className="dropdown dropdown-end"
          >
            <div
              tabIndex="0"
              role="button"
              className=" btn-link text-white p-0 m-0 mt-2"
            >
              {/* {isTooTipShown ? ( */}
              <BiChevronDown
                size={22}
                className="transition duration-300 delay-0 ease-in-out"
                style={{
                  transform: isTooTipShown ? "rotate(-0deg)" : "rotate(-90deg)",
                }}
                onClick={() => setIsTooTipShown(true)}
              />
              {/* ) : (
                <BiChevronDown
                  size={22}
                  onClick={() => setIsTooTipShown(true)}
                />
              )} */}
            </div>
            <ul
              tabIndex="0"
              className="dropdown-content w-[500px]  z-[1] p-0 menu shadow  rounded-box border border-1  bg-[#102232]"
            >
              <div className="flex pt-3 px-3  justify-between   items-center">
                <BiSearch />
                <input
                  ref={inputRef}
                  className="bg-transparent focus:outline-none w-11/12"
                  onChange={onHandleSearch}
                  placeholder={
                    inputPlaceholder || "Type to search transaction template"
                  }
                />
                {/* <div className="font-semibold">Select option</div> */}
                <div>
                  <IoMdClose
                    onClick={handleClick}
                    className="menu-dropdown-toggle"
                  />
                </div>
              </div>
              <div className="divider p-0 m-0 border-[#fff]"></div>
              <div className="px-1 py-2 mb-2 text-justify">
                <ul className="space-y-1">
                  {matchedSearch.length === 0 && (
                    <li className="py-1 px-2 hover:bg-slate-600 rounded-md">
                      No template found...
                    </li>
                  )}
                  {matchedSearch.slice(0, 5).map((item, i) => (
                    <li
                      onClick={() => {
                        handleClick();
                        setValue(name, item);
                        if (onSelect) onSelect(item);
                        clearErrors(name);
                      }}
                      className={` py-1 px-2 hover:bg-slate-600 rounded-md`}
                      key={item.id}
                    >
                      {item.label || item.name}
                    </li>
                  ))}
                </ul>
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Combobox;
