import { useState, useEffect, useReducer } from "react";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { IoMdArrowBack } from "react-icons/io";

import EditIndividualForm from "./EditIndividualForm";

import {
  useIndividualCaseQuery,
  useSingleIndividualCaseQuery,
} from "../../hooks/useIndividualCaseQuery";

const api = process.env.REACT_APP_BACKEND_API_URL;

export default function EditForm(props) {
  const history = useHistory();

  const params = useParams();

  const { data, isFetching } = useSingleIndividualCaseQuery(params.caseId);

  const [partyIndex, setPartyIndex] = useState(0);

  // loh
  const [parties, setParties] = useState(data?.parties);
  console.log(data?.parties[partyIndex]);

  useEffect(() => {
    console.log(isFetching);
    if (data)
      setParties([
        ...data?.parties
          .filter((party) => party.party === "FIRST")
          .map((party) => ({
            ...party,
            title: "1st Party details",
          })),
        ...data?.parties
          .filter((party) => party.party === "SECOND")
          .map((party) => ({
            ...party,
            title: "2nd Party details",
          })),
      ]);
  }, [isFetching]);
  return (
    <>
      <div className="text-white flex px-20 flex-row mb-4 text-xl container items-center">
        <span
          aria-hidden="true"
          className="cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          {<IoMdArrowBack />}
        </span>
        <label className="w-full flex justify-center font-medium">
          <span className="font-light mr-1">
            {parties?.length > 0 && parties[partyIndex]?.title}
            {/* {party === "first" ? "1st Party" : "2nd Party"} */}
          </span>
          {/* {party === "case" ? "details" : " information"} */}
        </label>
      </div>

      {parties?.length > 0 && partyIndex < parties.length && (
        <EditIndividualForm
          key={parties[partyIndex].id}
          data={parties[partyIndex]}
          setPartyIndex={setPartyIndex}
          showNext={partyIndex < parties.length - 1}
        />
      )}
      <>
        <div>Case form</div>
      </>
    </>
  );
}
