/**
 * Convert a string to snake_case format. eg "Hello World" to "hello_world".
 * @param {string} str - The string to convert.
 * @returns {string} The string converted to snake_case format.
 */
export function toSnakeCase(str) {
  // Replace non-alphanumeric characters with underscores
  const alphanumericStr = str.replace(/[^a-zA-Z0-9]/g, "_");
  // Replace consecutive underscores with a single underscore
  const sanitizedStr = alphanumericStr.replace(/_+/g, "_");
  // Convert to lowercase
  return sanitizedStr.toLowerCase();
}

/**
 * Remove the nth element from an array.
 * @param {Array} arr - The array from which to remove the element.
 * @param {number} n - The index of the element to remove.
 * @returns {Array} A new array with the nth element removed.
 */
export function removeNthElement(arr, n) {
  // Create a copy of the original array
  const newArr = [...arr];

  // Check if the index is valid
  if (n >= 0 && n < newArr.length) {
    // Use splice to remove the nth element
    newArr.splice(n, 1);
  }

  return newArr;
}
/**
 * Convert a JSON array into a JSON object.
 * @param {Array} array - The JSON array to be converted.
 * @returns {Object} - The resulting JSON object.
 */
export function convertArrayToObject(array) {
  // Initialize an empty object to store the converted data
  let obj = {};

  // Iterate through each object in the array
  array.forEach((item) => {
    // Extract the key and value from each object
    let key = Object.keys(item)[0];
    let value = item[key];

    // Add the key-value pair to the object
    obj[key] = value;
  });

  // Return the resulting object
  return obj;
}

/**
 * Generate four random letters.
 * @returns {string} - A string containing four random letters.
 */
export function generateRandomLetters() {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  let randomLetters = "";

  // Generate four random letters
  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * alphabet.length);
    randomLetters += alphabet[randomIndex];
  }

  return randomLetters;
}
/**
 * Checks if the provided object is iterable.
 *
 * An object is considered iterable if it has a `Symbol.iterator` property that is a function.
 *
 * @param {*} obj - The object to check.
 * @returns {boolean} - Returns `true` if the object is iterable, otherwise `false`.
 *
 * @example
 * console.log(isIterable([1, 2, 3]));  // true, arrays are iterable
 * console.log(isIterable('hello'));    // true, strings are iterable
 * console.log(isIterable(123));        // false, numbers are not iterable
 * console.log(isIterable({'a': 1, 'b': 2}));  // false, plain objects are not iterable
 * console.log(isIterable(new Map()));  // true, Maps are iterable
 * console.log(isIterable(new Set()));  // true, Sets are iterable
 * console.log(isIterable(null));       // false, null is not iterable
 * console.log(isIterable(undefined));  // false, undefined is not iterable
 */
export function isIterable(obj) {
  return obj != null && typeof obj[Symbol.iterator] === "function";
}
