import React, { useEffect, useState } from "react";
import CasesHeader from "./CasesHeader";
import SingleCase from "./SingleCase";
import EmptyCases from "./EmptyCases";
import { BiChevronDown } from "react-icons/bi";
// import { Modal } from "../views/app-views/Cases";
// import { useIndividualCaseQuery } from "../hooks/useIndividualCaseQuery";
// import { useTransactionsQuery } from "../hooks/useTransactionsQuery";
import { useTasksQuery } from "../hooks/useTasksQuery";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { IoIosAddCircleOutline } from "react-icons/io";
import InputField from "./InputField";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { useQueryClient } from "@tanstack/react-query";

const CaseList = [
  {
    title: "To-do",
    status: "to-do",
    id: 1,
    color: "#43ade2",
  },

  {
    title: "In-progress",
    id: 2,
    status: "in-progress",
    color: "#7E81FF",
  },
  {
    title: "Completed",
    id: 3,
    status: "done",
    color: "#FFC36B",
  },
];
const api = process.env.REACT_APP_BACKEND_API_URL;

function CurrentCases({selectedOption,saveCategories,category}) {
  const [isClicked, setIsClicked] = React.useState(false); //keep  track of mouse drag
  const casesHeaderRef = React.useRef(null); //to use for side scrolling
  // const [caseListState, setCaseListState] = React.useState(CaseList);
  const [currentSubTask, setCurrentSubTask] = React.useState([]);
  const [newTask, setNewTask] = React.useState({
    name: "",
    isDone: false,
    isSaving: true,
  });
  // const [task/]
  const [currentCase, setCurrentCase] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [sendEmail, setUpdateEmail] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);

  const queryClient = useQueryClient();

  const onOpenModal = (caseId) => {
    setCurrentCase(caseId);
    console.log(caseId);
    let endpoint =
      "/api/v1/tasks/" +
      caseId.collectionName +
      "/" +
      caseId.caseId +
      "/" +
      caseId.id;

    setUrl(endpoint);
    document.getElementById("my_modal_1").showModal();
  };

  const { data, isFetched, isFetching } = useTasksQuery();

  const [saving, setSaving] = useState(false);
  const [url, setUrl] = useState("");
  const [filterType, setFilterType] = useState("");
  const [modifiedData, setModifiedData] = useState([]);
  // const [selectedOption, setSelectedOption] = useState("");
  const handleSaveTask = () => {
    let endpoint =
      "/api/v1/tasks/" +
      currentCase.collectionName +
      "/" +
      currentCase.caseId +
      "/" +
      currentCase.id;

    setUrl(endpoint);
    setModifiedData((prev) => {
      return prev.map((item) => {
        if (item.id == currentCase.id) {
          item.subTasks.push(newTask);
          return item;
        } else {
          return item;
        }
      });
    });
    setCurrentCase((prev) => {
      return {
        ...prev,
        subTasks: [...prev.subTasks, newTask],
      };
    });

    setSaving(true);
    axios
      .patch(api + endpoint, { subTask: newTask })
      .then((res) => {
        console.log(res.data);
        queryClient.invalidateQueries({ queryKey: ["cases"] });
        setSaving(false);
        setNewTask({ name: "", isDone: false });
      })
      .catch((e) => {
        setSaving(false);
        setModifiedData((prev) => {
          return prev.map((item) => {
            if (item.id == currentCase.id) {
              item.subTasks.pop();
              return item;
            } else {
              return item;
            }
          });
        });
        setCurrentCase((prev) => {
          prev.subTasks.pop();
          return prev;
        });
      });
  };

  function extractEmailAddresses(array) {
    return array.map((obj) => {
      // Find the schema entry with name "emailaddress"
      const emailSchema = obj.formSchema.find((schema) =>
        schema.name.includes("emailaddress")
      );

      // If found, return its value
      if (emailSchema) {
        return emailSchema.value;
      } else {
        return null; // or handle the case where email address is not found
      }
    });
  }

  useEffect(() => {
    let tasks = [];
    if (data == null) return;
    data?.map((item) => {
      return item.tasks.map(async (task) => {
        let status = getStatus(task.subTasks);
        let isDone = task.isDone;
        let apiStatus = "pending";
        if (status === "done") {
          apiStatus = "completed";
          isDone = true;
        }
        if (status === "in-progress") apiStatus = "in-progress";

        if (currentCase?.id == item?.id) {
          axios
            .patch(
              api +
                "/api/v1/tasks/" +
                item.collectionName +
                "/" +
                item.caseId +
                "/" +
                task._id,
              { status: apiStatus, isDone: isDone }
            )
            .then(async (res) => {});
        }
        tasks.push({
          ...task,
          status: status,
          caseName: item.caseName,
          description: item.description,
          caseId: item.caseId,
          collectionName: item.collectionName,
        });

        const allSubTasks = item.tasks.reduce((acc, task) => {
          acc.push(...task.subTasks);
          return acc;
        }, []);

        // Check if all subtasks are done
        const allDone = allSubTasks.every((subTask) => subTask.isDone);

        // Check if none of the subtasks are done
        const noneDone = allSubTasks.every((subTask) => !subTask.isDone);

        // Check if some of the subtasks are done
        const someDone = !allDone && !noneDone;
        
        // Log the result
        if (allDone) {
          console.log("All subtasks are done.");
          axios
            .put(
              `${api}/api/v1/transactions/${item.collectionName}/${item.caseId}`,
              { status: "done" }
            )
            .then(async (res) => {
              console.log("Tasks done and email will be sent shortly");

              if (sendEmail && currentFile === item.caseId) {
                axios
                  .get(
                    `${api}/api/v1/transactions/${item.collectionName}/${item.caseId}`
                  )
                  .then((response) => {
                    const caseitem = response.data.data;
                    const emailAddresses = extractEmailAddresses(
                      caseitem?.kyc || []
                    );
                    let uniqueEmails = [...new Set(emailAddresses)];

                    console.log("response.data.data");
                    console.log(uniqueEmails);
                    console.log("response.data.data");
                    uniqueEmails?.map((email) => {
                      if (email) {
                        axios.post('https://collins-chilisa.testlive.space/api/v1/email', {
                          "email": email,
                          "subject": `Case Update: ${item.subTitle || item.caseName}`,
                          "text": `Good day,
                        
                          We are writing to inform you that your case,${item.subTitle || item.caseName}, has been completed successfully.
                        
                          We will continue to keep you informed of any further changes. Should you have any questions or require further assistance, please do not hesitate to contact us.
                        
                        Thank you for your patience and cooperation.
                        
                        Best regards,
                        Collins Chilisa Team`,
                        "html": `<p>Good day,</p>
                        <p>We are writing to inform you that your case, <strong>${item.subTitle || item.caseName}</strong>, has been completed successfully.</p>
                        <p>We will continue to keep you informed of any further changes. Should you have any questions or require further assistance, please do not hesitate to contact us.</p>
                        <p>Thank you for your patience and cooperation.</p>
                        <p>Best regards,<br>
                        Collins Chilisa Team</p>`
                        }
                        
                        )
                          .then(() => {
                            console.log("Sent Email");
                            setUpdateEmail(false);
                            setCurrentFile(null);
                          });
                      } else {
                        console.log("No email");
                      }
                    });
                  });
              }
            });
        } else if (noneDone) {
          console.log("None of the subtasks are done.");
          axios
            .put(
              `${api}/api/v1/transactions/${item.collectionName}/${item.caseId}`,
              { status: "to-do" }
            )
            .then(async (res) => {
              console.log("Tasks in-progress ");
            });
        } else if (someDone) {
          console.log("Some of the subtasks are done.");
          axios
            .put(
              `${api}/api/v1/transactions/${item.collectionName}/${item.caseId}`,
              { status: "in-progress" }
            )
            .then(async (res) => {
              console.log("Tasks pending");

              if (sendEmail && currentFile === item.caseId) {
                axios
                  .get(
                    `${api}/api/v1/transactions/${item.collectionName}/${item.caseId}`
                  )
                  .then((response) => {
                    const caseitem = response.data.data;
                    const emailAddresses = extractEmailAddresses(
                      caseitem?.kyc || []
                    );
                    let uniqueEmails = [...new Set(emailAddresses)];

                    console.log("response.data.data");
                    console.log(item);
                    console.log("response.data.data");
                    uniqueEmails?.map((email) => {
                      if (email) {
                    
                        axios.post('https://collins-chilisa.testlive.space/api/v1/email', {
                          "email": email,
                          "subject": `Case Update: ${item.subTitle || item.caseName}`,
                          "text": `Good day,
                        
                          We are writing to inform you that your case, ${item.subTitle || item.caseName}, is still in progress.
                        
                        We will continue to keep you informed as each task progresses. If you have any questions or need further assistance, please do not hesitate to contact us.
                        
                        Thank you for your patience and cooperation.
                        
                        Best regards,
                        Collins Chilisa Team`,
                        "html": `<p>Good day,</p>
                        <p>We are writing to inform you that your case, <strong>${item.subTitle || item.caseName}</strong>, is still in progress.</p>
                        <p>We will continue to keep you informed of any updates. Should you have any questions or require further assistance, please do not hesitate to contact us.</p>
                        <p>Thank you for your patience and cooperation.</p>
                        <p>Best regards,<br>
                        Collins Chilisa Team</p>`
                        }
                        
                        )
                          .then(() => {
                            console.log("Sent Email");
                            setUpdateEmail(false);
                            setCurrentFile(null);
                          });
                      } else {
                        console.log("No email");
                      }
                    });
                  });
              }
            });
        }
      });
    });

    console.log("curent aaaaaaaaaaa");
        console.log(tasks);
        console.log("curent aaaaaaaaaa");

    const filters =
      selectedOption === "Tasks"
        ? tasks.map((item) => item.name)
        : selectedOption === "Transactions"
        ? tasks.map((item) => item.collectionName)
        : "";

    const uniqueCategories = [...new Set(filters)];
    saveCategories(uniqueCategories);

    console.log("lllllllllllllllllllllllllllllllllllllllll");

    if (category !== "") {
      const list = tasks.filter(
        (item) => item.name === category || item.collectionName === category || item.name.toLowerCase().includes(category.toLowerCase())
      );
      console.log("lllllllllllllllllllllllllllllllllllllllll");
      console.log(list);
      setModifiedData(list);
    } else {
      setModifiedData(tasks);
    }
  }, [data, currentCase, category, selectedOption]);

  const [isDoneCount, setIsDoneCount] = useState(0);

  const RadioButtonGroup = ({ options, selectedOption, onChange }) => {
    return (
      <div>
        {options.map((option) => (
          <label key={option}>
            <input
              type="radio"
              value={option}
              checked={selectedOption === option}
              onChange={(e) => {
                // setSelectedOption(e.target.value);
              }}
            />
            {option}
          </label>
        ))}
      </div>
    );
  };
  const options = ["Tasks", "Transactions"];

  const handleChange = (event) => {
    // setSelectedOption(event.target.value);
  };
  return (
    <>
      <dialog id="my_modal_1" className="modal">
        <div className="modal-box pt-12 bg-[#102232]  flex justify-center flex-col items-center shadow-sm border-none">
          <h3 className=" text-lg">
            Please select a{" "}
            <span className="font-bold underline ">SubTask!</span>
          </h3>
          <div className="mt-10 w-full space-y-5 " key={currentCase}>
            {currentCase?.subTasks?.map((task) => {
              return (
                <Task
                  id={task._id}
                  taskId={task._id}
                  key={task._id}
                  url={url}
                  mainTaskId={currentCase.id}
                  isSaving={task.isSaving}
                  {...task}
                  isDoneCount={isDoneCount}
                  setIsDoneCount={setIsDoneCount}
                  setModifiedData={setModifiedData}
                  {...currentCase}
                />
              );
            })}
            {showInput && (
              <input
                onChange={(e) =>
                  setNewTask({ name: e.target.value, isDone: false })
                }
                value={newTask.name}
                placeholder="Enter a task"
                className=" placeholder-[#475663] border bg-transparent rounded-2xl justify-between w-full items-center border-[#585d61] p-3"
              />
            )}
            <button
              disabled={newTask.name.length < 1 && showInput}
              onClick={() => {
                if (showInput) {
                  handleSaveTask();
                } else {
                  setShowInput(true);
                  // setShowInput(false);
                }
              }}
              className={`${
                newTask.name.length < 1 && showInput && !saving
                  ? "cursor-not-allow text-[#555]  "
                  : "cursor-pointer text-[#42abdf] hover:bg-[#302a74]"
              } cursor-pointer  mt-4  rounded-lg items-center hover:border-transparent flex  py-1.5 px-2`}
            >
              <IoIosAddCircleOutline size={20} />
              <span className="ml-2 text-sm font-semibold">
                {showInput ? (saving ? "Saving..." : "Save") : "Add more"}
              </span>
            </button>
            <div className="flex justify-end">
              <div
                onClick={() => {
                  setShowInput(false);
                  setNewTask({ name: "", isDone: false });
                }}
                className="modal-action"
              >
                <form method="dialog">
                  {/* if there is a button in form, it will close the modal */}
                  <button
                    onClick={() => {
                      // console.log("Close");
                      queryClient.invalidateQueries({ queryKey: ["cases"] });
                      console.log("Close");

                      setUpdateEmail(!sendEmail);
                      setCurrentFile(currentCase.caseId);

                      setCurrentCase(null);
                      document.getElementById("my_modal_1").close();
                    }}
                    className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[60px] mt-0 text-white p-3 hover:scale-105 duration-300 h-[35px]"
                  >
                    Close
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </dialog>
      <div
        className={`p-8 xl:w-9/12 min-h-[850px] no-scrollbar overflow-auto mt-[96px] lg:mt-0`}
      >
     
        <div className="grid grid-cols-3 justify-start  gap-4">
          {CaseList.map((item, index) => (
            <div className="flex gap-4 flex-col">
              <CasesHeader title={item.title} />
              <div
                key={item.id}
                className=" space-y-4  col-span-1 overflow-scroll no-scrollbar  h-[70vh] flex-col gap-4 rounded-md"
              >
                {!isFetching &&
                  modifiedData?.length > 0 &&
                  modifiedData
                    ?.filter((md) => md.status === item.status)
                    .slice(0, 10)
                    .map((singleCase) => {
                      if (singleCase?.subTasks?.length > 0) {
                        return (
                          <SingleCase
                            key={singleCase._id}
                            id={singleCase._id}
                            caseId={singleCase.caseId}
                            onOpenModal={onOpenModal}
                            subTitle={singleCase.caseName}
                            color={item.color}
                            subTasks={singleCase.subTasks}
                            description={singleCase.description}
                            title={singleCase.label}
                            headerId={item.id}
                            collectionName={singleCase.collectionName}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
              </div>
            </div>
          ))}
        </div>

        <div className="flex gap-4 left-0 w-full relative ml-auto bg-slate-800 ">
          <div className="fixed  right-[2rem] flex gap-5">
            {/* <button
              className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[60px] mt-5 text-white p-3 hover:scale-105 duration-300 h-[35px]"
              onClick={() => {
                console.log("Download");
              }}
            >
              Download
            </button> */}
            <Link to={"/transactions/select"}>
              <button className="bg-[#2b2d77] align-center flex justify-center text-sm items-center rounded-[9px] px-[60px] mt-5 text-white p-3 hover:scale-105 duration-300 h-[35px]">
                + Add case
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default CurrentCases;
//checks if all tasks are done, some are done or none is done
function getStatus(tasks) {
  let allDone = tasks.every((task) => task.isDone);
  let someInProgress = tasks.some(
    (task) => task.isDone === true && task.isDone !== allDone
  );
  if (allDone) {
    return "done";
  } else if (someInProgress) {
    return "in-progress";
  } else {
    return "to-do";
  }
}

export function Task({
  isDone,
  name,
  id,
  url,
  taskId,
  isSaving,
  setModifiedData,
  mainTaskId,
  setIsDoneCount,
  isDoneCount,
  ...rest
}) {
  const [isTaskDone, setIsTaskDone] = useState(isDone);

  const [saving, setSaving] = useState(isSaving);

  const onChangeCheckbox = () => {
    setSaving(true);
    setIsTaskDone(!isTaskDone);
    try {
      axios
        .patch(api + url + "/" + taskId, {
          isDone: !isTaskDone,
        })
        .then((res) => {
          setSaving(false);
          setModifiedData((prev) => {
            return prev.map((item) => {
              if (item._id == id) {
                return {
                  ...item,
                  isDone: !isTaskDone,
                };
              } else {
                return item;
              }
            });
          });
          if (isTaskDone) {
            setIsDoneCount((prev) => {
              return prev - 1;
            });
          } else {
            setIsDoneCount((prev) => {
              return prev + 1;
            });
          }
        });
    } catch (e) {
      setIsTaskDone(isTaskDone);
      setSaving(false);
    }
  };
  return (
    <div
      onClick={() => {
        onChangeCheckbox();
      }}
      key={[id, isTaskDone, saving]}
      className={`flex border hover:cursor-pointer rounded-2xl justify-between w-full items-center border-[#585d61] px-3 py-[15px]
     
      `}
    >
      <label className="">{name}</label>

      {saving == true ? (
        <BeatLoader color="#a6adbb" />
      ) : (
        <input
          id={id}
          onChange={() => null}
          type="checkbox"
          className="checkbox"
          checked={isTaskDone}
        />
      )}
    </div>
  );
}
