import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import InputLabel from "./InputLabel";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { IoMdAddCircle } from "react-icons/io";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function CompanyForm({
  step1Complete,
  step2Complete,
  setStep1Complete,
  setStep2Complete,
  party1Data,
  setParty1Data,
  party2Data,
  setParty2Data,
  data,
}) {
  const schema = yup
    .object()
    .shape({
      companyName: yup.string().required(),
      incorporationCertificateFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      email: yup.string().email().required(),
      phoneNumber: yup
        .string()
        .matches(/^\+?\d+(?:\s*\d+)*$/, "Must be a valid phone number"),
      companyExtractFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      contactPerson: yup.string().required(),
      directorResolutionFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      companyResolutionFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      officerName: yup.string().required(),
      shareHoldersIdFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      kycFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      transactionType: yup.string().required("Transaction type is required"),
      userType: yup.string().required("Please select a value"),
      isCitizen: yup.boolean().required("Please select a value"),
    })
    .required();
  const {
    register,
    reset,
    getValues,

    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitAndStay = (data) => {
    console.log(data);
    if (step1Complete == false) {
      const form_data = dataToFormData(data, "FIRST");
      setParty1Data((prev) => [...prev, form_data]);
    } else {
      const form_data = dataToFormData(data, "SECOND");
      setParty2Data((prev) => [...prev, form_data]);
    }
  };

  function dataToFormData(data, party) {
    var form_data = new window.FormData();
    form_data.append("party", party);
    form_data.append("companyName", data.companyName);
    form_data.append(
      "incorporationCertificate",
      data.incorporationCertificateFile[0] || null
    );
    form_data.append("email", data.email);
    form_data.append("phone", data.phoneNumber);
    form_data.append("companyExtract", data.companyExtractFile[0] || null);
    form_data.append("contactPerson", data.contactPerson);
    form_data.append(
      "directorResolutionFile",
      data.directorResolutionFile[0] || null
    );
    form_data.append(
      "companyResolutionFile",
      data.companyResolutionFile[0] || null
    );
    form_data.append("officerName", data.officerName);
    form_data.append("shareHoldersIdFile", data.shareHoldersIdFile[0] || null);

    form_data.append("kycDocument", data.kycFile[0]);

    form_data.append("transactionType", data.transactionType);
    form_data.append("type", data.userType);
    form_data.append("citizenshipStatus", data.isCitizen);

    return form_data;
  }
  const onSubmitAndNext = (data) => {
    console.log(data);
    if (step1Complete == false) {
      const form_data = dataToFormData(data, "FIRST");
      setParty1Data((prev) => [...prev, form_data]);
      setStep1Complete(true);
    } else {
      const form_data = dataToFormData(data, "SECOND");
      setParty2Data((prev) => [...prev, form_data]);
      setStep2Complete(true);
      // reset();
    }
  };
  return (
    <form>
      <div className="border-b bg-red border-gray-900/10 mb-10 w-screen container">
        <div className=" grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mx-10 xl:mx-80 bg-[#102232]  rounded-3xl  px-10 pt-10 pb-6 shadow-lg  border border-[#585d61]">
          <>
            <InputField
              isErrored={errors.companyName}
              // message={errors.companyName?.message}
              label="Company name *"
              placeholder="Enter the company name here..."
              register={register}
              name="companyName"
            />

            <InputField
              id={"incorporationCertificateFile"}
              type="file"
              register={register}
              name={"incorporationCertificateFile"}
            />
            <InputLabel
              htmlFor={"incorporationCertificateFile"}
              title={"Attach certificate of incorporation *"}
              message={errors.incorporationCertificateFile?.message}
              hasFile={watch("incorporationCertificateFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the certificate of
                  incorporation, preferably in{" "}
                  <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              isErrored={errors.incorporationCertificateFile}
              subtitle={
                watch("incorporationCertificateFile")?.length > 0
                  ? watch("incorporationCertificateFile")[0].name
                  : "click to upload certificate of incoportation here..."
              }
            />
            <InputField
              label="Email *"
              isErrored={errors.email}
              message={errors.email?.message}
              placeholder="Enter an email here..."
              register={register}
              name="email"
            />
            <InputField
              label="Phone Number *"
              register={register}
              name="phoneNumber"
              placeholder="Enter the party's phone number here..."
              type="text"
              isErrored={errors.phoneNumber}
              message={errors.phoneNumber?.message}
            />
            <InputLabel
              htmlFor={"companyExtractFile"}
              title={"Attach company extract  *"}
              message={errors.companyExtractFile?.message}
              isErrored={errors.companyExtractFile}
              hasFile={watch("companyExtractFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the company extract ,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              subtitle={
                watch("companyExtractFile")?.length > 0
                  ? watch("companyExtractFile")[0].name
                  : "click to upload the company extract here..."
              }
            />
            <InputField
              id={"companyExtractFile"}
              type="file"
              register={register}
              name={"companyExtractFile"}
            />

            <InputField
              label="Contact person *"
              register={register}
              name="contactPerson"
              placeholder="Enter the contact person here.."
              isErrored={errors.contactPerson}
              // message={errors.contactPerson?.message}
            />

            <InputLabel
              htmlFor={"directorResolutionFile"}
              title={"Attach director's resolution *"}
              message={errors.directorResolutionFile?.message}
              isErrored={errors.directorResolutionFile}
              toolTip={
                <p>
                  Please attach an electronic copy of the director's resolution
                  , preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              hasFile={watch("directorResolutionFile")?.length > 0}
              subtitle={
                watch("directorResolutionFile")?.length > 0
                  ? watch("directorResolutionFile")[0].name
                  : "click to upload director's resolution here..."
              }
            />

            <InputField
              id={"directorResolutionFile"}
              type="file"
              register={register}
              name={"directorResolutionFile"}
            />

            <InputLabel
              htmlFor={"companyResolutionFile"}
              title={"Attach company resolution *"}
              message={errors.companyResolutionFile?.message}
              isErrored={errors.companyResolutionFile}
              toolTip={
                <p>
                  Please attach an electronic copy of the company resolution ,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              hasFile={watch("companyResolutionFile")?.length > 0}
              subtitle={
                watch("companyResolutionFile")?.length > 0
                  ? watch("companyResolutionFile")[0].name
                  : "click to upload company resolution here..."
              }
            />

            <InputField
              id={"companyResolutionFile"}
              type="file"
              register={register}
              name={"companyResolutionFile"}
            />

            <InputField
              label="Name of officer *"
              register={register}
              name="officerName"
              placeholder="Enter the name of the officer here.."
              isErrored={errors.officerName}
              // message={errors.officerName?.message}
            />

            <InputField
              register={register}
              type="file"
              name="shareHoldersIdFile"
              id="shareHoldersIdFile"
            />
            <InputLabel
              htmlFor={"shareHoldersIdFile"}
              title={"Attach shareholder's Id's *"}
              message={errors.shareHoldersIdFile?.message}
              isErrored={errors.shareHoldersIdFile}
              hasFile={watch("shareHoldersIdFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copies of the shareholders Ids ,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              subtitle={
                watch("shareHoldersIdFile")?.length > 0
                  ? watch("shareHoldersIdFile")[0].name
                  : "click to upload shareholders Ids  here..."
              }
            />
            <InputLabel
              htmlFor={"kycFile"}
              title={"Attach KYC Documentation *"}
              message={errors.kycFile?.message}
              isErrored={errors.kycFile}
              hasFile={watch("kycFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the KYC document ,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              subtitle={
                watch("kycFile")?.length > 0
                  ? watch("kycFile")[0].name
                  : "click to upload KYC Documentation here..."
              }
            />
            <InputField
              register={register}
              type="file"
              name="kycFile"
              id="kycFile"
            />
            <InputField
              label="Type of Transaction *"
              register={register}
              name="transactionType"
              placeholder="Enter the type of transaction here.."
              isErrored={errors.transactionType}
              message={errors.transactionType?.message}
            />

            <div
              className={`flex flex-col px-3 py-[15px] bg-[#081c2f] rounded-2xl  border ${
                errors.userType ? " border-[red]" : " border-[#585d61]"
              }`}
            >
              <label className="text-xs text-white">Select User Type *</label>
              <div>
                <div className="flex text-white  h-6 items-center justify-between">
                  <label className="flex items-center text-xs gap-2">
                    <p>Buyer</p>

                    <input
                      id="userType"
                      name="userType"
                      type="radio"
                      {...register("userType")}
                      value={"BUYER"}
                      // value={""}
                      className=" appearance-checkbox h-3 w-3 rounded radio: border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>

                  <label className="flex items-center text-xs gap-2">
                    <p>Seller</p>

                    <input
                      id="userType1"
                      name="userType"
                      value={"SELLER"}
                      {...register("userType")}
                      type="radio"
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>
                </div>
                {errors.userType && (
                  <div className="text-red-600 text-xs">
                    {errors.userType?.message}
                  </div>
                )}
              </div>
            </div>

            <div
              className={`flex flex-col px-3 py-[15px] bg-[#081c2f] rounded-2xl  border ${
                errors.isCitizen ? " border-[red]" : " border-[#585d61]"
              }`}
            >
              <label className="text-xs text-white">Citizenship Status *</label>
              <div>
                <div className="flex h-6 text-white items-center justify-between">
                  <label htmlFor="" className="flex items-center text-xs gap-2">
                    <p>Citizen</p>
                    <input
                      id="isCitizen1"
                      name="isCitizen"
                      {...register("isCitizen")}
                      type="radio"
                      value={true}
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>

                  <label className="flex items-center text-xs gap-2">
                    <p>Non-Citizen</p>
                    <input
                      id="isCitizen"
                      name="isCitizen"
                      type="radio"
                      value={false}
                      {...register("isCitizen")}
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>
                </div>
                {errors.isCitizen && (
                  <div className="text-red-600 text-xs">
                    {errors.isCitizen?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between sm:col-span-1 mt- md:col-span-1 xl:col-span-2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmitAndStay)();
                }}
                className="px-7 text-[#43ace1] gap-1 text-xs font-semibold flex items-center"
              >
                <IoMdAddCircle size={19} /> Add More (
                {step1Complete ? party2Data.length : party1Data.length})
              </button>
              <input
                onClick={() => {
                  handleSubmit(onSubmitAndNext)();
                }}
                value={"Next Step"}
                type="button"
                className="cursor-pointer rounded-lg bg-[#2b2d77] text-xs text-white px-12 py-1.5"
              />
            </div>
          </>
          <div className="flex items-center justify-center sm:col-span-1 md:col-span-1 xl:col-span-2">
            <p className="text-center tracking-wide text-xs  text-white font-thin">
              <span className="text-[#3b97c6] font-bold">Attention</span>:
              Please note that by using to this platform you agree to
              <br />
              the
              <span className="text-[#3b97c6] font-medium underline">
                {" "}
                Terms & Conditions
              </span>{" "}
              and{" "}
              <span className="text-[#3b97c6] font-medium underline">
                Privacy Policies
              </span>
            </p>{" "}
          </div>
          {/* <div>{JSON.stringify(party1Data)}</div> */}
        </div>
      </div>
    </form>
  );
}
