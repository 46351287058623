import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const authSlice = createSlice({
  name: "User",
  initialState: {
    name: "",
    email: "",
    role: "",
  },
  reducers: {
    loginSuccess: (state, action) => {
      const { name, email, role } = action.payload;
      state.name = name;
      state.email = email;
      state.role = role;
    },
    logout: (state, action) => {
      return { ...state, name: "", role: "", email: "" };
    },
  },
});

export const { loginSuccess, logout } = authSlice.actions;
export const authReducer = authSlice.reducer;
