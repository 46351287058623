import React, { useState } from "react";
import { LuPencil, LuTrash } from "react-icons/lu";
import { IoSaveOutline } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdDeleteOutline, MdOutlineCancel } from "react-icons/md";
import { useDispatch } from "react-redux";
import { toSnakeCase } from "../../utils/helpers";
import { setTasksData } from "../../store/transactionSlice";
import { toast } from "sonner";
import { TrashIcon } from "@heroicons/react/24/outline";
import DateInput from "../DateInput";

function Task({ title, id, setTasks, tasks, subTasks, displayDateRange }) {
  const [isEditing, setEditing] = useState(false);
  const [input, setInput] = useState(title);
  const [subTaskInput, setSubTaskInput] = useState("");
  const [showInput, setShowInput] = useState(false);
const dispatch  = useDispatch();
  console.log("taskssskkkkkkkkkkkkkkkkkkkkk")
  console.log(tasks)
  console.log("taskssskkkkkkkkkkkkkkkkkkkkk")

  const handleTaskEdit = (e) => {
    setInput(e.target.value);
  };
  const handleSave = () => {
    setEditing(false);
    if (input.length > 0) {
      const updatedTasks = tasks.map(task => {
        if (task.id === id) {
          return {
            ...task, // Copy the original task object
            label: input, // Update the label property
            name: toSnakeCase(input) // Update the name property if needed
          };
        }
        return task; // Return unchanged task if the ID doesn't match
      });
      setTasks([...updatedTasks])
    }
  };
  const addToSubTasks = () => {
    const taskIndex = tasks.findIndex(task => task.id === id);
    if (taskIndex === -1) {
      return; // Task not found, handle error or return early
    }
    
    const updatedTasks = [...tasks]; // Create a copy of the tasks array
    const updatedTask = { ...updatedTasks[taskIndex] }; // Copy the specific task object
    
    const subTasks = updatedTask.subTasks || []; // Get the existing subTasks array or initialize to an empty array
    const subTask = {
      isDone: false,
      name: subTaskInput,
      id: Date.now() + "" + subTasks.length,
    };
  
    // Update the subTasks array with the new subTask
    const newSubTasks = [...subTasks, subTask];
    
    // Update the task object with the new subTasks array
    updatedTask.subTasks = newSubTasks;
    
    // Update the tasks array with the modified task object
    updatedTasks[taskIndex] = updatedTask;
    
    // Update the state with the updated tasks array
    setTasks(updatedTasks);
  };
  
  const deleteSubTask = (subTaskId) => {
    const taskIndex = tasks.findIndex(task => task.id === id);
    if (taskIndex === -1) {
      return; // Task not found, handle error or return early
    }
  
    const updatedTasks = [...tasks]; // Create a copy of the tasks array
    const updatedTask = { ...updatedTasks[taskIndex] }; // Copy the specific task object
    
    const subTasks = updatedTask.subTasks || []; // Get the existing subTasks array or initialize to an empty array
  
    // Filter out the subTask with the provided subTaskId
    const newSubTasks = subTasks.filter(subTask => subTask.id !== subTaskId);
  
    // Update the subTasks array of the copied task object with the newSubTasks
    updatedTask.subTasks = newSubTasks;
  
    // Update the tasks array with the modified task object
    updatedTasks[taskIndex] = updatedTask;
  
    // Update the state with the updated tasks array
    setTasks(updatedTasks);
  };

  
  
  const editSubTask = (subTaskId, newName) => {
    const taskIndex = tasks.findIndex(task => task.id === id);
    if (taskIndex === -1) {
      return; // Task not found, handle error or return early
    }
  
    const updatedTasks = [...tasks]; // Create a copy of the tasks array
    const updatedTask = { ...updatedTasks[taskIndex] }; // Copy the specific task object
    
    const subTasks = updatedTask.subTasks || []; // Get the existing subTasks array or initialize to an empty array
  
    // Map over the subTasks array to find the subTask to edit and update its name
    const newSubTasks = subTasks.map(subTask => {
      if (subTask.id === subTaskId) {
        // Return a new object with the updated name
        return {
          ...subTask,
          name: newName
        };
      }
      return subTask; // Return unchanged subTask if the ID doesn't match
    });
  
    // Update the subTasks array of the copied task object with the newSubTasks
    updatedTask.subTasks = newSubTasks;
  
    // Update the tasks array with the modified task object
    updatedTasks[taskIndex] = updatedTask;
  
    // Update the state with the updated tasks array
    setTasks(updatedTasks);
  };
  
  const deleteTask = () => {
    const newTasks = tasks.filter((task) => task.id !== id);
    setTasks(newTasks);
  };

  // const set
  // console.log(subTasks);
  return (
    <div className=" px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative border-dashed">
      <div className="flex  justify-between items-center">
        {isEditing ? (
          <>
            <input
              onChange={handleTaskEdit}
              type="text"
              className="text-sm w-full mr-8 text-[#f2f2f2] bg-transparent focus:outline-none placeholder:text-xs"
              defaultValue={title}
            />
            <button
              onClick={() => {
                handleSave();
              }}
              className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
            >
              <IoSaveOutline />
            </button>
          </>
        ) : (
          <>
            <label className="text-sm text-white">{title}</label>
            <div className="flex gap-1">
              <button
                className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
                onClick={() => {
                  setEditing(true);
                }}
              >
                <LuPencil />
              </button>
              <button
                onClick={() => {
                  deleteTask();
                }}
                className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
              >
                <MdDeleteOutline className="text-red-500" size={18} />
              </button>
            </div>
          </>
        )}
      </div>

      <div>
        {subTasks.map((subTask) => {
          console.log(subTask);
          return (
            <SubTasks
              displayDateRange={displayDateRange}
              key={subTask.id}
              subTask={subTask}
              editSubTask={editSubTask}
              deleteSubTask={deleteSubTask}
            />
          );
        })}
      </div>
      {showInput && (
        <div className="flex justify-between items-center">
          <input
            onChange={(e) => setSubTaskInput(e.target.value)}
            type="text"
            value={subTaskInput}
            className="text-xs placeholder:text-xs w-full mr-8 text-[#f2f2f2] bg-transparent focus:outline-none"
            placeholder="Add new sub-task"
          />
          <button
            className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
            onClick={() => {
              if (subTaskInput !== "") {
                setSubTaskInput("");
                addToSubTasks();
                setShowInput(false);
              } else {
                toast.error("Sub tasks cannot be empty");
              }
              // deleteoption(option.id);
            }}
          >
            <IoSaveOutline />
          </button>
          <button
            className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
            onClick={() => {
              setShowInput(false);
              setSubTaskInput("");
            }}
          >
            <MdOutlineCancel size={18} />
          </button>
        </div>
      )}
      <button
        onClick={() => setShowInput(true)}
        className=" cursor-pointer  mt-4  rounded-lg items-center hover:border-transparent flex hover:bg-[#302a74] hover:text-[#f2f2f2] py-1.5 px-2"
      >
        <IoIosAddCircleOutline size={20} color="#42abdf" />
        <span className="text-[#42abdf] ml-2 text-sm font-semibold">
          Add more
        </span>
      </button>
    </div>
  );
}

export default Task;

const SubTasks = ({
  subTask,
  deleteSubTask,
  editSubTask,
  displayDateRange,
}) => {
  const [isEditing, setEditing] = useState(false);
  const [input, setInput] = useState(subTask.name);

  return (
    <div className="flex justify-between items-center">
      {displayDateRange && <DateInput />}

      {isEditing ? (
        <input
          type="text"
          className="text-xs w-full outline-none bg-transparent"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      ) : (
        <div className="text-[#868f9c] text-xs truncate">{subTask.name}</div>
      )}
      <div className="flex">
        <button
          className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
          onClick={() => {
            setEditing(!isEditing);
            if (isEditing) {
              editSubTask(subTask.id, input);
            }
            // deleteSubTask(subTask.id);
          }}
        >
          {isEditing ? (
            <IoSaveOutline />
          ) : (
            <LuPencil className="text-blue-500" size={18} />
          )}
        </button>
        <button
          className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
          onClick={() => {
            if (!isEditing) {
              deleteSubTask(subTask.id);
            } else {
              setEditing(false);
            }
          }}
        >
          {isEditing ? (
            <MdOutlineCancel size={18} />
          ) : (
            <MdDeleteOutline className="text-red-500" size={18} />
          )}
        </button>
      </div>
    </div>
  );
};
