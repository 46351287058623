import React from "react";
import backgroundImg from "../../images/home-img.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BiAlarm } from "react-icons/bi";
import AdminBanner from "../../components/AdminBanner";
import { useCookie } from "react-use";
import { useIndividualCaseQuery } from "../../hooks/useIndividualCaseQuery";
function Profile() {
  const [values] = useCookie("user");

  const userCookie = JSON.parse(values);

  const { data } = useIndividualCaseQuery();
  return (
    <div className="p-8 mt-[96px] lg:mt-0 container mx-auto relative">
      <AdminBanner />
      <div className="mt-8">
        <h5 className="font-semibold text-white text-xl uppercase">
          Your information
        </h5>
        <div className="pl-2 md:pl-12 text-white">
          <div className="flex gap-3 flex-col mt-5">
            <InfoCard title={"Full names"} value={userCookie?.name} canUpdate />
            <InfoCard
              title={"Phone number"}
              value={"(+267)  71 111 111"}
              canUpdate
            />
            <InfoCard
              title={"Email address"}
              value={userCookie?.email}
              canUpdate
            />
            <InfoCard title={"Law firm"} value={"Company & Co"} canUpdate />
            <InfoCard title={"Active cases"} value={data?.length} />
            <InfoCard title={"Closed cases"} value={"0"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

function InfoCard({ title, value, canUpdate = false }) {
  return (
    <div className="flex  justify-between md:w-10/12">
      <div>
        <div className="text-white">{title}</div>
        <div className="text-[#404f5e] font-medium">{value}</div>
      </div>
      {canUpdate && <div className="font-medium text-[#eab566]">Update</div>}
    </div>
  );
}
