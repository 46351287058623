import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../images/logo.png";
import loginImage from "../../images/loginImage.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InputField from "../../components/InputField";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "sonner";
import { useCookie } from "react-use";
import axios from "axios";
import { useQueryParams } from "../../hooks/useQueryParams";
const navigation = [
  //   { name: "Product", href: "#" },
  //   { name: "Features", href: "#" },
  //   { name: "Marketplace", href: "#" },
  //   { name: "Company", href: "#" },
];
export const API = process.env.REACT_APP_BACKEND_API_URL;
export default function ResetPassword() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const query = useQueryParams();

  const id = query.get("id");
  const token = query.get("token");
  const schema = yup
    .object()
    .shape({
      // new_password: yup.().required(),
      // confirm_password: yup.string().required(),
    })
    .required();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  console.log();

  const submit = (data) => {
    const newPassword = data.new_password;
    setLoading(true);
    if (newPassword !== "" && data.new_password === data.confirm_password) {
      axios
        .post(`${API}/api/v1/auth/reset-password/${id}/${token}`, {
          password: newPassword,
          passwordConfirm: newPassword,
        })
        .then(() => {
          setLoading(false);
          history.push("/auth/login");
          toast.success("Your password has been changed successfull");
        })
        .catch((err) => {
          setLoading(false);
          toast.error("An error occured", { description: "Email not found" });
        });
    } else {
      setLoading(false);
      toast.success(
        "Your passwords do not match. Please verify that they are the same."
      );
    }
  };

  return (
    <div className="flex flex-col h-[100vh]  relative  items-center">
      <header className="absolute container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-12 w-auto" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>

          <div className="hidden lg:items-center lg:gap-4 lg:flex lg:flex-1 lg:justify-end">
            <Link
              to="/auth/login"
              className="rounded-xl bg-[#2B2D77] px-8 py-1.5  text-sm font-  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              SIGN IN
            </Link>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      {/*container */}

      <div className="container flex flex-col justify-center  items-center h-[100vh]">
        <label className="text-white  md:mb-4 text-2xl md:text-3xl ">
          Reset Password ?
        </label>

        <div className="text-white   md:w-1/2 lg:w-1/3 mx-6 gap-4 md:gap-4  flex-col flex ">
          <p className="text-center text-[1rem]  my-2">
            Please enter a new password for your account.
          </p>

          <InputField
            label="New Password"
            type="password"
            register={register}
            name="new_password"
            placeholder="please enter your new password here..."
          />

          <InputField
            label="Confirm Password"
            register={register}
            type="password"
            name="confirm_password"
            placeholder="please enter password here..."
          />

          <button
            onClick={handleSubmit(submit)}
            className="bg-[#0e518b] hover:scale py-3 rounded-xl text-md"
          >
            {loading ? (
              <span className="loading loading-bars loading-sm"></span>
            ) : (
              "Send"
            )}
          </button>

          <Link
            to="/auth/login"
            className="text-center my-4 text-md  cursor-pointer hover:underline"
          >
            Return to login
          </Link>
        </div>
      </div>
    </div>
  );
}
