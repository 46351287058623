import { create } from "zustand";

export const useTransactionCreationData = create((set) => (
  {
  name: "",
  type: "",
  reportType:"",
  description: "",
  sellerCount: 0,
  buyerCount: 0,
  forms: [],
  previewStep: 0,
  editId: 0,
  isOverride: false,
  editIndex: -1,
  setPreviewStep: (step) => set((state) => ({ ...state, previewStep: step }

  )),

  resetData: () =>
    set({
      name: "",
      type: "",
      reportType:"",
      description: "",
      sellerCount: 0,
      buyerCount: 0,
      forms: [],
      editId: 0,
      previewStep: 0,
      isOverride: false,
      editIndex: -1,
    }),
  setEditIndex: (index) => set((state) => ({ ...state, editIndex: index })),
  setEditIndex: (index) => set((state) => ({ ...state, editIndex: index })),
  getEditIndex: () => this.editIndex,
  setIsOveride: (isOverride) => set((state) => ({ ...state, isOverride })),
  toggleIsOverride: () =>
    set((state) => ({ ...state, isOverride: !state.isOverride })),
  setEditId: (id) => set((state) => ({ ...state, editId: id })),
  resetForms: () =>
    set((state) => ({ ...state, forms: [], sellerCount: 0, buyerCount: 0 })),
  addBuyers: (form) => {
    set((state) => {
      let forms = state.forms;
      forms.unshift(form);
      return { ...state, forms, buyerCount: state.buyerCount + 1 };
    });
  },
  addSellers: (form) => {
    set((state) => {
      let transaction = state.forms[state.forms.length - 1];
      let forms = state.forms;
      forms.pop();
      forms.push(form);
      forms.push(transaction);
      return { ...state, forms, sellerCount: state.sellerCount + 1 };
    });
  },
  setForms: (forms) => set((state) => ({ ...state, forms })),
  pushToForm(form) {
    set((state) => {
      let forms = state.forms;
      forms.push(form);
      console.log(form);
      return { ...state, forms };
    });
  },
  unshiftToForm(form) {
    set((state) => {
      let forms = state.forms;
      forms.unshift(form);
      return { ...state, forms };
    });
  },

  setName(name) {
    set((state) => ({ ...state, name }));
  },
  setDescription(description) {
    set((state) => ({ ...state, description }));
  },
  setType(type) {
    set((state) => ({ ...state, type }));
  },
  setReportType(reportType) {
    set((state) => ({ ...state, reportType}));
  },
  setSellerCount(sellerCount) {
    set((state) => ({ ...state, sellerCount }));
  },
  setBuyerCount(buyerCount) {
    set((state) => ({ ...state, buyerCount }));
  },
}));
