// InputField.js
import axios from "axios";

import React, { useEffect, useState } from "react";
// import { useStartTyping } from "react-use";
// import inputP
const API = process.env.REACT_APP_BACKEND_API_URL;
const InputField = ({
  label,
  type,
  name,
  placeholder,
  onChange,
  value,
  optional,
  className,
  visible,
  id,
  isErrored,
  message,
  register,
  rightNode,
  setValue,
  defaultValue,
  disabled,
}) => {
  const [hasError, setHasError] = useState(isErrored);
  // useStartTyping(() => alert("You started typing!"));

  useEffect(() => {
    setHasError(isErrored);
  }, [isErrored]);

  // const date = "";
  const [displayValue, setDisplayValue] = useState(value);

  useEffect(() => {
    if (type == "date") {
      if (new Date(defaultValue) == "Invalid Date") {
        let date = new Date().toISOString().split("T")[0];
        setDisplayValue(date);
      } else {
        let date = new Date(defaultValue).toISOString().split("T")[0];
        setDisplayValue(date);
      }
    }
  }, [defaultValue]);
  // useEffect(() => {

  return (
    <div
      className={`flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border ${
        hasError ? "border-[red]" : "border-[#585d61]"
      }  ${type === "file" && " hidden "}`}
    >
      <label className="text-xs mb-1 text-white">{label}</label>
      {register === undefined ? (
        <input
          id={id}
          className={
            "mt-1 text-xs text-[#a6adbb] bg-transparent placeholder-[#475663] active:border-transparent focus:outline-none"
          }
          disabled={disabled}
          defaultValue={displayValue}
          // {...register(name, { required: true })}
          type={type}
          value={displayValue}
          // name={name}
          placeholder={placeholder}
        />
      ) : (
        <input
          {...register(name)}
          id={id}
          key={value}
          disabled={disabled}
          className={
            "mt-1 text-xs bg-transparent text-[#a6adbb] placeholder-[#475663] active:border-transparent focus:outline-none"
          }
          type={type}
          defaultValue={displayValue}
          onChange={(e) => {
            if (type === "file") {
              var form_data = new window.FormData();
              form_data.append("file", e.target.files[0]);
              console.log(form_data);
              axios
                .post(`${API}/api/v1/files`, form_data, {
                  headers: { "Content-Type": "multipart/form-data" },
                })
                .then(async (res) => {
                  setValue(name, res.data.data.url);
                  // setNewValue(name);
                  // console.log(res.data.data.url);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }}
          placeholder={
            type === "file" ? "click to upload file here..." : placeholder
          }
          {...(type !== "file" && {
            ...register(name, {
              validate: (name) => {
                if (!optional && !name) {
                  // If value is empty, check if there's an optional value
                  setHasError(true);
                }
                // If there's a value, it's considered valid
                if (!optional && name) {
                  // If value is empty, check if there's an optional value
                  setHasError(false);
                }
              },
            }),
          })}
        />
      )}

      {hasError && (
        <div className="text-[red] first-letter:capitalize  text-xs mt-1">
          {message || "Required"}
        </div>
      )}
    </div>
  );
};

export default InputField;
