import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const initialState = {
  _id:"",
  bank:"",
  registrationAmount:0,
  reportName:"",
  bankRef: "",
  createdBy: "",
  comments: "",
  reportDescription: "",
  reportType: "",
  status: "",
  templateSchema: [],
  validation: []
};
const reportSlice = createSlice({
  name: "Report",
  initialState,
  reducers: {
    setReport: (state, action) => {
      const {registrationAmount,reportDescription,reportName,_id,bank,bankRef, comments,status,createdBy,reportType,templateSchema,validation} = action.payload;
    state._id=_id;
    state.bank = bank;
    state.registrationAmount= registrationAmount;
    state.reportName= reportName;
    state.bankRef= bankRef;
    state.createdBy=createdBy;
    state.comments= comments;
    state.reportDescription= reportDescription;
    state.reportType= reportType;
    state.status= status;
    state.templateSchema= templateSchema;
    state.validation= validation;
    },

    clearReport: (state) => {
      // Reset the state to initial values
      Object.assign(state, initialState);
    }
  },
});

export const {setReport,clearReport} = reportSlice.actions;
export const reportReducer = reportSlice.reducer;
