import React from "react";
import { Route, Switch } from "react-router-dom";
import NewTemplate from "./NewTemplateV2";
import NewTemplate1 from "./NewTemplate";
import SavedTemplates from "./SavedTemplate";
import FormBuilder2 from "../onboarding-views/FormBuilder2";
import FormBuilder3 from "../onboarding-views/FormBuilder3";
// import EditTemplate from "./EditTemplate";

export const TEMPLATE_PREFIX_PATH = "/templates";

const TemplateViews = () => {
  return (
    <Switch>
      <Route
        path={TEMPLATE_PREFIX_PATH + "/saved-templates"}
        component={SavedTemplates}
      />
      <Route
        path={TEMPLATE_PREFIX_PATH + "/new-templates/build-kyc-form"}
        component={FormBuilder2}
      />
      <Route
        path={TEMPLATE_PREFIX_PATH + "/new-templates/build-data-form"}
        component={FormBuilder3}
      />
      <Route
        path={TEMPLATE_PREFIX_PATH + "/new-templates"}
        component={NewTemplate}
      />

      <Route
        path={TEMPLATE_PREFIX_PATH + "/new-templates1"}
        component={NewTemplate1}
      />

      <Route
        path={TEMPLATE_PREFIX_PATH + "/new-templates"}
        component={NewTemplate}
      />
    </Switch>
  );
};

export default TemplateViews;
