import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
// import DayString from "./DayString";
// import Day from "./Day";
import { BiChevronDown, BiChevronLeft, BiChevronRight } from "react-icons/bi";

function KeyDates() {
  const days = [];

  const [selectedDate, setSelectedDate] = useState(moment());

  let month = moment().format("MMMM");

  let lastDay = moment().month(month).endOf("month").format("D");
  // set the next month
  function nextMonth() {
    var current = moment().month(month).add(1, "month");
    let format =
      current.format("D") +
      " " +
      current.format("MMMM") +
      " " +
      current.format("YYYY");
    setSelectedDate(format);
  }
  //set the previous month
  function prevMonth() {
    var current = moment().month(month).add(-1, "month");
    let format =
      current.format("D") +
      " " +
      current.format("MMMM") +
      " " +
      current.format("YYYY");
    setSelectedDate(moment(format));
    // console.log(moment(format));
  }

  const dayOfWeekNumber = moment(selectedDate).startOf("month").day();
  const dayOfWeekNumberAdjusted = dayOfWeekNumber === 0 ? 7 : dayOfWeekNumber;

  const lastDayOfWeekNumber = moment(selectedDate).endOf("month").day();
  for (let index = 0; index < dayOfWeekNumberAdjusted; index++) {
    days.push(" ");
  }
  for (let index = 0; index < parseInt(lastDay); index++) {
    days.push((index + 1).toString());
  }
  for (let index = 0; index < Math.abs(lastDayOfWeekNumber - 6); index++) {
    days.push(" ".toString());
  }
  let dayStrings = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let today = moment().format("D");

  const activities = [
    {
      id: 1,
      title: "Burs Submission",
      bg: "#00ff00",
      color: "#000",
      startDate: "2/04/2024",
      endDate: "2/09/2024",
      top: "100",
      left: "100",
    },
  ];
  //   console.log(moment(activities[0].startDate).format("DD/MMMM/YYYY"));
  return (
    <div className="p-8 container mx-auto ">
      {/* <button onClick={() => nextMonth()}>Next</button> */}
      <div className="flex justify-between items-center mb-8">
        <div className="flex text-white font-medium gap-5 ">
          <div className="uppercase">Today`s date</div>
          <div className="uppercase">{moment().format("Do MMM  YY")}</div>
        </div>
        <div className="flex gap-4">
          <div className="  flex rounded-[10px] justify-center items-center gap-2  px-7 py-2 text-white  text-sm bg-[#102232]">
            Download
            <BiChevronDown size={25} />
          </div>
          <div className="  flex rounded-[10px] justify-center items-center gap-2  px-7 py-2 text-white   text-sm bg-[#102232]">
            Month
            <BiChevronDown size={25} />
          </div>
        </div>
      </div>
      <div className="relative rounded-[25px]">
        <div
          className={` absolute w-[1103px] text-xs uppercase left-[700px ] ml-24 top-16 text-white font-medium  pl-4 rounded-xl py-3 z-20 bg-[#243f57] `}
        >
          Burs Submission
        </div>
        <div
          className={` absolute w-[900px] left-[700px ] text-xs uppercase ml-[300px] top-28 text-white font-medium  pl-4 rounded-xl py-3 z-20 bg-[#6b6bff] `}
        >
          Deeds Office
        </div>
        <div
          className={` absolute w-[200px] text-xs left-[700px ] uppercase ml-[100px]  top-[210px] text-white font-medium  pl-4 rounded-xl py-3 z-20 bg-[#243f57] `}
        >
          Property Purchases
        </div>
        <div
          className={` absolute w-[200px] text-xs left-[700px ] uppercase ml-[100px]  top-[260px] text-white font-medium  pl-4 rounded-xl py-3 z-20 bg-[green] `}
        >
          Property Purchases
        </div>
        <div
          className={` absolute w-[200px] text-xs left-[700px ] uppercase ml-[100px]  top-[310px] text-white font-medium  pl-4 rounded-xl py-3 z-20 bg-[#243f57] `}
        >
          Property Purchases
        </div>
        <div
          className={` absolute w-[650px] text-xs left-[700px ] uppercase ml-[300px]  top-[440px] text-white font-medium  pl-4 rounded-xl py-3 z-20 bg-[#ffc36b] `}
        >
          Deed transfer
        </div>
        <div className="flex text-white font-medium flex-wrap rounded-[25px]  ">
          {dayStrings.map((el, index) => {
            return (
              <DayString
                day={days[index]}
                key={el}
                roundTopLeft={index === 0}
                roundTopRight={index === 6}
                title={el}
                bg={
                  today == el
                    ? "bg-[#171e24]"
                    : (index + 1) % 6 == 1
                    ? "bg-[#394958]"
                    : "bg-[#52606d]"
                }
              />
            );
          })}
          {days.slice(7).map((el, i) => (
            <Day
              title={el}
              bg={
                today == el
                  ? "bg-[#171e24]"
                  : (i + 1) % 7 == 1 || (i + 1) % 7 == 0
                  ? "bg-[#394958]"
                  : "bg-[#52606d]"
              }
              key={el + i}
            />
          ))}
        </div>
        <div className="flex  -mt[200px]"></div>
      </div>
    </div>
  );
}
function DayString({ title, bg, day, roundTopLeft, roundTopRight }) {
  return (
    <div
      className={
        title +
        " " +
        bg +
        ` uppercase border-r-[2px] border-gray-500 border-b-[2px]  w-[200px] h-[200px] p-3 relative ${
          roundTopLeft ? "rounded-ss-[30px]" : ""
        }  ${roundTopRight ? "rounded-se-[30px]" : ""}`
      }
    >
      {title}
      <div className="absolute right-8 bottom-8">{day}</div>
    </div>
  );
}
function Day({ title, bg, roundBottomLeft, roundBottomRight }) {
  return (
    <div
      className={
        bg +
        ` uppercase border-r-[2px] border-gray-500 border-b-[2px]  w-[200px] h-[200px] p-3 relative ${
          roundBottomLeft ? "rounded-es-[30px]" : ""
        }  ${roundBottomRight ? "rounded-se-[30px]" : ""}`
      }
    >
      <div className="absolute right-5 bottom-4 ">{title}</div>
    </div>
  );
}
export default KeyDates;
