import { create } from "zustand";
// import { data } from "../data";
export const useModal = create((set) => ({
  isOpen: false,
  title: "Loading...",
  description: "Please wait...",
  closeModal: () => set({ isOpen: false }),
  showModal: (title, description) =>
    set({ isOpen: true, title: title, description: description }),
}));
export const useDialog = create((set) => ({
  isOpen: false,
  closeModal: () => set({ isOpen: false }),
  title: "Success...",
  description: "Operation was successful",
  showModal: (title, description) =>
    set({ isOpen: true, title: title, description: description }),
}));
