import React, { useEffect, useState, useRef } from "react";
import { RiBriefcase3Line } from "react-icons/ri";
import { RiShieldUserLine } from "react-icons/ri";
import { Avatar, Typography } from "@material-tailwind/react";
import { AiOutlineHolder } from "react-icons/ai";
import InputField from "./InputField";
import caseImg from "./case.svg";
import InputLabel from "./InputLabel";
import { IoFileTrayOutline } from "react-icons/io5";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CiPaperplane } from "react-icons/ci";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { AiOutlineCloudDownload } from "react-icons/ai";
import axios from "axios";
import { useIndividualCaseQuery } from "../hooks/useIndividualCaseQuery";
import moment from "moment/moment";
import { useCookie, useLocation } from "react-use";
import { APP_PREFIX_PATH } from "../views/app-views";
import { OnBoard_PREFIX_PATH } from "../views/onboarding-views";
import { BiPlug, BiPlus, BiSend, BiSolidChevronDown } from "react-icons/bi";
import { useQueryParams } from "../hooks/useQueryParams";
import { CiFileOn } from "react-icons/ci";
import { useTasksQuery } from "../hooks/useTasksQuery";
import { MdHeight } from "react-icons/md";
// import { useIndividualCaseQuery } from "../../hooks/useIndividualCaseQuery";
import { IoIosArrowDown } from "react-icons/io";
import { clearTransaction, setTransaction } from "../store/transactionSlice";

const Tabs = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [casefiles, setCaseFiles] = useState([]);
  const [caseInProgress, setCasesInProgress] = useState([]);
  const { data, isFetched, isFetching } = useTasksQuery();
  const [caseDetails, setCaseDetails] = useState({
    title: "",
    id: 0,
    type: "",
    propertyInformation: "",
    endDate: new Date(),
    startDate: new Date(),
    name1st: "",
    name2st: "",
    color: "",
    isActive: false,
  });
  const [casesList, setCasesList] = useState([]);
  const [casesPending, setPendingCount] = useState(0);
  const [casesInProgress, setInProgressCount] = useState(0);
  const [casesDone, setDoneCount] = useState(0);
  const [tasksList, setTaskList] = useState([]);
  const messageRef = useRef(null);
  const queryParams = useQueryParams();
  const history = useHistory();
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
  const handleCaseDetails = (item) => {
    setCaseDetails(item);

    let fileObject = item.transactionData.formSchema?.filter((item) => {
      return item.type === "file" && item.value;
    });

    let fileObject1 = [];
    item.kyc.forEach((kycObj) => {
      kycObj.formSchema.forEach((keyfile) => {
        if (keyfile.type === "file" && keyfile.value) {
          fileObject1.push(keyfile);
        }
      });
    });

    if (fileObject.length > 0 || (fileObject1 && fileObject1.length > 0)) {
      // Do something with the file objects

      const combinedFiles = [...fileObject, ...(fileObject1 || [])];
      setCaseFiles(combinedFiles);
      console.log("File objects found:", combinedFiles);
      return combinedFiles;
    } else {
      console.log("No file objects found.");
      return null;
    }
  };

  useEffect(() => {
    axios
      .get("https://collins-chilisa.testlive.space/api/v1/transactions")
      .then((response) => {
        if (queryParams == "") {
          setCasesList(response.data.data);
        }

        if (queryParams == "case-progress=pending") {
          const pending = response.data.data.filter((item) => {
            return item.status == "to-do";
          });
          setCasesList(pending);
        }
        if (queryParams == "case-progress=in-progress") {
          const inProgress = response.data.data.filter((item) => {
            return item.status === "in-progress";
          });
          setCasesList(inProgress);
        }
        if (queryParams == "case-progress=done") {
          const done = response.data.data.filter((item) => {
            return item.status == "done";
          });
          setCasesList(done);
        }
      });
  }, [queryParams]);

  useEffect(() => {
    axios
      .get("https://collins-chilisa.testlive.space/api/v1/transactions")
      .then((response) => {
        const allCases = response.data.data;

        const pending = response.data.data.filter((item) => {
          return item.status === "to-do";
        });
        setPendingCount(pending?.length || 0);

        const inProgress = response.data.data.filter((item) => {
          return item.status === "in-progress";
        });
        setInProgressCount(inProgress?.length || 0);

        const done = response.data.data.filter((item) => {
          return item.status === "done";
        });
        setDoneCount(done?.length || 0);

        setCasesInProgress([
          {
            title: "All Cases",
            id: 0,
            color: "22eafe",
            link: "",
            count: allCases?.length || 0,
          },
          {
            title: "Pending",
            id: 1,
            color: "eace13",
            link: "?case-progress=pending",
            count: pending?.length || 0,
          },

          {
            title: "In Progress",
            id: 2,
            color: "59ff29",
            link: "?case-progress=in-progress",
            count: inProgress?.length || 0,
          },

          {
            title: "Done /Completed",
            id: 3,
            color: "f63f9b",
            link: "?case-progress=done",
            count: done?.length || 0,
          },
        ]);
      });
  }, []);






  useEffect(() => {
    let tasks = [];
    if (data == null) return;
    data?.map((item) => {
      return item.tasks.map(async (task) => {
       
      
        tasks.push({
          ...task,
          status:"Pending",
          caseName: item.caseName,
          description: item.description,
          caseId: item.caseId,
          collectionName: item.collectionName,
        });

        

      });
    });

    
const currentDate = new Date(); // Current date and time
const fourDaysInMillis = 4 * 24 * 60 * 60 * 1000; // Four days in milliseconds

const filteredTasks = tasks.filter(task => {
    const endDate = new Date(task.endDate); // Convert task's end date to Date object
    const timeDifference = endDate - currentDate; // Calculate time difference in milliseconds
    return timeDifference >= 0 && timeDifference <= fourDaysInMillis; // Filter tasks where end date is between current date and current date + 4 days
});
  
 console.log("A")
 console.log(filteredTasks);
 setTaskList(filteredTasks);
 console.log("A")
  }, [data]);











































  const [values] = useCookie("user");
  const userCookie = JSON.parse(values);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const Content1 = () => {
    const DisplayDate = (timestamp) => {
      const date = new Date(timestamp);

      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    const individualCases = useIndividualCaseQuery();

    const schema = yup
      .object()
      .shape({
        name: yup.string().required(),
      })
      .required();
    const {
      register,
      reset,
      getValues,
      handleSubmit,
      watch,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });

    const [showFiles, setShowFiles] = useState(false);
    return (
      <div className="text-white text-2xl grid sm:grid-flow-row sm:grid-cols-1 md:grid-cols-5 xl:grid-cols-8   md:gap-4 gap-4">
        {/* In Progress Cases */}

        <div className="bg-[#102232] border border-1 md:col-span-2 rounded-[20px]  md:rounded-[30px]   border-[#6e6f6f]">
          <div className=" border-b border-b-[#6e6f6f] text-[16px pl-6 p-3 mb-4">
            Progress
          </div>

          {caseInProgress.map((item, index) => (
            <Link replace key={item.id} to={item.link}>
              <div
                className={`flex  px-2 pt-1 gap-3 rounded-[16px] pb-5 mx-4 relative mt-1 hover:bg-[#243f57]   ${
                  (queryParams.get("case-progress") == null && index == 0) ||
                  item.link.includes(queryParams.get("case-progress"))
                    ? "bg-[#243f57]"
                    : ""
                }`}
              >
                <div
                  style={{ backgroundColor: `#${item.color}` }}
                  className={`mt-2 h-6 0 w-6 rounded-full flex items-center justify-center`}
                >
                  <p className="text-sm text-black font-semibold">{item.id}</p>
                </div>
                <div>
                  <p className="text-[14px] font-medium">{item.title}</p>
                  <p className="text-xs text-[#5b646d] font-medium">
                    <span className="text-xs font-bold pr-1">
                      {item?.count}
                    </span>
                    Cases files currently opened
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        {/* Pending Cases */}

        <div className="bg-[#102232] overflow-hidden lg:max-h-[380px] overflow-y-scroll no-scrollbar border lg:max-h-[380px] border-1 md:col-span-3  rounded-[20px]   pb-4 md:rounded-[30px]  border-[#6e6f6f]">
          <div className=" flex items center gap-3 border-b border-b-[#6e6f6f] text-[16px pl-6 p-3 mb-4">
            <RiBriefcase3Line size={34} /> Cases
          </div>

           {casesList?.length !== 0 ? (
            casesList?.slice(0, 4).map((item, i) => (
              <div
                key={item.id}
                onClick={() => {
                  // dispatch(clearTransaction());
                  // dispatch(setTransaction({...item,onGoingTransaction:true,tasksData:{...item.transactionData,onGoingTransaction:true,formType:"tasks",formSchema:item?.tasks}}));
                  // handleCaseDetails(item)
                  // document.getElementById("my_modal_4").showModal();
                  history.push(
                    `/app/cases/${item?._id}?collectionName=${item?.collectionName}`
                  );
                }}
                className={`flex items-center cursor-pointer py-3 mx-4 mt-1 rounded-2xl hover:bg-[#243f57]  ${
                  i == 0 ? "bg-[#243f57]" : ""
                }`}
              >
                <div className="flex items-center">
                  <AiOutlineHolder size={24} />
                  <div className="w-10 rounded">
                    <img
                      className="h-10 bg-center bg-no-repeat"
                      src={caseImg}
                      alt="Tailwind-CSS-Avatar-component"
                    />
                  </div>
                </div>
                <div className="flex items-center  px-2 gap-2 rounded-[16px] mx-4 relative">
                  <div>
                    <p className="text-[14px] font-medium text-white">
                      {item.transactionName}
                    </p>
                    <p className="text-xs text-[#5b646d]">
                      <span className="text-xs font-semibold">
                        {item.description}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex flex-col text-[#606262] gap-3 justify-center items-center pt-10 ">
              <IoFileTrayOutline color="#606262" size={40} />{" "}
              <p className="text-[18px]">No Case</p>
            </div>
          )}
        </div>

        {/* key Dates */}
        <div className=" flex flex-col gap-4  md:col-span-3 rounded-[20px]  lg:col-span-3 ">
          <div className="bg-[#102232] border border-1 pb-0 rounded-xl md:rounded-[30px] h-[170px] gap-2  border-[#6e6f6f]">
            <div className=" flex items-center gap-3 border-b border-b-[#6e6f6f] text-[16px pl-6 p-3 mb-4">
              <span>
                {" "}
                <RiShieldUserLine size={34} />
              </span>
              Conveyancing Officer
            </div>

            <div className={`flex  px-2  rounded-[16px] pb-5 mx-4 relative`}>
              <div>
                <p className="text-[14px] font-medium">{userCookie?.name}</p>
                <p className="text-xs text-[#5b646d] font-medium">
                  {userCookie?.email}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-[#102232] border border-1 pb-4 rounded-xl md:rounded-[30px] border-[#6e6f6f]">
            <div className="flex gap-3 items-center border-b border-b-[##6e6f6f] text-[16px pl-6 p-3 mb-4">
              <RiShieldUserLine size={34} />
              Upcoming Due Tasks
            </div>

            <div
              className={`flex flex-col overflow-y-scroll no-scrollbar max-h-[100px] min-h-[96px] px-2 gap-3 rounded-[16px] pb-5 mx-4 relative `}
            >
              {tasksList.map((item) => (
                <TaskWithTimeInfo
                  taskName={item?.name || item.label}
                  location={item.collectionName}
                  time="12:00"
                  date={DisplayDate(item.endDate)}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Put this part before </body> tag */}

        <dialog
          onClose={() => {
            setCaseFiles();
          }}
          id="my_modal_4"
          className="modal bg-[#071b2eaf]"
        >
          <div className="modal-box bg-[#112232] grid grid-cols-2 gap-8 w-11/12 max-w-5xl">
            <p className="col-span-2 text-sm font-semibold">
              Transaction/Case Preview Details
            </p>
            <div className="flex flex-col gap-6">
              <div
                value="Select to view tasks"
                className={`flex flex-col px-3 py-[15px] bg-[#081c2f] border-[#585d61] rounded-2xl border   `}
                name="caseFileStatus"
                placeholder="click to upload Valuation Report here..."
              >
                <label className="text-xs mb-1 text-white">
                  Case Type/Case Category
                </label>
                <span
                  className={
                    "mt-1 text-xs cursor-pointer bg-transparent placeholder-[#475663] active:border-transparent focus:outline-none"
                  }
                >
                  {caseDetails?.collectionName}
                </span>
              </div>
              {/* {caseDetails.id !== 0 &&
                caseDetails?.parties
                  .filter((item) => item.party == "FIRST")
                  .slice(0, 1)
                  .map((item) => (
                    <InputLabel
                      title={"1st party "}
                      subtitle={`${item?.name} ${
                        caseDetails.parties.filter(
                          (item) => item.party == "FIRST"
                        ).length > 1
                          ? " and others"
                          : ""
                      } `}
                    />
                  ))} */}
              {/* {caseDetails.id !== 0 &&
                caseDetails?.parties
                  .filter((item) => item.party == "SECOND")
                  .slice(0, 1)
                  .map((item) => (
                    <InputLabel
                      title={"2nd party "}
                      subtitle={`${item?.name} ${
                        caseDetails.parties.filter(
                          (item) => item.party == "SECOND"
                        ).length > 1
                          ? " and others"
                          : ""
                      } `}
                    />
                  ))} */}
              <div
                className={`flex flex-col px-3 py-[15px] bg-[#081c2f] border-[#585d61]  rounded-2xl  border`}
              >
                <label className="text-xs mb-1 text-white">Created By</label>
                <span
                  className={
                    "mt-1 text-xs cursor-pointer bg-transparent placeholder-[#475663] active:border-transparent focus:outline-none"
                  }
                >
                  <span>{caseDetails ? caseDetails?.createdBy : ""}</span>
                </span>
              </div>

              <div
                className={`flex flex-col px-3 py-[15px] bg-[#081c2f] border-[#585d61]  rounded-2xl  border`}
              >
                <label className="text-xs mb-1 text-white">
                  Case File Status
                </label>
                <span
                  className={
                    "mt-1 text-xs cursor-pointer bg-transparent placeholder-[#475663] active:border-transparent focus:outline-none"
                  }
                >
                  <span>
                    {caseDetails
                      ? caseDetails.status?.charAt(0).toUpperCase() +
                        caseDetails.status?.slice(1)
                      : ""}
                  </span>
                </span>
              </div>

              <div
                className={`flex flex-col px-3 pt-[15px] pb-[8px] bg-[#081c2f] border-[#585d61]  rounded-t-2xl rounded-b-none border border-b-0  `}
              >
                <label className="text-xs mb-1 text-white">Tasks</label>
                <span
                  className={
                    "mt-1 text-xs cursor-pointer bg-transparent placeholder-[#475663] active:border-transparent focus:outline-none"
                  }
                  onClick={() => {
                    const content = document.getElementById("hiddenContent");
                    const openElement = document.getElementById("open");

                    if (content.style.maxHeight === "0px") {
                      // Content is currently hidden, so reveal it
                      content.style.maxHeight = "400px";
                      openElement.textContent = "Close tasks";
                    } else {
                      // Content is currently visible, so hide it
                      content.style.maxHeight = "0px";
                      openElement.textContent = "Click to view tasks";
                    }
                  }}
                >
                  <span id="open"> Click to view tasks</span>
                </span>
              </div>
              <div
                className="border border-t-0 rounded-b-2xl pt-1  border-[#4e545a] "
                id="hiddenContent"
                style={{
                  marginTop: -24,
                  maxHeight: "0",
                  paddingBottom: "8px",

                  background: "#081c2f",
                  overflow: "hidden",
                  transition: "max-height 0.8s",
                }}
              >
                {caseDetails &&
                  caseDetails.tasks?.map((task, index) => (
                    <>
                      <div className="px-3  gap-5 flex items-center justify-between text-[#eeeeee] text-sm">
                        <span className="py-1 flex w-full items-center">
                          <AiOutlineHolder size={15} />
                          {task?.name &&
                            task.name.charAt(0).toUpperCase() +
                              task.name.slice(1)}
                        </span>
                        <span
                          className="flex px-3 items-center cursor-pointer gap-2 justify-center  text-[#bebdbd]"
                          onClick={() => {
                            const content = document.getElementById(
                              `hiddenTask${index}`
                            );
                            content.style.maxHeight =
                              content.style.maxHeight === "0px"
                                ? "400px"
                                : "0px";
                          }}
                        >
                          subtasks <IoIosArrowDown />
                        </span>
                      </div>
                      <div
                        className="flex flex-col "
                        id={`hiddenTask${index}`}
                        style={{
                          maxHeight: "0",
                          padding: "0",
                          border: "none",
                          background: "transparent",
                          overflow: "hidden",
                          transition: "max-height 0.8s",
                        }}
                      >
                        {task.subTasks?.map((tasks, index) => (
                          <p className="px-4 py-1 text-xs flex items-start">
                            {" "}
                            <span
                              className={`text-[#000000] font-bold text-xs mr-1 w-4 rounded-full justify-center text-center`}
                              style={{
                                backgroundColor: `#fff`,
                              }}
                            >
                              {index}
                            </span>{" "}
                            {tasks.name}
                          </p>
                        ))}
                      </div>
                    </>
                  ))}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="grid grid-row grid-cols-2 gap-4">
                <InputField
                  label="Estimated Start Date"
                  type="date"
                  // register={register}
                  disabled={true}
                  defaultValue={caseDetails.estimatedStartDate}
                  value={caseDetails.estimatedStartDate}
                  // onChange={handleData}
                  name="startDate"
                />

                <InputField
                  label="Estimated End Date"
                  type="date"
                  defaultValue={caseDetails.estimatedEndDate}
                  value={caseDetails.estimatedEndDate}
                  disabled={true}
                  // register={register}
                  // value={new Date()}
                  // onChange={handleData}
                  name="endDate"
                />
              </div>
              <p className="text-sm py-8 text-gray-500 ">
                Attached Documents ( {casefiles?.length || 0} )
                <span
                  className="pl-2 underline cursor-pointer hover:no-underline"
                  onClick={() => {
                    const content = document.getElementById("hiddenfiles");
                    const openElement = document.getElementById("openFiles");
                    const openMessage =
                      document.getElementById("hiddenMessage");

                    if (content.style.maxHeight === "0px") {
                      // Content is currently hidden, so reveal it
                      content.style.maxHeight = "400px";
                      openElement.textContent = "Close File Attachments";
                      openMessage.style.display = "none";
                    } else {
                      // Content is currently visible, so hide it
                      content.style.maxHeight = "0px";
                      openElement.textContent = "Click to view attachments";
                      openMessage.style.display = "block";
                    }
                  }}
                >
                  {" "}
                  <span
                    id="openFiles"
                    className="hover:text-[#2a95c3] hover:no-underline"
                  >
                    Click to view attachments
                  </span>
                </span>
              </p>
              <div
                id="hiddenfiles"
                style={{
                  marginTop: -24,
                  maxHeight: "0",
                  overflow: "hidden",
                  transition: "max-height 0.48s",
                }}
              >
                {
                  <div className=" transition-all min-h-[200px]  gap-5 flex-wrap   mt-1 py-4 px-4 text-xs row-span-2 flex justify-start text-start  bg-transparent placeholder-[#475663] border border-[#585d61] rounded-2xl  active:border-transparent focus:outline-none">
                    {casefiles?.map((item, index) => (
                      <a target="_blank" href={item?.value}>
                        {" "}
                        <div
                          key={item}
                          className="transition-all p-5 px-4 h-fit flex items-center gap-2 relative bg-[#3b97c6] rounded-md cursor-pointer"
                        >
                          <CiFileOn size={14} color="white" /> {item?.name}
                        </div>
                      </a>
                    ))}
                  </div>
                }
              </div>

              <div
                id="hiddenMessage"
                style={
                  {
                    // marginTop: -24,
                    // maxHeight: "0",
                    //  display: "none",
                    // transition: "display 0.7s",
                  }
                }
              >
                {
                  <div className=" transition-all min-h-[200px]  gap-5 flex-wrap  py-4 px-4 text-xs row-span-2 flex justify-start text-start  bg-transparent placeholder-[#475663] border border-[#585d61] rounded-2xl  active:border-transparent focus:outline-none">
                    <input
                      placeholder={"Enter something here..."}
                      rows={"6"}
                      name="message"
                      ref={messageRef}
                      className=" outline-none"
                    />
                  </div>
                }
              </div>

              <div className="bg-red w-full flex justify-end">
                <button
                  // onClick={() => {
                  //   history.push({
                  //     pathname: "/onboard",
                  //     state: { data: caseDetails },
                  //   });
                  // }}
                  className="btn text-white w-[115px] focus:border-none outline-none border-none rounded-xl hover:scale-70 hover:bg-[#2b2d77]  bg-[#2b2d77]"
                >
                  Send <BiSend />
                </button>
              </div>
              <div className="bg-red w-full flex justify-end">
                <button
                  onClick={() => {
                    history.push(`/transactions/new/fill-forms`);
                  }}
                  className="btn btn-link text-white focus:border-none outline-none border-none rounded-xl hover:scale-7 pr-0 mr-0"
                >
                  Advanced editing
                </button>
              </div>
              <div className="modal-action">
                <form method="dialog">
                  <div className="flex gap-5">
                    <button className="btn text-white w-[115px] focus:border-none outline-none border-none rounded-xl hover:scale-70 hover:bg-[#2b2d77]  bg-[#2b2d77]">
                      update
                    </button>
                    <button
                      onClick={() => setCaseFiles()}
                      className="btn text-white w-[115px] h-[20px] focus:border-none outline-none border-none rounded-xl hover:scale-70 hover:bg-[#2b2d77]  bg-[#2b2d77]"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button>close</button>
          </form>
        </dialog>
        {/* Put this part before </body> tag */}
      </div>
    );
  };

  const Content2 = () => {
    return <div className="text-white text-2xl">No closed cases</div>;
  };

  const Content3 = () => {
    return <div className="text-white text-2xl">Content 3</div>;
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between gap-4 text-white">
        <TabButton tabNumber={1} activeTab={activeTab} onClick={handleTabClick}>
          Current Cases
        </TabButton>
        {/* <TabButton tabNumber={2} activeTab={activeTab} onClick={()=>{console.log("")}}>
          Closed Cases
        </TabButton> */}
        {/* <TabButton tabNumber={3} activeTab={activeTab} onClick={handleTabClick}>
          Tab 3
        </TabButton> */}
     
      </div>
      <div className="mt-6">
        {activeTab === 1 && <Content1 />}
        {activeTab === 2 && <Content2 />}
        {activeTab === 3 && <Content3 />}
      </div>
    </div>
  );
};

const TabButton = ({ tabNumber, activeTab, onClick, children }) => {
  const isActive = tabNumber === activeTab;

  return (
    <label
      className={`px-4 py-2 ${
        isActive ? " text-white   border-b-[#0d83bf] border-b-4" : " "
      }`}
      onClick={() => onClick(tabNumber)}
    >
      {children}
    </label>
  );
};
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const TaskWithTimeInfo = ({ taskName, location, time, date }) => {
  return (
    <div className="flex justify-between items-center">
      <div>
        <p className="text-[14px] font-medium">{capitalizeFirstLetter(taskName)}</p>
        <p className="text-xs text-[#5b646d] font-medium">{location}</p>
      </div>

      <div className="flex flex-col text-[#475461]">
        <p className="text-sm font-medium">{time}</p>
        <p className="text-xs font-medium">{date}</p>
      </div>
    </div>
  );
};
export default Tabs;
