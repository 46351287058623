import React, { useEffect,useRef, useState } from "react";
import CurrentCases from "../../components/CurrentCases";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQueryParams } from "../../hooks/useQueryParams";
import { BiChevronDown, BiPlus, BiSend } from "react-icons/bi";
import { BiAlarm } from "react-icons/bi";
import { PiSuitcaseSimple } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { setTransaction,clearTransaction } from "../../store/transactionSlice";
import { BeatLoader, PulseLoader } from "react-spinners";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoArrowBackOutline } from "react-icons/io5";

import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import { useSingleTransactionsQuery } from "../../hooks/useTransactionsQuery";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
const api = process.env.REACT_APP_BACKEND_API_URL;

function SingleCase() {
  


  const query = useQueryParams();
  const dispatch = useDispatch();
  let collectionName = query.get("collectionName");
  const [tab, setTab] = React.useState(query.get("tab") || "current-cases");
  // const api = "https://collins-chilisa.testlive.space";

  const history = useHistory();

  const { id } = useParams();
  const { data, isLoading, isError } = useSingleTransactionsQuery(
    id,
    collectionName
  );
  const [files, setFiles] = useState([]);
  const [fileInput, setFileInput] = useState(null);
  const [newTask, setNewTask] = React.useState({
    name: "",
    isDone: false,
    isSaving: true,
  });
  // const [task/]

  const [currentCase, setCurrentCase] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [hovered, setHovered] = useState(false);
  const queryClient = useQueryClient();
  const onOpenModal = (taskIndex) => {
    setCurrentTask(data?.tasks[taskIndex]);

    let endpoint =
      "/api/v1/tasks/" +
      data?.collectionName +
      "/" +
      data?._id +
      "/" +
      data?.tasks[taskIndex]._id;
    setUrl(endpoint);
    document.getElementById("my_modal_2").showModal();
  };

  const [saving, setSaving] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (!isLoading) {
      if (isError) {
        toast.error("You've been redirected because the case not was found");
        history.replace("/app/cases");
      }
    }
  }, [isError]);
 
 
 
 
 
  const handleSaveTask = () => {
    console.log(data, currentTask);

    let endpoint =
      "/api/v1/tasks/" +
      data.collectionName +
      "/" +
      data._id +
      "/" +
      currentTask._id;
    setUrl(endpoint);

    setCurrentTask((prev) => {
      console.log(prev);
      return {
        ...prev,
        subTasks: [...prev.subTasks, newTask],
      };
    });
    try {
      setSaving(true);

      axios
        .patch(api + endpoint, { subTask: newTask })
        .then((res) => {

////////////////////////////////////////////////////vvvvvvvvvvveeeeeeeeeeerrrrrrrrriiiiiiiiiiiiiiiiiifffffffffffyyyyyyyyyyyyyyyy
          axios.get(`${api}/api/v1/transactions/${data?.collectionName}/${data?._id}`).then((response)=>{
          const item = response.data.data



            const allSubTasks = item?.tasks.reduce((acc, task) => {
              acc.push(...task.subTasks);
              return acc;
          }, []);
     
    
    
    
    // Check if all subtasks are done
    const allDone = allSubTasks.every(subTask => subTask.isDone);
    
    // Check if none of the subtasks are done
    const noneDone = allSubTasks.every(subTask => !subTask.isDone);
    
    // Check if some of the subtasks are done
    const someDone = !allDone && !noneDone;
   
    // Log the result
    if (allDone) {
        console.log("All subtasks are done.");
        axios.put(`${api}/api/v1/transactions/${item.collectionName}/${item._id}`,{status:"done"}).then(async (res)=>{
          console.log("Tasks done and email will be sent shortly")
         
        
    
    axios.get(`${api}/api/v1/transactions/${item.collectionName}/${item._id}`).then((response)=>{
    const caseitem  = response.data.data
      const emailAddresses = extractEmailAddresses(caseitem?.kyc || []);
    
      console.log("response.data.data")
      console.log(emailAddresses)
      let uniqueEmails = [...new Set(emailAddresses)];
      console.log("response.data.data")
      uniqueEmails?.map((email)=>{
      if(email){
        axios.post('https://collins-chilisa.testlive.space/api/v1/email',{
          "email": email,
          "subject": "Case Update ",
          "text":`Good day this is to notify you that case : ${item.transactionName} has been completed successfully`,
          "html": `<p>Good day this is to notify you that case : ${item.transactionName} has been completed successfully</p>`
        }
        ).then(()=>{
          console.log("Sent Email")
          // setUpdateEmail(false);
          // setCurrentFile(null)
        })
      }
      else{
        console.log("No email")
      }
    })
    })  
      
    
    
        })
    
    } else if (noneDone) {
        console.log("None of the subtasks are done.");
        axios.put(`${api}/api/v1/transactions/${item.collectionName}/${item._id}`,{status:"to-do"}).then(async (res)=>{console.log("Tasks in-progress ")})
    
    } else if (someDone) {
      let clientNameValue = null;
for (const field of item.transactionData.formSchema) {
  if (field.name === "client_name") {
    clientNameValue = field.value;
    break;
  }
}

console.log(clientNameValue); // Output: John

        console.log("Some of the subtasks are done.");
        axios.put(`${api}/api/v1/transactions/${item.collectionName}/${item._id}`,{status:"in-progress"}).then(async (res)=>{
          console.log("Tasks pending")
             
       
    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhh")
    console.log(newTask)
    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhh")
            axios.get(`${api}/api/v1/transactions/${item.collectionName}/${item._id}`).then((response)=>{
            const caseitem  = response.data.data
              const emailAddresses = extractEmailAddresses(caseitem?.kyc || []);
              let uniqueEmails = [...new Set(emailAddresses)];
              console.log("response.data.data")
              console.log( uniqueEmails)
              console.log("response.data.data")
              uniqueEmails?.map((email)=>{
              if(email){
                axios.post('https://collins-chilisa.testlive.space/api/v1/email', {
                  "email": email,
                  "subject": `Case Update: ${item.transactionName}`,
                  "text": `Dear ${clientNameValue},
                
                We are writing to inform you of the latest update on your case, ${item.transactionName}.
                New Task:
                Task: ${newTask.name}
                Date: ${new Date()}

                We will continue to keep you informed as each task progresses. If you have any questions or need further assistance, please do not hesitate to contact us.
                
                Thank you for your patience and cooperation.
                
                Best regards,
                Collins Chilisa Team`,
                  "html": `<p>Dear ${clientNameValue},</p>
                           <p>We are writing to inform you of the latest update on your case, <strong>${item.transactionName}</strong>.</p>
                           <p><strong>New Task:</strong></p>
                           <p>Task: ${newTask.name}<br>
                            Date: ${new Date().getDate()}</p>
                         
                           <p>We will continue to keep you informed as each task progresses. If you have any questions or need further assistance, please do not hesitate to contact us.</p>
                           <p>Thank you for your patience and cooperation.</p>
                           <p>Best regards,<br>
                           Collins Chilisa Team</p>`
                }
                
                ).then(()=>{
                  console.log("Sent Email")
                  // setUpdateEmail(false);
                  // setCurrentFile(null);
                })
              }
              else{
                console.log("No email")
              }
            })
            })  
              
    
        })
    
    }
















































          })
////////////////////////////////////////////////////vvvvvvvvvvveeeeeeeeeeerrrrrrrrriiiiiiiiiiiiiiiiiifffffffffffyyyyyyyyyyyyyyyy









          console.log(res.data);
          document.getElementById("my_modal_2").close();
          toast.success("Task Saved");
          setSaving(false);
          setNewTask({ name: "", isDone: false });
          setShowInput(false);
          queryClient.invalidateQueries({
            queryKey: ["cases" + collectionName + id],
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
      setSaving(false);

      setCurrentCase((prev) => {
        prev.subTasks.pop();
        return prev;
      });
    }
    console.log(newTask);
    console.log(currentCase);
  };


  function extractEmailAddresses(array) {
    return array.map(obj => {
        // Find the schema entry with name "emailaddress"
        const emailSchema = obj.formSchema.find(schema => schema.name.includes("emailaddress"));
        
        // If found, return its value
        if (emailSchema) {
            return emailSchema.value;
        } else {
            return null; // or handle the case where email address is not found
        }
    });
}

  const sendEmail = () => {

   

  const emailAddresses = extractEmailAddresses(data.kyc);
  console.log(emailAddresses); // Output: ["prochivs@gmail.com"]

console.log("laslllllllllllllllltMessage")
console.log(data)
console.log("lastMesllllllllllsage")
  emailAddresses?.map((email)=>{
  if(email){
    axios.post('https://collins-chilisa.testlive.space/api/v1/email',{
      "email": email,
      "subject": "Case Update ",
      "text": message,
      "html": `<p>${message}</p>`
    }
    ).then(()=>{
      console.log("Sent Email")
    })
  }
  else{
    console.log("No email for other parties")
  }

  })

  };


  useEffect(() => {
    if (data) {
      let kycSchemas = data.kyc.flatMap((el) =>
        el.formSchema.flatMap((el2) => {
          return { ...el2, fromForm: "kyc", partyName: el.partyName };
        })
      );
      console.log(data);
      let transactionSchemas = data.transactionData?.formSchema.flatMap(
        (el) => {
          return {
            ...el,
            fromForm: "transaction",
            partyName: data.transactionName,
          };
        }
      );
      setFiles([]);
      [...kycSchemas, ...transactionSchemas].map((item) => {
        if (item.type == "file") {
          console.log(item);
          setFiles((prev) => [...prev, item]);
        }
      });
    }
    console.log("data")
    console.log(data)
console.log("data")
   setMessages(data?.messages)

  }, [data]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  useEffect(() => {
    //     setIsFileUploading(true);

    console.log(fileInput?.length);
    if (!fileInput) return;
    if (fileInput?.length < 1) return;
    if (isFileUploading) return;
    setIsFileUploading(true);
    var form_data = new window.FormData();
    form_data.append("file", fileInput[0]);
    console.log(form_data);
    axios
      .post(`${api}/api/v1/files`, form_data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res) => {
        let newData = JSON.parse(JSON.stringify(data));
        newData.transactionData.formSchema.push({
          label: "Attachment " + fileInput[0].name,
          name: fileInput[0].name,
          type: "file",
          value: res.data.data.url,
          id: Date.now(),
        });
        axios
          .put(
            `${api}/api/v1/transactions/${data.collectionName}/${data._id}`,
            newData
          )
          .then((res) => {
            toast.success("File Updated");
            queryClient.invalidateQueries({
              queryKey: ["cases" + collectionName + id],
            });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setFileInput(null);
            setIsFileUploading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFileInput(null);
        setIsFileUploading(false);
      });

    console.log(fileInput);
  }, [fileInput]);

  const onSelectFile = (e) => {
    console.log(e.target.files);
    setFileInput(e.target.files);
  };

  let caseId = id;
 
 
 
  const deleteFile = async (id) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData.transactionData.formSchema =
      newData.transactionData.formSchema.filter((item) => item.id != id);
   await axios.delete(`${api}/api/v1/files/${id}`).then((res) => {
      axios.put(
          `${api}/api/v1/transactions/${data.collectionName}/${data._id}`,
          newData
        )
        .then((res) => {
          toast.success("File Deleted");
          queryClient.invalidateQueries({
            queryKey: ["cases" + collectionName + caseId],
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };


  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
const textAreaRef = useRef(null);



useEffect(() => {
  // Adjust the textarea height automatically
  if(message !== "" && textAreaRef.current !== ""){
    const textArea = textAreaRef.current;
    textArea.style.height = 'auto'; // Reset the height
    textArea.style.height = `${Math.min(textArea.scrollHeight, 100)}px`; // Set the height with a limit
  }

}, [message]);



  useEffect(() => {
   
  }, [messages]);

  if (isLoading) {
    return (
      <div className=" flex  justify-center items-center">
        <div className="mt-[30%]">
          <PulseLoader color="#a6adbb" />
        </div>
      </div>
    );
  }

  const closeModal = () => {
    queryClient.invalidateQueries({
      queryKey: ["cases" + collectionName + id],
    });
    document.getElementById("my_modal_2").close();
  };


  


const partyNames = data.kyc?.flatMap(item =>
  item.formSchema
      .filter(field =>  field.name.includes("partyName"))
      .map(field => field.value)
);


  return (
    <>
      <div className="px-4">
        <dialog id="my_modal_2" className="modal">
          <div className="modal-box pt-12 bg-[#102232]  flex justify-center flex-col items-center shadow-sm border-none">
            <h3 className=" text-lg">
              Please select a{" "}
              <span className="font-bold underline ">SubTask!</span>
            </h3>

            <div className="mt-10 w-full space-y-5 " key={currentTask}>
              {currentTask?.subTasks?.map((task) => {
                return (
                  <>
                    <SubTask
                      onClose={closeModal}
                      {...currentTask}
                      currentTask={currentTask}
                      setCurrentCase={setCurrentCase}
                      {...task}
                      id={task._id}
                      taskId={task._id}
                      key={task._id}
                      url={url}
                      data={data}
                      isDone={task.isDone}
                      isSaving={false}
                      name={task.name}
                    />
                  </>
                );
              })}
              {showInput && (
                <input
                  onChange={(e) =>
                    setNewTask({ name: e.target.value, isDone: false })
                  }
                  placeholder="Enter a task"
                  className=" placeholder-[#475663] border bg-transparent rounded-2xl justify-between w-full items-center border-[#585d61] p-3"
                />
              )}

              <button
                disabled={newTask.name.length < 1 && showInput}
                onClick={() => {
                  if (showInput) {
                    handleSaveTask();
                  } else {
                    setShowInput(true);
                    // setShowInput(false);
                  }
                }}
                className={`${
                  newTask.name.length < 1 && showInput && !saving
                    ? "cursor-not-allow text-[#555]  "
                    : "cursor-pointer text-[#42abdf] hover:bg-[#302a74]"
                } cursor-pointer  mt-4  rounded-lg items-center hover:border-transparent flex  py-1.5 px-2`}
              >
                <IoIosAddCircleOutline size={20} />
                <span className="ml-2 text-sm font-semibold">
                  {showInput ? (saving ? "Saving..." : "Save") : "Add more"}
                </span>
              </button>

              <div className="flex justify-end">
                <div
                  onClick={() => {
                   
                    setShowInput(false);
                    setNewTask({ name: "", isDone: false });
                  }}
                  className="modal-action"
                >
                  <form method="dialog">
                    {/* if there is a button in form, it will close the modal */}
                    <button className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[60px] mt-0 text-white p-3 hover:scale-105 duration-300 h-[35px]">
                      Close
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </dialog>
        <div className="grid min-h-[95vh] grid-cols-12">
          <div className="col-span-3  overflow-scroll no-scrollbar h-[100vh]  flex flex-col relative border-r  border-[#585d61] px-4 py-6">
          <div onClick={()=>{history.goBack()}} className="flex justify-end text-lg gap-2 cursor-pointer items-center"><IoArrowBackOutline size={24} />Back</div>  <Header title={data?.transactionName} />
            <div className="my-5">
              <div className="font-bold">Property Information</div>
              <div className="space-y-2  mt-3">
                <Card
                  title={"1st Party Name"}
                  // subTitle={data?.kyc[0]?.partyName}
                  subTitle={partyNames[0] || ""}

                />
                <Card
                  title={"2nd Party Name"}
                  // subTitle={data?.kyc[1]?.partyName}
                  subTitle={partyNames[1] || ""}

                />
                <Card
                  title={"Property Information"}
                  subTitle={data?.description}
                />
                <Card title={"Transaction Status"} subTitle={data?.status} />
              </div>
            </div>
            <Header title={"Tasks"} />
            <div className="mt-3 space-y-2">
              {data?.tasks?.map((task, index) => {
                return (
                  <MainTaskCard index={index} onClick={onOpenModal} {...task} />
                );
              })}
            </div>
            <div className="mt-4"></div>
          
            
            <div className="mt-auto">
            <button
                className="bg-transparent border border-[#16283b] border-[2px] w-full block text-white px-4 my-2 py-2 rounded-md"
                onClick={() =>{
                  console.log("lllllllllllllllllllllllllllllllllllllllllllllllllllllllllll")
                  console.log(data)
                  console.log("lllllllllllllllllllllllllllllllllllllllllllllllllllllllllll")
                  dispatch(clearTransaction());
                  dispatch(setTransaction({...data,onGoingTransaction:true,tasksData:{...data.transactionData,onGoingTransaction:true,formType:"tasks",formSchema:data?.tasks}}));
                  history.push(`/transactions/new/fill-forms`);
                 } }
              >
                Advanced Editing
              </button>
              <button
                className="bg-[#17293c] w-full block hover:scale-90 text-white px-4 py-2 rounded-md"
                onClick={() =>
                  axios
                    .delete(
                      `${api}/api/v1/transactions/${data?.collectionName}/${id}`
                    )
                    .then(() => {
                      history.push("/app/home");
                    })
                }
              >
                Delete case
              </button>
            </div>
          </div>

          <div className="col-span-6 p-6 border-r-2 h-[100vh] flex flex-col border-[#585d61]">
            <Header title={"File Repository"} />
            <div className="my-5 grid overflow-scroll no-scrollbar  grid-cols-3 gap-3">
              {files?.map((file) => {
                if (file.value) {
                  return (
                    <FileCard
                      deleteFile={deleteFile}
                      partyName={data?.partyName}
                      {...file}
                    />
                  );
                }
              })}
            </div>
            <div className="mt-auto">
              <Header title={"Attachments"} />
              <div
                className={`h-48  text-white  bg-[#102232] ${
                  hovered ? "bg-[#060e15]" : ""
                } mt-5 border-dashed border rounded-2xl mt`}
              >
                <label
                  onDragOver={(event) => {
                    event.preventDefault();
                  }}
                  onDragEnter={(event) => {
                    setHovered(true);
                  }}
                  onDragLeave={(event) => {
                    setHovered(false);
                  }}
                  onDrop={(event) => {
                    event.preventDefault();
                    setHovered(false);
                    const droppedFiles = event.dataTransfer.files;
                    if (droppedFiles.length > 0) {
                      const newFiles = Array.from(droppedFiles);
                      setFileInput(newFiles);
                    }
                  }}
                  title="Click to select a file"
                  className="h-full w-full flex text-sm justify-center items-center cursor-pointer"
                  htmlFor="file"
                >
                  {hovered ? "Drop files here" : "Select or Drag files here"}
                </label>
                <input
                  onChange={onSelectFile}
                  type="file"
                  name="file"
                  className="hidden"
                  id="file"
                ></input>
              </div>
            </div>
          </div>
          <div className="col-span-3 p-6  h-[100vh] flex flex-col border-[#585d61]">
           <Header title={"Communication"}/>
           
              <div className="flex gap-3  overflow-scroll mt-5 mb-2 pb-2 no-scrollbar flex-col items-center">
                {messages?.map((message) => {
                  return (
                    <div className="bg-[#0E4D85] relative text-white w-[100%] px-2 py-5 rounded-2xl rounded-bl-none">
                      <div className="text-sm max-w-[80%]">
                        {message.message}
                      </div>
                      <div className="absolute text-xs right-2">
                        {new Date(message.date).toLocaleTimeString()}
                        
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="mt-auto">
              <div className="border border-[#a6adba] flex items-center justify-between px-2 rounded-2xl text-sm py-2">
                <textarea
                 ref={textAreaRef}
                 rows={1}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                 className="w-full border-none no-scrollbar  outline-none focus:outline-none outlined-none focus:border-none bg-[#0e2131] max-h-20 overflow-y-auto resize-none p-3 border border-gray-300 rounded-md text-sm text-base leading-6"

                  placeholder="Send Message..."
                />

                <button
                  disabled={message.length < 1}
                  onClick={() => {
                    setMessage("");
                    setMessages((prev) => [
                      ...prev,
                      { message, date: new Date() },
                    ]);
                    axios.put(`${api}/api/v1/transactions/${data.collectionName}/${data._id}`,{messages:[...data.messages,{ message, date: new Date() }]})
                    sendEmail()
                  }}
                  // bg-[#302a74]  py-1.5 px-2
                  className={` outline-none    cursor-pointer    rounded-lg items-center hover:border-transparent flex `}
                >
                  {/* Send */}
                  <BiSend size={20} className="ml-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {isLoading && <div>Loading...</div>}
      </div>
    </>
  );
}
// A custom hook that builds on useLocation to parse
// the query string for you.

export default SingleCase;

function Header({ title }) {
  return (
    <div>
      <div className="text-xl font-medium">{title}</div>
      <div className="divider  bottom-2 m-0  mt-2 h-1"></div>
    </div>
  );
}
function Card({ title, subTitle }) {
  return (
    <div
      className={` border hover:cursor-pointer rounded-2xl justify-between w-full items-center border-[#585d61] px-3 py-[15px]`}
    >
      <div className="capitalize text-sm font-medium">{title}</div>
      <div className="text-sm capitalize">{subTitle}</div>
    </div>
  );
}

export function MainTaskCard({ name, _id, onClick, index, label }) {
  return (
    <div
      onClick={() => onClick(index)}
      key={_id}
      className={`flex border hover:cursor-pointer rounded-xl justify-between w-full bg-[#243f57] items-center border-[#585d61] px-3 py-[10px]
     
      `}
    >
      <label className="text-sm">{label}</label>
    </div>
  );
}
function FileCard({ label, name, id, value, partyName, deleteFile }) {
  let splitOne = value.split("/")[3].split(".")[0].split("_");
  let fileName = splitOne.slice(3, splitOne.length).join(" ");
  let splitTwo = value.split(".");
  let fileType = splitTwo[splitTwo.length - 1];
  return (
    <div
      title={fileName + "." + fileType}
      className="rounded-2xl h-[6.25rem] relative py-8 cursor-default px-4 text-white bg-[#43ACE1]"
    >
      <button
        onClick={async () => {
          if (id) {
            await deleteFile(id);
          }
        }}
        className="absolute top-2 right-2 "
      >
        <BiPlus size={24} className="rotate-45" />
      </button>

      <div className="flex flex-col gap-4"> 
       <div className="text-xs truncate mb-2 font-medium text-ellipsis">
        {fileName}.{fileType}
      </div>
      <a  target="_blank" href={value}>
        <div className="text-xs cursor-pointer text-end">view file</div>
      </a></div>
      
      
      
      
     
    </div>
  );
}
export function SubTask({
  isDone,
  name,
  id,
  url,
  data,
  taskId,
  isSaving,
  setModifiedData,
  mainTaskId,
  setIsDoneCount,
  isDoneCount,
  ...rest
}) {
  const [isTaskDone, setIsTaskDone] = useState(isDone);

  const [saving, setSaving] = useState(isSaving);

  function extractEmailAddresses(array) {
    return array.map(obj => {
        // Find the schema entry with name "emailaddress"
        const emailSchema = obj.formSchema.find(schema => schema.name.includes("emailaddress"));
        
        // If found, return its value
        if (emailSchema) {
            return emailSchema.value;
        } else {
            return null; // or handle the case where email address is not found
        }
    });
}

  const onChangeCheckbox = () => {
    setSaving(true);
    setIsTaskDone(!isTaskDone);
    try {
      axios
        .patch(api + url + "/" + taskId, {
          isDone: !isTaskDone,
        })
        .then((res) => {


          axios.get(`${api}/api/v1/transactions/${data?.collectionName}/${data?._id}`).then(async (response)=>{
            const item = response.data.data
  
  
  
              const allSubTasks = item?.tasks.reduce((acc, task) => {
                acc.push(...task.subTasks);
                return acc;
            }, []);
       
      
      
      
      // Check if all subtasks are done
      const allDone = allSubTasks.every(subTask => subTask.isDone);
      
      // Check if none of the subtasks are done
      const noneDone = allSubTasks.every(subTask => !subTask.isDone);
      
      // Check if some of the subtasks are done
      const someDone = !allDone && !noneDone;
     
      // Log the result
      if (allDone) {
          console.log("All subtasks are done.");
         await axios.put(`${api}/api/v1/transactions/${item.collectionName}/${item._id}`,{status:"done"}).then(async (res)=>{
            console.log("Tasks done and email will be sent shortly")
           
          
      
      axios.get(`${api}/api/v1/transactions/${item.collectionName}/${item._id}`).then((response)=>{
      const caseitem  = response.data.data
        const emailAddresses = extractEmailAddresses(caseitem?.kyc || []);
         let uniqueEmails = [...new Set(emailAddresses)];
        console.log("response.data.data")
        console.log(uniqueEmails)
        console.log("response.data.data")
        uniqueEmails?.map(async (email)=>{
        if(email){
          axios.post('https://collins-chilisa.testlive.space/api/v1/email', {
            "email": email,
            "subject": `Case Update: ${item.transactionName}`,
            "text": `Good day,
          
          We are writing to inform you of the latest update on your case, ${item.transactionName}.
          
          Completed Task:
          Task: ${name}
          Completion Date: ${new Date().getDate()}
          
          We will continue to keep you informed as each task progresses. If you have any questions or need further assistance, please do not hesitate to contact us.
          
          Thank you for your patience and cooperation.
          
          Best regards,
          Collins Chilisa Team`,
            "html": `<p>Good day,</p>
                     <p>We are writing to inform you that your case, <strong>${item.transactionName}</strong>. has been completed successfully</p>
                                   
                     <p>We will continue to keep you informed if any changes. If you have any questions or need further assistance, please do not hesitate to contact us.</p>
                     <p>Thank you for your patience and cooperation.</p>
                     <p>Best regards,<br>
                     Collins Chilisa Team</p>`
          }
          
          ).then(()=>{
            console.log("Sent Email")
            // setUpdateEmail(false);
            // setCurrentFile(null)
          })
        }
        else{
          console.log("No email")
        }
      })
      })  
 
          })
      
      } else if (noneDone) {
          console.log("None of the subtasks are done.");
         await axios.put(`${api}/api/v1/transactions/${item.collectionName}/${item._id}`,{status:"to-do"}).then(async (res)=>{console.log("Tasks in-progress ")})
      
      } else if (someDone) {
          console.log("Some of the subtasks are done.");
          axios.put(`${api}/api/v1/transactions/${item.collectionName}/${item._id}`,{status:"in-progress"}).then(async (res)=>{
            console.log("Tasks pending")
               
         
      
              axios.get(`${api}/api/v1/transactions/${item.collectionName}/${item._id}`).then((response)=>{
              const caseitem  = response.data.data
                const emailAddresses = extractEmailAddresses(caseitem?.kyc || []);
                let uniqueEmails = [...new Set(emailAddresses)];

                console.log("response.data.data")
                console.log(uniqueEmails)
                console.log("response.data.data")
                uniqueEmails?.map((email)=>{
                if(email){
                  axios.post('https://collins-chilisa.testlive.space/api/v1/email', {
            "email": email,
            "subject": `Case Update: ${item.transactionName}`,
            "text": `Good day,
          
          We are writing to inform you of the latest update on your case, ${item.transactionName}.
          
          Completed Task:
          Task: ${name}
          Completion Date: ${new Date().getDate()}
          
          We will continue to keep you informed as each task progresses. If you have any questions or need further assistance, please do not hesitate to contact us.
          
          Thank you for your patience and cooperation.
          
          Best regards,
          Collins Chilisa Team`,
            "html": `<p>Good day,</p>
                     <p>We are writing to inform you of the latest update on your case, <strong>${item.transactionName}</strong>.</p>
                     <p><strong>Completed Task:</strong></p>
                     <p>Task: ${name}<br>
                     Completion Date: ${new Date()}</p>
                    
                     <p>We will continue to keep you informed as each task progresses. If you have any questions or need further assistance, please do not hesitate to contact us.</p>
                     <p>Thank you for your patience and cooperation.</p>
                     <p>Best regards,<br>
                     Collins Chilisa Team</p>`
          }
          
          ).then(()=>{
                    console.log("Sent Email")
                    // setUpdateEmail(false);
                    // setCurrentFile(null);
                  })
                }
                else{
                  console.log("No email")
                }
              })
              })  
                
      
          })
      
      }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
            })



          setSaving(false);
        });
    } catch (e) {
      setIsTaskDone(isTaskDone);
      setSaving(false);
    }
  };
  return (
    <div
      onClick={() => {
        onChangeCheckbox();
      }}
      key={[id, isTaskDone, saving]}
      className={`flex border hover:cursor-pointer rounded-2xl justify-between w-full items-center border-[#585d61] px-3 py-[15px]
     
      `}
    >
      <label className="">{name}</label>

      {saving == true ? (
        <BeatLoader color="#a6adbb" />
      ) : (
        <input
          id={id}
          onChange={() => null}
          type="checkbox"
          className="checkbox"
          checked={isTaskDone}
        />
      )}
    </div>
  );
}
