import React from "react";
import backgroundImg from "../images/home-img.png";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BiAlarm } from "react-icons/bi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function AdminBanner() {
  const history = useHistory();

  return (
    <div className="flex   justify-center items-center filter ">
      <div className="absolute z-10 justify-between w-full lg:top-auto top-0 items-center p-12 md:p-20  lg:flex">
        <div className="">
          <div className=" text-white font-medium">
            <div className="md:block text-fluidlg hidden ">
              Thank you <br /> for <br /> choosing us
            </div>
            <div className="md:hidden block  text-xl">
              Thank you for <br /> choosing us
            </div>
          </div>
          <div className="lg:text-fluidmd text-sm  text-white md:w-[65%]">
            Giving you piece of mind by providing you with the tools you need
            when you need them
          </div>
        </div>
        <div className="">
          <ViewCaseCard />
          <button
            className="bg-[#2b2d77] align-center flex justify-center text-[10px] md:text-sm items-center rounded-[9px] px-[10px] w-[230px]  mt-5 text-white  hover:scale-105 duration-300 h-[40px]"
            onClick={() => {
              history.push("/app/cases");
            }}
          >
            VIEW CASE FILES
          </button>
        </div>
      </div>
      <img
        src={backgroundImg}
        className="w-full h-[200px] md:h-[270px] rounded-3xl  lg:h-auto object-cover z-0"
      />
    </div>
  );
}

export default AdminBanner;
function ViewCaseCard() {
  return (
    <div className="bg-[#243F57] rounded-2xl  p-4 hidden lg:block">
      <BiAlarm size={25} color="#FFF" />
      <div className=" rounded-[9px] ">
        <div className="text-white text-xs  px-7 underline  ">
         Create Case Files
        </div>
        <div className="text-white text-xs  pl-7  xl:mb-6 mt-2 ">
          Click on the 'View Case <br /> Files' button below
        </div>
      </div>
    </div>
  );
}
