import React, { useRef, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { loginSuccess } from "../../store/authSlice";
import logo from "../../images/logo.png";
import loginImage from "../../images/loginImage.png";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { useDispatch } from "react-redux";
import { IoMdArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import InputField from "../../components/InputField";
import TextareaField from "../../components/TextareaField";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "sonner";
import { useCookie } from "react-use";
import { MdTextFields } from "react-icons/md";
import { TiAttachmentOutline } from "react-icons/ti";
import { CiCalendarDate } from "react-icons/ci";
import { TfiHandDrag } from "react-icons/tfi";
import { LuPencil } from "react-icons/lu";
import { AiOutlineDelete } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";
import { clearTransaction } from "../../store/transactionSlice";
import InputLabel from "../../components/InputLabel";
import { useTemplateCreationData } from "../../hooks/useTemplateCreationData";
import SelectField from "../../components/SelectField";
import { IoChevronBack } from "react-icons/io5";
import { useTransactionCreationData } from "../../hooks/useTransactionCreationData";
const navigation = [];
const api = process.env.REACT_APP_BACKEND_API_URL;
export default function FormBuilder() {
  const dispatch = useDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const history = useHistory();
  const location = useLocation();
  // Access the state object passed through history.push
  const editformSchema = location.data && location.data.data;
  console.log('///////////////////////////////////////////////////////')
  console.log(editformSchema)
  console.log('///////////////////////////////////////////////////////')

  const [visible, setVisible] = useState(editformSchema ? false : true);
  const [value, updateCookie, deleteCookie] = useCookie("user");
  const caseDetails = useSelector((state)=> state.transactionState);
  // console.log(caseDetails)
  const [formDetails, setFormDetails] = useState({
    formName: editformSchema?.formName || editformSchema?.reportName,
    formDescription: editformSchema?.formDescription || editformSchema?.reportDescription,
    formBankName: editformSchema?.formBankName || editformSchema?.reportType,
    formType: editformSchema?.formType || editformSchema?.reportType,
    partyType: !editformSchema?.isMultiparty
 ? "Single" : "Multiple",
  });
  // if(editformSchema!==undefined)
  const schema = yup
    .object()
    .shape({
      formName: yup.string().required(),
      formDescription: yup.string().required(),
      formType: yup.string().required(),
      formBankName:yup.string().required(),
      partyType: yup.string().required(),
    })
    .required();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      formName: editformSchema?.formName || editformSchema?.reportName,
      formDescription: editformSchema?.formDescription || editformSchema?.reportDescription,
      formType: editformSchema?.formType || editformSchema?.reportType,
      formBankName:editformSchema?.formBankName || editformSchema?.reportType,
      partyType: !editformSchema?.isMultiparty
      ? "Single" : "Multiple",
    },
    resolver: yupResolver(schema),
  });
  // const


  const user = JSON.parse(value);
  const {
    formName,
    formType,
    createBy,
    formDescription,
    formSchema,
    setFormBankName,
    setPartyType,
    partyType,
    setFormName,
    setFormDescription,
    setFormType,
  } = useTemplateCreationData();

  const { editId } = useTransactionCreationData();
  useEffect(() => {
    setFormDescription(watch("formDescription"));
    setFormDetails({
      ...formDetails,
      formDescription: watch("formDescription"),
    });
  }, [watch("formDescription")]);


  useEffect(() => {
    setFormBankName(watch("formBankName"));
    setFormDetails({
      ...formDetails,
      formBankName: watch("formBankName"),
    });
  }, [watch("formBankName")]);

  useEffect(() => {
    setFormName(watch("formName"));
    setFormDetails({
      ...formDetails,
      formName: watch("formName"),
    });
  }, [watch("formName")]);

  useEffect(() => {
    setFormType(watch("formType"));
    setFormDetails({
      ...formDetails,
      formType: watch("formType"),
    });
  }, [watch("formType")]);

  useEffect(() => {
    setPartyType(watch("partyType"));
    setFormDetails({ ...formDetails, partyType: watch("partyType") });
  }, [watch("partyType")]);

  return visible ? (
    <div>
      <div className="flex flex-col justify-center items-center">
        <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/app/home/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-12 w-auto" src={logo} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <a
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div className="text-white flex px-20 flex-row mb-1 mt-[14px] container mx-auto text-xl items-center">
        <span
          aria-hidden="true "
          className="cursor-pointer absolute"
          onClick={() => {
            history.push("/transactions/select");
          }}
        >
          {<IoMdArrowBack />}
        </span>
      </div>
      <div className="flex flex-col justify-center items-center">
        <form>
          <div className="border-b mx-auto  w-[680px] flex flex-col justify-center items-center border-gray-900/10 mb-10 -screen  mt-16 ">
            <div className=" w-full bg-[#102232] px-10  rounded-3xl  pt-5 pb-8 shadow-lg   border-[#585d61]">
              <div className="font-medium text-center mt-10 my-5 text-white">
                <span className="font-thin text-xl"> Select Template </span>
                TYPE
              </div>

              <div className="mt-5 space-y-5">
                <div
                  onClick={(prev) => {
                    dispatch(clearTransaction())
                    setFormDetails({ ...formDetails, formType: "kyc" });
                    setValue("formType", "kyc");
                    setVisible(!prev);
                  }}
                  className="cursor-pointer flex flex-col  px-3 py-[18px] items-center  text-[#cacaca]  justfify-center rounded-xl border border-[#50565c]"
                >
                  Create KYC Form
                </div>

                <div
                  className="cursor-pointer flex flex-col  px-3 py-[18px] items-center  text-[#cacaca]  justfify-center rounded-xl border border-[#50565c]"
                  onClick={(prev) => {
                    dispatch(clearTransaction())
                    setFormDetails({ ...formDetails, formType: "report" });
                    setValue("formType", "report");
                    setVisible(!prev);
                  }}
                >
                  Create Report Template
                </div>

                <div
                  className="cursor-pointer bg-[#2b2d77] text-[#cacaca]  flex flex-col px-3 py-[18px] items-center border-none  justfify-center rounded-xl border"
                  onClick={(prev) => {
                    dispatch(clearTransaction());
                    setFormDetails({ ...formDetails, formType: "transaction" });
                    setValue("formType", "transaction");
                    setVisible(!prev);
                  }}
                >
                  Create Transaction Form
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  ) : visible1 ? (
    <div className="flex flex-col justify-center items-center">
      <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-12 w-auto" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <span
        aria-hidden="true "
        className="cursor-pointer absolute"
        onClick={() => {
          history.goBack();
        }}
      >
        {<IoMdArrowBack />}
      </span>
      <div className="flex flex-col justify-center items-center">
        <label className="text-white font-bold text-xl font-">
          <span className="font-light">{formDetails.formType}</span> Template
        </label>
        <div className="text-white mt-3 flex justify-center items-center">
          <p className="text-center">
            Please scroll through the transaction templates to verify all the{" "}
            <br></br>
            available fields and edit template where necessary.Else,Click
            <br></br>
            'Continue to fill up the forms
          </p>
        </div>
      </div>

      <dialog
        onClose={() => {}}
        id="my_modal_4"
        className="modal bg-[#0c1a27cc]"
      >
        <div className="modal-box bg-transparent flex justify-center flex-col items-center shadow-none border-none">
          <div className="modal-box grid grid-cols-3  py-12 bg-[#102232] ">
            <div className="col-span-3 ">
              <div className="text-center  text-lg text-white mb-4">
                Template Confirmation
              </div>
              <div className="flex items-center flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61]">
                <label className="text-md gap-3 my-2 text-white flex justify-center items-center">
                  Form Template successfully Created{" "}
                  <BsCheckCircleFill size={25} color="#2b7734" />
                </label>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-end my-4">
            <button
              onClick={() => {
                const modal = document.getElementById("my_modal_4");
                if (modal) {
                  modal.close();
                }
              }}
              className="border border-[#585d61] px-8   py-3 text-white  text-sm font-medium float-right  rounded-lg"
            >
              Discard
            </button>
            <button
              onClick={() => history.push("/templates/saved-templates")}
              className="bg-[#302a74] px-8   py-3 text-white  text-sm font-medium float-right mx-4 rounded-lg"
            >
              View
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  ) : (
    <div>
      <div className="flex flex-col justify-center items-center">
        <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-12 w-auto" src={logo} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <a
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div className="text-white flex px-20 flex-row mb-4 container mx-auto text-xl items-center">
        <span
          aria-hidden="true "
          className="cursor-pointer absolute"
          onClick={() => {
           if(editformSchema?._id){
  history.goBack()
}
else{
            setVisible(!visible);
            setFormDetails(null);
}
          }}
        >
          {<IoMdArrowBack />}
        </span>
        <label className="w-full text-xl flex justify-center font-medium">
          <span className="font-light text-white mr-2">Create </span>
          New Form
        </label>
      </div>

      <form
        onSubmit={(e) => {
          // console.log("");
        }}
      >
        <div className="border-b mx-auto  w-[680px] flex flex-col justify-center items-center border-gray-900/10 mb-10 -screen  mt-6 ">
          <div className=" w-full bg-[#102232] px-10  rounded-3xl  py-5 shadow-lg   border-[#585d61]">
            <div className="font-medium text-center my-5 text-white">
              <span className="font-light   mr-1">{formDetails?.formType.charAt(0).toUpperCase() + formDetails?.formType.slice(1)}</span>
              Details
            </div>

            <div className="mt-5 space-y-5">
              <InputField
                isErrored={errors.formName}
                disabled={editformSchema ? true : false}
                label="Form name *"
                placeholder="Enter the form name here..."
                register={register}
                name="formName"
              />
            
              <TextareaField
                rows={"6"}
                register={register}
                disabled={editformSchema ? true : false}
                name="formDescription"
                isErrored={errors.formDescription}
                placeholder={"Enter form description here..."}
                label={"Form description *"}
              />
             
             {editformSchema?._id && formDetails.formType === "transaction" && (
                <InputField
                isErrored={errors.formName}
                disabled={editformSchema ? true : false}
                label="No of parties"
                placeholder="Enter party type here here..."
                register={register}
                name="partyType"
              />
              )}
             
              {!editformSchema && formDetails.formType === "transaction" && (
                <SelectField
                  label={"No of parties"}
                  clearErrors={() => {}}
                  setValue={setValue}
                  visible={editformSchema ? true : false}
                  toolTip={["Single", "Multiple"]}
                  onChange={() => console.log("changed")}
                  placeholder="click arrow to select a value"
                  name={"partyType"}
                  isErrored={errors.partyType}
                  register={register}
                  message={"Please select a value"}
                />
              )}



              {editformSchema?._id && formDetails?.formType !== "kyc" && formDetails?.formType !== "transaction" && formDetails?.formType !== "tasks" && (
                 <InputField
                 isErrored={errors.formName}
                 disabled={editformSchema ? true : false}
                 label="Name of Bank"
                 placeholder="Enter the name of Bank here..."
                 register={register}
                 name="formBankName"
               />
              )}

{!editformSchema  &&   formDetails?.formType !== "kyc" && formDetails?.formType !== "transaction" && formDetails?.formType !== "tasks" && (
                <SelectField
                  label={"Name of Bank"}
                  clearErrors={() => {}}
                  setValue={setValue}
                  disabled = {editformSchema ? true : false}
                  toolTip={["FNB", "ABSA","BANK OF GABORONE"]}
                  onChange={() => console.log("changed")}
                  placeholder="click arrow to select a value"
                  name={"formBankName"}
                  isErrored={errors.bank}
                  register={register}
                  message={"Please select a value"}
                />
              )}
            </div>
          </div>
          <div className="flex  gap-4 w-full relative mx-auto mt-5 ">
            <div className="flex w-full gap-3 justify-end  ">
              <button
                className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[40px]  text-white p-3 hover:scale-105 duration-300 h-[35px]"
                onClick={(e) => {
                   e.preventDefault()
                  if(
                    editformSchema?._id
                  ){
                    history.goBack();
                  }
                  else{
                    history.goBack();
                    setVisible((prev) => !prev);
                  }
                }}
              >
                Discard
              </button>
              <span
                // type="submit"
                className="bg-[#2b2d77] cursor-pointer align-center flex justify-center text-sm items-center rounded-[9px] px-[40px] text-white p-3 hover:scale-105 duration-300 h-[35px]"
                onClick={(e) => {
                  // console.log("detsilas deyails detsils")
                  // console.log(formDetails)
                  e.preventDefault();
           
                  if (formName === "" && formDescription === "") {
                    toast.error("Please fill in all fields");
                  } 
                    else {
                    setFormName(formDetails.formName);
                    setFormDescription(formDetails.formDescription);
                    setFormType(formDetails.formType);
                    // console.log(formDetails);

                    if (formDetails.formType === "kyc") {

    history.replace({
      pathname: "/templates/new-templates/build-kyc-form",
      ...location.data,
    });
  }
  
                     else  if (formDetails.formType === "report") {
                        history.replace({
                          pathname: "/templates/new-templates/build-kyc-form",
                          ...location.data,
                        });
                      } 

                   else if (formDetails.formType === "transaction"  && caseDetails.editId && caseDetails.onGoingTransaction) {
                      history.replace({
                        pathname: "/templates/new-templates/build-kyc-form",
                        ...location.data,
                      });
                    }
                    else if (formDetails.formType === "transaction" ) {
                      history.replace({
                        pathname: "/templates/new-templates/build-data-form",
                        ...location.data,
                      });
                    }

                    else if (formDetails.formType === "tasks"  && caseDetails.editId) {
                      history.replace({
                        pathname: "/templates/new-templates/build-data-form",
                        ...location.data,
                      });
                    }
                  }
                }
              }
              >
                Continue
              </span>
            </div>
          </div>

        </div>
      </form>
    </div>
  );
}
