import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLocalStorage } from "react-use";

const api = "https://collins-chilisa.testlive.space";
export function useTransactionsQuery() {
  // const [user] = useLocalStorage("user", null);
  return useQuery({
    queryKey: ["cases"],
    queryFn: async () => {
      let response = await axios.get(`${api}/api/v1/transactions`, {
        // headers: {
        //   Authorization: `Bearer ${user.jwt}`,
        // },
      });

      let data = await response.data;

      return data.data;
    },
  });
}
export function useSingleTransactionsQuery(id, collectionName) {
  // const [user] = useLocalStorage("user", null);
  return useQuery({
    queryKey: ["cases" + collectionName + id],
    queryFn: async () => {
      let response = await axios.get(
        `${api}/api/v1/transactions/${collectionName}/${id}`,
        {
          // headers: {
          //   Authorization: `Bearer ${user.jwt}`,
          // },
        }
      );

      let data = await response.data;

      return data.data;
    },
  });
}
