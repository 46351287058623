import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../images/logo.png";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

import OnboardingForm from "../../components/OnboardingForm";
import { useQueryParams } from "../../hooks/useQueryParams";

const navigation = [
  //   { name: "Product", href: "#" },
  //   { name: "Features", href: "#" },
  //   { name: "Marketplace", href: "#" },
  //   { name: "Company", href: "#" },
];
export default function OnBoarding(props) {
  const { data } = props.location.state || {};

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const history = useHistory();

  const queryParams = useQueryParams();

  useEffect(() => {
    if (queryParams.get("type") === null) {
      // history.replace("/onboard?type=1");
    }
  }, [queryParams.get("type")]);

  useEffect(() => {
    const myModal = document.getElementById("my_modal_5");
    myModal.showModal();
  }, []);

  const [isSelected, setIsSelected] = useState(false); //has user selected the type

  const [buyer, setBuyer] = useState("");
  const [seller, setSeller] = useState("");
  return (
    <div className="flex flex-col justify-center items-center">
      <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-12 w-auto" src={logo} alt="" />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <>
        <dialog
          id="my_modal_5"
          className="modal modal-bottom sm:modal-middle w-p500pc"
        >
          <div className="modal-box  overflow-visible bg-[#102232]">
            <h3 className="font-bold  text-lg"> Select parties involved !</h3>

            <div className="w-full bg-[#071b2e] mt-6 rounded-xl  border-[.5px]">
              <div className="">
                <div className="min-h-12 gap-5 justify-between py-3 px-4 items-center  text-start rounded-btn w-full">
                  <div className="font-medium">Buyer</div>
                  <div className="grid  grid-cols-2 gap-4 my-2">
                    <div className="flex items-center gap-1">
                      <input
                        type="checkbox"
                        checked={buyer === "Individual"}
                        onChange={(e) =>
                          setBuyer(e.target.checked ? "Individual" : "")
                        }
                        name="partyType"
                        id="individual"
                        className="checkbox checkbox-xs "
                      />
                      <label
                        htmlFor="individual"
                        className="text-sm cursor-pointer"
                      >
                        Individual
                      </label>
                    </div>
                    <div className="flex items-center gap-1">
                      <input
                        type="checkbox"
                        id="company"
                        checked={buyer === "Company"}
                        className="checkbox checkbox-xs"
                        onChange={(e) =>
                          setBuyer(e.target.checked ? "Company" : "")
                        }
                      />
                      <label
                        htmlFor="company"
                        className="text-sm cursor-pointer"
                      >
                        Company
                      </label>
                    </div>
                    <div className="flex items-center gap-1">
                      <input
                        type="checkbox"
                        checked={buyer === "Estate"}
                        className="checkbox checkbox-xs"
                        id="estate"
                        onChange={(e) =>
                          setBuyer(e.target.checked ? "Estate" : "")
                        }
                      />
                      <label
                        htmlFor="estate"
                        className="text-sm cursor-pointer"
                      >
                        Estate
                      </label>
                    </div>
                    <div className="flex items-center gap-1">
                      <input
                        type="checkbox"
                        checked={buyer === "Society"}
                        className="checkbox checkbox-xs"
                        id="society"
                        onChange={(e) =>
                          setBuyer(e.target.checked ? "Society" : "")
                        }
                      />
                      <label
                        htmlFor="society"
                        className="text-sm cursor-pointer"
                      >
                        Society/Association
                      </label>
                    </div>
                    <div className="flex items-center gap-1">
                      <input
                        type="checkbox"
                        checked={buyer === "Trust"}
                        className="checkbox checkbox-xs"
                        id="trust"
                        onChange={(e) =>
                          setBuyer(e.target.checked ? "Trust" : "")
                        }
                      />
                      <label htmlFor="trust" className="text-sm cursor-pointer">
                        Trust
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full bg-[#071b2e] mt-6 rounded-xl  border-[.5px] ">
              <div className="min-h-12 gap-5 justify-between py-3 px-4 items-center  text-start rounded-btn w-full">
                <div className="font-medium">Seller</div>
                <div className="grid grid-cols-2 gap-4 my-2">
                  <div className="flex grid-2 items-center gap-1">
                    <input
                      type="checkbox"
                      checked={seller === "Individual"}
                      onChange={(e) =>
                        setSeller(e.target.checked ? "Individual" : "")
                      }
                      name="partyType"
                      id="individual1"
                      className="checkbox checkbox-xs "
                    />
                    <label
                      htmlFor="individual1"
                      className="text-sm cursor-pointer"
                    >
                      Individual
                    </label>
                  </div>
                  <div className="flex items-center gap-1">
                    <input
                      type="checkbox"
                      id="company1"
                      checked={seller === "Company"}
                      className="checkbox checkbox-xs"
                      onChange={(e) =>
                        setSeller(e.target.checked ? "Company" : "")
                      }
                    />
                    <label
                      htmlFor="company1"
                      className="text-sm cursor-pointer"
                    >
                      Company
                    </label>
                  </div>
                  <div className="flex items-center gap-1">
                    <input
                      type="checkbox"
                      checked={seller === "Estate"}
                      className="checkbox checkbox-xs"
                      id="estate1"
                      onChange={(e) =>
                        setSeller(e.target.checked ? "Estate" : "")
                      }
                    />
                    <label htmlFor="estate1" className="text-sm cursor-pointer">
                      Estate
                    </label>
                  </div>
                  <div className="flex items-center gap-1">
                    <input
                      type="checkbox"
                      id="society1"
                      checked={seller === "Society"}
                      className="checkbox checkbox-xs"
                      onChange={(e) =>
                        setSeller(e.target.checked ? "Society" : "")
                      }
                    />
                    <label
                      htmlFor="society1"
                      className="text-sm cursor-pointer"
                    >
                      Society/Association
                    </label>
                  </div>
                  <div className="flex items-center gap-1">
                    <input
                      type="checkbox"
                      checked={seller === "Trust"}
                      className="checkbox checkbox-xs"
                      id="trust1"
                      onChange={(e) =>
                        setSeller(e.target.checked ? "Trust" : "")
                      }
                    />
                    <label htmlFor="trust1" className="text-sm cursor-pointer">
                      Trust
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-action">
              <form method="dialog">
                {/* if there is a button in form, it will close the modal */}
                <button
                  onClick={() => {
                    history.goBack();
                  }}
                  className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[60px] mt-0 text-white p-3 hover:scale-105 duration-300 h-[35px]"
                >
                  Close
                </button>
              </form>
              <button
                onClick={() => {
                  document.getElementById("my_modal_5").close();
                  setIsSelected(true);
                }}
                disabled={buyer === ""}
                className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[60px] mt-0 text-white p-3 hover:scale-105 duration-300 h-[35px]"
              >
                Next
              </button>
            </div>
          </div>
        </dialog>
      </>

      {/* {<div>{JSON.stringify(buyer)}</div>}
      {<div>{JSON.stringify(seller)}</div>} */}
      {isSelected && (
        <OnboardingForm seller={seller} buyer={buyer} data={data} />
      )}
      {/*
      {queryParams.get("type") === "individual-to-estate" && (
        <IndividualEstateOnboardingForm data={data} />
      )}
      {queryParams.get("type") === "company-to-company" && (
        <div>
          <CompanyCompanyOnboardingForm />
        </div>
      )}
      {queryParams.get("type") === "company-to-individual" && (
        <div>
          <CompanyIndividualOnboardingForm />
        </div>
      )}
      {queryParams.get("type") === "one-party" && (
        <div className="">
          <SelectKyc />
        </div>
      )}
      {queryParams.get("type") === "multi-party" && <div>Multi party</div>} */}
    </div>
  );
}
