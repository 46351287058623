import React, { useEffect, useRef, useState } from "react";
// import { set } from "react-hook-form";
import { BiChevronDown, BiChevronUp, BiInfoCircle } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
const RadioField = ({
  label,
  onChange,
  value,
  className,
  id,
  name,
  isErrored,
  message,
  disabled,
  register,
  options,
  setValue,
  clearErrors,
}) => {
  const [hasError, setHasError] = useState(isErrored);
  // useStartTyping(() => alert("You started typing!"));

  useEffect(() => {
    setHasError(isErrored);
  }, [isErrored]);

  useEffect(() => {
    if (value === undefined) {
      setValue(name, "");
    }
    console.log(value);
  }, [value]);
  return (
    <div
      className={`flex flex-col px-3 py-[15px] bg-[#081c2f] rounded-2xl  border ${
        hasError ? " border-[red]" : " border-[#585d61]"
      }`}
    >
      <label className="text-xs text-white">{label}</label>
      <div>
        <div className="flex h-6 text-white items-center gap-6">
          {options?.map((option, index) => (
            <label
              onClick={() => {
                setValue(name, option);
                clearErrors(name);
              }}
              key={option}
              htmlFor={`${name + "-" + option + "-" + index}`}
              className="flex items-center text-xs gap-2"
            >
              <p>{option}</p>
              {register === undefined ? (
                <input
                  id={`${name + "-" + option + "-" + index}`}
                  name={name}
                  disabled={disabled}
                  type="radio"
                  value={option}
                  className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              ) : (
                <input
                  id={`${name + "-" + option + "-" + index}`}
                  name={name}
                  defaultChecked={value === option}
                  disabled={disabled}
                  onChange={(e) => {
                    console.log(e);
                    setValue(name, option);
                    clearErrors(name);
                  }}
                  {...register(name)}
                  type="radio"
                  value={option}
                  className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              )}
            </label>
          ))}
        </div>
        {hasError && (
          <div className="text-red-600 text-xs">{"Please select a value"}</div>
        )}
      </div>
    </div>
  );
};

export default RadioField;
