import { useState, useEffect, useReducer } from "react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoMdArrowBack } from "react-icons/io";
import IndividualForm from "./IndividualForm";
import CaseForm from "./CaseForm";
import CompanyForm from "./CompanyForm";
import EstateForm from "./EstateForm";
import SocietyForm from "./SocietyForm";
import TrustForm from "./TrustForm";
import { useQueryParams } from "../hooks/useQueryParams";
import TransactionData from "./TransactionData";
// import { toast } from "sonner";

const api = process.env.REACT_APP_BACKEND_API_URL;

export default function OnboardingForm({ seller, buyer }) {
  const history = useHistory();
  const [step1Complete, setStep1Complete] = useState(seller === "");
  const [step2Complete, setStep2Complete] = useState(false);

  const [party1Data, setParty1Data] = useState([]);
  const [party2Data, setParty2Data] = useState([]);
  const [caseData, setCaseData] = useState([]);

  const queryParams = useQueryParams();
  console.log(seller);
  console.log(queryParams.get("type"));
  const RenderFirstParty = (buyer, seller) => {
    let type = "";
    if (buyer) {
      type = buyer;
    } else if (seller) {
      type = seller;
    }
    console.log(seller);
    if (type === "Individual") {
      return (
        <IndividualForm
          key={`step-${party1Data.length}-${party2Data.length}`}
          party1Data={party1Data}
          party2Data={party2Data}
          setParty1Data={setParty1Data}
          setParty2Data={setParty2Data}
          step1Complete={step1Complete}
          step2Complete={step2Complete}
          setStep1Complete={setStep1Complete}
          setStep2Complete={setStep2Complete}
        />
      );
    }
    if (type === "Company") {
      return (
        <CompanyForm
          key={`step-${party1Data.length}-${party2Data.length}`}
          party1Data={party1Data}
          party2Data={party2Data}
          setParty1Data={setParty1Data}
          setParty2Data={setParty2Data}
          step1Complete={step1Complete}
          step2Complete={step2Complete}
          setStep1Complete={setStep1Complete}
          setStep2Complete={setStep2Complete}
        />
      );
    }
    if (type === "Estate") {
      return (
        <EstateForm
          key={`step-${party1Data.length}-${party2Data.length}`}
          party1Data={party1Data}
          party2Data={party2Data}
          setParty1Data={setParty1Data}
          setParty2Data={setParty2Data}
          step1Complete={step1Complete}
          step2Complete={step2Complete}
          setStep1Complete={setStep1Complete}
          setStep2Complete={setStep2Complete}
        />
      );
    }
    if (type === "Society") {
      return (
        <SocietyForm
          key={`step-${party1Data.length}-${party2Data.length}`}
          party1Data={party1Data}
          party2Data={party2Data}
          setParty1Data={setParty1Data}
          setParty2Data={setParty2Data}
          step1Complete={step1Complete}
          step2Complete={step2Complete}
          setStep1Complete={setStep1Complete}
          setStep2Complete={setStep2Complete}
        />
      );
    }
    if (type === "Trust") {
      return (
        <TrustForm
          key={`step-${party1Data.length}-${party2Data.length}`}
          party1Data={party1Data}
          party2Data={party2Data}
          setParty1Data={setParty1Data}
          setParty2Data={setParty2Data}
          step1Complete={step1Complete}
          step2Complete={step2Complete}
          setStep1Complete={setStep1Complete}
          setStep2Complete={setStep2Complete}
        />
      );
    }
  };
  return (
    <>
      <div className="text-white flex px-20 flex-row mb-4 text-xl container items-center">
        <span
          aria-hidden="true"
          className="cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          <IoMdArrowBack />
        </span>
        <label className="w-full flex justify-center font-medium">
          <span className="font-light mr-1">
            {step1Complete ? (seller == "" ? "Buyer " : "Seller ") : "Buyer "}
          </span>
          {step1Complete && step2Complete
            ? "Information"
            : "details" + buyer + seller}
        </label>
      </div>
      {!step2Complete ? (
        <>{RenderFirstParty(buyer, seller)}</>
      ) : (
        <>
          <TransactionData />
          {/* <CaseForm
            caseData={caseData}
            setCaseData={setCaseData}
            party1Data={party1Data}
            party2Data={party2Data}
          /> */}
        </>
      )}
    </>
  );
}
