import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BiHomeAlt } from "react-icons/bi";
import { PiSuitcase } from "react-icons/pi";
import { BiLeftIndent, BiGroup } from "react-icons/bi";
import { LuLayoutDashboard } from "react-icons/lu";
import { GoFileDirectory } from "react-icons/go";
import { LuLayoutTemplate } from "react-icons/lu";

const APP_PREFIX_PATH = "/app";
const SuperAdminNavTree = [
  {
    key: "1",
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: "Dashboard",
    icon: <LuLayoutDashboard size={20} />,
  },
  {
    key: "2",
    path: `${APP_PREFIX_PATH}/users`,
    title: "Manage Users",
    icon: <BiGroup size={22} />,
  },
  {
    key: "3",
    path: `${APP_PREFIX_PATH}/logs`,
    title: "Systems Logs",
    icon: <BiLeftIndent size={22} />,
  },
];

const AdminNavTree = [
  {
    key: "1",
    path: `${APP_PREFIX_PATH}/home`,
    title: "Home",
    icon: <BiHomeAlt size={22} />,
  },
  {
    key: "2",
    path: `${APP_PREFIX_PATH}/cases_list`,
    // path: "#",
    title: "Case Files",
    icon: <PiSuitcase size={22} />,
  },
  {
    key: "3",
    path: `${APP_PREFIX_PATH}/cases`,
    // path: "#",
    title: "Manage Cases",
    icon: <GoFileDirectory  size={22} />,
  },
  {
    key: "4",
    path: `/templates/saved-templates`,
    // path: "#",
    title: "Templates",
    icon: <LuLayoutTemplate size={22} />,
  },
];

const navigationConfig = [...SuperAdminNavTree, ...AdminNavTree];

export const superAdminConfig = [...SuperAdminNavTree];

export const adminConfig = [...AdminNavTree];

export default navigationConfig;
