import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../images/logo.png";
import { IoMdAddCircle, IoMdArrowBack } from "react-icons/io";
import { useQueryParams } from "../../hooks/useQueryParams";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import {
  set_ID,
  setPreviewStep,
  setEditId,
  setIsOverride,
  clearEditIndex,
  clearEditId,
  setEditIndex,
  setTransactionData,
  setOnGoingTransaction,
  setKyc,
} from "../../store/transactionSlice";
import * as yup from "yup";
import { toast } from "sonner";
import InputField from "../../components/InputField";
import InputLabel from "../../components/InputLabel";
import { FaArrowRight } from "react-icons/fa";
import SelectField from "../../components/SelectField";
import { colorTextPairs } from "../../colors";

import axios from "axios";
import RadioField from "../../components/RadioField";
import { useFormEditData } from "../../hooks/useFormEditData";
import { useTransactionCreationData } from "../../hooks/useTransactionCreationData";
const api = process.env.REACT_APP_BACKEND_API_URL;

function renderInput(item, index = 0) {
  if (item.type === "file") {
    return (
      <div id={item.id}>
        <InputLabel
          title={item.label}
          name={item.name}
          toolTip={<p>{item.helperText}</p>}
          label={item.label}
          htmlFor={item.name}
          subtitle={item.placeholder}
        />
        <InputField
          id={item.name}
          type={item.type}
          disabled={true}
          // register={register}
          name={item.name}
        />
      </div>
    );
  } else if (item.type === "select") {
    return (
      <div id={item.id}>
        <SelectField
          label={item.label}
          name={item.name}
          disabled={true}
          placeholder={item.placeholder}
          message={item.placeholder}
          setValue={(name, value) => null}
          clearErrors={() => null}
          toolTip={item.values}
        />
      </div>
    );
  } else if (item.type === "radio") {
    return (
      <div id={item.id}>
        <RadioField
          label={item.label}
          name={item.name}
          disabled={true}
          placeholder={item.placeholder}
          message={item.placeholder}
          setValue={(name, value) => null}
          clearErrors={() => null}
          options={item.values}
        />
      </div>
    );
  } else if (item.type == "tasks") {
    return (
      <Tasks
        index={index}
        name={item.label}
        subTasks={item.subTasks}
        id={item.id}
      />
    );
  } else {
    return (
      <div id={item.id}>
        <InputField
          type={item.type}
          name={item.name}
          disabled={true}
          label={item.label}
          placeholder={item.placeholder}
        />
      </div>
    );
  }
}
const navigation = [
  //   { name: "Product", href: "#" },
  //   { name: "Features", href: "#" },
  //   { name: "Marketplace", href: "#" },
  //   { name: "Company", href: "#" },
];
function RenderForms() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const caseDetails = useSelector((state) => state.transactionState);

  const queryParams = useQueryParams();

  const { editData, setEditData } = useFormEditData();

  const { buyerCount, sellerCount, forms } = useTransactionCreationData();

  const ViewTemplate = (step) => {
    try {
      let data = "";
      if (caseDetails.kyc.length > step) {
        data = caseDetails.kyc[step] || [];
      } else if (caseDetails.kyc.length == step) {
        data = caseDetails.transactionData || [];
      } else {
        data = caseDetails.tasksData || [];
      }

      dispatch(clearEditIndex());
      dispatch(clearEditId());
      dispatch(setOnGoingTransaction(true));
      dispatch(setPreviewStep(step));
      dispatch(setEditIndex(step));
      dispatch(setEditId(data._id));
      dispatch(setIsOverride(false));
      const locationData = { data, showFormDialog: false, stage: step };
      history.push({
        pathname: "/templates/new-templates",
        data: locationData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [step, setStep] = useState(0);
  useEffect(() => {
    if (queryParams.get("step")) {
      setStep(queryParams.get("step"));
    } else {
      setStep(0);
    }
  }, [queryParams.get("step")]);
  // const editData = useFormEditData();

  useEffect(() => {
    if (step === caseDetails.kyc.length + 2) {
      function generateRandomNumbers(count, min, max) {
        const randomNumbers = [];
        for (let i = 0; i < count; i++) {
          // Generate a random number between min and max (inclusive)
          const randomNumber =
            Math.floor(Math.random() * (max - min + 1)) + min;

          randomNumbers.push(randomNumber);
        }
        return randomNumbers;
      }

      try {
        const randomNumbers = generateRandomNumbers(13, 0, 9); // Example range from 0 to 9
        const updatedKycArray = caseDetails.kyc?.map((data, index) => {
          // const filteredSchema = data.formSchema?.filter(field => !["email", "email_address","partyName"].includes(field.name));
          const filteredSchema = data.formSchema?.filter(
            (field) =>
              !field.name.includes("email") &&
              !field.name.includes("partyName") &&
              !field.name.includes("fullnames") &&
              !field.name.includes("company_name") &&
              !field.name.includes("firstname") &&
              !field.name.includes("lastname") &&
              !field.name.includes("full_name")
          );

          const dataSchema = filteredSchema?.concat([
            {
              id: randomNumbers,
              name: "emailaddress",
              label: "Email Address",
              optional: false,
              placeholder: "Enter Email Address here...",
              type: "text",
            },
            {
              id: randomNumbers + 1,
              name: "partyName",
              label: "Party Full Names",
              optional: false,
              placeholder: "Enter party name here...",
              type: "text",
            },
          ]);

          const uniqueFields = dataSchema.filter(
            (value, index, self) =>
              index ===
              self.findIndex((t) => JSON.stringify(t) === JSON.stringify(value))
          );

          const locationData = { ...data, formSchema: uniqueFields };
          console.log("WHOLE NEWllllllllllllll");
          // alert(index);
          return locationData;
        });

        // console.log("Updated KYC Array:", updatedKycArray);

        let updatedKYCData = [...updatedKycArray];

        updatedKYCData.forEach((form, index) => {
          // Loop through each object in formSchema and update the name
          form.formSchema = form.formSchema.map((field) => ({
            ...field,
            name: `${field.name}_${index}`,
          }));
        });

        console.log("Updated KYC Array:", updatedKYCData);
        dispatch(setKyc(updatedKYCData));
      } catch (error) {
        console.log(error);
      }

      history.push({
        pathname: "/transactions/new/fill-forms",
      });
    }
  }, [step]);


  const [transactionStep, setTransactionStep] = useState(0);


  useEffect(() => {
    if (forms?.length == 0 || !forms[0]) {
      toast.error("Please select transaction type first");
      history.replace("/transactions/select");
    }
  }, []);
  useEffect(() => {
    if (caseDetails?.previewStep) {
      // setStep(caseDetails?.previewStep);
      setStep(0);
    }
  }, [caseDetails?.previewStep]);

  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-12 w-auto" src={logo} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <a
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div className="text-white flex px-20 flex-row mb-4 container mx-auto text-xl items-center">
        <span
          aria-hidden="true"
          className="cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          {<IoMdArrowBack />}
        </span>
        <label className="w-full text-xl flex justify-center font-medium">
          <span className="font-light text-white mr-1">
            {" "}
            {caseDetails.kyc?.length > step
              ? "KYC"
              : caseDetails.kyc?.length === step
              ? "Transaction"
              : `${caseDetails.tasksData?.formName} Tasks`}
          </span>
          Template
        </label>
      </div>
      <div className="container text-white mx-auto w-[530px] text-center mt-5">
        Please scroll through the transaction templates to verify all the
        available fields and{" "}
        <strong className="underline">edit template</strong> where necessary.
        Else, Click <strong>‘Continue’</strong> to fill up the forms.
      </div>

      <div className="container mt-4 flex justify-center mx-auto">
        <ul className="steps w-full">
          {queryParams.get("step") && (
            <>
              <li className="step step-primary"></li>
              <li
                className={`step ${
                  parseInt(queryParams.get("step")) > buyerCount &&
                  "step-primary"
                }`}
              ></li>
              <li
                className={`step ${
                  parseInt(queryParams.get("step")) >
                    sellerCount + buyerCount && "step-primary"
                }`}
              ></li>
              <li
                className={`step ${
                  queryParams.get("step") > buyerCount + sellerCount + 1 &&
                  "step-primary"
                }`}
              ></li>
            </>
          )}
        </ul>
      </div>
      <form>
        <div
          key={step + "-" + transactionStep}
          className="border-b bg-red border-gray-900/10 mb-10 w-scree mt-10 "
        >
          <div className=" grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mx-10 xl:mx-80 bg-[#102232]  rounded-3xl  p-10 shadow-lg  border border-[#585d61]">
            <div className="col-span-full flex justify-between">
              <div>
                <h1 className="text-white text-lg font-semibold">
                  {caseDetails.kyc?.length > step
                    ? caseDetails.kyc[step]?.formName
                    : caseDetails.kyc?.length === step
                    ? caseDetails.transactionData?.formName
                    : `${caseDetails.tasksData?.formName} Tasks`}
                </h1>
              </div>
              <div>
                <div
                  onClick={() => {
                    ViewTemplate(step);
                  }}
                  className="text-[#43ace1] cursor-pointer underline"
                >
                  edit template
                </div>
              </div>
            </div>

            {caseDetails.kyc?.length > step
              ? caseDetails.kyc[step]?.formSchema.map(renderInput)
              : caseDetails.kyc?.length === step
              ? caseDetails.transactionData?.formSchema.map(renderInput)
              : caseDetails.tasksData?.formSchema.map(renderInput)}
          </div>
        </div>
      </form>
      <div className="flex  mx-10 xl:mx-80 gap-4 w-f relative mt-5 ">
        <div className="flex w-full gap-3 justify-end  ">
          <button
            className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[40px]  text-white p-3 hover:scale-105 duration-300 h-[35px]"
            onClick={(e) => {
              e.preventDefault();
              if (step > 0) {
                if (transactionStep > 0) {
                  setTransactionStep(0);
                } else {
                  setStep(step - 1);
                }
              } else {
                history.goBack();
              }
            }}
          >
            Back
          </button>
          <button
            className="bg-[#2b2d77] align-center flex justify-center text-sm items-center rounded-[9px] px-[40px] text-white p-3 hover:scale-105 duration-300 h-[35px]"
            onClick={(e) => {
              e.preventDefault();

              if (step === 0) {
                try {
                  let data = caseDetails.transactionData;
                  const reportSchema = caseDetails.reportType.formSchema;
                  const dataTransaction = data.formSchema?.concat(reportSchema);

                  const uniqueFields = dataTransaction.filter(
                    (value, index, self) =>
                      index ===
                      self.findIndex(
                        (t) => JSON.stringify(t) === JSON.stringify(value)
                      )
                  );

                  const locationData = { ...data, formSchema: uniqueFields };
                  console.log("WHOLE NEW");
                  console.log(locationData);
                  console.log("WHOLE NEW");
                  dispatch(setTransactionData(locationData));
                } catch (error) {
                  console.log(error);
                }
              }
              setStep(step + 1);
              //  dispatch(setPreviewStep(step))
            }}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default RenderForms;

export function Tasks({ name, subTasks, index, bg }) {
  const [rotation, setRotation] = useState(-45);
  const [height, setHeight] = useState(0);
  return (
    <div
      onClick={() => {
        if (rotation === 45) {
          setRotation(-45);
          setHeight(0);
        } else {
          setHeight(40);
          setRotation(45);
        }
      }}
      className={`flex  ${
        index === 0 && ""
      } cursor-pointer  relative items-center gap-3 border border-[#585d61] p-3 rounded-2xl bg-[#081c2f]`}
    >
      <div
        style={{
          color: colorTextPairs[index % colorTextPairs.length].text,
          backgroundColor: colorTextPairs[index % colorTextPairs.length].color,
        }}
        className={` text-sm h-[30px] flex justify-center items-center w-[30px] rounded-full font-bold`}
      >
        {index + 1}
      </div>
      <div className="text-white text-xs w-5/12">{name}</div>
      <div className=" flex justify-end  w-4/12 py-3 px-auto">
        <FaArrowRight
          className="transition-all"
          style={{ transform: `rotateZ(${rotation}deg)` }}
        />
      </div>

      {rotation == 45 && (
        <div
          style={{
            height: `${subTasks.length * height}px`,
            transition: "height 0.5s",
          }}
          className={`  rounded-bl-2xl rounded-br-2xl absolute overflow-hidden transition-all group p-3 w-full left-0 top-[48px] border-b border-l border-r  border-[#585d61] z-20  bg-[#081c2f]`}
        >
          <div className="flex h-full flex-col justify-between">
            {subTasks.map((item, i) => {
              return (
                <div className="text-xs truncate" key={item.name + index}>
                  {item.name}
                </div>
              );
            })}
          </div>
          {/* {JSON.stringify(subTasks)} */}
        </div>
      )}
    </div>
  );
}
