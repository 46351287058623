import React, { useState } from "react";
import { LuPencil } from "react-icons/lu";
import { IoSaveOutline } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdDeleteOutline, MdOutlineCancel } from "react-icons/md";

import { toast } from "sonner";

function Options({ title, id, setOptions, options }) {
  const [optionInput, setOptionInput] = useState("");
  const [showInput, setShowInput] = useState(false);
  console.log(options);
  const addToOptions = () => {
    setOptions([...options, optionInput]);
  };
  const deleteoption = (optionName) => {
    const newoptions = options.filter((option) => option !== optionName);
    setOptions(newoptions);
  };
  const editoption = (option, newName) => {
    const newoptions = options.map((opt) => {
      if (opt === option) {
        return newName;
      }
      return opt;
    });
    setOptions(newoptions);
  };
  // console.log(options);
  return (
    <div className="px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative border-dashed">
      <div className="flex  mb-4 justify-between items-center">
        <label className="text-sm text-white">{title}</label>
      </div>

      <div>
        {options.map((option) => {
          console.log(option);
          return (
            <Option
              key={option}
              title={option}
              option={option}
              editoption={editoption}
              deleteoption={deleteoption}
            />
          );
        })}
      </div>
      {showInput && (
        <div className="flex pl-2 justify-between items-center mb-6">
          <input
            onChange={(e) => setOptionInput(e.target.value)}
            type="text"
            value={optionInput}
            className="text-xs placeholder:text-xs w-full mr-8 text-[#f2f2f2] bg-transparent focus:outline-none"
            placeholder="Enter option name"
          />
          <button
            className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
            onClick={() => {
              if (optionInput !== "") {
                console.log(optionInput);
                addToOptions();
                setOptionInput("");
                setShowInput(false);
              } else {
                toast.error("Option name cannot be empty");
              }
              // deleteoption(option.id);
            }}
          >
            <IoSaveOutline />
          </button>
          <button
            className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
            onClick={() => {
              setShowInput(false);
              setOptionInput("");
            }}
          >
            <MdOutlineCancel size={18} />
          </button>
        </div>
      )}
      <button
        onClick={() => setShowInput(true)}
        className=" cursor-pointer  mt-2  rounded-lg items-center hover:border-transparent flex hover:bg-[#302a74] hover:text-[#f2f2f2] py-1.5 px-2"
      >
        <IoIosAddCircleOutline size={20} color="#42abdf" />
        <span className="text-[#42abdf] ml-2 text-sm font-semibold">
          Add more
        </span>
      </button>
    </div>
  );
}

export default Options;

const Option = ({ option, deleteoption, editoption }) => {
  const [isEditing, setEditing] = useState(false);
  const [input, setInput] = useState(option);

  return (
    <div className="flex justify-between gap-2 items-center">
      {isEditing ? (
        <input
          className="text-xs px-2 outline-none bg-transparent"
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      ) : (
        <div className="text-[#868f9c] px-2 text-xs">{option}</div>
      )}
      <div className="flex">
        <button
          className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
          onClick={() => {
            setEditing(!isEditing);
            if (isEditing) {
              editoption(option, input);
            }
            // deleteoption(option.id);
          }}
        >
          {isEditing ? (
            <IoSaveOutline />
          ) : (
            <LuPencil className="text-blue-500" size={18} />
          )}
        </button>
        <button
          className=" flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2]  py-1.5 px-2 "
          onClick={() => {
            if (!isEditing) {
              deleteoption(option);
            } else {
              setEditing(false);
            }
          }}
        >
          {isEditing ? (
            <MdOutlineCancel size={18} />
          ) : (
            <MdDeleteOutline className="text-red-500" size={18} />
          )}
        </button>
      </div>
    </div>
  );
};
