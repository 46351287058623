import React, { useEffect } from "react";
import InputField from "./InputField";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "./InputLabel";
import axios from "axios";
import { useCookie } from "react-use";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
const api = process.env.REACT_APP_BACKEND_API_URL;
function EstateForm({ party1Data, caseData, setCaseData }) {
  const history = useHistory();
  const [values] = useCookie("user");

  const userCookie = JSON.parse(values);
  const schema = yup
    .object()
    .shape({
      representativeName: yup
        .string()
        .required("Representative name is required"),
      representativePhone: yup.string().matches(/^\+?\d+$/, "Must be a number"),
      representativeEmail: yup.string().email("Invalid email"),
      deathCertificateFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      beneficiariesFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      familyResolutionFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      masterConsentFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      kgosiLetterFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      lettersOfAdminFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
    })
    .required();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  console.log("################Party1####################");
  //
  party1Data.map((party) => {
    for (var pair of party.entries()) {
      if (typeof party.get(pair[0]) == "object") {
        console.log(party.get(pair[0]), "marriageCertificate");
      } else {
        console.log(pair[0] + " - " + pair[1]);
      }
    }
  });
  const queryClient = useQueryClient();

  const onSubmit = (data) => {
    console.log(data);
    setFormSubmitting(true);

    var form_data = new window.FormData();
    // form_data.append("titleDeed", data.beneficiariesFile[0]);
    // form_data.append("offerLetter", data.offerLetterFile[0]);
    // form_data.append("valuationReport", data.valuationReportFile[0]);
    // form_data.append("startDate", new Date());
    // form_data.append("endDate", new Date());
    // setCaseData(form_data);
    // let sucessfull = true;
    // axios
    //   .post(`${api}/api/v1/individual-to-individual`, form_data, {
    //     headers: { "Content-Type": "multipart/form-data" },
    //   })
    //   .then(async (res) => {
    //     [...party1Data].forEach(function (formData) {
    //       formData.set("caseId", res.data.id);

    //       axios
    //         .post(`${api}/api/v1/individuals`, formData, {
    //           headers: { "Content-Type": "multipart/form-data" },
    //         })
    //         .then(function (response) {
    //           console.log(response.data);
    //         })
    //         .catch(function (error) {
    //           // Handle error
    //           sucessfull = false;
    //           console.error("Error:", error);
    //           alert("An error occured", JSON.stringify(error));
    //         });
    //     });
    //     if (sucessfull === true) {
    //       await axios.post(
    //         `"https://collins-chilisa.testlive.space"/api/v1/logs`,
    //         {
    //           activity: "Case updated",
    //           userId: userCookie.id,
    //           timestamp: new Date().toISOString(), // Get current date and time in ISO format
    //         }
    //       );
    //       toast.success("Sucesss", {
    //         description: "Case successfully created",
    //       });
    //       queryClient.invalidateQueries({ queryKey: ["individual-cases"] });
    //       history.push("/app/home");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     toast.error("An error occured", { description: JSON.stringify(err) });
    //   });
  };
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  return (
    <form>
      <div className="border-b bg-red border-gray-900/10 mb-10 w-screen container">
        <div className=" grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mx-10 xl:mx-80 bg-[#102232]  rounded-3xl  px-10 pt-10 pb-6 shadow-lg  border border-[#585d61]">
          <>
            <InputField
              id="representativeName"
              type="text"
              label={"Representative Name *"}
              register={register}
              isErrored={errors.representativeName}
              message={errors.representativeName?.message}
              placeholder={"Enter the name of the representative here."}
              name="representativeName"
            />
            <InputField
              id="representativePhone"
              type="text"
              label={"Representative phone *"}
              placeholder={"Enter the phone number here."}
              register={register}
              isErrored={errors.representativePhone}
              message={errors.representativePhone?.message}
              name="representativePhone"
            />
            <InputField
              id="representativeEmail"
              type="text"
              label={"Representative email *"}
              isErrored={errors.representativeEmail}
              message={errors.representativeEmail?.message}
              placeholder={"Enter the email here."}
              register={register}
              name="representativeEmail"
            />

            <InputLabel
              htmlFor={"deathCertificateFile"}
              title={"Attach Death Certificate *"}
              message={errors.deathCertificateFile?.message}
              isErrored={errors.deathCertificateFile}
              toolTip={
                <p>
                  Please attach an electronic copy of the death certificate,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              hasFile={watch("deathCertificateFile")?.length > 0}
              subtitle={
                watch("deathCertificateFile")?.length > 0
                  ? watch("deathCertificateFile")[0].name
                  : "click to upload death cerfiticate here..."
              }
            />

            <InputField
              id={"deathCertificateFile"}
              type="file"
              register={register}
              name={"deathCertificateFile"}
            />
            <InputLabel
              htmlFor={"beneficiariesFile"}
              title={"Attach beneficiaries file *"}
              message={errors.beneficiariesFile?.message}
              isErrored={errors.beneficiariesFile}
              hasFile={watch("beneficiariesFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the beneficiaries Id's,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              subtitle={
                watch("beneficiariesFile")?.length > 0
                  ? watch("beneficiariesFile")[0].name
                  : "click to upload beneficiaries file here..."
              }
            />
            <InputField
              id="beneficiariesFile"
              type="file"
              register={register}
              name="beneficiariesFile"
            />
            <InputLabel
              htmlFor={"familyResolutionFile"}
              title={"Attach a family resolution *"}
              message={errors.familyResolutionFile?.message}
              isErrored={errors.familyResolutionFile}
              hasFile={watch("familyResolutionFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the family resolution,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              subtitle={
                watch("familyResolutionFile")?.length > 0
                  ? watch("familyResolutionFile")[0].name
                  : "click to upload family resolution here..."
              }
            />
            <InputField
              register={register}
              type="file"
              name="familyResolutionFile"
              id="familyResolutionFile"
            />

            <InputLabel
              htmlFor={"masterConsentFile"}
              title={"Attach master consent file *"}
              message={errors.masterConsentFile?.message}
              isErrored={errors.masterConsentFile}
              hasFile={watch("masterConsentFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the Master's consent,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              subtitle={
                watch("masterConsentFile")?.length > 0
                  ? watch("masterConsentFile")[0].name
                  : "click to upload a master consent here..."
              }
            />
            <InputField
              register={register}
              type="file"
              name="masterConsentFile"
              id="masterConsentFile"
            />

            {/* <InputField
              id={"familyIds"}
              type="file"
              register={register}
              name={"familyIds"}
            />
            <InputLabel
              htmlFor={"familyIds"}
              title={"Attach Family Ids *"}
              message={errors.familyIds?.message}
              hasFile={watch("familyIds")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the family's Ids ,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              isErrored={errors.familyIds}
              subtitle={
                watch("familyIds")?.length > 0
                  ? watch("familyIds")[0].name
                  : "click to upload  family ids  here..."
              }
            /> */}
            <InputLabel
              htmlFor={"kgosiLetterFile"}
              title={"Attach letter from Kgosi *"}
              message={errors.kgosiLetterFile?.message}
              isErrored={errors.kgosiLetterFile}
              hasFile={watch("kgosiLetterFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of theletter from Kgosi,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              subtitle={
                watch("kgosiLetterFile")?.length > 0
                  ? watch("kgosiLetterFile")[0].name
                  : "click to upload KYC Documentation here..."
              }
            />
            <InputField
              register={register}
              type="file"
              name="kgosiLetterFile"
              id="kgosiLetterFile"
            />
            <InputLabel
              htmlFor={"lettersOfAdminFile"}
              title={"Attach letters of admin *"}
              message={errors.lettersOfAdminFile?.message}
              isErrored={errors.lettersOfAdminFile}
              hasFile={watch("lettersOfAdminFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the letters of admin,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              subtitle={
                watch("lettersOfAdminFile")?.length > 0
                  ? watch("lettersOfAdminFile")[0].name
                  : "click to upload letters of admin here..."
              }
            />
            <InputField
              register={register}
              type="file"
              name="lettersOfAdminFile"
              id="lettersOfAdminFile"
            />

            <div className="flex items-center justify-center cursor-pointer sm:col-span-1 mt- md:col-span-1 xl:col-span-2">
              {formSubmitting ? (
                <button className="rounded-lg bg-[#2b2d77] text-xs h-[40px]  text-white px-12 py-2">
                  <span className="loading loading-bars loading-xs"></span>
                </button>
              ) : (
                <div className="flex justify-end w-full">
                  <input
                    type="button"
                    disabled={formSubmitting}
                    value={"Finish"}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(onSubmit)();
                    }}
                    className="rounded-lg bg-[#2b2d77] text-xs  text-white px-12 py-3"
                  />
                </div>
              )}
            </div>
          </>
        </div>
      </div>
    </form>
  );
}

export default EstateForm;
