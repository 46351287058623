import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Onboarding from "../onboarding-views/onboarding";
import EditParty from "./EditParty";
import FormBuilder2 from "./FormBuilder2";
// import FormBuilder from "./FormBuilder";

// import EditParty from "./EditParty";

export const OnBoard_PREFIX_PATH = "/onboard";
export const OnBoardViews = () => {
  return (
    <Switch>
      <Route
        path={`${OnBoard_PREFIX_PATH}/edit-party/:caseId`}
        component={EditParty}
      />
      <Route
        path={`${OnBoard_PREFIX_PATH}/form_builder`}
        component={FormBuilder2}
      />

      <Route path={OnBoard_PREFIX_PATH} component={Onboarding} />
    </Switch>
  );
};

export default OnBoardViews;
