import React, { useRef, useEffect, useState } from "react";
import {
  MagnifyingGlassIcon,
  ArrowDownTrayIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import caseImg from "../../components/case.svg";
import { Breadcrumbs } from "@material-tailwind/react";
import { EyeIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import { PiEyeThin } from "react-icons/pi";
import { VscAdd } from "react-icons/vsc";
import { RiDeleteBin6Line } from "react-icons/ri";
import { LuCalendarRange } from "react-icons/lu";
import { LuClipboardEdit } from "react-icons/lu";
import { BiAlarm, BiSolidChevronDown } from "react-icons/bi";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PiSuitcaseSimple } from "react-icons/pi";
import { exportToExcel } from "./Excel";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";
import axios from "axios";
import InputField from "../../components/InputField";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "sonner";
import { Avatar } from "@material-tailwind/react";
import { useUserQuery } from "../../hooks/useUserQuery";
import { BeatLoader, ClipLoader } from "react-spinners";
import { useDialog, useModal } from "../../hooks/useModal";
import Pagination from "../../components/Pagination";
import ReportDateRange from "../../components/ReportDateRnage";
// import DateInput from "../../components/DateRange";
// import { register } from "module";
const TABLE_HEAD = [
  "Transaction Name",
  "Instruction Owner",
  "Created By",
  "Transaction Status",
  "Registration Date",
  "Actions",
];
const api = process.env.REACT_APP_BACKEND_API_URL;
export default function CasesList() {
  const history = useHistory();
  const [TABLE_ROWS, setDataTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [queryReport, setReportQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const [matchedSearch, setMatchedSearch] = React.useState([]);
  const [selectedRangeDates, setSelectedRangeDates] = useState();
  const [selectedReport, setReportData] = useState();
  const [reportName, setReportName] = useState();
  const ReportDates = (data) => {
    console.log("data");
    setSelectedRangeDates(data);

    console.log("data");
  };

  const onHandleSearch = (e) => {
    axios
      .get("https://collins-chilisa.testlive.space/api/v1/report-templates")
      .then((res) => {
        const reports = res.data.data;
        const results = matchedSearch.filter((item) =>
          item.reportType.toLowerCase().includes(queryReport.toLowerCase())
        );

        console.log("reports")
        console.log(reports)
        console.log("reports")

        if (queryReport === "") {
          setMatchedSearch(reports);
        } else {
          setMatchedSearch(results ? results : results);
        }
      });
  };
  useEffect(() => {
    onHandleSearch();
  }, [queryReport]);

  const inputRef = React.useRef(null);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const schema = yup
    .object()
    .shape({
      name: yup.string().required(),
      email: yup.string().email().required(),
      userId: yup.string().nullable(),
    })
    .required();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const search = async () => {
    try {
      let response = await axios.get(`${api}/api/v1/transactions`);
      response = response.data.data;

      // Filter the response data based on the query
      let filteredData = response.filter((item) => {
        // Check if name, id, or project of the item matches the query
        return (
          item.transactionName.toLowerCase().includes(query.toLowerCase()) ||
          item.reportType.toLowerCase().includes(query.toLowerCase()) ||
          item.collectionName.toLowerCase().includes(query.toLowerCase()) ||
          item.createdAt.toLowerCase().includes(query.toLowerCase()) ||
          item.createdBy.toLowerCase().includes(query.toLowerCase())
        );
      });
      filteredData = filteredData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      if (filteredData) {
        console.log("filteredData");
        console.log(filteredData);
        setDataTable(filteredData);
        setLoading(false);
      } else if (query === "") {
        setLoading(false);
        console.log(response);
        setDataTable(response);
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    search();
  }, [query]);

  const modal = useModal();
  const dialog = useDialog();
  const Delete = (id, collectionName) => {
    modal.showModal("Deleting case", "Please wait...");
    try {
      axios
        .delete(`${api}/api/v1/transactions/${collectionName}/${id}`)
        .then((res) => {
          modal.closeModal();
          dialog.showModal("Success", "Case deleted successfully");
          search();
        });
    } catch (error) {
      modal.closeModal();

      console.log(error);
    }
  };

  useEffect(() => {
    if (open === true) {
      document.getElementById("userform").showModal();
    }
  }, [open]);

  const [creating, setCreating] = useState(false);
  const [modalTitle, setModalTitle] = useState("Create New User");

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = TABLE_ROWS.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const DisplayDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, "0");
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const monthName = monthNames[monthIndex].substr(0, 3); // Get the first three letters of the month name

    return `${day}-${monthName}-${year}`;
  };

  const tasksData = [
    { date: "2024-06-01", task: "Task 1" },
    { date: "2024-06-02", task: "Task 2" },
    { date: "2024-06-03", task: "Task 3" },
    { date: "2024-06-04", task: "Task 4" },
    { date: "2024-06-05", task: "Task 5" },
    { date: "2024-06-06", task: "Task 6" },
    // Add more tasks as needed...
  ];

  return (
    <div className="container h-[100vh]  flex-col flex  mx-auto">
      <div className="mt-44 mb-6 mx-10">
        <label className="text-[#e9e9e9] text-[21px]"> View all Cases</label>
        <Breadcrumbs className="text-[#e9e9e9]" separator="-">
          <a href="#" className="opacity-60">
            Dashboard
          </a>
          <a href="#">Cases</a>
        </Breadcrumbs>
      </div>

      <Card className="bg-[#102232] shadow-[60%] py-8 mx-11  border border-[#5455551a]">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none bg-transparent my-1 text-[#e9e9e9]"
        >
          <div className="mb-2 flex items-center justify-between mx-10 gap-8">
            <div>
              <label className="h-fit w-fit border rounded-lg py-2 px-2 border-[#454545] bg-transparent  flex items-center gap-2">
                <input
                  type="text"
                  onChange={(e) => {
                    setQuery(e.target.value);
                  }}
                  className="bg-transparent outline-none"
                  placeholder="Search"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="w-4 h-4 opacity-70"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                    clipRule="evenodd"
                  />
                </svg>
              </label>
            </div>
            <div className="flex  gap-4   sm:flex-row">
              <button
                className="flex items-center px-4 py-2 rounded-lg border border-[#] justify-center bg-transparent font-normal text-sm border-[#454545] gap-1 text-[#e9e9e9]"
                onClick={() =>
                  document.getElementById("my_modal_5").showModal()
                }
              >
                <AiOutlineCloudDownload
                  strokeWidth={2}
                  className="h-4 w-fit "
                />
                Download Reports
              </button>

              <button
                className="flex items-center px-4 py-2 rounded-lg text-sm bg-[#2b2d77] font-normal gap-1 text-[#e9e9e9]"
                onClick={() => {
                  history.push(`/transactions/new`);
                }}
              >
             <VscAdd  className="h-4 w-4 " />
                New Case
              </button>
            </div>
          </div>
        </CardHeader>

        <CardBody className="text-white mx-10">
          <table className="mt-4 w-full min-w-max table-auto ">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className="cursor-pointer  bg-blue-gray-50/50 py-4 transition-colors text-start bg-[#162e42]"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                    >
                      {head}{" "}
                      {index !== TABLE_HEAD.length - 1 && (
                        <ChevronUpDownIcon
                          strokeWidth={2}
                          className="h-4 w-4"
                        />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading
                ? Array.from({ length: 3 }).map((_, index) => (
                    <tr className="py-2">
                      <td>
                        <div className="skeleton h-[56px] w-[56px]"></div>
                      </td>
                      <td>
                        <div className="skeleton h-4 w-11/12"></div>
                      </td>
                      <td>
                        <div className="skeleton h-4 w-10/12"></div>
                      </td>
                      <td>
                        <div className="skeleton h-4 w-12"></div>
                      </td>{" "}
                      <td>
                        <div className="skeleton h-4 w-full"></div>
                      </td>
                    </tr>
                  ))
                : currentPosts.map(
                    (
                      {
                        collectionName,
                        transactionName,
                        createdBy,
                        createdAt,
                        reportType,
                        status,
                        _id,
                      },
                      index
                    ) => {
                      
                      const isLast = index === TABLE_ROWS.length - 1;
                      const classes = isLast ? "py-2" : "py-2 border-none";

                      return (
                        <tr key={_id} className="text-[#bbbbbb]">
                          <td className={classes}>
                            <div className="flex items-center gap-2">
                              <div className="w-10 rounded">
                                <img
                                  className="h-10 bg-center bg-no-repeat"
                                  src={caseImg}
                                  alt="Tailwind-CSS-Avatar-component"
                                />
                              </div>
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  className="font-normal text-[#bbbbbb]"
                                >
                                  {transactionName}
                                </Typography>
                                <Typography
                                  variant="small"
                                  className="text-xs font-normal text-[#bbbbbb]"
                                >
                                  {collectionName}
                                </Typography>
                              </div>
                            </div>
                          </td>

                          <td className={classes}>
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {reportType}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {createdBy}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {status == "to-do" && (
                                <span className="flex items-center gap-2">
                                  <span className="bg-red-700 rounded-full h-2 w-2" />{" "}
                                  Todo
                                </span>
                              )}
                              {status == "in-progress" && (
                                <span className="flex items-center gap-2">
                                  <span className="bg-yellow-700 rounded-full h-2 w-2" />{" "}
                                  In Progress
                                </span>
                              )}

                              {status == "done" && (
                                <span className="flex items-center gap-2">
                                  <span className="bg-green-700 rounded-full h-2 w-2" />{" "}
                                  Completed
                                </span>
                              )}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {DisplayDate(createdAt)}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <div className="flex">
                              <Tooltip content="View case">
                                <IconButton
                                  className="flex justify-center items-center"
                                  onClick={() => {
                                    history.push(
                                      `/app/cases/${_id}?collectionName=${collectionName}`
                                    );
                                  }}
                                  variant="text"
                                >
                                  <LuClipboardEdit size={20} color="#ccc" />
                                </IconButton>
                              </Tooltip>

                              <Tooltip content="Delete case">
                                <IconButton
                                  className="flex justify-center items-center"
                                  onClick={() => {
                                    Delete(_id, collectionName);
                                  }}
                                  variant="text"
                                >
                                  <RiDeleteBin6Line size={20} color="red" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
            </tbody>
          </table>
        </CardBody>

        <CardFooter className="flex py-1 px-3 sm:px-10 lg:px-12  mx-1 ">
          <Pagination
            currentPage={currentPage}
            postsPerPage={postsPerPage}
            totalPosts={TABLE_ROWS.length}
            paginate={paginate}
          />
        </CardFooter>
      </Card>
      <dialog id="my_modal_5" className="modal modal-bottom sm:modal-middle ">
        <div className="modal-box  overflow-visible bg-[#102232]">
          <h3 className="font-bold  text-lg">Select Report Type</h3>

          <div className="w-full bg-[#071b2e] mt-6 rounded-box  border-none">
            <div
              tabIndex={0}
              role="button"
              className="min-h-12 gap-5 justify-between  pl-5 pr-2 items-center flex text-start btn-ghost rounded-lg border-[#2f3740] w-full"
            >
              <div className=" w-11/12 flex items-center gap-5 cursor-pointer">
                <LuCalendarRange />
                <ReportDateRange onclick={ReportDates} />
              </div>
            </div>
          </div>

          <div className="w-full bg-[#071b2e] mt-6 rounded-box  border-none">
            <div className="dropdown w-full dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="min-h-12  gap-5 justify-between  pl-5 pr-2 items-center flex text-start btn-ghost rounded-lg border-[#2f3740] w-full"
              >
                <div className=" w-11/12 flex items-center gap-5 cursor-pointer">
                  <PiSuitcaseSimple />
                  <input
                    value={queryReport || reportName}
                    name="query"
                    className="bg-transparent cursor-pointer focus:outline-none w-11/12"
                    onChange={(e) => setReportQuery(e.target.value)}
                    placeholder="Type to search report type"
                  />
                </div>
                <BiSolidChevronDown size={25} />
              </div>

              <ul
                 tabIndex={0}
                className="menu dropdown-content border bg-[#102232] z-[1] p-2 w-full shadow bg-bae-100 rounded-box border-[#2f3740] mt-4"
              >
                {matchedSearch.slice(0, 4).map((el) => (
                  <li className="b" key={el._id}>
                    <span
                      onClick={() => {
                        setReportName(el.reportName)
                        setReportData(el);
                      }}
                    >
                      {el.reportName}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="modal-action">
            <form method="dialog">
              <div className="flex items-center gap-3">
                <button className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[5px] px-[40px] mt-0 text-white p-3 hover:scale-105 duration-300 h-[35px]">
                  Close
                </button>
                <button onClick={()=>{
                 const reportType=selectedReport ;
                 const dates=selectedRangeDates
                 exportToExcel(reportType, dates)  
                }} disabled ={!selectedReport && !selectedRangeDates} className={`${selectedReport && selectedRangeDates ? "bg-[#2b2d77] text-white hover:scale-105 border-[#2b2d77] border" : "bg-[#102232]  text-gray-700 border-gray-700 border"}  align-center flex justify-center text-sm items-center rounded-[5px] px-[40px] mt-0  p-3  duration-300 h-[35px] `}>
                  Get Report
                </button>
              </div>
            </form>
          </div>
        </div>
      </dialog>
    </div>
  );
}
