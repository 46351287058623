import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Login from "../auth-views/login";
import ForgotPassword from "../auth-views/ForgotPassword";
import ResetPassword from "../auth-views/PasswordReset";


export const AuthViews = () => {
  const AUTH_PREFIX_PATH = "/auth";
  return (
   
    <Switch>
      <Route path={`${AUTH_PREFIX_PATH}/login`} component={Login} />
      <Route path={`${AUTH_PREFIX_PATH}/forgot_password`} component={ForgotPassword} />
        <Route path={`${AUTH_PREFIX_PATH}/reset_password`} component={ResetPassword} />

      {/* <Route path={`${AUTH_PREFIX_PATH}/engines`} component={Engines} /> */}

      <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
    </Switch>
  );
};

export default AuthViews;
