import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../utils/constants";
import {
  MdFormatListBulletedAdd,
  MdOutlineCheckBox,
  MdTextFields,
} from "react-icons/md";
import { TfiHandDrag } from "react-icons/tfi";
import { TiAttachmentOutline } from "react-icons/ti";
import { CiCalendarDate } from "react-icons/ci";
import { useEffect, useState } from "react";
import { generateRandomLetters, toSnakeCase } from "../utils/helpers";
import { FaCaretDown } from "react-icons/fa";
export function DraggableTextField({ isDisabled }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.INPUTS,
    item: { type: "text" },
    canDrag: !isDisabled,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <div
      className={`${
        isDisabled ? "text-gray-600  cursor-not-allowed" : " cursor-pointer"
      }`}
      ref={drag}
    >
      <p className="flex  border-b-[#2c3c4b] border-b-2 gap-2 pb-1.5">
        <MdTextFields size={24} />
        Text input
      </p>
    </div>
  );
}

export function DraggableSelectField({ isDisabled }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.INPUTS,
    item: { type: "select" },
    canDrag: !isDisabled,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <div
      className={`${
        isDisabled ? "text-gray-600  cursor-not-allowed" : " cursor-pointer"
      }`}
      ref={drag}
    >
      <p className="flex items-center  border-b-[#2c3c4b] border-b-2 gap-2 pb-1.5">
        <FaCaretDown size={25} />
        Drop Down
      </p>
    </div>
  );
}
export function DraggableRadioField({ isDisabled }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.INPUTS,
    item: { type: "radio" },
    canDrag: !isDisabled,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <div
      className={`${
        isDisabled ? "text-gray-600  cursor-not-allowed" : " cursor-pointer"
      }`}
      ref={drag}
    >
      <p className="flex items-center  border-b-[#2c3c4b] border-b-2 gap-2 pb-1.5">
        <MdOutlineCheckBox size={25} />
        Radio buttons
      </p>
    </div>
  );
}
export function DraggableAttachment({ isDisabled }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.INPUTS,
    item: { type: "file" },
    canDrag: !isDisabled,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <div
      className={`${
        isDisabled ? "text-gray-600  cursor-not-allowed" : " cursor-pointer"
      }`}
      ref={drag}
    >
      <p className="flex   border-b-2  border-b-[#2c3c4b] gap-2 pb-1.5">
        <TiAttachmentOutline size={24} />
        Attachment
      </p>
    </div>
  );
}
export function DraggableDate({ isDisabled }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.INPUTS,
    item: { type: "date" },
    canDrag: !isDisabled,

    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <div
      className={`${
        isDisabled ? "text-gray-600  cursor-not-allowed" : " cursor-pointer"
      }`}
      ref={drag}
    >
      <p className="flex items-center border-b-2  border-b-[#2c3c4b] gap-2 pb-1.5">
        <CiCalendarDate size={24} />
        Date
      </p>
    </div>
  );
}
export function DraggableTasks({ isDisabled }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    canDrag: !isDisabled,
    type: ItemTypes.TASKS,
    item: { type: "tasks" },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <div
      className={`${
        isDisabled ? "text-gray-600  cursor-not-allowed" : " cursor-pointer"
      }`}
      ref={drag}
    >
      <p className="flex items-center border-b-2  border-b-[#2c3c4b] gap-2 pb-1.5">
        <MdFormatListBulletedAdd size={24} />
        List/Tasks
      </p>
    </div>
  );
}
// export function DraggableEndDate({ isDisabled }) {
//   const [{ isDragging }, drag] = useDrag(() => ({
//     type: ItemTypes.INPUTS,
//     canDrag: !isDisabled,
//     item: { type: "date" },

//     collect: (monitor) => ({
//       isDragging: !!monitor.isDragging(),
//     }),
//   }));
//   return (
//     <div
//       className={`${
//         isDisabled ? "text-gray-600  cursor-not-allowed" : " cursor-pointer"
//       }`}
//       ref={drag}
//     >
//       <p className="flex items-center border-b-2  border-b-[#2c3c4b] gap-2 pb-1.5">
//         <CiCalendarDate size={24} />
//         End Date
//       </p>
//     </div>
//   );
// }

export function DropableComponent({
  setDroppedComponents,
  droppedComponents,
  shape,
  setShape,
  accept,
}) {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: accept,
      drop: (e) => {
        let fieldname = "Task " + generateRandomLetters();

        console.log(droppedComponents.find((el) => el.label == fieldname));
        // console.log(droppedComponents[droppedComponents.length]);
        let name = "";
        let placeholder = `Enter ${fieldname} here...`;
        if (e.type === "select") {
          // fieldname = "Select " + droppedComponents.length;
          fieldname = "Select field " + generateRandomLetters();
          name = toSnakeCase(fieldname);
          placeholder = `click arrow to select ${fieldname} here...`;
        }
        if (e.type === "radio") {
          // fieldname = "Radio " + droppedComponents.length;
          fieldname = "Radio field " + generateRandomLetters();
          name = toSnakeCase(fieldname);
          placeholder = `choose ${fieldname} here...`;
        }
        if (e.type === "text") {
          // fieldname = "Radio " + droppedComponents.length;
          fieldname = "Text field " + generateRandomLetters();
          name = toSnakeCase(fieldname);
          placeholder = `enter ${fieldname} here...`;
        }
        if (e.type === "file") {
          // fieldname = "Radio " + droppedComponents.length;
          fieldname = "Attachment field " + generateRandomLetters();
          name = toSnakeCase(fieldname);
          placeholder = `click to upload ${fieldname} here...`;
        }
        if (e.type === "date") {
          // fieldname = "Radio " + droppedComponents.length;
          fieldname = "Date field " + generateRandomLetters();
          name = toSnakeCase(fieldname);
          placeholder = `choose ${fieldname} here...`;
        }
        console.log(name);
        setDroppedComponents([
          ...droppedComponents,
          e.type === "tasks"
            ? {
                id: Date.now(),
                type: e.type,
                placeholder,
                label: fieldname,
                name,
                startDate: new Date(),
                endDate: new Date(),
                subTasks: [],
              }
            : {
                id: Date.now(),
                type: e.type,
                placeholder,
                label: fieldname,
                name,
              },
        ]);
        if (e.type === "tasks") {
          return;
        }
        setShape((prev) => {
          return [...prev, { [name]: [["yup.string"], ["yup.required"]] }];
        });
      },
      hover: (item, monitor) => {
        monitor.isOver();
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [droppedComponents]
  );
  return (
    <div ref={drop}>
      <h2
        className={`${
          isOver && "bg-gray-800"
        } space-y-8 flex-col  text-[#585d61]  border-dashed border border-[#495968] rounded-xl min-h-32 mx-12 font-medium justify-center flex-center flex items-center 	mt-8 mb-2`}
      >
        <TfiHandDrag size={30} />
        {droppedComponents?.length > 0
          ? "Drop items here"
          : "  Drop items here to get started..."}
      </h2>
    </div>
  );
}
