import React, { useRef, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { loginSuccess } from "../../store/authSlice";
import logo from "../../images/logo.png";
import loginImage from "../../images/loginImage.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { useDispatch } from "react-redux";
import InputField from "../../components/InputField";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "sonner";
import { useCookie } from "react-use";
import { BeatLoader, ScaleLoader, SyncLoader } from "react-spinners";
const navigation = [
  //   { name: "Product", href: "#" },
  //   { name: "Features", href: "#" },
  //   { name: "Marketplace", href: "#" },
  //   { name: "Company", href: "#" },
];
const api = process.env.REACT_APP_BACKEND_API_URL;

export default function Login() {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const history = useHistory();

  const handleForm = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  // const [value, updateCookie, deleteCookie] = useCookie("user");

  const schema = yup
    .object()
    .shape({
      email: yup.string().required(),
      password: yup.string().required(),
    })
    .required();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [value, updateCookie, deleteCookie] = useCookie("user");

  const user = JSON.parse(value);

  console.log(user);
  if (user?.jwt) {
    console.log(user?.role);
    dispatch(loginSuccess(user));
    if (user?.role === "superAdmin") {
      history.replace("/app/dashboard");
    } else {
      history.replace("/app/home");
    }
  }
  // Sample user type
  // console.log(user.role);
  const [isLoading, setIsLoading] = useState(false);
  const userType = user?.role || "";
  const signIn = async (data) => {
    console.log(data);
    setIsLoading(true);
    try {
      const response = await axios.post(`${api}/api/v1/auth/login`, data);
      console.log(response.data.data.user);
      const role =
        response.data.data.user.role.toLowerCase() == "user"
          ? "admin"
          : "superAdmin";
      console.log(role);
      response.data.data.user.role = role;
      const userr = { ...response.data.data.user, jwt: response.data.token };
      localStorage.setItem("user", userr);
      console.log(userr);
      updateCookie(JSON.stringify(userr));
      toast.success("Login Successful");
      // await axios.post(`"https://collins-chilisa.testlive.space"/api/v1/logs`, {
      //   activity: "Logged In",
      //   userId: response.data.user.id,
      //   timestamp: new Date().toISOString(), // Get current date and time in ISO format
      // });
      dispatch(loginSuccess(userr));
      history.push("/app");
    } catch (error) {
      console.log(error);
      toast.error("Login Unsuccessful", {
        description: "Please check your credentials and try again",
      });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex flex-col justify-center items-center">
      <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-12 w-auto" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>

          <div className="hidden lg:items-center lg:gap-4 lg:flex lg:flex-1 lg:justify-end">
            <Link
              to="/auth/forgot_password"
              className="rounded-xl bg-transparent  px-7 py-1.5 border border-[#636c7062] font-m text-sm font-semibold bg-indigo-500 text-[#7a7a7a65] shadow-sm text-white hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Reset Password
            </Link>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      {/* <div className="relative isolate lg:flex lg:justify-center lg:items-center px-6 pt-14 lg:px-8"> */}

      {/* login container */}
      <label className="text-white mb-4 text-xl font-">
        <span className="font-light">Login</span>
      </label>
      <div className="bg-[#102232] flex rounded-3xl   shadow-lg max-w-3xl border border-[#585d61]">
        {/* image */}
        <div className="md:block hidden w-1/2">
          <img className="rounded-l-2xl" src={loginImage} alt="Login" />
        </div>

        {/* form */}
        <div className="md:w-1/2 px-8 py-16 lg:pt-10 md:pt-10 md:px-4">
          <form className="flex flex-col gap-4">
            <InputField
              register={register}
              name={"email"}
              type="email"
              message={errors.email?.message}
              isErrored={errors.email}
              placeholder={"Enter your email address"}
              label={"Full names / Email Address*"}
              className="mt-3 text-xs focus:outline-none focus:bg-transparent bg-transparent text-[#a6adbb]"
            />
            <InputField
              register={register}
              name={"password"}
              type="password"
              message={errors.password?.message}
              isErrored={errors.password}
              placeholder={"Enter your password "}
              label={"Password*"}
              className="mt-3 text-xs focus:outline-none focus:bg-transparent bg-transparent text-[#a6adbb]"
            />

            <div className="mt-3 items-center flex flex-col text-gray-400 px-">
              <p className="text-center text-xs font-thin text-white ">
                <span className="text-[#3b97c6] font-medium">Attention</span>:
                Please note that by using to this platform <br /> you agree to{" "}
                <br />
                the
                <span className="text-[#3b97c6] font-medium underline">
                  {" "}
                  Terms & Conditions
                </span>{" "}
                and{" "}
                <span className="text-[#3b97c6] font-medium underline">
                  Privacy Policies
                </span>
              </p>
              <button
                disabled={isLoading}
                className="bg-[#002D74] align-center flex justify-center text-sm items-center rounded-[9px] px-[75px] mt-5 text-white p-3 hover:scale-105 duration-300 h-[35px]"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(signIn)();
                }}
              >
                {isLoading ? <BeatLoader size={10} color="#fff" /> : " Log-In"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
