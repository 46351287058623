import React, { useEffect, useRef, useState } from "react";
import logo from "../../images/logo.png";
import { MdTextFields } from "react-icons/md";
import { TiAttachmentOutline } from "react-icons/ti";
import { CiCalendarDate } from "react-icons/ci";
import { TfiHandDrag } from "react-icons/tfi";
import { LuPencil } from "react-icons/lu";
import { AiOutlineDelete } from "react-icons/ai";
import { useFormEditData } from "../../hooks/useFormEditData";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import { useDrag } from "react-dnd";
import { useSelector,useDispatch } from "react-redux";
import { clearTransaction, setKyc, setOnGoingTransaction, setTasksData, setTransactionData } from "../../store/transactionSlice";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  DraggableAttachment,
  DraggableDate,
  DraggableRadioField,
  DraggableSelectField,
  DraggableTasks,
  DraggableTextField,
  DropableComponent,
} from "../../components/DragableFields";
import InputField from "../../components/InputField";
// import { ItemTypes } from "../../utils/constants";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { removeNthElement, toSnakeCase } from "../../utils/helpers";
import { useQueryParams } from "../../hooks/useQueryParams";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "sonner";
import { useTemplateCreationData } from "../../hooks/useTemplateCreationData";
import { transformAll } from "@demvsystems/yup-ast";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import Task from "../../components/tasks/task";
import Options from "../../components/options/options";
import { ItemTypes } from "../../utils/constants";
import InputLabel from "../../components/InputLabel";
import RadioField from "../../components/RadioField";
import { useTransactionCreationData } from "../../hooks/useTransactionCreationData";

const api = process.env.REACT_APP_BACKEND_API_URL;

function FormBuilder3() {


  // const updateTemp = localStorage.getItem("updateTemp");
  const [droppedComponents, setDroppedComponents] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { editData, setEditData } = useFormEditData();
  const [shape, setShape] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const caseDetails = useSelector((state)=>  state.transactionState)
  const dispatch =useDispatch();

  // Access the state object passed through history.push
  const editformSchema = location.data && location.data;

  // console.log("editformSchema")


  // console.log(editformSchema)
  // console.log("editformSchema")

  //  ["yup.object"],
  //   ["yup.shape"],
  //   ["yup.required"],
  // Function to add a new key-value pair to the existing shape schema
  // function addToShape(existingSchema, key, validators) {
  //   existingSchema[1][1][key] = validators;
  // }
  const [form, setForm] = useState([]);

  const [saving, setSaving] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  useEffect(() => {
    if (form.length !== 0) {
      if (droppedComponents?.length == 0) {
        setTemplateId(form._id);
        setDroppedComponents(form?.formSchema);
        setShape(form.validation);
      }
    } else if (
      form.length === 0 &&
      caseDetails.editId !== null &&
      editformSchema !== null &&
      editformSchema !== undefined
    ) {
      if (droppedComponents?.length == 0) {
        if(editformSchema.formType === "transaction"){
       const  combinedComponents = editformSchema?.formSchema[0]?.tasksData.concat(editformSchema?.formSchema[0]?.transactionData)
       setDroppedComponents(combinedComponents)
        }
       else if(editformSchema.formType === "tasks"){
   
     
     const  combinedComponents = editformSchema?.formSchema
          setDroppedComponents(combinedComponents)
           }
        setShape(editformSchema?.validation);
      }
    }
  }, [form]);

  useEffect(() => {
    // console.log(shape);
  }, [shape]);
  useEffect(() => {
    // console.log(droppedComponents);
  }, [droppedComponents]);
  // const

  const {
    data,
    addBuyer,
    addSeller,
    setBuyer,
    setSeller,
    fields,
    setForms,
    buyerCount,
    sellerCount,
    forms,
    setSellerCount,
    setBuyerCount,
    transactionDataForm,
    resetForms,
    setPreviewStep,
    previewStep,
    editId: editDataId,
    editIndex,
  } = useTransactionCreationData();
  const [editId, setEditId] = useState(caseDetails.editId);

  const schema = yup
    .object()
    .shape({
      inputName: yup.string().required(),
      inputMachineName: yup.string().required(),
      inputOptions: yup.array().of(yup.string()),
      optional: yup.boolean(),
    })
    .required();
  //
  // const newSchema = transformAll([
  //   ["yup.object"],
  //   ["yup.shape"],
  //   ["yup.required"],
  // ]);
  // console.log(schema.toString());
  useEffect(() => {
  
      if (editformSchema) {
        setFormId(editformSchema._id);
        setForm(editformSchema);
        // console.log(editformSchema);
      }
    
  }, []);

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { optional: false, inputOptions: ["option1", "option2"] },
    resolver: yupResolver(schema),
  });
  const [formId, setFormId] = useState(caseDetails.editId);
  const [step, setStep] = useState(editformSchema && editformSchema.formType === "tasks" ? 2 : 1);
  const {
    formName,
    formDescription,
    formType,
    partyType,
    screateBy,
    setFormName,
    setFormDescription,
    setFormType,
    // isOverride,
  } = useTemplateCreationData();

  const handleSave = (data, id) => {
    const updatedComponents = droppedComponents.map((comp) => {
      let placeholder = "";
      // console.log(comp.type);
      switch (comp.type) {
        case "text":
          placeholder = "Enter " + data.inputName + " here...";
          break;
        case "file":
          placeholder = "click to upload " + data.inputName + " here...";
          break;
        case "select":
          placeholder = watch("inputOptions")?.join(" | ");
          break;
        case "radio":
          placeholder = watch("inputOptions")?.join(" | ");
          break;
        default:
          placeholder = "Enter value here...";
          break;
      }
      if (comp.id == id) {
        setShape((prev) =>
          prev.filter((shape) => shape[comp.name] == comp.inputMachineName)
        );

        if (comp.type === "tasks") {
          return {
            id: comp.id,
            name: data.inputMachineName,
            label: data.inputName,
            placeholder: placeholder,
            type: comp.type,
            optional: data.optional,
            startDate: new Date(),
            endDate: new Date(),
            subTasks: [],
          };
        } else if (comp.type === "radio" || comp.type === "select") {
          return {
            id: comp.id,
            name: data.inputMachineName,
            label: data.inputName,
            placeholder: placeholder,
            type: comp.type,
            optional: data.optional,
            values: data.inputOptions,
          };
        }
        return {
          id: comp.id,
          name: data.inputMachineName,
          label: data.inputName,
          placeholder: placeholder,
          type: comp.type,
          optional: data.optional,
        };
      }
      return comp;
    });
    setDroppedComponents(updatedComponents);
    setShape((prev) => {
      if (data.optional) {
        return [...prev, { [data.inputMachineName]: [["yup.string"]] }];
      } else {
        return [
          ...prev,
          { [data.inputMachineName]: [["yup.string"], ["yup.required"]] },
        ];
      }
    });
  };

  const LeftPanel = () => {
    return (
      <div className="bg-[#0e2131] border  border-[#585d61]  text-white py-8 bg flex-col  col-span-1 rounded-3xl h-fit flex justify-center items-center">
        <h2 className="text-white mb-2 text-sm font-medium">Drag & Drop</h2>
        <div className="flex text-left text-xs mt-4 flex-col space-y-4 cursor-pointer">
          <DraggableTextField isDisabled={step !== 1} />
          <DraggableAttachment isDisabled={step !== 1} />
          <DraggableRadioField isDisabled={step !== 1} />
          <DraggableSelectField isDisabled={step !== 1} />
          <DraggableDate isDisabled={step !== 1} />
          <DraggableTasks isDisabled={step == 1} />
          {/* <p
            className="flex gap-2 pb-1.5"
            draggable={true}
            onDragStart={(event) => handleDragStart(event, "date")}
          >
            <CiCalendarDate size={24} />
            End Date
          </p> */}
        </div>
      </div>
    );
  };

  const RightPanel = () => {
    const renderInput = (component, index) => {
      if (component.type === "tasks") {
        // Access tasks array dynamically based on component ID
        let i = 1;
        i = i + index;
        const title = component.label;
        return (
          <Task
            title={title.length > 0 ? title : "Task " + i}
            id={component.id}
            subTasks={component.subTasks}
            setTasks={setDroppedComponents}
            setStart={setStartDate}
            setEnd={setEndDate}
            key={component.id}
            startDate={startDate}
            endDate={endDate}
            tasks={droppedComponents}
          />
        );
      } else if (component.type === "text") {
        return (
          <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative">
            <label className="text-xs mb-1 text-white">
              {component.label ? component.label : "Field Name"}
            </label>
            <input
              name="value"
              type="text"
              label={component.label}
              className={
                "mt-1 text-xs bg-transparent placeholder-[#bbb] text-white active:border-transparent focus:outline-none"
              }
              //   onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
              disabled
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id, component.name)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "date") {
        return (
          <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative">
            <label className="text-xs mb-1 text-white">
              {component.label ? component.label : "Start date"}
            </label>
            <input
              name="value"
              type="date"
              label={component.label}
              className={
                "mt-1 text-xs bg-transparent placeholder-[#bbb] text-white active:border-transparent focus:outline-none"
              }
              onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id, component.name)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "file") {
        return (
          <div id={component.id}>
            <InputLabel
              title={component.label}
              name={component.name}
              // toolTip={<p>{component.helperText}</p>}
              label={component.label}
              htmlFor={component.name}
              subtitle={component.placeholder}
            />
            <InputField
              id={component.name}
              type={component.type}
              // register={register}
              name={component.name}
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id, component.name)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "select") {
        return (
          <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative">
            <label className="text-xs mb-1 text-white">
              {component.label ? component.label : "Select"}
            </label>
            <input
              name="value"
              type="text"
              label="Select"
              className={
                "mt-1 text-xs bg-transparent placeholder-[#bbb] placeholder:text-xs text-white active:border-transparent focus:outline-none"
              }
              onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id, component.name)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "radio") {
        // console.log(component);
        return (
          <>
            <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative">
              <label className="text-xs mb-1 text-white">
                {component.label ? component.label : "Radio"}
              </label>
              <input
                name="value"
                type="text"
                label="Select"
                className={
                  "mt-1 text-xs bg-transparent placeholder-[#bbb] text-white active:border-transparent focus:outline-none"
                }
                onChange={(e) => handleInputChange(component.id, e)}
                value={component.value}
                placeholder={component.placeholder}
              />
              <button
                className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
                onClick={() => handleDelete(component.id, component.name)}
              >
                <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
              </button>
            </div>
          </>
        );
      }
    };

    const handleInputChange = (id, event) => {
      const { name, value } = event.target;
      setDroppedComponents((prev) =>
        prev.map((comp) => {
          if (comp.id === id) {
            return { ...comp, [name]: value };
          }
          return comp;
        })
      );
    };

    const handleDelete = (id, name) => {
      setDroppedComponents((prev) => prev.filter((comp) => comp.id !== id));
      // console.log(shape.map((comp) => comp[name]);
      // setShape((prev) => prev.filter((comp) => comp[name].length > 0));
      let arr = shape;

      let index = 0;
      shape.map((comp, i) => {
        if (comp[name]) {
          index = i;
        }
      });
      arr = removeNthElement(arr, index);
      setShape(arr);
      // console.log(index);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const submittedObject = droppedComponents.reduce((acc, component) => {
        acc[component.id] = { ...component };
        return acc;
      }, {});
      // this is the structure of the form here
      // console.log(submittedObject);
      // this is the structure of the form here
    };
     
    const handleSaveForm = async (e) => {
     
      if (step == 1) {
        setStep(2);
      }
      else if (step == 2 && editformSchema && editformSchema.formType === "tasks"){
        const tasksData = droppedComponents
        .filter((comp) => comp.type == "tasks")
        .map((component) => ({
          ...component,
        }));
        const formValidation = shape.map((component) => ({
        ...component,
      }));
          const rightOne = caseDetails.tasksData;
          // Create a copy of the array with the updated element
          let updated = {...caseDetails.tasksData};
          updated= { ...rightOne, formSchema:tasksData,validation:formValidation };
          dispatch(setTasksData(updated));
          dispatch(setOnGoingTransaction(false))
         toast.success("Template updated saved successfully", { duration: 3000 });
         history.goBack();
      }

      else if (step == 2){
        setSaving(true);
        const transactionData = droppedComponents
          .filter((comp) => comp.type !== "tasks")
          .map((component) => ({
            ...component,
          }));
        const tasksData = droppedComponents
          .filter((comp) => comp.type == "tasks")
          .map((component) => ({
            ...component,
          }));
        const formValidation = shape.map((component) => ({
          ...component,
        }));
        const data = {
          formName: formName,
          formType: "transaction",
          createdBy: "Officer 246",
          formDescription: formDescription,
          validation: formValidation,
          isMultiparty: partyType == "Multiple" ? true : false,
          formSchema: [
            {
              transactionData,
              tasksData,
            },
          ],
        };
        if (caseDetails.isOverride) {
          axios.patch(`${api}/api/v1/forms/${formId}`, data).then(() => {
          
            setSaving(false);
            setDroppedComponents([]);
            reset();
            dispatch(clearTransaction());
            history.push("/templates/saved-templates");
            toast.success("Form updated successfully", { duration: 3000 });
          });
        } else {    
          if (!formId) {

          await axios.post(`${api}/api/v1/forms`, data)
              .then(() => {
                setSaving(false);
                toast.success("Form saved successfully", { duration: 3000 });
                history.push("/templates/saved-templates");

                //  document.getElementById("my_modal_4").showModal();
              })
              .catch((error) => {
                setSaving(false);
                console.log(error);
              });
          } else {
            let oldForms = JSON.parse(JSON.stringify(forms));

            if (oldForms?.length !== 0) {
              oldForms[oldForms.length - 1].formSchema = tasksData;
              oldForms[oldForms.length - 2].formSchema = transactionData;
              // let newForms = oldForms
              setForms(oldForms);
            }
          }
          // history.goBack();
        }
      }
    };

    return (
      <form onSubmit={(e) => e.preventDefault()} className="w-full min-h-40">
        <div className="min-h-40  py-2">
          <div className="gap-6 grid grid-cols-1 mb-4 mx-12 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-1">
            <div className="text-white font-semibold mb-6">
              {formName} :{" "}
              <span className="font-normal">
                {step === 1 ? "Data requirements" : "Tasks"}{" "}
              </span>
            </div>
          </div>
          <div className="gap-6 grid grid-cols-1 mt-3 mx-12 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 ">
            {step == 1
              ? droppedComponents
                  ?.filter((comp) => comp.type !== "tasks")
                  .map((component) => (
                    <div key={component.id} className="relative">
                      {renderInput(component)}

                      <label
                        htmlFor="my-drawer-4"
                        className="drawer-button cursor-pointer absolute top-4 right-[1.1rem]"
                      >
                        {step == 1 && (
                          <LuPencil
                            onClick={() => {
                              queryParams.set("editId", component.id);
                              history.replace("?" + queryParams.toString());
                              const myModal =
                                document.getElementById("my-drawer-4");
                              myModal.click();
                            }}
                            size={18}
                            color="#585d61"
                          />
                        )}
                      </label>
                    </div>
                  ))
              : droppedComponents
                  ?.filter((comp) => comp.type === "tasks")
                  .map((component) => (
                    <div key={component.id} className="relative">
                      {renderInput(component)}

                      <label
                        htmlFor="my-drawer-4"
                        className="drawer-button cursor-pointer absolute top-4 right-[1.1rem]"
                      >
                        {step == 1 && (
                          <LuPencil
                            onClick={() => {
                              queryParams.set("editId", component.id);
                              history.replace("?" + queryParams.toString());
                              const myModal =
                                document.getElementById("my-drawer-4");
                              myModal.click();
                            }}
                            size={18}
                            color="#585d61"
                          />
                        )}
                      </label>
                    </div>
                  ))}
          </div>

          <DropableComponent
            setDroppedComponents={setDroppedComponents}
            droppedComponents={droppedComponents}
            shape={shape}
            accept={step == 1 ? ItemTypes.INPUTS : ItemTypes.TASKS}
            setShape={setShape}
          />
        </div>

        <button
          disabled={saving}
          type="submit"
          onClick={handleSaveForm}
          className="bg-[#302a74] hover:scale-110 transition-all px-5 my-1 py-2 text-white  text-sm font-medium float-right mr-12 rounded-lg"
        >


          {saving ? (
            <BeatLoader size={10} color="#fff" />
          ) : step == 1 ? (
            "Continue"
          ) : (
            " Save form"
          )}
        </button>
        {step < 2 && (
          <button
            disabled={saving}
            type="submit"
            onClick={() => {
              history.goBack();
            }}
            className="bg-[#081c2f] border border-[#585d61] hover:scale-110 transition-all px-5 my-1 py-2 text-white  text-sm font-medium float-right mr-4 rounded-lg"
          >
            Back
          </button>
        )}
        {step == 2 && editformSchema && editformSchema.formType !== "tasks" && (
         
         <button
            disabled={saving}
            type="submit"
            onClick={() => {
              setStep(1);
            }}
            className="bg-[#081c2f] border border-[#585d61] hover:scale-110 transition-all px-5 my-1 py-2 text-white  text-sm font-medium float-right mr-4 rounded-lg"
          >Back
          </button>
        )}
      </form>
    );
  };
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const onSubmit = (data) => {
    // console.log(data);
    handleSave(data, data.fieldId);
    const myModal = document.getElementById("my-drawer-4");
    myModal.click();
    toast.success("Field saved successfully");
  };
  const queryParams = useQueryParams();

  useEffect(() => {
    if (queryParams.get("editId") && droppedComponents.length == 0)
      history.goBack();
  }, []);
  useEffect(() => {
    setValue("inputMachineName", toSnakeCase(watch("inputName")));
  }, [watch("inputName")]);

  useEffect(() => {
    // const edit_id = editId;
    // console.log(editId);
    // console.log(
    //   droppedComponents?.filter((comp) => comp.id == editId)[0]?.optional
    // );

    if (editId) {
      setValue(
        "inputName",
        droppedComponents.filter((comp) => comp.id == editId)[0]?.label
      );
      setValue("fieldId", editId);

      if (droppedComponents.filter((comp) => comp.id == editId)[0]?.values) {
        setValue(
          "inputOptions",
          droppedComponents.filter((comp) => comp.id == editId)[0]?.values
        );
        setValue(
          "optional",
          droppedComponents.filter((comp) => comp.id == editId)[0]?.optional
        );
      } else {
        setValue("inputOptions", ["option1", "option2"]);
        setValue("optional", false);
      }
    }
  }, [editId]);

  useEffect(() => {
    // const modal=document.get
    const edit_id = queryParams.get("editId");
    // console.log(edit_id);
    if (edit_id) {
      setEditId(edit_id);
    }
  }, [queryParams.get("editId")]);

  function setOptions(options) {
    setValue("inputOptions", options);
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <div className="drawer drawer-end z-10 ">
          <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content"></div>
          <div className="drawer-side z-10">
            <label
              htmlFor="my-drawer-4"
              aria-label="close sidebar"
              className="drawer-overlay"
            ></label>
            <ul className="menu bg-[#081c2f] p-4 w-[560px] z-10 min-h-full text-base-content">
              <h2 className="text-lg px-2 my-6">Edit form field </h2>
              <form
                onSubmit={(e) => e.preventDefault()}
                className="px-2 flex flex-col gap-5"
              >
                <div>
                  <InputField
                    label={"Field name"}
                    register={register}
                    name="inputName"
                    error={errors.inputName}
                    isErrored={errors.inputName}
                    placeholder={"Enter form label name here"}
                  />
                </div>

                <div
                  onClick={() => {
                    setValue("optional", !watch("optional"));
                  }}
                  className={`flex cursor-pointer items-center justify-between  px-3 py-[25px] bg-[#081c2f]  rounded-2xl border border-[#585d61]`}
                >
                  <div className="font-medium text-white">
                    Toggle to make field Optional
                  </div>
                  <input
                    type="checkbox"
                    className="toggle toggle-info"
                    checked={watch("optional")}
                    defaultChecked={watch("optional")}
                  />
                </div>
                {(droppedComponents?.filter((comp) => comp.id == editId)[0]
                  ?.type == "select" ||
                  droppedComponents?.filter((comp) => comp.id == editId)[0]
                    ?.type == "radio") && (
                  <>
                    <Options
                      setOptions={setOptions}
                      title={"Values"}
                      options={watch("inputOptions")}
                    />
                    {/* <textarea
                        key={editId}
                        value={watch("inputOptions")?.join("\n")}
                        className={
                          "mt-1 text-xs bg-transparent placeholder-[#475663] active:border-transparent text-white focus:outline-none"
                        }
                        label="Form Description"
                        rows={6}
                        type="text"
                        onChange={(e) => {
                          setValue("inputOptions", e.target.value.split("\n"));
                        }}
                        placeholder="Please enter the form description"
                      ></textarea> */}

                    {/* <div>{JSON.stringify(watch("inputOptions"))}</div> */}
                  </>
                )}
                <div className="absolute bottom-5 right-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(onSubmit)();
                    }}
                    className="bg-[#302a74] px-5 my-1 py-2 text-white  text-sm font-medium float-right mx-4 rounded-lg"
                  >
                    Save field
                  </button>
                </div>
              </form>
            </ul>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <header className=" z-2 container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0">
            <nav
              className="flex items-center justify-between p-6 lg:px-8"
              aria-label="Global"
            >
              <div className="flex lg:flex-1">
                <Link to="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img className="h-12 w-auto" src={logo} alt="" />
                </Link>
              </div>
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </nav>
            <Dialog
              as="div"
              className="lg:hidden"
              open={mobileMenuOpen}
              onClose={setMobileMenuOpen}
            >
              <div className="fixed inset-0 z-50" />
              <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                  <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Your Company</span>
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                      alt=""
                    />
                  </a>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="py-6">
                      <a
                        href="#"
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        Log in
                      </a>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </header>

          <label className="text-white text-xl font-">
            <span className="font-light">Create your new</span> Form
          </label>

          <div className="grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-6 mx-10 xl:mx-80 container    px-10 pt-10 pb-6">
            <LeftPanel />

            <div className="bg-[#102232] py-4 col-span-4 h-30 flex justify-center items-center border border-[#585d61] rounded-3xl ">
              <RightPanel />
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

export default FormBuilder3;
