import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import InputLabel from "./InputLabel";
import SelectField from "./SelectField";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { IoMdAddCircle } from "react-icons/io";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function TrustForm({
  step1Complete,
  step2Complete,
  setStep1Complete,
  setStep2Complete,
  party1Data,
  setParty1Data,
  party2Data,
  setParty2Data,
  data,
}) {
  const history = useHistory();

  const schema = yup
    .object()
    .shape({
      name: yup.string().required(),
      phoneNumber: yup.string().matches(/^\+?\d+$/, "Must be a number"),
      email: yup.string().email().required(),
      memberListFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      memberIdsFile: yup
        .mixed()
        .test("file", "You need to upload a file", function (value) {
          // Access the field values using `this.parent`

          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      constitutionFile: yup
        .mixed()
        .test("file", "You need to upload a file", function (value) {
          // Access the field values using `this.parent`

          if (value.length > 0) {
            return true;
          }
          return false;
        }),
    })
    .required();
  const {
    register,
    reset,
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitAndStay = (data) => {
    console.log(data);
    // if (step1Complete == false) {
    //   const form_data = dataToFormData(data, "FIRST");
    //   setParty1Data((prev) => [...prev, form_data]);
    // } else {
    //   // reset();
    //   const form_data = dataToFormData(data, "SECOND");
    //   setParty2Data((prev) => [...prev, form_data]);
    // }
  };
  const onSubmitAndNext = (data) => {
    console.log(data);
    // if (step1Complete == false) {
    //   const form_data = dataToFormData(data, "FIRST");
    //   setParty1Data((prev) => [...prev, form_data]);
    //   setStep1Complete(true);
    // } else {
    //   const form_data = dataToFormData(data, "SECOND");
    //   setParty2Data((prev) => [...prev, form_data]);
    //   setStep2Complete(true);
    //   // reset();
    // }
  };

  function dataToFormData(data, party) {
    var form_data = new window.FormData();
    form_data.append("name", data.name);
    form_data.append("email", data.email);
    form_data.append("phone", data.phoneNumber);
    form_data.append("id_passport", data.idNumber);
    form_data.append(
      "marriageCertificate",
      data.marriageCertificateFile[0] || null
    );
    form_data.append("party", party);
    form_data.append("decreeAbsolute", data.memberIdsFile[0]);
    form_data.append(
      "deathCertificate",
      data.constitutionFile ? data.constitutionFile[0] : null
    );
    form_data.append("citizenshipStatus", data.isCitizen);
    form_data.append("kycDocument", data.kycFile[0]);
    form_data.append("idPassportDocument", data.memberListFile[0]);
    // form_data.append("type", data.userType);
    // form_data.append("transactionType", data.transactionType);

    return form_data;
  }

  // if case id exists, then
  return (
    <form>
      <div className="border-b bg-red border-gray-900/10 mb-10 w-screen container">
        <div className=" grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mx-10 xl:mx-80 bg-[#102232]  rounded-3xl  px-10 pt-10 pb-6 shadow-lg  border border-[#585d61]">
          <>
            <InputField
              isErrored={errors.name}
              message={errors.name?.message}
              label="Fullname *"
              placeholder="Enter the names here..."
              register={register}
              name="name"
            />
            <InputField
              label="Phone Number *"
              register={register}
              name="phoneNumber"
              placeholder="Enter the party's phone number here..."
              type="text"
              isErrored={errors.phoneNumber}
              message={errors.phoneNumber?.message}
            />
            <InputField
              label="Email *"
              isErrored={errors.email}
              message={errors.email?.message}
              placeholder="Enter the party`s email here..."
              register={register}
              name="email"
            />

            <InputField
              id={"memberListFile"}
              type="file"
              register={register}
              name={"memberListFile"}
            />
            <InputLabel
              htmlFor={"memberListFile"}
              title={"Attach trusties list *"}
              message={errors.memberListFile?.message}
              hasFile={watch("memberListFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the trusties list,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              isErrored={errors.memberListFile}
              subtitle={
                watch("memberListFile")?.length > 0
                  ? watch("memberListFile")[0].name
                  : "click to upload  trusties list here..."
              }
            />

            <InputLabel
              htmlFor={"memberIdsFile"}
              title={"Attach trusties ids *"}
              toolTip={
                <p>
                  Please attach an electronic copy of the trusties ids,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              message={errors.memberIdsFile?.message}
              isErrored={errors.memberIdsFile}
              hasFile={watch("memberIdsFile")?.length > 0}
              subtitle={
                watch("memberIdsFile")?.length > 0
                  ? watch("memberIdsFile")[0].name
                  : "click to upload  trusties ids  here..."
              }
            />
            <InputField
              id={"memberIdsFile"}
              type="file"
              register={register}
              name={"memberIdsFile"}
            />

            <InputLabel
              htmlFor={"constitutionFile"}
              title={"Attach  trust deed *"}
              message={errors.constitutionFile?.message}
              isErrored={errors.constitutionFile}
              hasFile={watch("constitutionFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the trust deed file,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              subtitle={
                watch("constitutionFile")?.length > 0
                  ? watch("constitutionFile")[0].name
                  : "click to upload trust deed here..."
              }
            />

            <InputField
              id={"constitutionFile"}
              type="file"
              register={register}
              name={"constitutionFile"}
            />

            {/* <div
              className={`flex flex-col px-3 py-[15px] bg-[#081c2f] rounded-2xl  border ${
                errors.userType ? " border-[red]" : " border-[#585d61]"
              }`}
            >
              <label className="text-xs text-white">Select User Type *</label>
              <div>
                <div className="flex text-white  h-6 items-center justify-between">
                  <label className="flex items-center text-xs gap-2">
                    <p>Buyer</p>

                    <input
                      id="userType"
                      name="userType"
                      type="radio"
                      {...register("userType")}
                      value={"BUYER"}
                      // value={""}
                      className="h-3 w-3 rounded radio: border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>

                  <label className="flex items-center text-xs gap-2">
                    <p>Seller</p>

                    <input
                      id="userType1"
                      name="userType"
                      value={"SELLER"}
                      {...register("userType")}
                      type="radio"
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>
                </div>
                {errors.userType && (
                  <div className="text-red-600 text-xs">
                    {errors.userType?.message}
                  </div>
                )}
              </div>
            </div> */}

            {/* <div
              className={`flex flex-col px-3 py-[15px] bg-[#081c2f] rounded-2xl  border ${
                errors.isCitizen ? " border-[red]" : " border-[#585d61]"
              }`}
            >
              <label className="text-xs text-white">Citizenship Status *</label>
              <div>
                <div className="flex h-6 text-white items-center justify-between">
                  <label htmlFor="" className="flex items-center text-xs gap-2">
                    <p>Citizen</p>
                    <input
                      id="isCitizen1"
                      name="isCitizen"
                      {...register("isCitizen")}
                      type="radio"
                      value={true}
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>

                  <label className="flex items-center text-xs gap-2">
                    <p>Non-Citizen</p>
                    <input
                      id="isCitizen"
                      name="isCitizen"
                      type="radio"
                      value={false}
                      {...register("isCitizen")}
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>
                </div>
                {errors.isCitizen && (
                  <div className="text-red-600 text-xs">
                    {errors.isCitizen?.message}
                  </div>
                )}
              </div>
            </div> */}

            <div className="flex items-center justify-between sm:col-span-1 mt- md:col-span-1 xl:col-span-2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmitAndStay)();
                }}
                className="px-7 text-[#43ace1] gap-1 text-xs font-semibold flex items-center"
              >
                <IoMdAddCircle size={19} /> Add More (
                {step1Complete ? party2Data.length : party1Data.length})
              </button>
              <input
                onClick={() => {
                  handleSubmit(onSubmitAndNext)();
                }}
                value={"Next Step"}
                type="button"
                className="cursor-pointer rounded-lg bg-[#2b2d77] text-xs text-white px-12 py-1.5"
              />
            </div>
          </>
          <div className="flex items-center justify-center sm:col-span-1 md:col-span-1 xl:col-span-2">
            <p className="text-center tracking-wide text-xs  text-white font-thin">
              <span className="text-[#3b97c6] font-bold">Attention</span>:
              Please note that by using to this platform you agree to
              <br />
              the
              <span className="text-[#3b97c6] font-medium underline">
                {" "}
                Terms & Conditions
              </span>{" "}
              and{" "}
              <span className="text-[#3b97c6] font-medium underline">
                Privacy Policies
              </span>
            </p>{" "}
          </div>
        </div>
      </div>
    </form>
  );
}

export default TrustForm;
