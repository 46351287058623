import React, { useRef, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../images/logo.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { IoIosRadioButtonOn } from "react-icons/io";
import { IoMdArrowBack } from "react-icons/io";
import { useCookie } from "react-use";
import { MdDeleteOutline, MdTextFields } from "react-icons/md";
import { GrAttachment } from "react-icons/gr";
import { CiCalendarDate } from "react-icons/ci";
import { TfiHandDrag } from "react-icons/tfi";
import { LuPencil } from "react-icons/lu";
import { AiOutlineDelete } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { FaSortDown } from "react-icons/fa6";
import { useTemplateCreationData } from "../../hooks/useTemplateCreationData";
import { IoSaveOutline } from "react-icons/io5";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { MdOutlineCancel } from "react-icons/md";
import uuid from "react-uuid";
import { toast } from "sonner";
import { DatePicker, Space } from "antd";
import { IoCloseOutline } from "react-icons/io5";
const navigation = [];
const api = process.env.REACT_APP_BACKEND_API_URL;

export default function FormBuilder() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [visible, setVisible] = useState(true);
  const [transactionData, setTransactionDataSchema] = useState();
  const [visible1, setVisible1] = useState(false);
  const handleSelect = (value) => {
    setSelectedValue(value);
  };
  const [selectedValue, setSelectedValue] = useState(null);
  const [step2, setStep2] = useState(false);
  const [formDetails, setFormDetails] = useState({
    formName: "",
    formDescription: "",
    formType: "transaction",
  });
  const formNameRef = useRef(null);
  const formDescriptionRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  // Access the state object passed through history.push
  const editformSchema = location.data && location.data;
  console.log("scheeeeeeeeeeeeemmmmmmmmmmmmmmmmmaaaaaaaaaaaaaa");
  console.log(editformSchema);
  console.log("scheeeeeeeeeeeeemmmmmmmmmmmmmmmmmaaaaaaaaaaaaaa");
  const [value, updateCookie, deleteCookie] = useCookie("user");
  const user = JSON.parse(value);
  const LeftPanel = () => {
    const handleDragStart = (event, type) => {
      event.dataTransfer.setData("type", type);
    };

    return (
      <div className="bg-[#0e2131]  border border-[#585d61]   text-white py-8 bg flex-col  col-span-1 rounded-3xl h-fit flex justify-center items-center">
        <h className="text-white mb-2 text-sm font-medium">Drag & Drop</h>
        <div className="flex text-left text-xs mt-4 flex-col space-y-4 cursor-pointer">
          <p
            className="flex  border-b-[#2c3c4b] border-b-2 gap-2 pb-1.5"
            draggable={true}
            onDragStart={(event) => handleDragStart(event, "text")}
          >
            <MdTextFields size={24} /> Field Name
          </p>
          <p
            className="flex  border-b-2  border-b-[#2c3c4b] gap-2 pb-1.5"
            draggable={true}
            onDragStart={(event) => handleDragStart(event, "file")}
          >
            <GrAttachment size={22} />
            Attachment
          </p>
          <p
            className="flex  items-center border-b-2  border-b-[#2c3c4b] gap-2 pb-1.5"
            draggable={true}
            onDragStart={(event) => handleDragStart(event, "select")}
          >
            <FaSortDown size={24} className="mb-3" />
            Drop Down
          </p>

          <p
            className="flex  items-center border-b-2  border-b-[#2c3c4b] gap-2 pb-1.5"
            draggable={true}
            onDragStart={(event) => handleDragStart(event, "radio")}
          >
            <IoIosRadioButtonOn size={24} />
            Radio
          </p>
          <p
            className="flex  border-b-2  border-b-[#2c3c4b] gap-2 pb-1.5"
            draggable={true}
            onDragStart={(event) => handleDragStart(event, "date")}
          >
            <CiCalendarDate size={24} />
            Start Date
          </p>
          <p
            className="flex  border-b-2  border-b-[#2c3c4b] gap-2 pb-1.5"
            draggable={true}
            onDragStart={(event) => handleDragStart(event, "date")}
          >
            <CiCalendarDate size={24} />
            End Date
          </p>

          <p
            className="flex gap-2 pb-1.5"
            draggable={true}
            onDragStart={(event) => handleDragStart(event, "tasks")} // Add drag start event for TodoList
          >
            <MdFormatListBulletedAdd size={24} />
            List/Tasks
          </p>
        </div>
      </div>
    );
  };
  const RightPanel = () => {
    const [droppedComponents, setDroppedComponents] = useState(
      editformSchema?.formType === "transaction"
        ? editformSchema.formSchema[0]?.transactionData || []
        : editformSchema
        ? editformSchema?.formSchema
        : []
    );
    const [editingComponent, setEditingComponent] = useState(null);
    const [inputProperties, setInputProperties] = useState({});
    const [tasks, setTasks] = useState([]);
    const [newTask, setNewTask] = useState({ name: "", isDone: false });
    const [newName, setNewName] = useState("");
    const [ID, setID] = useState();

    const [value, setValue] = useState({
      startDate: new Date(),
      endDate: new Date().setMonth(11),
    });
    let [componentTasks, setComponentTasks] = useState(tasks);
    const [editingTaskIndex, setEditingTaskIndex] = useState(null);
    const [editingComponentId, setEditingComponentId] = useState(null);

    const handleInputChangee = (event, id) => {
      if (editingComponentId === id) {
        setNewTask({ id: uuid(), name: event.target.value, isDone: false });
      }
    };

    useEffect(() => {
      if (editformSchema && step2) {
        setDroppedComponents(editformSchema.formSchema[0]?.tasksData);
      }
    }, []);

    const handleFormSubmit = (id) => {
      if (newTask.name.trim() !== "") {
        // Dynamically generate key based on ID

        const updatedTasks = { ...tasks };

        console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
        console.log(id);
        console.log(droppedComponents);
        console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
        if (!updatedTasks[id]) {
          updatedTasks[id] = []; // Initialize array if not present
        }
        // updatedTasks[id].push(newTask); // Push new task to corresponding array
        // Update tasks
        // setTasks(updatedTasks);

        // Update droppedComponents
        setDroppedComponents((prev) => {
          return prev.map((item) => {
            if (item.id === id) {
              // If the item matches the provided id
              if (item.type === "tasks") {
                // If the type is not "tasks", update with the value property
                return {
                  ...item,
                  value: [...item.value, newTask],
                };
              }
            } else {
              // Otherwise, return the item unchanged
              return item;
            }
          });
        });

        //   console.log();

        setNewTask({ name: "", isDone: "" });
        setEditingComponentId(null);
      }
    };

    const handleDrop = (event) => {
      event.preventDefault();
      const type = event.dataTransfer.getData("type");

      setDroppedComponents((prev) => [
        ...prev,
        {
          id: Date.now(),
          type,
          placeholder: "",
          name: "",
          value: "",
          label: "",
        },
      ]);
    };

    const handleDragOver = (event) => {
      event.preventDefault();
    };

    const renderInput = (component) => {
      if (component.type === "tasks") {
        // Access tasks array dynamically based on component ID

        componentTasks = tasks[component.id] || component?.value || [];

        console.log("TASKSS  TO BE RENDERED");
        console.log(componentTasks);
        return (
          <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative border-dashed">
            <label className="text-xs mb-1 text-white">
              {component?.label || "TextField"}
            </label>

            <ul id="todo-list" className="mt-4">
              {componentTasks.map((task, index) => {
                return (
                  <li
                    key={task.id}
                    className={`flex items-center justify-between py-1 ${
                      task.completed ? "completed" : ""
                    }`}
                  >
                    <label className="flex items-center">
                      {editingTaskIndex === task.id ? (
                        <div className="flex mb-2">
                          <input
                            type="text"
                            name="newName"
                            value={newName}
                            className="text-sm w-full mr-8 text-[#f2f2f2] bg-transparent focus:outline-none"
                            onChange={(e) => setNewName(e.target.value)}
                            placeholder={"Edit task : " + task.name}
                          />
                        </div>
                      ) : (
                        <span className="text-[#868f9c] text-sm">
                          {task.name}
                        </span>
                      )}
                    </label>
                    <div className="flex">
                      {editingTaskIndex === task.id ? (
                        <>
                          <div
                            className="text-blue-500 hover:text-blue-700 mr-1 cursor-pointer"
                            onClick={() => {
                              const updatedTasks = { ...tasks }; // Make a copy of tasks object
                              console.log("tasks to be edited on ");
                              console.log(updatedTasks);

                              try {
                                const index = updatedTasks[`${ID}`].findIndex(
                                  (obj) => obj.id === task.id
                                );

                                // If index is found, replace the object
                                if (index !== -1) {
                                  updatedTasks[`${ID}`][index] = {
                                    id: uuid(),
                                    name: newName,
                                    isDone: true,
                                  };
                                } else {
                                  console.log(
                                    "Object with ID " + task.id + " not found"
                                  );
                                }
                                setTasks(updatedTasks);

                                setNewName("");
                              } catch {
                                const index2 = component.value.findIndex(
                                  (obj) => obj.id === task.id
                                );
                                // If index is found, replace the object
                                if (index2 !== -1) {
                                  component.value[index2] = {
                                    id: uuid(),
                                    name: newName,
                                    isDone: true,
                                  };
                                } else {
                                  console.log(
                                    "Object with ID " + task.id + " not found"
                                  );
                                }
                                setTasks(updatedTasks);

                                setNewName("");
                              }
                            }}
                          >
                            <IoSaveOutline size={17} />
                          </div>
                          <div
                            className="text-red-500 hover:text-red-700 mr-2 cursor-pointer"
                            onClick={() => setEditingTaskIndex(null)}
                          >
                            <MdOutlineCancel size={18} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="text-blue-500 hover:text-blue-700 mr-1 cursor-pointer"
                            onClick={() => {
                              setEditingTaskIndex(task.id);
                              setID(component.id);
                            }}
                          >
                            <LuPencil size={17} />
                          </div>
                          <div
                            className="text-red-500 hover:text-red-700 mr-2 cursor-pointer"
                            onClick={() => {
                              const updatedTasks = { ...tasks }; // Make a copy of tasks object

                              try {
                                const index = updatedTasks[
                                  `${component.id}`
                                ].findIndex((obj) => obj.id === task.id);

                                // If index is found, delete the object
                                if (index !== -1) {
                                  updatedTasks[`${component.id}`].splice(
                                    index,
                                    1
                                  ); // Remove the task at the found index
                                } else {
                                  console.log(
                                    "Object with ID " + task.id + " not found"
                                  );
                                }
                                setTasks(updatedTasks);
                                setNewName("");
                              } catch {
                                const index2 = component.value.findIndex(
                                  (obj) => obj.id === task.id
                                );
                                // If index is found, delete the object
                                if (index2 !== -1) {
                                  component.value.splice(index2, 1); // Remove the task at the found index
                                } else {
                                  console.log(
                                    "Object with ID " + task.id + " not found"
                                  );
                                }
                                setTasks(updatedTasks);
                                setNewName("");
                              }
                            }}
                          >
                            <MdDeleteOutline size={18} />
                          </div>
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>

            <>
              {
                <div className="flex mt-4">
                  {editingComponentId === component.id ? (
                    <input
                      key={component.id}
                      type="text"
                      className="text-sm w-full mr-8 text-[#f2f2f2] bg-transparent focus:outline-none placeholder:text-xs"
                      value={newTask.name}
                      onChange={(e) => handleInputChangee(e, component.id)}
                      placeholder="Add new task"
                    />
                  ) : (
                    ""
                  )}
                  <div
                    onClick={
                      !editingComponentId
                        ? () => setEditingComponentId(component.id)
                        : () => handleFormSubmit(component.id)
                    }
                    className=" cursor-pointer flex rounded-lg items-center hover:border-transparent justify-center hover:bg-[#302a74] hover:text-[#f2f2f2] py-1.5 px-2"
                  >
                    {editingComponentId !== component.id ? (
                      <>
                        <IoIosAddCircleOutline size={20} color="#42abdf" />
                        <span className="text-[#42abdf] ml-2 text-sm font-semibold">
                          Add more
                        </span>
                      </>
                    ) : (
                      <span className="text-[#42abdf]  text-sm font-semibold">
                        Save
                      </span>
                    )}
                  </div>
                </div>
              }
            </>
          </div>
        );
      } else if (component.type === "date") {
        return (
          <div className="flex flex-col px-3 py-[15px]  bg-[#081c2f]  rounded-2xl border border-[#585d61] relative border-dashed">
            <label className="text-xs mb-1 text-white">
              {component?.label || "TextField"}
            </label>
            <input
              name="value"
              type="date"
              label={component.label}
              className={
                "mt-1 text-xs pr-10 bg-transparent placeholder-[#cccccc] text-white active:border-transparent focus:outline-none"
              }
              onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "text") {
        return (
          <div className="flex flex-col px-3 py-[15px]  bg-[#081c2f]  rounded-2xl border border-[#585d61] relative border-dashed">
            <label className="text-xs mb-1 text-white">
              {component?.label || "TextField"}
            </label>
            <input
              name="value"
              type="text"
              label={component.label}
              className={
                "mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white active:border-transparent  mr-8 focus:outline-none"
              }
              onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "select") {
        return (
          <div className="flex flex-col px-3 py-[15px]  bg-[#081c2f]  rounded-2xl border border-[#585d61] relative border-dashed">
            <label className="text-xs mb-1 text-white">
              {component?.label || "TextField"}
            </label>
            <input
              name="value"
              type="text"
              label={component.label}
              className={
                "mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white active:border-transparent  mr-8 focus:outline-none"
              }
              onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "radio") {
        return (
          <div className="flex flex-col px-3 py-[15px]  bg-[#081c2f]  rounded-2xl border border-[#585d61] relative border-dashed">
            <label className="text-xs mb-1 text-white">
              {component?.label || "TextField"}
            </label>
            <input
              name="value"
              type="text"
              label={component.label}
              className={
                "mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white active:border-transparent  mr-8 focus:outline-none"
              }
              onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
            />

            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "file") {
        return (
          <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative border-dashed">
            <label className="text-xs mb-1 text-white">
              {component?.label || "TextField"}
            </label>
            <GrAttachment size={18} color="#f6f6f6" />
            <input
              name="value"
              type="file"
              disabled
              label={component.label}
              style={{ display: "none" }} // hide the file input
            />
            <span
              className="mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white active:border-transparent focus:outline-none"
              onClick={() => document.querySelector("input[type=file]").click()} // trigger file input click on span click
            >
              {/* {component.placeholder} */}
            </span>
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      }
    };

    const handleEdit = (id) => {
      const component = droppedComponents.find((comp) => comp.id === id);
      if (component) {
        setEditingComponent(component);
        setInputProperties({
          placeholder: component.placeholder || "",
          name: component.name || "",
          label: component.label || "",
          value: component.value || "",
        });
      }
    };

    const handleInputChange = (id, event) => {
      const { name, value } = event.target;
      setDroppedComponents((prev) =>
        prev.map((comp) => {
          if (comp.id === id) {
            return { ...comp, [name]: value };
          }
          return comp;
        })
      );
    };

    const handleSave = () => {
      const updatedComponents = droppedComponents.map((comp) => {
        if (comp.id === editingComponent.id) {
          return { ...comp, ...inputProperties };
        }
        return comp;
      });
      setDroppedComponents(updatedComponents);
      setEditingComponent(null);
      setInputProperties({});
    };

    const handleDelete = (id) => {
      setDroppedComponents((prev) => prev.filter((comp) => comp.id !== id));
      if (editingComponent && editingComponent.id === id) {
        setEditingComponent(null);
        setInputProperties({});
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      // Extract values without preserving keys
      const formSchema = droppedComponents.map((component) => ({
        ...component,
      }));

      const data = {
        formName: formDetails?.formName || formNameRef?.current.value,
        formType: editformSchema
          ? editformSchema?.formType
          : formDetails?.formType,
        createdBy: "Officer 246",
        validation: [],
        formDescription:
          formDetails?.formDescription || formDescriptionRef.current.value,
        formSchema: formSchema,
      };

      console.log(data);
      try {
        axios
          .patch(`${api}/api/v1/forms/${editformSchema._id}`, data)
          .then(() => {
            document.getElementById("my_modal_4").showModal();
          });
      } catch (err) {
        console.log(err);
      }
    };

    const [formData, setFormData] = useState({
      formName: editformSchema?.formName || [],
      formDescription: editformSchema?.formDescription || [],
      // Initialize with empty string or any default value you want
    });

    // Function to handle changes in the form input
    const handleChange = (event) => {
      const { name, value } = event.target;
      // Update the corresponding field in the formData state
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    return (
      <form onSubmit={handleSubmit} className="w-full min-h-40">
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="min-h-40  py-2"
        >
          <div className="gap-6 grid grid-cols-1 mb-4 mx-12 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-1">
            <div className="mb-1 text-white align-middle flex items-center justify-between font-medium">
              <p className="text-xl">
                {formDetails?.formName || editformSchema?.formName}

                {editformSchema?.formType === "transaction" && step2
                  ? " Tasks"
                  : formDetails?.formType === "transaction" && step2
                  ? " Tasks"
                  : formDetails?.formType === "transaction" && !editformSchema
                  ? " Data Requirements"
                  : editformSchema?.formType === "transaction"
                  ? " Data Requirements"
                  : ""}
              </p>
            </div>
            {editformSchema && (
              <>
                {step2 ? (
                  <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61]">
                    <label className="text-xs mb-1 text-white">
                      Form Name *
                    </label>
                    <input
                      id="formName"
                      value={formData.formName}
                      onChange={handleChange}
                      ref={formNameRef}
                      name="formName"
                      type="text"
                      label="Form Name *"
                      className={
                        "mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white active:border-transparent focus:outline-none"
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
                {step2 ? (
                  <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61]">
                    <label className="text-xs mb-1 text-white">
                      Form Description *
                    </label>

                    <textarea
                      id="formDescriptionRef"
                      name="formDescription"
                      value={formData?.formDescription}
                      onChange={handleChange}
                      rows={2}
                      className={
                        "mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white active:border-transparent focus:outline-none"
                      }
                      ref={formDescriptionRef}
                    ></textarea>
                  </div>
                ) : (
                  ""
                )}
                {editformSchema?.formType === "KYC Form" ? (
                  <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61]">
                    <label className="text-xs mb-1 text-white">
                      Form Name *
                    </label>
                    <input
                      id="formName"
                      value={formData.formName}
                      onChange={handleChange}
                      ref={formNameRef}
                      name="formName"
                      type="text"
                      label="Form Name *"
                      className={
                        "mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white active:border-transparent focus:outline-none"
                      }
                    />
                  </div>
                ) : (
                  ""
                )}
                {editformSchema?.formType === "KYC Form" ? (
                  <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61]">
                    <label className="text-xs mb-1 text-white">
                      Form Description *
                    </label>

                    <textarea
                      id="formDescriptionRef"
                      name="formDescription"
                      value={formData?.formDescription}
                      onChange={handleChange}
                      rows={2}
                      className={
                        "mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white active:border-transparent focus:outline-none"
                      }
                      ref={formDescriptionRef}
                    ></textarea>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          {droppedComponents?.length === 0 ? (
            <h2 className="space-y-8 flex-col text-[#585d61]  border-dashed border border-[#495968] rounded-xl min-h-60 mx-12 font-medium justify-center flex-center flex items-center 	mt-8 mb-2">
              <TfiHandDrag size={30} />
              Drop items here to get started...
            </h2>
          ) : (
            ""
          )}

          {editingComponent ? (
            <div className="gap-6 grid grid-cols-1 mx-12 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 ">
              {droppedComponents.map((component) => (
                <div key={component.id} className="relative">
                  {component.id === editingComponent.id ? ( // Check if this component is being edited
                    <div
                      className={`flex flex-col px-3 ${
                        component.type !== "tasks" ? "py-[15px]" : "py-[25px]"
                      } bg-[#081c2f]  rounded-2xl border border-[#585d61] relative border-dashed`}
                    >
                      <label className="text-xs mb-1 text-white">
                        <input
                          className={
                            "text-xs bg-transparent placeholder-[#cccccc] mr-8 text-white  w-full active:border-transparent focus:outline-none"
                          }
                          type="text"
                          name="label"
                          value={inputProperties.label}
                          onChange={(e) =>
                            setInputProperties({
                              ...inputProperties,
                              label: e.target.value,
                            })
                          }
                          placeholder="Enter field label here..."
                        />
                      </label>

                      {component.type !== "tasks" && (
                        <input
                          className={
                            "mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white mr-8 active:border-transparent focus:outline-none"
                          }
                          placeholder="Enter field placeholder here..."
                          type="text"
                          name="placeholder"
                          value={inputProperties.placeholder}
                          onChange={(e) =>
                            setInputProperties({
                              ...inputProperties,
                              placeholder: e.target.value,
                            })
                          }
                        />
                      )}
                      {component.type === "radio" && (
                        <input
                          className={
                            "mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white mr-8 active:border-transparent focus:outline-none"
                          }
                          placeholder="Enter an option and click enter to add another one"
                          type="text"
                          name="value"
                          value={inputProperties.value}
                          onChange={(e) =>
                            setInputProperties({
                              ...inputProperties,
                              value: e.target.value,
                            })
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent default behavior of Enter key
                              const { selectionStart, selectionEnd, value } =
                                e.target;
                              const newValue =
                                value.substring(0, selectionStart) +
                                " | " + // Insert your separator here
                                value.substring(selectionEnd);
                              setInputProperties({
                                ...inputProperties,
                                value: newValue,
                              });
                              // Adjust cursor position after inserting separator
                              e.target.setSelectionRange(
                                selectionStart + 2,
                                selectionStart + 2
                              );
                            }
                          }}
                        />
                      )}

                      {component.type === "select" && (
                        <input
                          className={
                            "mt-1 text-xs bg-transparent placeholder-[#cccccc] text-white mr-8 active:border-transparent focus:outline-none"
                          }
                          placeholder="Enter option and click enter to add another one"
                          type="text"
                          name="value"
                          value={inputProperties.value}
                          onChange={(e) =>
                            setInputProperties({
                              ...inputProperties,
                              value: e.target.value,
                            })
                          }
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent default behavior of Enter key
                              const { selectionStart, selectionEnd, value } =
                                e.target;
                              const newValue =
                                value.substring(0, selectionStart) +
                                " | " + // Insert your separator here
                                value.substring(selectionEnd);
                              setInputProperties({
                                ...inputProperties,
                                value: newValue,
                              });
                              // Adjust cursor position after inserting separator
                              e.target.setSelectionRange(
                                selectionStart + 2,
                                selectionStart + 2
                              );
                            }
                          }}
                        />
                      )}

                      <div
                        className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
                        onClick={handleSave}
                      >
                        <IoSaveOutline size={20} style={{ color: "#f2f2f2" }} />
                      </div>
                    </div>
                  ) : (
                    <>
                      {renderInput(component)}
                      <div
                        className="mt-3 absolute top-0 right-4"
                        onClick={() => handleEdit(component.id)}
                      >
                        <LuPencil size={20} color="#585d61" />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="gap-4 grid grid-cols-1 mx-12  min-h-40 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 ">
              {droppedComponents?.map((component) => (
                <div key={component.id} className="relative">
                  {renderInput(component)}
                  <div
                    className="mt-3 absolute top-0 right-4"
                    onClick={() => handleEdit(component.id)}
                  >
                    <LuPencil size={20} color="#585d61" />
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* </div> */}
        </div>

        <div>
          {formDetails?.formType === "KYC Form" ? (
            <button
              type="submit"
              className="bg-[#302a74] px-5 my-1 py-2 text-white  text-sm font-medium float-right mr-12 rounded-lg"
            >
              Finish
            </button>
          ) : editformSchema?.formType === "KYC Form" ? (
            <button
              type="submit"
              className="bg-[#302a74] px-5 my-1 py-2 text-white  text-sm font-medium float-right mr-12 rounded-lg"
            >
              Finish
            </button>
          ) : (
            <div
              onClick={() => {
                if (step2 === false) {
                  const _schema = droppedComponents.map((component) => ({
                    ...component,
                  }));

                  if (_schema.length !== 0) {
                    setStep2(true);
                  } else {
                    toast.error("Add components in the form to continue");
                  }

                  setTransactionDataSchema(_schema);
                  setDroppedComponents([]);
                } else {
                  const tasksss = droppedComponents.map((component) => ({
                    ...component,
                  }));

                  const tasksData = tasksss.map((component) => ({
                    ...component,
                  }));

                  if (tasksData.length !== 0) {
                    const data = {
                      formName:
                        formDetails?.formName || formNameRef?.current.value,
                      formType: editformSchema
                        ? editformSchema?.formType
                        : formDetails?.formType,
                      createdBy: "Officer 246",
                      validation: [],
                      formDescription:
                        formDetails?.formDescription ||
                        formDescriptionRef.current.value,
                      formSchema: {
                        transactionData,
                        tasksData,
                      },
                    };

                    try {
                      console.log(data);
                      editformSchema
                        ? axios
                            .patch(
                              `${api}/api/v1/forms/${editformSchema._id}`,
                              data
                            )
                            .then(() => {
                              document.getElementById("my_modal_4").showModal();
                              setDroppedComponents([]);
                            })
                        : axios.post(`${api}/api/v1/forms`, data).then(() => {
                            document.getElementById("my_modal_4").showModal();
                            setDroppedComponents([]);
                          });
                    } catch (err) {
                      console.log(err);
                    }
                  } else {
                    toast.error("Add components in the form to continue");
                  }
                }
              }}
              className="bg-[#302a74] px-5 my-1 py-2 text-white  text-sm font-medium float-right mr-12 rounded-lg cursor-pointer"
            >
              {step2 ? "Finish" : "Continue"}
            </div>
          )}
          <div className="cursor-pointer px-5 my-1 py-2 text-white  text-sm font-medium float-right mx-2 rounded-lg">
            Discard
          </div>
        </div>
      </form>
    );
  };

  return editformSchema ? (
    <div className="flex flex-col justify-center items-center">
      <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-12 w-auto" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div className="flex flex-col container mx-auto justify-center items-center relative">
        <label className="text-white font-bold text-xl font-">
          <span className="font-light">Edit {editformSchema?.formType}</span>{" "}
          Template
        </label>
        <span
          aria-hidden="true "
          className="cursor-pointer left-14 top-4  absolute"
          onClick={() => {
            history.push("/templates/saved-templates");
          }}
        >
          {<IoMdArrowBack size={24} color="white" />}
        </span>
        <div className="text-white mt-3 flex flex-col justify-center items-center">
          <p className="text-center">
            Please scroll through the transaction templates to verify all the{" "}
            <br></br>
            available fields and edit template where necessary.Else,Click
            <br></br>
            'Continue to fill up the forms
          </p>
          {(
            editformSchema?.formType === "transaction" ? (
              <div className="container mt-4 flex justify-center mx-auto">
                <ul className="steps w-full ">
                  <>
                    <li className="step step-primary "></li>
                    <li className={`step ${step2 && "step-primary"} `}></li>
                  </>
                </ul>
              </div>
            ) : editformSchema?.formType !== "transaction" ? (
              ""
            ) : (
              formDetails?.formType === "transaction"
            )
          ) ? (
            <div className="container mt-4 flex justify-center mx-auto">
              <ul className="steps w-full ">
                <>
                  <li className="step step-primary "></li>
                  <li className={`step ${step2 && "step-primary"} `}></li>
                </>
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-6 mx-10 xl:mx-80 container    px-10 pt-10 pb-6">
        <LeftPanel />

        <div className="bg-[#102232] py-4 col-span-4 h-30 flex justify-center items-center border border-[#585d61] rounded-3xl ">
          <RightPanel />
        </div>
      </div>
      <dialog
        onClose={() => {}}
        id="my_modal_4"
        className="modal bg-[#0c1a27cc]"
      >
        <div className="modal-box bg-transparent flex justify-center flex-col items-center shadow-none border-none relative">
          <div className="modal-box grid grid-cols-3  pt-12 bg-[#102232] ">
            <div className="col-span-3 ">
              <div className="text-center  text-lg text-white mb-4">
                Template Confirmation
              </div>
              <div className="flex items-center flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61]">
                <label className="text-md gap-3 my-2 text-white flex justify-center items-center">
                  Form Template successfully Created{" "}
                  <BsCheckCircleFill size={25} color="#2b7734" />
                </label>
              </div>
              <span
                onClick={() => {
                  setVisible1(!visible1);
                  setVisible(!visible);
                  setStep2(false);
                  setFormDetails({
                    formName: "",
                    formDescription: "",
                    formType: "transaction",
                  });
                  setTransactionDataSchema(null);

                  history.push("/templates/new-templates");

                  const modal = document.getElementById("my_modal_4");
                  if (modal) {
                    modal.close();
                  }
                }}
                className="absolute top-3 right-4"
              >
                <IoCloseOutline color="#d4d7da" size={24} />
              </span>
              {/* <span className="flex items-center justify-center"> */}
              <button
                onClick={() => history.push("/templates/saved-templates")}
                className="bg-[#302a74] px-7 mt-6  py-2 text-white  text-sm font-medium float-right rounded-lg"
              >
                View Templates
              </button>
            </div>
          </div>
          <div className="flex w-full justify-end my-4"></div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  ) : //  visible ? (
  //   // steppp1111111111111111111111111
  //   <div className="h-screen flex justify-center items-center">
  //     <form>
  //       <div className="border-b mx-auto  w-[480px] flex flex-col justify-center items-center border-gray-900/10 mb-10 -screen  mt-16 ">
  //         <div className=" w-full bg-[#102232] px-10  rounded-3xl  py-5 shadow-lg   border-[#585d61]">
  //           <div className="font-medium text-center my-5 text-white">
  //             <span className="font-light   mr-1"> Select Template </span>
  //             Type
  //           </div>

  //           <div className="mt-5 space-y-5">
  //             <div
  //               onClick={(prev) => {
  //                 setFormDetails({ ...formDetails, formType: "KYC Form" });
  //                 setVisible(!prev);
  //               }}
  //               className="cursor-pointer flex flex-col  px-3 py-[15px] items-center  text-white justfify-center rounded-2xl border"
  //             >
  //               Create New KYC Forms
  //             </div>

  //             <div
  //               className="cursor-pointer bg-[#2b2d77] text-white flex flex-col px-3 py-[15px] items-center border-none  justfify-center rounded-2xl border"
  //               onClick={(prev) => {
  //                 setFormDetails({ ...formDetails, formType: "Transaction" });
  //                 setVisible(!prev);
  //               }}
  //             >
  //               Create New Transaction
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </form>
  //   </div>
  // ) :

  visible1 ? (
    <div className="flex flex-col justify-center items-center">
      <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-12 w-auto" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <div className="flex  w-full container mx-auto flex-col justify-center items-center relative">
        <label className="text-white font-bold text-xl font-">
          <span className="font-light">{formDetails?.formType}</span> Template
        </label>
        <span
          aria-hidden="true "
          className="cursor-pointer left-14 top-4  absolute"
          onClick={() => {
            setVisible1(!visible1);
            setVisible(!visible);
          }}
        >
          {<IoMdArrowBack size={24} color="white" />}
        </span>
        <div className="text-white mt-3 flex flex-col justify-center items-center">
          <p className="text-center">
            Please scroll through the transaction templates to verify all the{" "}
            <br></br>
            available fields and edit template where necessary.Else,Click
            <br></br>
            'Continue to fill up the forms
          </p>
          {formDetails?.formType === "transaction" && (
            <div className="container mt-4 flex justify-center mx-auto">
              <ul className="steps w-full ">
                <>
                  <li className="step step-primary "></li>
                  <li className={`step ${step2 && "step-primary"} `}></li>
                </>
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-6 mx-10 xl:mx-80 container    px-10 pt-10 pb-6">
        <LeftPanel />

        <div className="bg-[#102232] py-4 col-span-4 h-30 flex justify-center items-center border border-[#585d61] rounded-3xl ">
          <RightPanel />
        </div>
      </div>

      <dialog
        onClose={() => {}}
        id="my_modal_4"
        className="modal bg-[#0c1a27cc]"
      >
        <div className="modal-box bg-transparent flex justify-center flex-col items-center shadow-none border-none relative">
          <div className="modal-box grid grid-cols-3  pt-12 bg-[#102232] ">
            <div className="col-span-3 ">
              <div className="text-center  text-lg text-white mb-4">
                Template Confirmation
              </div>
              <div className="flex items-center flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61]">
                <label className="text-md gap-3 my-2 text-white flex justify-center items-center">
                  Form Template successfully Created{" "}
                  <BsCheckCircleFill size={25} color="#2b7734" />
                </label>
              </div>
              <span
                onClick={() => {
                  setVisible1(!visible1);
                  setVisible(!visible);
                  setStep2(false);
                  setFormDetails({
                    formName: "",
                    formDescription: "",
                    formType: "transaction",
                  });
                  setTransactionDataSchema(null);

                  history.push("/templates/new-templates");

                  const modal = document.getElementById("my_modal_4");
                  if (modal) {
                    modal.close();
                  }
                }}
                className="absolute top-3 right-4"
              >
                <IoCloseOutline color="#d4d7da" size={24} />
              </span>
              {/* <span className="flex items-center justify-center"> */}
              <button
                onClick={() => history.push("/templates/saved-templates")}
                className="bg-[#302a74] px-7 mt-6  py-2 text-white  text-sm font-medium float-right rounded-lg"
              >
                View Templates
              </button>
              {/* </span>  */}
            </div>
          </div>
          <div className="flex w-full justify-end my-4">
            {/* <button
              onClick={() => {
                const modal = document.getElementById("my_modal_4");
                if (modal) {
                  modal.close();
                }
              }}
              className="border border-[#585d61] px-8   py-3 text-white  text-sm font-medium float-right  rounded-lg"
            >
              Discard
            </button> */}
            {/* <button
              onClick={() => history.push("/templates/saved-templates")}
              className="bg-[#302a74] px-8   py-3 text-white  text-sm font-medium float-right mx-4 rounded-lg"
            >
              View
            </button> */}
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </div>
  ) : (
    <div>
      <div className="flex flex-col justify-center items-center">
        <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-12 w-auto" src={logo} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <a
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div className="text-white flex px-20 flex-row mb-4 container mx-auto text-xl items-center">
        <span
          aria-hidden="true "
          className="cursor-pointer absolute"
          onClick={() => {
            history.push("/templates/new-templates");
          }}
        >
          {<IoMdArrowBack />}
        </span>
        <label className="w-full text-xl flex justify-center font-medium">
          <span className="font-light text-white mr-2">Create </span>
          New Form
        </label>
      </div>

      <form
        onSubmit={(e) => {
          console.log("alues");
        }}
      >
        <div className="border-b mx-auto  w-[680px] flex flex-col justify-center items-center border-gray-900/10 mb-10 -screen  mt-6 ">
          <div className=" w-full bg-[#102232] px-10  rounded-3xl  py-5 shadow-lg   border-[#585d61]">
            <div className="font-medium text-center my-5 text-white">
              <span className="font-light   mr-1">{formDetails?.formType}</span>
              Details
            </div>

            <div className="mt-5 space-y-5">
              <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border  border-[#585d61]">
                <label className="text-xs mb-1 text-white">
                  {formDetails?.formType === "KYC Form"
                    ? "Form Name"
                    : "Transaction Name"}
                </label>

                <input
                  className={
                    "mt-1 text-xs bg-transparent placeholder-[#cccccc] active:border-transparent text-white focus:outline-none"
                  }
                  label="Form Name"
                  name="formName"
                  value={formDetails?.formName}
                  required
                  onChange={(e) => {
                    setFormDetails({
                      ...formDetails,
                      [e.target.name]: e.target.value,
                    });
                    console.log(e.target.value);
                  }}
                  placeholder="Enter form name"
                />
              </div>

              <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61]">
                <label className="text-xs mb-1 text-white">
                  Form Description
                </label>

                <textarea
                  className={
                    "mt-1 text-xs bg-transparent placeholder-[#cccccc] active:border-transparent text-white focus:outline-none"
                  }
                  label="Form Description"
                  rows={6}
                  type="text"
                  required
                  value={formDetails?.formDescription}
                  name="formDescription"
                  onChange={(e) => {
                    setFormDetails({
                      ...formDetails,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  placeholder="Please enter the form description"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="flex  gap-4 w-full relative mx-auto mt-5 ">
            <div className="flex w-full gap-3 justify-end  ">
              <button
                className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[40px]  text-white p-3 hover:scale-105 duration-300 h-[35px]"
                // onClick={() => {}}
              >
                Discard
              </button>
              <button
                type="submit"
                className="bg-[#2b2d77] align-center flex justify-center text-sm items-center rounded-[9px] px-[40px] text-white p-3 hover:scale-105 duration-300 h-[35px]"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    formDetails.formName === "" ||
                    formDetails.formDescription === ""
                  ) {
                    toast.error(
                      "Please fill in all the neccessary fields to continue"
                    );
                  } else {
                    setVisible(false);
                    setVisible1(true);
                  }
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
