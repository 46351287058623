import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../images/logo.png";
import { IoMdAddCircle, IoMdArrowBack } from "react-icons/io";
// import SelectField from "../../components/Combobox";
import Combobox from "../../components/ComboboxMultiSelect";
import { useDispatch } from "react-redux";
import {setSellers,setBuyers,setBuyerCount,setSellerCount, setKyc } from "../../store/transactionSlice";
// import SelectField from "../../components/SelectField";
// import { Select } from "@material-tailwind/react";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "sonner";
import { useTransactionCreationData } from "../../hooks/useTransactionCreationData";
import { useKYCFormTemplatesQuery } from "../../hooks/useFormTemplatesQuery";
import { BsDatabase } from "react-icons/bs";
import { useFormEditData } from "../../hooks/useFormEditData";
const navigation = [
  //   { name: "Product", href: "#" },
  //   { name: "Features", href: "#" },
  //   { name: "Marketplace", href: "#" },
  //   { name: "Company", href: "#" },
];
function SelectParties() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const history = useHistory();
  const dispatch=useDispatch()

  const [buyers, setBuyerss] = useState([]);
  const [sellers, setSellerss] = useState([]);
  const [partyType, setPartyType] = useState("");


  const [buyersCount, setBuyersCount] = useState(1);
  const [sellersCount, setSellersCount] = useState(1);
  const [parties, setParties] = useState([]);

  const schema = yup.object({
    buyers: yup.array().of(yup.string().optional()).optional(),
  });

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      buyers: null,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (watch("buyers").length > 0) setBuyersCount(watch("buyers").length);
  }, [watch("buyers")]);
  useEffect(() => {
     setPartyType(watch("partyType"));
  }, [watch("partyType")]);

  // useEffect(() => {
  //   console.log(forms);
  // }, [forms]);

  const { data: newParties, isFetching } = useKYCFormTemplatesQuery();
 
 
 
  const onSubmit = (datas) => {

    // console.log("////////////////////////////////////")
    // console.log(datas)
    // console.log("////////////////////////////////////")

    const filtered = datas.buyers.filter((item) => item !== "");
    let kycForms = filtered.map((item) => {
        return newParties.data.find((i) => i.formName === item);
      });
   
      if(datas.partyType === "seller"){
        dispatch(setSellerCount(kycForms.length));
        dispatch(setSellers(kycForms))
         
    }else{
        dispatch(setBuyerCount(kycForms.length))  
        dispatch(setBuyers(kycForms))
    }

      dispatch(setKyc([...kycForms]));
     
      history.push("/transactions/new/preview-forms");
   
  };





  // addBuyerToParties using the buyers count.
  function addBuyerToParties(value, index) {
    if (buyers.length < buyersCount) {
      setBuyerss((prev) => [...prev, value]);
    } else {
      setBuyerss((prev) => {
        const updatedBuyers = [...prev];
        updatedBuyers[index] = value;
        return updatedBuyers;
      });
    }
  }
 

 
  useEffect(() => {
    // normalise the parties data.  ie convert the data to an array of objects with the required keys
    if (newParties?.data) {
      let arr = newParties?.data?.map((item) => {
        return { label: item.formName, name: item.formName, id: item._id };
      });
      setParties(arr);
    }
  }, [isFetching]);






  return (
    <div>
      <div className="flex flex-col justify-center items-center">
        <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-12 w-auto" src={logo} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <a
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div className="text-white flex px-20 flex-row mb-4 container mx-auto text-xl items-center">
        <span
          aria-hidden="true"
          className="cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          {<IoMdArrowBack />}
        </span>
        <label className="w-full text-xl flex justify-center font-medium">
          <span className="font-light text-white mr-1">Select parties</span>
          involved
        </label>
      </div>

      <div className="border-b bg-red border-gray-900/10 mb-10 w-scree mt-10 ">
        <div className=" grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mx-10 xl:mx-80 bg-[#102232]  rounded-3xl  p-10 shadow-lg  border border-[#585d61]">
        <div>
            <div className="w-full text-xl  text-white font-medium">
              <span className="font-light  mr-1">Party</span>
              type
            </div>
            <div className="mt-7 space-y-5">
           
                  <Combobox
                    key={"partyType"}
                    name={`partyType`}
                    setValue={setValue}
                    register={register}
                    clearErrors={clearErrors}
                    label={"Select party type"}
                    inputPlaceholder={"Type to search  select type"}
                    placeholder={
                     "Click on arrow to select"
                    }
                    toolTip={[{label:"Seller"},{label:"Buyer"}]}
                  />
             
            </div>
          </div>
          <div>
            <div className="w-full text-xl  text-white font-medium">
              <span className="font-light  mr-1">KYC </span>
              type
            </div>
            <div className="mt-7 space-y-5">
              {Array.from({ length: buyersCount }).map((item, i) => {
                return (
                  <Combobox
                    key={"buyer" + i + parties.length}
                    name={`buyers[${i}]`}
                    setValue={setValue}
                    register={register}
                    clearErrors={clearErrors}
                    label={"Select party kyc "}
                    onSelect={addBuyerToParties}
                    arrIndex={i}
                    inputPlaceholder={"Type to search  kyc type"}
                    placeholder={
                      "Click on arrow to select"
                    }
                    toolTip={parties}
                  />
                );
              })}
              {/* <button
                onClick={() => {
                  if (buyers.length === buyersCount) {
                    setValue("buyers", [...getValues("buyers"), ""]);
                  } else {
                    toast.error(
                      "Please select a buyer type before adding more"
                    );
                  }
                }}
                className="px-2 text-[#43ace1] gap-1 text-xs font-semibold flex items-center"
              >
                <IoMdAddCircle className="" size={19} /> Add More
              </button> */}
            </div>
          </div>
       
        </div>
      </div>
     
     
     
      <div className="flex  mx-10 xl:mx-80 gap-4 w-f relative mt-5 ">
        <div className="flex w-full gap-3 justify-end  ">
          {/*  <button
            className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[40px]  text-white p-3 hover:scale-105 duration-300 h-[35px]"
            onClick={() => {}}
          >
            Discard
          </button>*/}
          <button
          // disabled={true}
            className={`${buyers.length !== 0 && partyType?.trim() !== "" ? "bg-[#2b2d77] hover:scale-105 text-white" : "bg-[#102232] text-[#4f4f4f]"}  align-center flex justify-center text-sm items-center rounded-[9px] px-[40px]  p-3  duration-300 h-[35px]`}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(onSubmit)();
            //   reset();
            }}
          >
            Continue
          </button>
        </div>
      </div>













    </div>
  );
}

export default SelectParties;
