import React, { useState, useEffect } from "react";
import { BiPlus } from "react-icons/bi";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/logo.png";
import Logo from "../images/logo.png";
import { useHistory } from "react-router-dom";
import { useCookie } from "react-use";
import { toast } from "sonner";
import axios from "axios";
export default function Sidebar({ navigationItems }) {
  const [currentLink, setCurrentLink] = useState(1);
  let history = useHistory();
  const [values] = useCookie("user");

  const userCookie = JSON.parse(values);
  useEffect(() => {
    setCurrentLink(history.location.pathname);
  }, [history.location.pathname]);
  const [value, updateCookie, deleteCookie] = useCookie("user");

  return (
    <>
      <header className="container bg-[#081c2f] block lg:hidden fixed  border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
        <nav
          className=" flex items-center mt-5 justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-12 w-auto" src={logo} alt="" />
            </a>
          </div>

          <div className="flex lg:hidden">
            <div className=" z-30   drawer-end">
              <input
                id="my-drawer-4"
                type="checkbox"
                className="drawer-toggle"
              />

              <div className="drawer-content">
                <label
                  htmlFor="my-drawer-4"
                  className="relative cursor-pointer"
                >
                  <Bars3Icon
                    className="h-6 w-6 text-gray-700"
                    aria-hidden="true"
                  />
                </label>
              </div>

              <div className="drawer-side">
                <label
                  htmlFor="my-drawer-4"
                  aria-label="close sidebar"
                  className="drawer-overlay"
                ></label>

                <ul
                  style={{ fontSize: "clamp(14px,0.8vw,40px)" }}
                  className="menu  p-4 w-80 min-h-full bg-[#081c2f] text-base-content"
                >
                  <div
                    style={{ marginTop: "min(4rem,2rem)" }}
                    className="flex lg:flex-1 mb-10"
                  >
                    <a href="#" className="-m-1.5 p-1.5">
                      <span className="sr-only">Your Company</span>
                      <img className="h-12 w-auto" src={logo} alt="" />
                    </a>
                  </div>

                  <label htmlFor="my-drawer-4">
                    <BiPlus
                      style={{ marginTop: "min(4rem,2rem)" }}
                      size={35}
                      className="absolute top-5 z-50 right-5 rotate-45"
                    />
                  </label>

                  <MenuItems />
                  <div
                    style={{
                      marginTop: "min(4rem,rem)",
                      fontSize: "clamp(16px,0.8vw,40px)",
                    }}
                    className="absolute bottom-10 right-0 items-center flex-col w-full gap-1 text-[13px] text-[#ffffff] flex justify-center"
                  >
                    <div
                      // onClick={login}
                      className=" flex  cursor-pointer p-2  gap-2 mb-2 rounded-lg items-center"
                    >
                      <RiLogoutBoxRLine color="#404f5e" size={24} />
                      <span className="font-semibold text-[#404f5e]">
                        LOGOUT
                      </span>
                    </div>
                    <p className=" text-center text-[#404f5e] font-semibold">
                      Powered by Xavier Africa.
                    </p>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className="border-r-[2px] hidden lg:flex  fixed  border-r-[#35353b]  gap-5 text-[#ffffff]  h-[100vh] w-[16%] flex-col  items-center justify-between  py-[2rem]">
        <div className="flex flex-col  gap-[4rem] w-full">
          <div className="flex justify-center align-center">
            {/* logo */}
            <div className="justify-center mt-8 items-centers relative z-10 md:flex md:pl-7 pt-0">
              <img src={Logo} alt="Logo" width={200} height={36} />
            </div>
            {/* logo end */}
          </div>

          <div
            style={{
              marginTop: "min(4rem,rem)",
              fontSize: "clamp(10px,0.8vw,40px)",
            }}
            className="flex flex-col bg-transparent justify-center items-center gap-4 font-medium  text-[#ffffff]"
          >
            <ul className="list-none flex flex-col gap-6">
              <MenuItems />
            </ul>
          </div>
        </div>

        <div
          style={{
            marginTop: "min(4rem,rem)",
            fontSize: "clamp(10px,0.8vw,40px)",
          }}
          className="items-center flex-col gap-1 text-fluid text-[#ffffff] flex justify-center"
        >
          <div className=" flex  cursor-pointer p-2  gap-2 mb-2 rounded-lg items-center">
            <RiLogoutBoxRLine color="#404f5e" size={24} />
            <span
              onClick={async () => {
                // await axios.post(
                //   `https://collins-chilisa.testlive.space/api/v1/logs`,
                //   {
                //     activity: "Logged out",
                //     userId: userCookie.id,
                //     timestamp: new Date().toISOString(), // Get current date and time in ISO format
                //   }
                // );
                deleteCookie();
                toast.success("You have been logged out successfully");
                history.push("/auth/login");
              }}
              className="font-semibold text-[#404f5e]"
            >
              LOGOUT
            </span>
          </div>
          <p className=" text-center text-[#404f5e] font-semibold">
            Powered by Xavier Africa.
          </p>
        </div>
      </div>
    </>
  );

  function MenuItems() {
    return (
      <>
        {navigationItems.map((item, index) => (
          <Link to={item.path} className="" key={index}>
            <label htmlFor="my-drawer-4">
              <li
                className={`px-[16px] flex bg-transparent hover:text-[#fff] ${
                  currentLink === item.path ? "text-white" : "text-[#4e5c6a]"
                }`}
              >
                <div className="flex bg-transparent items-center gap-4">
                  {item.icon}
                  <span className="text-[14px]">{item.title}</span>
                </div>
              </li>
            </label>
          </Link>
        ))}
      </>
    );
  }
}
