import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    _id:"",
    name: "",
    type: "",
    onGoingTransaction:false,
    reportType:"",
    description: "",
    sellerCount: 0,
    kyc:[],
    buyers:[],
    sellers:[],
    comments:"",
    status:"",
    buyerCount: 0,
    tasksData: [],
    transactionData:[],
    previewStep: 0,
    editId: 0,
    templateId:0,
    isOverride: false,
    editIndex: -1,
};
const transactionSlice = createSlice({
  name: "Transaction",
  initialState,
  reducers: {
    setTransaction: (state, action) => {
        Object.assign(state, action.payload);
    },
    set_ID: (state, action) => {
        state._id = action.payload;
    },
    setName: (state, action) => {
        state.name = action.payload;
    },
    setSellers: (state, action) => {
        state.sellers = action.payload;
    },
    setBuyers: (state, action) => {
        state.buyers = action.payload;
    },
    setType: (state, action) => {
        state.type = action.payload;
    },
    setOnGoingTransaction: (state, action) => {
        state.onGoingTransaction = action.payload;
    },
    setReportType: (state, action) => {
        state.reportType = action.payload;
    },
    setDescription: (state, action) => {
        state.description = action.payload;
    },
    setSellerCount: (state, action) => {
        state.sellerCount = action.payload;
    },
    setComments: (state, action) => {
        state.comments = action.payload;
    },
    setStatus: (state, action) => {
        state.status = action.payload;
    },
    setKyc: (state, action) => {
        state.kyc = action.payload;
    },
    setBuyerCount: (state, action) => {
        state.buyerCount = action.payload;
    },
    setTasksData: (state, action) => {
        state.tasksData = action.payload;
    },
    setTransactionData: (state, action) => {
        state.transactionData = action.payload;
    },
    setPreviewStep: (state, action) => {
        state.previewStep = action.payload;
    },
    setEditId: (state, action) => {
        state.editId = action.payload;
    },
    setTemplateId: (state, action) => {
        state.templateId = action.payload;
    },
    setIsOverride: (state, action) => {
        state.isOverride = action.payload;
    },
    setEditIndex: (state, action) => {
        state.editIndex = action.payload;
    },
    clearEditIndex: (state) => {
        // Reset the state to initial values
        Object.assign(state, initialState.editIndex);
      },

      clearEditId: (state) => {
        // Reset the state to initial values
        Object.assign(state, initialState.editId);
      },

    clearTransaction: (state) => {
      // Reset the state to initial values
      Object.assign(state, initialState);
    }
  },
});

export const {
    set_ID,
    setTransaction,
    clearTransaction,
    clearEditId,
    clearEditIndex,
    setName,
    setType,
    setReportType,
    setOnGoingTransaction,
    setDescription,
    setSellerCount,
    setComments,
    setStatus,
    setKyc,
    setSellers,
    setBuyers,
    setBuyerCount,
    setTasksData,
    setTransactionData,
    setPreviewStep,
    setEditId,
    setTemplateId,
    setIsOverride,
    setEditIndex} = transactionSlice.actions;
export const transactionReducer = transactionSlice.reducer;
