// InputField.js
import React, { useEffect, useState } from "react";
// import { useStartTyping } from "react-use";
// import inputP
const TextareaField = ({
  label,
  type,
  name,
  placeholder,
  onChange,
  value,
  className,
  visible,
  disabled,
  id,
  isErrored,
  message,
  register,
  rows = 3,
  rightNode,
}) => {
  const [hasError, setHasError] = useState(isErrored);
  // useStartTyping(() => alert("You started typing!"));

  useEffect(() => {
    setHasError(isErrored);
  }, [isErrored]);
  let newWalue = value;

  return (
    <div
      className={`flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border ${
        hasError ? "border-[red]" : "border-[#585d61]"
      }  ${type === "file" && " hidden "}`}
    >
      <label className="text-xs mb-1 text-white">{label}</label>
      {register === undefined ? (
        <textarea
          id={id}
          className={
            "mt-1 text-xs bg-transparent placeholder-[#475663] active:border-transparent focus:outline-none"
          }
          rows={rows}
          // {...register(name, { required: true })}
          type={type}
          value={value}
          // name={name}
          placeholder={placeholder}
        ></textarea>
      ) : (
        <textarea
          {...register(name)}
          id={id}
       disabled={disabled}
          rows={rows}
          className={
            "mt-1 text-xs bg-transparent placeholder-[#475663] active:border-transparent focus:outline-none"
          }
          // {...register(name, { required: true })}
          type={type}
          value={newWalue}
          // name={name}
          placeholder={placeholder}
          // onChange={(e) => {
          //   onChange(e);
          //   setHasError(false);
          // }}
        ></textarea>
      )}

      {hasError && (
        <div className="text-[red] first-letter:capitalize  text-xs mt-1">
          {message || "Required"}
        </div>
      )}
    </div>
  );
};

export default TextareaField;
