import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import SelectTemplate from "./SelectTemplate";
import NewTransaction from "./NewTransaction";
import SelectParties from "./SelectParties";
import SingleParties from "./SingleParty";
import RenderForms from "./RenderForms";
import FillForms from "./FillForms";
import { useDispatch, useSelector } from "react-redux";
import { useCookie } from "react-use";
import { loginSuccess } from "../../store/authSlice";
// import NewTemplate from "./SelectTemplate";
// import Onboarding from "../onboarding-views/onboarding";
//
// import FormBuilder from "./FormBuilder";

// import EditParty from "./EditParty";

export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const TEMPLATE_PREFIX_PATH = "/transactions";
export default function TransactionViews() {
  const usr = localStorage.getItem("user");
  const { role } = useSelector((state) => state.authState);
  const dispatch = useDispatch();
  const [value, updateCookie, deleteCookie] = useCookie("user");
  const user = JSON.parse(value) || usr;

  // console.log(user);
  if (user?.jwt) {
    dispatch(loginSuccess(user));
  }
  const userType = user?.role || "";
  function RouteProtector({ children, allowedUserTypes, ...rest }) {
    // console.log(allowedUserTypes);
    if (!allowedUserTypes.includes(role)) {
      return <Redirect to={AUTH_PREFIX_PATH} />;
    }

    return <Route {...rest} />;
  }
  return (
    <Switch>
      {/* <Route
        path={`${OnBoard_PREFIX_PATH}/edit-party/:caseId`}
        component={EditParty}
      />
      <Route
        path={`${OnBoard_PREFIX_PATH}/form_builder`}
        component={FormBuilder2}
      /> */}

      <RouteProtector
        path={TEMPLATE_PREFIX_PATH + "/select"}
        allowedUserTypes={["admin"]}
        component={SelectTemplate}
      />
      <RouteProtector
        path={TEMPLATE_PREFIX_PATH + "/new/preview-forms"}
        allowedUserTypes={["admin"]}
        component={RenderForms}
      />

      <RouteProtector
        path={TEMPLATE_PREFIX_PATH + "/new/parties"}
        allowedUserTypes={["admin"]}
        component={SelectParties}
      />

      <RouteProtector
        path={TEMPLATE_PREFIX_PATH + "/new/single"}
        allowedUserTypes={["admin"]}
        component={SingleParties}
      />





      <RouteProtector
        path={TEMPLATE_PREFIX_PATH + "/new/fill-forms"}
        allowedUserTypes={["admin"]}
        component={FillForms}
      />

      <RouteProtector
        path={TEMPLATE_PREFIX_PATH + "/new"}
        allowedUserTypes={["admin"]}
        component={NewTransaction}
      />
    </Switch>
  );
}

// export default TransactionViews;
