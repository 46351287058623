import { create } from "zustand";
export const useTemplateCreationData = create((set) => ({
  formName: "",
  formType: "",
  createBy: "Officer 246",
  formDescription: "",
  formBankName:"",
  formSchema: {},
  partyType: "",
  setPartyType: (partyType) => set({ partyType: partyType }),
  setFormName: (name) => set({ formName: name }),
  setFormType: (type) => set({ formType: type }),
  setCreateBy: (createBy) => set({ createBy: createBy }),
  setFormDescription: (description) => set({ formDescription: description }),
  setFormBankName: (bankName) => set({ formBankName: bankName }),
  setFormSchema: (schema) => set({ formSchema: schema }),
}));
