import React from "react";
import backgroundImg from "../../../images/home-img.png";
import Tabs from "../../../components/Tabs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GiBackwardTime } from "react-icons/gi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { PiSuitcaseSimple } from "react-icons/pi";
function SuperAdminDashboard() {
  const history = useHistory();
  return (
    <div className="container mx-auto pt-7 md:px-10 px-5 ">
      <div
        style={{
          backgroundImage: `url(${backgroundImg})`,
        }}
        className="mb-5  h-[26vh] md:h-[32vh] md:shrink-0 rounded-xl md:rounded-3xl shadow-md overflow-hidden mt-[110px] lg:mt-10 bg-no-repeat bg-cover"
      >
        <div className="md:flex">
          <div className=" flex flex-col md:justify-center md:w-1/2  pt-10 md:pt-0 pl-10 md:pl-10">
            <p
              className=" tracking-wide text-xl md:text-4xl md:mb-4 mb-2 text-white
           font-semibold"
            >
              Thank you <br></br>for<br></br>Choosing us
            </p>
            <p className="block leading-tight font-sm text-white ">
              Giving you peace of mind by providing you with the tools{" "}
              <br className="hidden md:flex"></br>
              you need when you need them
            </p>
          </div>

          <div className="md:w-1/2 md:pt-10  flex-col items-end pr-3 md:pr-10 flex justify-center">
            <div className="bg-[#243f57] w-1/2 md:w-[240px] lg:w-[300px] px-10 md:flex justify-center flex-col items-center hidden  relative h-[180px] rounded-3xl">
              <GiBackwardTime
                color="white"
                size={30}
                className="absolute top-4 left-4 "
              />
              <div className="space-y-2 ">
                {" "}
                <p className="underline  font-display text-white">
                  Create new users ?
                </p>
                <p className="text-white text-sm">
                  Click the 'Manage Users <br></br> button below
                </p>
              </div>
            </div>
            <>
              <dialog
                id="my_modal_5"
                className="modal modal-bottom sm:modal-middle "
              >
                <div className="modal-box  overflow-visible bg-[#102232]">
                  <h3 className="font-bold  text-lg">
                    {" "}
                    Select transaction type to continue!
                  </h3>

                  <div className="w-full bg-[#071b2e] mt-6 rounded-xl  border-[.5px]">
                    <div className="dropdown w-full dropdown-end">
                      <div
                        tabIndex={0}
                        role="button"
                        className=" min-h-12 gap-5  pl-5 items-center flex text-start btn-ghost rounded-btn w-full"
                      >
                        <PiSuitcaseSimple />
                        Dropdown
                      </div>
                      <ul
                        tabIndex={0}
                        className="menu dropdown-content border bg-[#102232] z-[1] p-2 w-full shadow bg-bae-100 rounded-box mt-4"
                      >
                        <div className="p-4">Select an item</div>
                        <div className="divider  border-white p-0 m-0"></div>
                        <li className="b">
                          <Link to="/onboard">
                            1. Individual-to-indiviadual
                          </Link>
                          <Link to="/onboard">2. Individual-to-estate</Link>
                          <Link to="/onboard">3. Company-to-company</Link>
                          <Link to="/onboard">4. Individual-to-company</Link>
                          <Link to="/onboard">
                            5. Society/Trust/Association
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="modal-action">
                    <form method="dialog">
                      {/* if there is a button in form, it will close the modal */}
                      <button className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[60px] mt-0 text-white p-3 hover:scale-105 duration-300 h-[35px]">
                        Close
                      </button>
                    </form>
                  </div>
                </div>
              </dialog>
            </>
            <button
              onClick={() => history.push("users")}
              className="md:w-[240px] lg:w-[300px] bg-[#2b2d77] md:h-[45px]  px-4 py-2 md:px-0 md:py-0 flex justify-center items-center mt-2 text-white text-md rounded-xl md:rounded-xl"
            >
              Manage Users
            </button>
          </div>
        </div>
      </div>
      <Tabs />
    </div>
  );
}

export default SuperAdminDashboard;
