import React, { useEffect, useRef, useState } from "react";
import logo from "../../images/logo.png";
import { MdTextFields } from "react-icons/md";
import { TiAttachmentOutline } from "react-icons/ti";
import { CiCalendarDate } from "react-icons/ci";
import { TfiHandDrag } from "react-icons/tfi";
import { LuPencil } from "react-icons/lu";
import { AiOutlineDelete } from "react-icons/ai";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";
import { useDrag } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  DraggableAttachment,
  DraggableDate,
  DraggableRadioField,
  DraggableSelectField,
  DraggableTextField,
  DropableComponent,
} from "../../components/DragableFields";
import InputField from "../../components/InputField";
// import { ItemTypes } from "../../utils/constants";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { removeNthElement, toSnakeCase } from "../../utils/helpers";
import { useSelector,useDispatch } from "react-redux";
import { useQueryParams } from "../../hooks/useQueryParams";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "sonner";
import { useTemplateCreationData } from "../../hooks/useTemplateCreationData";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { clearReport } from "../../store/reportSlice";
import Options from "../../components/options/options";
import { ItemTypes } from "../../utils/constants";
import InputLabel from "../../components/InputLabel";
import { useFormEditData } from "../../hooks/useFormEditData";
import { useTransactionCreationData } from "../../hooks/useTransactionCreationData";
import { setKyc, setOnGoingTransaction, setTasksData, setTransactionData } from "../../store/transactionSlice";

const api = process.env.REACT_APP_BACKEND_API_URL;
function FormBuilder2() {
  const reportInformation = useSelector((state)=>  state.reportState)
  const caseDetails = useSelector((state)=>  state.transactionState)
  

 const dispatch =useDispatch();
  const updateTemp = localStorage.getItem("updateTemp");

  const [droppedComponents, setDroppedComponents] = useState([]);
  const [shape, setShape] = useState([]);
  const history = useHistory();
  const location = useLocation();

  const {
    data,
    addBuyer,
    addSeller,
    setBuyer,
    setSeller,
    fields,
    setForms,
    buyerCount,
    sellerCount,
    forms,
    setSellerCount,
    setBuyerCount,
    transactionDataForm,
    resetForms,
    setPreviewStep,
    previewStep,
    editId: editDataId,
    isOverride,
    // editIndex,
  } = useTransactionCreationData();
  const [editId, setEditId] = useState(caseDetails.editId);
  // Access the state object passed through history.push
  const editformSchema = location.data && location.data;

 
  // console.log("editDataId");
  // console.log(caseDetails.editId);

  // console.log("editDataId");

  const [saving, setSaving] = useState(false);
  const [templateId, setTemplateId] = useState(null);
  const [form, setForm] = useState([]);
  const [formId, setFormId] = useState(caseDetails.editId);

  useEffect(() => {
    if (updateTemp !== null) {
      if (editformSchema) {
        setForm([]);
      }
    } else {
      if (editformSchema) {
        setFormId(editformSchema._id);
        setForm(editformSchema);
        // console.log(editformSchema);
      }
    }
  }, []);

  useEffect(() => {
    if (form.length !== 0) {
      if (droppedComponents?.length == 0) {
        setTemplateId(form._id);
       setDroppedComponents(form?.formSchema || form?.templateSchema);
        setShape(form.validation);
      }
    } else if (
      form.length === 0 &&
      updateTemp !== null &&
      editformSchema !== null &&
      editformSchema !== undefined
    ) {
      if (droppedComponents?.length == 0) {
        setTemplateId(editformSchema?._id);
        setDroppedComponents(editformSchema?.formSchema || editformSchema?.templateSchema );
        setShape(editformSchema?.validation);
      }
    }
  }, [form]);

  const handleSave = (data, id) => {
    const updatedComponents = droppedComponents.map((comp) => {
      let placeholder = "";

      // console.log(comp.type);
      switch (comp.type) {
        case "text":
          placeholder = "Enter " + data.inputName + " here...";
          break;
        case "file":
          placeholder = "click to upload " + data.inputName + " here...";
          break;
        case "select":
          placeholder = watch("inputOptions")?.join(" | ");
          break;
        case "radio":
          placeholder = watch("inputOptions")?.join(" | ");
          break;
        default:
          placeholder = "Enter value here...";
          break;
      }
      if (comp.id == id) {
        setShape((prev) =>
          prev.filter((shape) => shape[comp.name] == comp.inputMachineName)
        );
        return {
          id: comp.id,
          name: data.inputMachineName,
          label: data.inputName,
          placeholder: placeholder,
          type: comp.type,
          optional: data.optional,
          values: data.inputOptions,
        };
      }
      return comp;
    });
    setDroppedComponents(updatedComponents);
    setShape((prev) => {
      if (data.optional) {
        return [
          ...prev,
          { [data.inputMachineName]: [["yup.string"], ["yup.nullable"]] },
        ];
      }
      return [
        ...prev,
        { [data.inputMachineName]: [["yup.string"], ["yup.required"]] },
      ];
    });
  };





  const schema = yup
    .object()
    .shape({
      inputName: yup.string().required(),
      inputMachineName: yup.string().required(),
      inputOptions: yup.array().of(yup.string()),
      optional: yup.boolean(),
    })
    .required();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { optional: false, inputOptions: ["option1", "option2"] },
    resolver: yupResolver(schema),
  });

  const {
    formName,
    formDescription,
    formType,
    partyType,
    createBy,
    formBankName,
    setFormName,
    setFormDescription,
    setFormType,
  } = useTemplateCreationData();
  const LeftPanel = () => {
    return (
      <div className="bg-[#0e2131] border  border-[#585d61]  text-white py-8 bg flex-col  col-span-1 rounded-3xl h-fit flex justify-center items-center">
        <h2 className="text-white mb-2 text-sm font-medium">Drag & Drop</h2>
        <div className="flex text-left text-xs mt-4 flex-col space-y-4 cursor-pointer">
        <DraggableTextField />
          <DraggableAttachment isDisabled={formBankName  || editformSchema?.bank  ? true : false}/>
          <DraggableRadioField isDisabled={formBankName  || editformSchema?.bank  ? true : false}/>
          <DraggableSelectField isDisabled={formBankName  || editformSchema?.bank  ? true : false}/>
          <DraggableDate isDisabled={formBankName  || editformSchema?.bank  ? true : false}/>
        </div>
      </div>
    );
  };

  const RightPanel = () => {
    const renderInput = (component) => {
      // console.log(component);
      if (component.type === "text") {
        return (
          <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative">
            <label className="text-xs mb-1 text-white">
              {component.label ? component.label : "Field Name"}
            </label>
            <input
              name="value"
              type="text"
              label={component.label}
              className={
                "mt-1 text-xs bg-transparent placeholder-[#bbb] text-white active:border-transparent focus:outline-none"
              }
              //   onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
              disabled
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id, component.name)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "date") {
        return (
          <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative">
            <label className="text-xs mb-1 text-white">
              {component.label ? component.label : "Start date"}
            </label>
            <input
              name="value"
              type="date"
              label={component.label}
              className={
                "mt-1 text-xs bg-transparent placeholder-[#bbb] text-white active:border-transparent focus:outline-none"
              }
              onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id, component.name)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "file") {
        return (
          <div id={component.id}>
            <InputLabel
              title={component.label}
              name={component.name}
              // toolTip={<p>{component.helperText}</p>}
              label={component.label}
              htmlFor={component.name}
              subtitle={component.placeholder}
            />
            <InputField
              id={component.name}
              type={component.type}
              // register={register}
              name={component.name}
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id, component.name)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "select") {
        return (
          <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative">
            <label className="text-xs mb-1 text-white">
              {component.label ? component.label : "Select"}
            </label>
            <input
              name="value"
              type="text"
              label="Select"
              className={
                "mt-1 text-xs bg-transparent placeholder-[#bbb] placeholder:text-xs text-white active:border-transparent focus:outline-none"
              }
              onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id, component.name)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      } else if (component.type === "radio") {
        return (
          <div className="flex flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61] relative">
            <label className="text-xs mb-1 text-white">
              {component.label ? component.label : "Select"}
            </label>
            <input
              name="value"
              type="text"
              label="Select"
              className={
                "mt-1 text-xs bg-transparent placeholder-[#bbb] text-white active:border-transparent focus:outline-none"
              }
              onChange={(e) => handleInputChange(component.id, e)}
              value={component.value}
              placeholder={component.placeholder}
            />
            <button
              className="mt-2 ml-auto px-2 py-1 text-xs  text-white rounded absolute right-2 top-6"
              onClick={() => handleDelete(component.id, component.name)}
            >
              <AiOutlineDelete size={20} style={{ color: "#ae2c22" }} />
            </button>
          </div>
        );
      }
    };

    const handleInputChange = (id, event) => {
      const { name, value } = event.target;
      setDroppedComponents((prev) =>
        prev.map((comp) => {
          if (comp.id === id) {
            return { ...comp, [name]: value };
          }
          return comp;
        })
      );
    };

    const handleDelete = (id, name) => {
      setDroppedComponents((prev) => prev.filter((comp) => comp.id !== id));
      // console.log(shape.map((comp) => comp[name]);
      // setShape((prev) => prev.filter((comp) => comp[name].length > 0));
      let arr = shape;

      let index = 0;
      shape.forEach((comp, i) => {
        if (comp[name]) {
          index = i;
          return;
        }
      });
      arr = removeNthElement(arr, index);
      setShape(arr);
      // console.log(index);
    };

    const handleSaveForm = (e) => {
      e.preventDefault();
      setSaving(true);

      const formSchema = droppedComponents?.map((component) => ({
        ...component,
      }));
      const formValidation = shape?.map((component) => ({
        ...component,
      }));

      setSaving(false);

      const data = {
        formName: formName,
        formType: formType,
        createdBy: "Officer 246",
        formDescription: formDescription,
        formSchema: formSchema,
        validation: formValidation,
        isMultiparty: partyType == "Multiple" ? true : false,
      };

      if (caseDetails.isOverride) {
       
        axios.patch(`${api}/api/v1/forms/${formId}`, data).then(() => {
          localStorage.removeItem("updateTemp");
          setSaving(false);
          setDroppedComponents([]);
          reset();
          history.push("/templates/saved-templates");
          toast.success("Form updated successfully", { duration: 3000 });
        }).catch((e)=> console.log( e));
      }
       
      else {

        if (!formId) {
          axios
            .post(`${api}/api/v1/forms`, data)
            .then(() => {
              setSaving(false);
              toast.success("Form saved successfully", { duration: 3000 });
              history.push("/templates/saved-templates");

              //  document.getElementById("my_modal_4").showModal();
            })
            .catch((error) => {
              setSaving(false);
              console.log(error);
            });
        } else {

          if(caseDetails.kyc?.length > caseDetails.previewStep )  
           {
            const rightOne = caseDetails.kyc[caseDetails.editIndex];
          // Create a copy of the array with the updated element
          const updated = [...caseDetails.kyc];
          updated[caseDetails.editIndex] = { ...rightOne, formSchema: data.formSchema };
          // Update the state with the new array
         dispatch(setOnGoingTransaction(false));
         dispatch(setKyc([...updated]));

           }
         
           else if (caseDetails.kyc?.length === caseDetails.previewStep){
            const rightOne = caseDetails.transactionData;
            // Create a copy of the array with the updated element
            let updated = {...caseDetails.transactionData};
            updated= { ...rightOne, formSchema: data.formSchema };
           dispatch(setOnGoingTransaction(false));
           dispatch(setTransactionData(updated));
           }
          
            toast.success("Template updated successfully", { duration: 3000 });

            history.goBack();
          
        }
      }
 };

     
    const handleSaveReportForm = (e) => {
      e.preventDefault();
      // setSaving(true);

      const formSchema = droppedComponents?.map((component) => ({
        ...component,
      }));
      const formValidation = shape?.map((component) => ({
        ...component,
      }));

      // setSaving(false);
      const data = {
        bank: formName,
        registrationAmount: 200,
        reportName: formName,
        bankRef: "bankabc",
        formType:"report",
        createdBy: "Current User",
        comments: "String1",
        reportDescription: formDescription,
        reportType: formBankName,
        status: "String",
        templateSchema: formSchema,
        validation:formValidation
      };
   
      // console.log("reportInformation")
      // console.log(data)
      // console.log("reportInformation")

      if (reportInformation?._id !== "") {
        axios.patch(`${api}/api/v1/report-templates/${reportInformation?._id}`, data).then(() => {
          setSaving(false);
          setDroppedComponents([]);
          dispatch(clearReport());
          reset();
          history.push("/templates/saved-templates");
          toast.success("Form updated successfully", { duration: 3000 });
        });
      }
      else {
        axios.post(`${api}/api/v1/report-templates`, data)
          .then(() => {
            dispatch(clearReport());
            reset();
            toast.success("Template saved successfully", { duration: 3000 });
            history.push("/templates/saved-templates");
          })
          .catch((error) => {
            console.log(error);
          });
        }     
    };

    return (
      <form className="w-full min-h-40">
        <div className="min-h-40  py-2">
          <div className="gap-6 grid grid-cols-1 mb-4 mx-12 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-1">
            <div className="text-white  mb-6">{formName} Details</div>
            {/* <div>{formDescription}</div>
            <div>{formType}</div> */}
          </div>
          {/* {droppedComponents.length !== 0 && (
            <label className="mb-5  mt-16 text-white align-middle flex items-center justify-center font-medium">
              Preview form
            </label>
          )} */}

          <div className="gap-6 grid grid-cols-1 mt-3 mx-12 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 ">
            {droppedComponents?.map((component) => (
              <div key={component.id} className="relative">
                {renderInput(component)}

                <label
                  htmlFor="my-drawer-4"
                  className="drawer-button cursor-pointer absolute top-4 right-[1.1rem]"
                >
                  <LuPencil
                    onClick={() => {
                      queryParams.set("editId", component.id);
                      history.replace("?" + queryParams.toString());
                      const myModal = document.getElementById("my-drawer-4");
                      myModal.click();
                    }}
                    size={18}
                    color="#585d61"
                  />
                </label>
              </div>
            ))}
          </div>

          <DropableComponent
            accept={ItemTypes.INPUTS}
            setDroppedComponents={setDroppedComponents}
            droppedComponents={droppedComponents}
            shape={shape}
            setShape={setShape}
          />
        </div>
        {/* <button
          disabled={saving}
          type="submit"
          onClick={handleSaveForm}
          className="bg-[#302a74] px-5 my-1 py-2 text-white  text-sm font-medium float-right mx-12 rounded-lg"
        >
          {saving ? <BeatLoader size={10} color="#fff" /> : " Save form"}
        </button>*/}

        <div className="flex w-full gap-3 justify-end">
          <button
            className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-5 py-2  text-white  hover:scale-105 duration-300"
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            Back
          </button>
          <button
            disabled={saving}
            type="submit"
            onClick={formType  !== "kyc" && formType  !== "transaction"  ? handleSaveReportForm : handleSaveForm }
            className="bg-[#302a74] px-5 py-2 text-white  text-sm font-medium float-right mr-12 rounded-lg"
          >
            {saving ? <BeatLoader size={10} color="#fff" /> : " Save form"}
          </button>
        </div>
      </form>
    );
  };
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
 
 
 
  const onSubmit = (data) => {
    // console.log(data);
    handleSave(data, data.fieldId);
    const myModal = document.getElementById("my-drawer-4");
    myModal.click();
    toast.success("Field saved successfully");
  };







  const queryParams = useQueryParams();

  useEffect(() => {
    if (queryParams.get("editId") && droppedComponents.length == 0)
      history.goBack();
  }, []);
  useEffect(() => {
    setValue("inputMachineName", toSnakeCase(watch("inputName")));
  }, [watch("inputName")]);

  useEffect(() => {
    // const edit_id = editId;
    // console.log(editId);
    // console.log(
    //   droppedComponents.filter((comp) => comp.id == editId)[0]?.optional
    // );

    if (editId) {
      setValue(
        "inputName",
        droppedComponents.filter((comp) => comp.id == editId)[0]?.label
      );
      setValue("fieldId", editId);

      if (droppedComponents.filter((comp) => comp.id == editId)[0]?.values) {
        setValue(
          "inputOptions",
          droppedComponents.filter((comp) => comp.id == editId)[0]?.values
        );
        setValue(
          "optional",
          droppedComponents.filter((comp) => comp.id == editId)[0]?.optional
        );
      } else {
        setValue("inputOptions", ["option1", "option2"]);
        setValue("optional", false);
      }
    }
  }, [editId]);

  useEffect(() => {
    // const modal=document.get
    const edit_id = queryParams.get("editId");
    // console.log(edit_id);
    if (edit_id) {
      // console.log("ggggggggggggggg")
      // console.log(edit_id)
      setEditId(edit_id);
      // console.log("ggggggggggggggg")
    }
  }, [queryParams.get("editId")]);

  function setOptions(options) {
    setValue("inputOptions", options);
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <div className="drawer drawer-end z-10 ">
          <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
          <div className="drawer-content"></div>
          <div className="drawer-side z-10">
            <label
              htmlFor="my-drawer-4"
              aria-label="close sidebar"
              className="drawer-overlay"
            ></label>
            <ul className="menu bg-[#081c2f] p-4 w-[560px] z-10 min-h-full text-base-content">
              <h2 className="text-lg px-2 my-6">Edit form field </h2>
              <form
                onSubmit={(e) => e.preventDefault()}
                className="px-2 flex flex-col gap-5"
              >
                <div>
                  <InputField
                    label={"Field name"}
                    register={register}
                    name="inputName"
                    error={errors.inputName}
                    isErrored={errors.inputName}
                    placeholder={"Enter form label name here"}
                  />
                </div>

                <div
                  onClick={() => {
                    setValue("optional", !watch("optional"));
                  }}
                  className={`flex cursor-pointer items-center justify-between  px-3 py-[25px] bg-[#081c2f]  rounded-2xl border border-[#585d61]`}
                >
                  <div className="font-medium text-white">
                    Toggle to make field Optional
                  </div>
                  <input
                    type="checkbox"
                    className="toggle toggle-info"
                    checked={watch("optional")}
                    onChange={() => {}}
                    defaultChecked={watch("optional")}
                  />
                </div>
                {(droppedComponents?.filter((comp) => comp.id == editId)[0]
                  ?.type == "select" ||
                  droppedComponents?.filter((comp) => comp.id == editId)[0]
                    ?.type == "radio") && (
                  <>
                    <Options
                      setOptions={setOptions}
                      title={"Values"}
                      options={watch("inputOptions")}
                    />
                    {/* <textarea
                        key={editId}
                        value={watch("inputOptions")?.join("\n")}
                        className={
                          "mt-1 text-xs bg-transparent placeholder-[#475663] active:border-transparent text-white focus:outline-none"
                        }
                        label="Form Description"
                        rows={6}
                        type="text"
                        onChange={(e) => {
                          setValue("inputOptions", e.target.value.split("\n"));
                        }}
                        placeholder="Please enter the form description"
                      ></textarea> */}

                    {/* <div>{JSON.stringify(watch("inputOptions"))}</div> */}
                  </>
                )}
                <div className="absolute bottom-5 right-2">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(onSubmit)();
                    }}
                    className="bg-[#302a74] px-5 my-1 py-2 text-white  text-sm font-medium float-right mx-4 rounded-lg"
                  >
                    Save field
                  </button>
                </div>
              </form>
            </ul>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <header className=" z-2 container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0">
            <nav
              className="flex items-center justify-between p-6 lg:px-8"
              aria-label="Global"
            >
              <div className="flex lg:flex-1">
                <Link to="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img className="h-12 w-auto" src={logo} alt="" />
                </Link>
              </div>
              <div className="flex lg:hidden">
                <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </nav>
            <Dialog
              as="div"
              className="lg:hidden"
              open={mobileMenuOpen}
              onClose={setMobileMenuOpen}
            >
              <div className="fixed inset-0 z-50" />
              <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                  <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Your Company</span>
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                      alt=""
                    />
                  </a>
                  <button
                    type="button"
                    className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                    <div className="py-6">
                      <a
                        href="#"
                        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        Log in
                      </a>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </header>

          <label className="text-white text-xl font-">
            <span className="font-light">Create your new</span> Form
          </label>
        
          <div className="grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-6 mx-10 xl:mx-80 container    px-10 pt-10 pb-6">
            <LeftPanel />

            <div className="bg-[#102232] py-4 col-span-4 h-30 flex justify-center items-center border border-[#585d61] rounded-3xl ">
              <RightPanel />
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
}

export default FormBuilder2;
