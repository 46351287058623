import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function SingleCase(props) {
  const {
    title,
    description,
    color,
    subTitle,
    onOpenModal,
    caseId,
    subTasks,
    collectionName,
    id,
  } = props;
  return (
    <div
      style={{ borderLeftColor: color }}
      className={`bg-[#102232]  border-l-[8px] border-l-[${color}] rounded-lg`}
    >
      <div className="text-sm flex justify-between p- font-medium text-white">
        <div className="pt-4 text-2xl pl-4">{title}</div>
        <button className="pt-5 pr-4" onClick={() => onOpenModal({ ...props })}>
          <BiArrowBack className="-rotate-[225deg]" size={25} />
        </button>
      </div>
      <div className="divider my-2 py-0 border-white"></div>
      <div style={{ color: color }} className="pl-5 pb- flex gap-5  font-bold">
        {subTitle}
      </div>
      <div className="pl-5  flex gap-5 text-sm">{description}</div>
      <Link to={`/app/cases/${caseId}?collectionName=${collectionName}`}>
        <div className="pl-5 text-xs pb-3 text-end pr-4  gap-5 underline ">
          View Case File
        </div>
      </Link>
    </div>
  );
}

export default SingleCase;
