import React from "react";
import AppViews from "../../views/app-views";
import Sidebar from "../../components/Sidebar";
import { superAdminConfig, adminConfig } from "../../components/SidebarConfigs";
import { useSelector } from "react-redux";
export default function AppLayout() {
  // THIS IS GOING TO CHANGE AND NOW USE THE REDUX / CONTEXT   BELOW IS JUST DATA
  const { role } = useSelector((state) => state.authState);

  return (
    <>
      {role === "superAdmin" ? (
        <Sidebar navigationItems={superAdminConfig} />
      ) : role === "admin" ? (
        <Sidebar navigationItems={adminConfig} />
      ) : (
        ""
      )}
      <div className="lg:ml-[16%]">
        <AppViews />
      </div>
    </>
  );
}
