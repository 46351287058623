import React, { useEffect } from "react";

import { useState } from "react";
import { DateRange } from "react-date-range";
function ReportDateRange({
onclick
}) {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#43ace1",
    },
  ]);

 
  if (!state[0]) {
    return null;
  }
 
  return (
    <div className="dropdown w-full ">
      <div className="flex  items-center justify-between  rounded-lg  py-2 px-2  w-full">
        <div tabIndex={0} role="button" className=" w-full text-xs">
          {state[0].startDate.toDateString()} -{" "}
          {state[0].endDate?.toDateString()}
        </div>
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content border border-[#585d61] z-[1] menu p-2 shadow rounded-box bg-[#081c2f]"
      >
        {state?.length > 0 && (
          <DateRange
            classNames={["test"]}
            color="red"
            editableDateInputs={false}
            onChange={(item) => {
              setState([item.selection]);
              onclick(item.selection)
            }}
            moveRangeOnFirstSelection={false}
            onRangeFocusChange={(item) => console.log("Item")}
            ranges={state}
          />
        )}
      </ul>
    </div>
  );
}

export default ReportDateRange;
