// import { Dialog } from "@headlessui/react";
// import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
// import React, { useEffect, useMemo, useState } from "react";
// import { useCookie, useList } from "react-use";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   Link,
//   useHistory,
//   useLocation,
// } from "react-router-dom/cjs/react-router-dom.min";
// import logo from "../../images/logo.png";
// import { IoMdAddCircle, IoMdArrowBack } from "react-icons/io";
// import { useQueryParams } from "../../hooks/useQueryParams";
// import { set, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import { toast } from "sonner";
// import { useTransactionCreationData } from "../../hooks/useTransactionCreationData";
// import InputField from "../../components/InputField";
// import InputLabel from "../../components/InputLabel";
// import { FaArrowRight } from "react-icons/fa";
// import SelectField from "../../components/SelectField";
// import { colorTextPairs } from "../../colors";
// import {
//   clearTransaction,
//   setKyc,
//   setTasksData,
//   setTransactionData,
// } from "../../../src/store/transactionSlice";
// import {
//   useFormByIdQuery,
//   useKYCFormByNameQuery,
// } from "../../hooks/useFormTemplatesQuery";
// import axios from "axios";
// import RadioField from "../../components/RadioField";
// import { transformAll } from "@demvsystems/yup-ast";
// import {
//   convertArrayToObject,
//   generateRandomLetters,
//   toSnakeCase,
// } from "../../utils/helpers";
// import { useFormEditData } from "../../hooks/useFormEditData";
// import DateInput from "../../components/DateInput";
// import { Button, Empty } from "antd";
// import { Step } from "@material-tailwind/react";
// import { clearReport } from "../../store/reportSlice";
// const api = "https://collins-chilisa.testlive.space/api/";




// const navigation = [
//   //   { name: "Product", href: "#" },
//   //   { name: "Features", href: "#" },
//   //   { name: "Marketplace", href: "#" },
//   //   { name: "Company", href: "#" },
// ];
// function FillForms() {


//   const caseDetails = useSelector((state) => state.transactionState);
  


//   function renderInput(
//     item,
//     register,
//     // errors,
//     setValue,
//     clearErrors,
//     value,
//     index = 0,
//     addToDateList,
//     list,
//     updateAt
//   ) {

//     if (item.type === "file") {
//       return (
//         <div key={item.id} id={item.id}>
//           <InputLabel
//             title={item.label}
//             name={item.name}
//             key={value}
//             toolTip={<p>{item.helperText}</p>}
//             label={item.label}
//             htmlFor={item.name}
//             subtitle={typeof value == "string" ? value : item.placeholder}
//           />
//           <InputField
//             id={item.name}
//             type={item.type}
//             register={register}
//             name={item.name}
//             setValue={setValue}
//           />
//         </div>
//       );
//     } else if (item.type === "select") {
//       return (
//         <div key={item.id} id={item.id}>
//           <SelectField
//             label={item.label}
//             placeholder="click arrow to select a value"
//             name={item.name}
//             // isErrored={errors[item.name]}
//             register={register}
//             message={"Please select a value"}
//             setValue={setValue}
//             value={item.value}
//             clearErrors={clearErrors}
//             toolTip={item.values}
//           />
//         </div>
//       );
//     } else if (item.type === "radio") {
//       console.log(item);
  
//       return (
//         <div key={item.id} id={item.id}>
//           <RadioField
//             label={item.label}
//             name={item.name}
//             placeholder={item.placeholder}
//             message={item.placeholder}
//             setValue={setValue}
//             clearErrors={clearErrors}
//             // isErrored={errors[item.name]}
//             value={item.value}
//             options={item.values}
//             register={register}
//           />
//         </div>
//       );
//     } else if (item.type == "tasks") {

// console.log("alllllllllllllllllllllllllllllllllll")
// console.log(list)
// console.log("alllllllllllllllllllllllllllllllllll")


//       let taskInList = list?.find((dateItem) => dateItem.itemId == item._id);
//       return (
//         <Tasks
//           key={item._id}
//           index={index}
//           startDate={new Date()}
//           endDate={new Date()}
//           name={item.label || item.name}
//           addToDateList={addToDateList}
//           subTasks={item.subTasks}
//           id={item._id}
//           list={list}
//           itemId={item._id}
//           updateAt={updateAt}
//         />
//       );
//     } else if (item.type == "date") {
//       return (
//         <div key={item.id} id={item.id}>
//         <InputField
//           // isErrored={errors[item.name]}
//           register={register}
//           id={item.name}
//           type={item.type}
//           name={item.name}
//           defaultValue={value}
//           label={item.label}
//           value={item.value ?? undefined}
//           placeholder={item.placeholder}
//         />
//       </div>);
//     } else {
//       return (
//         <div key={item.id + item.value} id={item.id}>
//           <InputField
//             // isErrored={errors[item.name]}
//             register={register}
//             id={item.id}
//             type={item.type}
//             optional={item.optional}
//             name={item.name}
//             label={item.label}
//             value={item.value ?? undefined}
//             placeholder={item.placeholder}
//           />
//         </div>
//       );
//     }
//   }
//   const [values] = useCookie("user");
//   const userCookie = JSON.parse(values);
//   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
//   const history = useHistory();

//   const dispatch = useDispatch();



//   const { buyerCount, sellerCount } = useTransactionCreationData();

//   const queryParams = useQueryParams();
//   const [step, setStep] = useState(0);
//   const [stepHeader, setStepHeader] = useState("1st party");
//   const [stepSubHeader, setStepSubHeader] = useState("details");
//   const [updatedForms, setUpdatedForms] = useState(
//     caseDetails.kyc?.length > step
//       ? caseDetails?.kyc[step]
//       : caseDetails.kyc?.length === step
//       ? caseDetails.transactionData
//       : caseDetails.tasksData
//   );

//   const [forms, setForms] = useState(
//     caseDetails.kyc?.length > step
//       ? caseDetails?.kyc[step]
//       : caseDetails.kyc?.length === step
//       ? caseDetails.transactionData
//       : caseDetails.tasksData
//   );
//   useEffect(() => {
//     // alert(step)
//     setForms(
//       caseDetails.kyc?.length > step
//         ? caseDetails?.kyc[step]
//         : caseDetails.kyc?.length === step
//         ? caseDetails.transactionData
//         : caseDetails.tasksData
//     );
//     setUpdatedForms(
//       caseDetails.kyc?.length > step
//         ? caseDetails?.kyc[step]
//         : caseDetails.kyc?.length === step
//         ? caseDetails.transactionData
//         : caseDetails.tasksData
//     );
//   }, [step]);

//   useEffect(() => {
//     if (caseDetails.kyc?.length > step) {
//       // for(let i = 0 ;caseDetails.kyc?.length; i++){
//         const stp = step === 0 ? 1 : step + 1;
//         const suffixes = ["st", "nd", "rd", "th"];
//         const lastDigit = stp % 10;
//         const secondLastDigit = Math.floor(stp / 10) % 10;
//         const suffixIndex = (secondLastDigit === 1) ? 3 : (lastDigit < 4) ? lastDigit - 1 : 3;
//         const num =  stp + suffixes[suffixIndex];
//       setStepHeader(num);
//       setStepSubHeader("Party Details");
//       // }
//     } else if (caseDetails.kyc?.length === step) {
//       setStepHeader("Transaction");
//       setStepSubHeader("Information");
//     } else {
//       setStepHeader("Transaction Tasks");
//       setStepSubHeader("");
//     }
//   }, [step]);

//   const [validation, setValidation] = useState(forms[0]?.validation);

//   useEffect(() => {
//     if (step < forms.length) {
//       setValidation(
//         // []
//         forms[step].validation
//       );
//     } else {
//     }
//   }, [step]);

//   // console.log(validation);
//   const newSchema = transformAll([
//     ["yup.object"],
//     ["yup.shape"],
//     ["yup.required"],
//   ]);

//   const {
//     register,
//     reset,
//     getValues,
//     handleSubmit,
//     watch,
//     setValue,
//     clearErrors,
//     // formState: { errors },
//   } = useForm({
//     shouldUseNativeValidation: true,
//     defaultValues: forms[step]?.formSchema?.reduce((acc, el) => {
//       acc[el.name] = el.value;
//       return acc;
//     }, {}),
//     // resolver: yupResolver(newSchema),
//   });
//   const [transactionStep, setTransactionStep] = useState(0);

//   const { resetData } = useTransactionCreationData();
//   const { resetEditData } = useFormEditData();

 

//   const onSubmit = (data) => {
   

//     let emptyFields = false ;

//     {caseDetails.kyc?.length > step
//       ? 
        
// // Iterate through the form schema
// caseDetails.kyc[step].formSchema?.forEach(field => {
 
//   // Check if the field exists in the personDetails object
//   if (data.hasOwnProperty(field.name)) {
  
//       // Check if the field is not optional and its value in personDetails is empty
//       if (field.optional === false   && data[field.name] === "") {
//          emptyFields = true;
//       }
//       else{
//         emptyFields= false;
//       }
     
//   } else {
//       console.log(` does not exist in collectio`);
//   }
// })
      
         
        
//       : caseDetails.kyc?.length === step
//       ? 
//       // Iterate through the form schema
//       caseDetails.transactionData.formSchema?.forEach(field => {
       
//         // Check if the field exists in the personDetails object
//         if (data.hasOwnProperty(field.name)) {
        
//             // Check if the field is not optional and its value in personDetails is empty
//             if (field.optional === false   && data[field.name] === "") {
//                emptyFields = true;
//             }
//             else{
//               emptyFields= false;
//             }
           
//         } else {
//             console.log(` does not exist in collectio`);
//         }
//       })
//       :  caseDetails.tasksData.formSchema?.forEach(field => {
       
//         // Check if the field exists in the personDetails object
//         if (data.hasOwnProperty(field.name)) {
        
//             // Check if the field is not optional and its value in personDetails is empty
//             if (field.optional === false   && data[field.name] === "") {
//                emptyFields = true;
//             }
//             else{
//               emptyFields= false;
//             }
           
//         } else {
//             console.log(` does not exist in collectio`);
//         }
//       })}



























//         if (emptyFields) {
//           toast.error("Please fill in all the fields.");
//         }else
//         {


//           setStep((step) => {
//             if (caseDetails.kyc && caseDetails.kyc.length > step) {
      
//               return step + 1;
//             } else if (caseDetails.kyc && caseDetails.kyc.length === step) {
//               return step + 1;
//             } else {
//               return step + 1;
//             }
//           });
      
//           let currentForm = forms;
      
//           if (caseDetails.kyc && caseDetails.kyc.length > step) {
//             let newSchema = currentForm?.formSchema?.map((el) => {
//               // Check if the element is of type "file" and its value is not a link
//               if (el.type === "file" && !isLink(data[el.name])) {
//                 return el; // Retain the original element without modifying its value
//               }
      
//               // For other types or file elements with link values, update the value from the data object
//               return { ...el, value: data[el.name] };
//             });
      
//             // Function to check if a value is a link (you can adjust this based on your link detection criteria)
//             function isLink(value) {
//               // Example: Check if the value starts with "http://" or "https://"
//               return (
//                 typeof value === "string" &&
//                 (value.startsWith("http://") || value.startsWith("https://"))
//               );
//             }
      
//             let object = caseDetails.kyc[step];
//             let objects = [...caseDetails.kyc];
//             let updatedObject = { ...object, formSchema: newSchema };
//             objects[step] = updatedObject;
      
//             // setKYCForms(objects)
//             dispatch(setKyc(objects));
//           } else if (caseDetails.kyc?.length === step) {
//             let newSchema = currentForm?.formSchema?.map((el) => {
//               // Check if the element is of type "file" and its value is not a link
//               if (el.type === "file" && !isLink(data[el.name])) {
//                 return el; // Retain the original element without modifying its value
//               }
      
//               // For other types or file elements with link values, update the value from the data object
//               return { ...el, value: data[el.name] };
//             });
      
//             // Function to check if a value is a link (you can adjust this based on your link detection criteria)
//             function isLink(value) {
//               // Example: Check if the value starts with "http://" or "https://"
//               return (
//                 typeof value === "string" &&
//                 (value.startsWith("http://") || value.startsWith("https://"))
//               );
//             }
//             let object = caseDetails.transactionData;
//             let updatedObject = { ...object, formSchema: newSchema };
//             dispatch(setTransactionData(updatedObject));
//           } else {
//             let newSchemaaaa = currentForm?.formSchema?.map((el) => {
//               return { ...el };
//             });
//             // startDate: "2024-25-08T14:43:46.480Z" ,endDate: "2024-25-08T14:43:46.480Z"
//             let object = caseDetails.tasksData;
//             let updatedObject = { ...object, formSchema: newSchemaaaa };
//             dispatch(setTasksData(updatedObject));
//           }
      
      
//           if (step > caseDetails.kyc?.length ) {



//             let tasksWidthDates = caseDetails.tasksData.formSchema?.map((task) => {
//               let taskInDateList = list?.find((el) => task._id == el.itemId);
//               if (taskInDateList) {
                          
// console.log("llllllllllllllllllllllllllllllllll")
// console.log(taskInDateList)
// console.log("llllllllllllllllllllllllllllllllll")
//                 return {
//                   ...task,
//                   startDate: taskInDateList.startDate,
//                   endDate: taskInDateList.endDate,  
//                   dates: {
//                     startDate: taskInDateList.startDate,
//                     endDate: taskInDateList.endDate,  
//                   },
//                 };
//               }
//               return { ...task, dates: null };
//             });
      
//             let allDates = tasksWidthDates.reduce((dates, task) => {
//               if (task.dates) {
//                 dates.push(task.dates.startDate, task.dates.endDate);
//               }
//               return dates;
//             }, []);
//             // Finding the latest date
//             let latestDate = new Date(Math.max(...allDates));
//             // Finding the oldest date
//             let oldestDate = new Date(Math.min(...allDates));
      
//             // Convert dates back to string format if needed
//             // const oldestDateString = oldestDate.toISOString(); // "2024-05-07T13:01:19.567Z"
//             // const latestDateString = latestDate.toISOString(); // "2024-05-07T13:01:19.567Z"
      
//             let data = {
//               buyerCount: caseDetails?.buyerCount,
//               sellerCount: caseDetails?.sellerCount,
//               createdBy: userCookie?.name,
//               description: caseDetails?.description,
//               transactionName: caseDetails?.name || caseDetails?.transactionName,
//               estimatedStartDate: oldestDate,
//               reportType: caseDetails.reportType?.instructionOwner,
//               estimatedEndDate: latestDate,
//               collectionName: caseDetails.type?.name,
//               status: caseDetails?.onGoingTransaction === true ? caseDetails?.status : "to-do",
//               comments: "",
//               registrationAmount: 3400,
//               kyc: caseDetails?.kyc,
//               transactionData: caseDetails?.transactionData,
//               tasks: tasksWidthDates,
//             };
      
           
//             if (caseDetails?.onGoingTransaction === true) {
//           console.log("Date")
//           console.log(data)
//           console.log("Date")
//               // alert("updating")
//               axios
//                 .put(
//                   `${api}v1/transactions/${caseDetails.collectionName?.toLowerCase()}/${
//                     caseDetails._id
//                   }`,
//                  data
//                 )
//                 .then((res) => {
//                   console.log("res.data");
//                   console.log(res.data);
//                   console.log("res.data");
//                   resetData();
//                   toast.success("Transaction Updated successfully");
//                   resetEditData();
//                   localStorage.clear();
//                   history.push({
//                     pathname: "/app/home",
//                   });
//                 })
//                 .catch((err) => {
//                   console.log(err);
//                 });
//             } else {
        
//               axios
//                 .post(`${api}v1/transactions`, data)
//                 .then((res) => {
//                   console.log("res.data");
//                   console.log(res.data);
//                   console.log("res.data");
//                   resetData();
//                   toast.success("Transaction created successfully");
//                   // resetData();
//                   resetEditData();
//                   dispatch(clearTransaction());
//                   dispatch(clearReport());
//                   localStorage.clear();
//                   history.push({
//                     pathname: "/app/home",
//                   });
//                 })
//                 .catch((err) => {
//                   console.log(err);
//                 });
//             }
//           }





//         }



    
//   };

//   const [
//     list,
//     {
//       set,
//       push,
//       updateAt,
//       insertAt,
//       update,
//       updateFirst,
//       upsert,
//       sort,
//       filter,
//       removeAt,
//       clear,
//       reset: resetList,
//     },
//   ] = useList([]);
//   return (
//     <div>
//       {/* Open the modal using document.getElementById('ID').showModal() method */}

//       <div className="flex flex-col justify-center items-center">
//         <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
//           <nav
//             className="flex items-center justify-between p-6 lg:px-8"
//             aria-label="Global"
//           >
//             <div className="flex lg:flex-1">
//               <Link to="/" className="-m-1.5 p-1.5">
//                 <span className="sr-only">Your Company</span>
//                 <img className="h-12 w-auto" src={logo} alt="" />
//               </Link>
//             </div>
//             <div className="flex lg:hidden">
//               <button
//                 type="button"
//                 className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
//                 onClick={() => setMobileMenuOpen(true)}
//               >
//                 <span className="sr-only">Open main menu</span>
//                 <Bars3Icon className="h-6 w-6" aria-hidden="true" />
//               </button>
//             </div>
//             <div className="hidden lg:flex lg:gap-x-12">
//               {navigation.map((item) => (
//                 <a
//                   key={item.name}
//                   href={item.href}
//                   className="text-sm font-semibold leading-6 text-gray-900"
//                 >
//                   {item.name}
//                 </a>
//               ))}
//             </div>
//           </nav>
//           <Dialog
//             as="div"
//             className="lg:hidden"
//             open={mobileMenuOpen}
//             onClose={setMobileMenuOpen}
//           >
//             <div className="fixed inset-0 z-50" />
//             <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
//               <div className="flex items-center justify-between">
//                 <a href="#" className="-m-1.5 p-1.5">
//                   <span className="sr-only">Your Company</span>
//                   <img
//                     className="h-8 w-auto"
//                     src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
//                     alt=""
//                   />
//                 </a>
//                 <button
//                   type="button"
//                   className="-m-2.5 rounded-md p-2.5 text-gray-700"
//                   onClick={() => setMobileMenuOpen(false)}
//                 >
//                   <span className="sr-only">Close menu</span>
//                   <XMarkIcon className="h-6 w-6" aria-hidden="true" />
//                 </button>
//               </div>
//               <div className="mt-6 flow-root">
//                 <div className="-my-6 divide-y divide-gray-500/10">
//                   <div className="space-y-2 py-6">
//                     {navigation.map((item) => (
//                       <a
//                         key={item.name}
//                         href={item.href}
//                         className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
//                       >
//                         {item.name}
//                       </a>
//                     ))}
//                   </div>
//                   <div className="py-6">
//                     <a
//                       href="#"
//                       className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
//                     >
//                       Log in
//                     </a>
//                   </div>
//                 </div>
//               </div>
//             </Dialog.Panel>
//           </Dialog>
//         </header>
//       </div>
//       <div className="text-white flex px-20 flex-row mb-4 container mx-auto text-xl items-center">
//         <span
//           aria-hidden="true"
//           className="cursor-pointer"
//           onClick={() => {
//             history.goBack();
//           }}
//         >
//           {<IoMdArrowBack />}
//         </span>
//         <label className="w-full text-xl flex justify-center font-medium">
//           <span className="font-light text-white mr-1">{stepHeader} </span>
//           {stepSubHeader}
//         </label>
//       </div>
//       <div className="container mx-auto w-[530px] text-gray-100 text-center mt-5">
//         Kindly fill in the{" "}
//         <strong>
//           {stepHeader} {stepSubHeader}
//         </strong>{" "}
//         form as accurately as possible. Attach documents where necessary and
//         proceed accordingly.
//       </div>

//       <div className="container mt-4 flex justify-center mx-auto">
//         <ul className="steps w-full">
//           {queryParams.get("step") && (
//             <>
//               <li className="step step-primary"></li>
//               <li
//                 className={`step ${
//                   parseInt(queryParams.get("step")) > buyerCount &&
//                   "step-primary"
//                 }`}
//               ></li>
//               <li
//                 className={`step ${
//                   parseInt(queryParams.get("step")) >
//                     sellerCount + buyerCount && "step-primary"
//                 }`}
//               ></li>
//               <li
//                 className={`step ${
//                   queryParams.get("step") > buyerCount + sellerCount + 1 &&
//                   "step-primary"
//                 }`}
//               ></li>
//             </>
//           )}
//         </ul>
//       </div>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <div
//           key={step + "-" + transactionStep}
//           className="border-b bg-red border-gray-900/10 mb-10 w-scree mt-10 "
//         >
//           <div className=" grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mx-10 xl:mx-80 bg-[#102232]  rounded-3xl  p-10 shadow-lg  border border-[#585d61]">
//             {/* {buyerCount + sellerCount} */}
//             <div className="col-span-full flex justify-between">
//               <div>
//                 <h1 className="text-white text-lg font-semibold">
//                   {caseDetails.kyc?.length > step
//                     ? caseDetails.kyc[step]?.formName
//                     : caseDetails.kyc?.length === step
//                     ? caseDetails.transactionData?.formName
//                     : `${caseDetails.tasksData?.formName} Tasks`}
//                 </h1>
//               </div>
//             </div>
          
   
//             {caseDetails.kyc?.length > step
//               ? caseDetails.kyc[step]?.formSchema?.map((el) =>
//                   renderInput(
//                     el,
//                     register,
//                     // errors,
//                     setValue,
//                     clearErrors,
//                     el.value ?? watch(el.name)
//                   )
//                 )
//               : caseDetails.kyc?.length === step
//               ? caseDetails.transactionData.formSchema?.map((el) => 
//                   renderInput(
//                     el,
//                     register,
//                     // errors,
//                     setValue,
//                     clearErrors,
//                     el.value ?? watch(el.name)
//                   )
                 
//                 )
//               : caseDetails.tasksData.formSchema?.map((el, i) =>
//                   renderInput(
//                     el,
//                     () => {},
//                     // errors,
//                     setValue,
//                     clearErrors,
//                     el,
//                     i,
//                     push,
//                     list,
//                     updateAt
//                   )
//                 )}
//           </div>
//         </div>
//         <div className="flex  mx-10 xl:mx-80 gap-4 w-f relative mt-5 ">
//         <div className="flex w-full gap-3 justify-end  ">
//           {step > 0 && (
//             <button
//               className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[40px]  text-white p-3 hover:scale-105 duration-300 h-[35px]"
//               onClick={(e) => {
//                 e.preventDefault();

//                 if(step > caseDetails.kyc?.length){
//                   setStep((step) => step - 1);
//                 }
//                 else{
//                 setStep((step) => step - 1);
//                 }
//               }}
//             >
//               Back
//             </button>
//           )}
//           <button
//            type="submit"
//             className="bg-[#2b2d77] align-center flex justify-center text-sm items-center rounded-[9px] px-[40px] text-white p-3 hover:scale-105 duration-300 h-[35px]"
//             // onClick={(e) => {
//             //   e.preventDefault();
//             //   handleSubmit(onSubmit)();
//             //   // reset();
//             // }}
//           >
//             Continue
//           </button>
//         </div>
//       </div>
//       </form>
//     </div>
//   );
// }

// export default FillForms;

// export function Tasks({
//   name,
//   subTasks,
//   index,
//   bg,
//   endDate,
//   startDate,
//   addToDateList,
//   itemId,
//   list,
//   updateAt,
// }) {
//   const [rotation, setRotation] = useState(-45);
//   const [height, setHeight] = useState(0);

//   return (
//     <div
//       className={`flex  ${
//         index === 0 && ""
//       } cursor-pointer  relative items-center gap-3 border border-[#585d61] p-3 rounded-2xl bg-[#081c2f]`}
//     >
//       <div
//         style={{
//           color: colorTextPairs[index % colorTextPairs.length].text,
//           backgroundColor: colorTextPairs[index % colorTextPairs.length].color,
//         }}
//         className={` text-sm h-[30px] flex justify-center items-center w-[30px] rounded-full font-bold`}
//       >
//         {index + 1}
//       </div>
//       <div className="text-white text-xs w-5/12">{name}</div>
//       <div
//         onClick={() => {
//           if (rotation === 45) {
//             setRotation(-45);
//             setHeight(0);
//           } else {
//             setHeight(40);
//             setRotation(45);
//           }
//         }}
//         className=" flex justify-end  w-4/12 py-3 px-auto"
//       >
//         <FaArrowRight
//           className="transition-all"
//           style={{ transform: `rotateZ(${rotation}deg)` }}
//         />
//       </div>

//       {rotation == 45 && (
//         <div
//           style={{
//             marginBottom: `${subTasks.length * height + 65}px`,
//             transition: "margin 0.5s",
//           }}
//           className={`  rounded-bl-2xl rounded-br-2xl absolute overflow-visible  transition-all group p-3 w-full left-0 top-[48px] border-b border-l border-r  border-[#585d61] z-20  bg-[#081c2f]`}
//         >
//           <div className="flex-col overflow-visible justify-between">
//             <div className="mb-3 ">
//               <DateInput
//                 addToDateList={addToDateList}
//                 startDate={startDate}
//                 endDate={endDate}
//                 itemId={itemId}
//                 list={list}
//                 updateAt={updateAt}
//               />
//             </div>
//             <div className="pl-3">
//               <div className="divider mt-1 mb-0"></div>

//               {subTasks?.map((item, i) => {
//                 return (
//                   <>
//                     <TaskRender
//                       title={item.name}
//                       index={i + 1}
//                       key={`${item.name}-${name}-${item.id}-${i}`}
//                     />
//                   </>
//                 );
//               })}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// function TaskRender({ title, index }) {
//   return (
//     <div className="text-xs pt-1 truncate overflow-visible">
//       <div className="pl-3">
//         {index}. {title}
//       </div>
//       <div className="divider mt-1 mb-0"></div>
//     </div>
//   );
// }
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useMemo, useState } from "react";
import { useCookie, useList } from "react-use";
import { useSelector, useDispatch } from "react-redux";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import logo from "../../images/logo.png";
import { IoMdAddCircle, IoMdArrowBack } from "react-icons/io";
import { useQueryParams } from "../../hooks/useQueryParams";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "sonner";
import { useTransactionCreationData } from "../../hooks/useTransactionCreationData";
import InputField from "../../components/InputField";
import InputLabel from "../../components/InputLabel";
import { FaArrowRight } from "react-icons/fa";
import SelectField from "../../components/SelectField";
import { colorTextPairs } from "../../colors";
import {
  clearTransaction,
  setKyc,
  setTasksData,
  setTransactionData,
} from "../../../src/store/transactionSlice";
import {
  useFormByIdQuery,
  useKYCFormByNameQuery,
} from "../../hooks/useFormTemplatesQuery";
import axios from "axios";
import RadioField from "../../components/RadioField";
import { transformAll } from "@demvsystems/yup-ast";
import {
  convertArrayToObject,
  generateRandomLetters,
  toSnakeCase,
} from "../../utils/helpers";
import { useFormEditData } from "../../hooks/useFormEditData";
import DateInput from "../../components/DateInput";
import { Button, Empty } from "antd";
import { Step } from "@material-tailwind/react";
import { clearReport } from "../../store/reportSlice";
const api = "https://collins-chilisa.testlive.space/api/";

const navigation = [
  //   { name: "Product", href: "#" },
  //   { name: "Features", href: "#" },
  //   { name: "Marketplace", href: "#" },
  //   { name: "Company", href: "#" },
];
function FillForms() {
  const caseDetails = useSelector((state) => state.transactionState);

  function renderInput(
    item,
    register,
    // errors,
    setValue,
    clearErrors,
    value,
    index = 0,
    addToDateList,
    list,
    updateAt
  ) {
    if (item.type === "file") {
      return (
        <div key={item.id} id={item.id}>
          <InputLabel
            title={item.label}
            name={item.name}
            key={value}
            toolTip={<p>{item.helperText}</p>}
            label={item.label}
            htmlFor={item.name}
            subtitle={typeof value == "string" ? value : item.placeholder}
          />
          <InputField
            id={item.name}
            type={item.type}
            register={register}
            name={item.name}
            setValue={setValue}
          />
        </div>
      );
    } else if (item.type === "select") {
      return (
        <div key={item.id} id={item.id}>
          <SelectField
            label={item.label}
            placeholder="click arrow to select a value"
            name={item.name}
            // isErrored={errors[item.name]}
            register={register}
            message={"Please select a value"}
            setValue={setValue}
            value={item.value}
            clearErrors={clearErrors}
            toolTip={item.values}
          />
        </div>
      );
    } else if (item.type === "radio") {
      console.log(item);

      return (
        <div key={item.id} id={item.id}>
          <RadioField
            label={item.label}
            name={item.name}
            placeholder={item.placeholder}
            message={item.placeholder}
            setValue={setValue}
            clearErrors={clearErrors}
            // isErrored={errors[item.name]}
            value={item.value}
            options={item.values}
            register={register}
          />
        </div>
      );
    } else if (item.type == "tasks") {
      console.log("alllllllllllllllllllllllllllllllllll");
      console.log(list);
      console.log("alllllllllllllllllllllllllllllllllll");

      let taskInList = list?.find((dateItem) => dateItem.itemId == item._id);
      return (
        <Tasks
          key={item?.id || item?._id}
          index={index}
          startDate={new Date()}
          endDate={new Date()}
          name={item.label || item.name}
          addToDateList={addToDateList}
          subTasks={item.subTasks}
          id={item?.id || item?._id}
          list={list}
          itemId={item?.id || item?._id}
          updateAt={updateAt}
        />
      );
    } else if (item.type == "date") {
      return (
        <div key={item.id} id={item.id}>
          <InputField
            // isErrored={errors[item.name]}
            register={register}
            id={item.name}
            type={item.type}
            name={item.name}
            defaultValue={value}
            label={item.label}
            value={item.value ?? undefined}
            placeholder={item.placeholder}
          />
        </div>
      );
    } else {
      return (
        <div key={item.id + item.value} id={item.id}>
          <InputField
            // isErrored={errors[item.name]}
            register={register}
            id={item.id}
            type={item.type}
            optional={item.optional}
            name={item.name}
            label={item.label}
            value={item.value ?? undefined}
            placeholder={item.placeholder}
          />
        </div>
      );
    }
  }
  const [values] = useCookie("user");
  const userCookie = JSON.parse(values);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();

  const { buyerCount, sellerCount } = useTransactionCreationData();

  const queryParams = useQueryParams();
  const [step, setStep] = useState(0);
  const [stepHeader, setStepHeader] = useState("1st party");
  const [stepSubHeader, setStepSubHeader] = useState("details");
  const [updatedForms, setUpdatedForms] = useState(
    caseDetails.kyc?.length > step
      ? caseDetails?.kyc[step]
      : caseDetails.kyc?.length === step
      ? caseDetails.transactionData
      : caseDetails.tasksData
  );

  const [forms, setForms] = useState(
    caseDetails.kyc?.length > step
      ? caseDetails?.kyc[step]
      : caseDetails.kyc?.length === step
      ? caseDetails.transactionData
      : caseDetails.tasksData
  );
  useEffect(() => {
    // alert(step)
    setForms(
      caseDetails.kyc?.length > step
        ? caseDetails?.kyc[step]
        : caseDetails.kyc?.length === step
        ? caseDetails.transactionData
        : caseDetails.tasksData
    );
    setUpdatedForms(
      caseDetails.kyc?.length > step
        ? caseDetails?.kyc[step]
        : caseDetails.kyc?.length === step
        ? caseDetails.transactionData
        : caseDetails.tasksData
    );
  }, [step]);

  useEffect(() => {
    if (caseDetails.kyc?.length > step) {
      // for(let i = 0 ;caseDetails.kyc?.length; i++){
      const stp = step === 0 ? 1 : step + 1;
      const suffixes = ["st", "nd", "rd", "th"];
      const lastDigit = stp % 10;
      const secondLastDigit = Math.floor(stp / 10) % 10;
      const suffixIndex =
        secondLastDigit === 1 ? 3 : lastDigit < 4 ? lastDigit - 1 : 3;
      const num = stp + suffixes[suffixIndex];
      setStepHeader(num);
      setStepSubHeader("Party Details");
      // }
    } else if (caseDetails.kyc?.length === step) {
      setStepHeader("Transaction");
      setStepSubHeader("Information");
    } else {
      setStepHeader("Transaction Tasks");
      setStepSubHeader("");
    }
  }, [step]);

  const [validation, setValidation] = useState(forms[0]?.validation);

  useEffect(() => {
    if (step < forms.length) {
      setValidation(
        // []
        forms[step].validation
      );
    } else {
    }
  }, [step]);

  // console.log(validation);
  const newSchema = transformAll([
    ["yup.object"],
    ["yup.shape"],
    ["yup.required"],
  ]);

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    // formState: { errors },
  } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: forms[step]?.formSchema?.reduce((acc, el) => {
      acc[el.name] = el.value;
      return acc;
    }, {}),
    // resolver: yupResolver(newSchema),
  });
  const [transactionStep, setTransactionStep] = useState(0);

  const { resetData } = useTransactionCreationData();
  const { resetEditData } = useFormEditData();

  function extractEmailAddresses(array) {
    return array.map((obj) => {
      // Find the schema entry with name "emailaddress"
      const emailSchema = obj.formSchema.find((schema) =>
        schema.name.includes("emailaddress")
      );

      // If found, return its value
      if (emailSchema) {
        return emailSchema.value;
      } else {
        return null; // or handle the case where email address is not found
      }
    });
  }

  const onSubmit = (data) => {
    let emptyFields = false;

    {
      caseDetails.kyc?.length > step
        ? // Iterate through the form schema
          caseDetails.kyc[step].formSchema?.forEach((field) => {
            // Check if the field exists in the personDetails object
            if (data.hasOwnProperty(field.name)) {
              // Check if the field is not optional and its value in personDetails is empty
              if (field.optional === false && data[field.name] === "") {
                emptyFields = true;
              } else {
                emptyFields = false;
              }
            } else {
              console.log(` does not exist in collectio`);
            }
          })
        : caseDetails.kyc?.length === step
        ? // Iterate through the form schema
          caseDetails.transactionData.formSchema?.forEach((field) => {
            // Check if the field exists in the personDetails object
            if (data.hasOwnProperty(field.name)) {
              // Check if the field is not optional and its value in personDetails is empty
              if (field.optional === false && data[field.name] === "") {
                emptyFields = true;
              } else {
                emptyFields = false;
              }
            } else {
              console.log(` does not exist in collectio`);
            }
          })
        : caseDetails.tasksData.formSchema?.forEach((field) => {
            // Check if the field exists in the personDetails object
            if (data.hasOwnProperty(field.name)) {
              // Check if the field is not optional and its value in personDetails is empty
              if (field.optional === false && data[field.name] === "") {
                emptyFields = true;
              } else {
                emptyFields = false;
              }
            } else {
              console.log(` does not exist in collectio`);
            }
          });
    }

    if (emptyFields) {
      toast.error("Please fill in all the fields.");
    } else {
      setStep((step) => {
        if (caseDetails.kyc && caseDetails.kyc.length > step) {
          return step + 1;
        } else if (caseDetails.kyc && caseDetails.kyc.length === step) {
          return step + 1;
        } else {
          return step + 1;
        }
      });

      let currentForm = forms;

      if (caseDetails.kyc && caseDetails.kyc.length > step) {
        let newSchema = currentForm?.formSchema?.map((el) => {
          // Check if the element is of type "file" and its value is not a link
          if (el.type === "file" && !isLink(data[el.name])) {
            return el; // Retain the original element without modifying its value
          }

          // For other types or file elements with link values, update the value from the data object
          return { ...el, value: data[el.name] };
        });

        // Function to check if a value is a link (you can adjust this based on your link detection criteria)
        function isLink(value) {
          // Example: Check if the value starts with "http://" or "https://"
          return (
            typeof value === "string" &&
            (value.startsWith("http://") || value.startsWith("https://"))
          );
        }

        let object = caseDetails.kyc[step];
        let objects = [...caseDetails.kyc];
        let updatedObject = { ...object, formSchema: newSchema };
        objects[step] = updatedObject;

        // setKYCForms(objects)
        dispatch(setKyc(objects));
      } else if (caseDetails.kyc?.length === step) {
        let newSchema = currentForm?.formSchema?.map((el) => {
          // Check if the element is of type "file" and its value is not a link
          if (el.type === "file" && !isLink(data[el.name])) {
            return el; // Retain the original element without modifying its value
          }

          // For other types or file elements with link values, update the value from the data object
          return { ...el, value: data[el.name] };
        });

        // Function to check if a value is a link (you can adjust this based on your link detection criteria)
        function isLink(value) {
          // Example: Check if the value starts with "http://" or "https://"
          return (
            typeof value === "string" &&
            (value.startsWith("http://") || value.startsWith("https://"))
          );
        }
        let object = caseDetails.transactionData;
        let updatedObject = { ...object, formSchema: newSchema };
        dispatch(setTransactionData(updatedObject));
      } else {
        let newSchemaaaa = currentForm?.formSchema?.map((el) => {
          return { ...el };
        });
        // startDate: "2024-25-08T14:43:46.480Z" ,endDate: "2024-25-08T14:43:46.480Z"
        let object = caseDetails.tasksData;
        let updatedObject = { ...object, formSchema: newSchemaaaa };
        dispatch(setTasksData(updatedObject));
      }

      if (step > caseDetails.kyc?.length) {
        let tasksWidthDates = caseDetails.tasksData.formSchema?.map((task) => {
          let taskInDateList = list?.find((el) => task?.id || task?._id == el.itemId);
          if (taskInDateList) {
            console.log("llllllllllllllllllllllllllllllllll");
            console.log(taskInDateList);
            console.log("llllllllllllllllllllllllllllllllll");
            return {
              ...task,
              startDate: taskInDateList.startDate,
              endDate: taskInDateList.endDate,
              dates: {
                startDate: taskInDateList.startDate,
                endDate: taskInDateList.endDate,
              },
            };
          }
          return { ...task, dates: null };
        });

        let allDates = tasksWidthDates.reduce((dates, task) => {
          if (task.dates) {
            dates.push(task.dates.startDate, task.dates.endDate);
          }
          return dates;
        }, []);
        // Finding the latest date
        let latestDate = new Date(Math.max(...allDates));
        // Finding the oldest date
        let oldestDate = new Date(Math.min(...allDates));

        // Convert dates back to string format if needed
        // const oldestDateString = oldestDate.toISOString(); // "2024-05-07T13:01:19.567Z"
        // const latestDateString = latestDate.toISOString(); // "2024-05-07T13:01:19.567Z"

        let data = {
          buyerCount: caseDetails?.buyerCount,
          sellerCount: caseDetails?.sellerCount,
          createdBy: userCookie?.name,
          description: caseDetails?.description,
          transactionName: caseDetails?.name || caseDetails?.transactionName,
          estimatedStartDate: oldestDate,
          reportType: caseDetails.reportType?.instructionOwner,
          estimatedEndDate: latestDate,
          collectionName: caseDetails.type?.name,
          status:
            caseDetails?.onGoingTransaction === true
              ? caseDetails?.status
              : "to-do",
          comments: "",
          registrationAmount: 3400,
          kyc: caseDetails?.kyc,
          transactionData: caseDetails?.transactionData,
          tasks: tasksWidthDates,
        };

        if (caseDetails?.onGoingTransaction === true) {
          console.log("Date");
          console.log(data);
          console.log("Date");
          // alert("updating")
          axios
            .put(
              `${api}v1/transactions/${caseDetails.collectionName?.toLowerCase()}/${
                caseDetails._id
              }`,
              data
            )
            .then((res) => {
              console.log("res.data");
              console.log(res.data);
              console.log("res.data");
              resetData();
              toast.success("Transaction Updated successfully");
              resetEditData();
              localStorage.clear();
              history.push({
                pathname: "/app/home",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          axios
            .post(`${api}v1/transactions`, data)
            .then((res) => {
              console.log("res.data");
              console.log(res.data);
              const emailAddresses = extractEmailAddresses(data?.kyc || []);
              let uniqueEmails = [...new Set(emailAddresses)];
              const formatDate = (dateString) => {
                const options = {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                };
                return new Date(dateString).toLocaleDateString(
                  undefined,
                  options
                );
              };

              const taskDetails = data.tasks
                .map((task, index) => {
                  const subTasks = task.subTasks
                    .map((subTask) => `-${subTask.name}<br/>`)
                    .join("");
                  return `
    <p>Task Name: ${task.label}<br/>
   Due Date: ${formatDate(task.endDate)}<br/>
    Subtasks:<br/>
   ${subTasks}
    </p>
  `;
                })
                .join("");

              const emailContent = `
  <p>Good day,</p>
  <p>We are pleased to inform you that a new case, <strong>${
    data.transactionName
  }</strong>, has been initiated successfully on ${formatDate(new Date())}.</p>
  <p> Below are the details of the transaction and the tasks that will be involved:<br/>
  <strong>Tasks and Estimated Timelines:</strong></p>
  ${taskDetails}
  <p>We will continue to keep you informed if any changes. If you have any questions or need further assistance, please do not hesitate to contact us.</p>
  <p>Thank you for your patience and cooperation.</p>
  <p>Best regards,<br>
  Collins Chilisa Team</p>
`;

              uniqueEmails?.map(async (email) => {
                if (email) {
                  axios
                    .post(
                      "https://collins-chilisa.testlive.space/api/v1/email",
                      {
                        email: email,
                        subject: `Case Update: ${data.transactionName}`,
                        text: `Good day,
                
                    We are pleased to inform you that a new case, ${
                      data.transactionName
                    }, has been initiated on ${formatDate(new Date())}.
                    Below are the details of the transaction and the tasks that will be involved:
                
                    Tasks and Estimated Timelines:
                
                    ${data.tasks
                      .map(
                        (task, index) => `
                      Task Name: ${task.label}
                      Due Date: ${formatDate(task.endDate)}
                      Subtasks:
                      ${task.subTasks
                        .map((subTask) => `- ${subTask.name}`)
                        .join("\n")}
                    `
                      )
                      .join("\n\n")}
                
                    We will continue to keep you informed as each task progresses. If you have any questions or need further assistance, please do not hesitate to contact us.
                
                    Thank you for your patience and cooperation.
                
                    Best regards,
                    Collins Chilisa Team`,
                        html: emailContent,
                      }
                    )
                    .then((response) => {
                      console.log("Email sent successfully:", response);
                    })
                    .catch((error) => {
                      console.error("Error sending email:", error);
                    });
                }
              });
              console.log("res.data");
              resetData();
              toast.success("Transaction created successfully");
              // resetData();
              resetEditData();
              dispatch(clearTransaction());
              dispatch(clearReport());
              localStorage.clear();
              history.push({
                pathname: "/app/home",
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  };

  const [
    list,
    {
      set,
      push,
      updateAt,
      insertAt,
      update,
      updateFirst,
      upsert,
      sort,
      filter,
      removeAt,
      clear,
      reset: resetList,
    },
  ] = useList([]);
  return (
    <div>
      {/* Open the modal using document.getElementById('ID').showModal() method */}

      <div className="flex flex-col justify-center items-center">
        <header className="container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
          <nav
            className="flex items-center justify-between p-6 lg:px-8"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-12 w-auto" src={logo} alt="" />
              </Link>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </nav>
          <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
          >
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    alt=""
                  />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <a
                      href="#"
                      className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
      </div>
      <div className="text-white flex px-20 flex-row mb-4 container mx-auto text-xl items-center">
        <span
          aria-hidden="true"
          className="cursor-pointer"
          onClick={() => {
            history.goBack();
          }}
        >
          {<IoMdArrowBack />}
        </span>
        <label className="w-full text-xl flex justify-center font-medium">
          <span className="font-light text-white mr-1">{stepHeader} </span>
          {stepSubHeader}
        </label>
      </div>
      <div className="container mx-auto w-[530px] text-gray-100 text-center mt-5">
        Kindly fill in the{" "}
        <strong>
          {stepHeader} {stepSubHeader}
        </strong>{" "}
        form as accurately as possible. Attach documents where necessary and
        proceed accordingly.
      </div>

      <div className="container mt-4 flex justify-center mx-auto">
        <ul className="steps w-full">
          {queryParams.get("step") && (
            <>
              <li className="step step-primary"></li>
              <li
                className={`step ${
                  parseInt(queryParams.get("step")) > buyerCount &&
                  "step-primary"
                }`}
              ></li>
              <li
                className={`step ${
                  parseInt(queryParams.get("step")) >
                    sellerCount + buyerCount && "step-primary"
                }`}
              ></li>
              <li
                className={`step ${
                  queryParams.get("step") > buyerCount + sellerCount + 1 &&
                  "step-primary"
                }`}
              ></li>
            </>
          )}
        </ul>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          key={step + "-" + transactionStep}
          className="border-b bg-red border-gray-900/10 mb-10 w-scree mt-10 "
        >
          <div className=" grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mx-10 xl:mx-80 bg-[#102232]  rounded-3xl  p-10 shadow-lg  border border-[#585d61]">
            {/* {buyerCount + sellerCount} */}
            <div className="col-span-full flex justify-between">
              <div>
                <h1 className="text-white text-lg font-semibold">
                  {caseDetails.kyc?.length > step
                    ? caseDetails.kyc[step]?.formName
                    : caseDetails.kyc?.length === step
                    ? caseDetails.transactionData?.formName
                    : `${caseDetails.tasksData?.formName} Tasks`}
                </h1>
              </div>
            </div>

            {caseDetails.kyc?.length > step
              ? caseDetails.kyc[step]?.formSchema?.map((el) =>
                  renderInput(
                    el,
                    register,
                    // errors,
                    setValue,
                    clearErrors,
                    el.value ?? watch(el.name)
                  )
                )
              : caseDetails.kyc?.length === step
              ? caseDetails.transactionData.formSchema?.map((el) =>
                  renderInput(
                    el,
                    register,
                    // errors,
                    setValue,
                    clearErrors,
                    el.value ?? watch(el.name)
                  )
                )
              : caseDetails.tasksData.formSchema?.map((el, i) =>
                  renderInput(
                    el,
                    () => {},
                    // errors,
                    setValue,
                    clearErrors,
                    el,
                    i,
                    push,
                    list,
                    updateAt
                  )
                )}
          </div>
        </div>
        <div className="flex  mx-10 xl:mx-80 gap-4 w-f relative mt-5 ">
          <div className="flex w-full gap-3 justify-end  ">
            {step > 0 && (
              <button
                className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[40px]  text-white p-3 hover:scale-105 duration-300 h-[35px]"
                onClick={(e) => {
                  e.preventDefault();

                  if (step > caseDetails.kyc?.length) {
                    setStep((step) => step - 1);
                  } else {
                    setStep((step) => step - 1);
                  }
                }}
              >
                Back
              </button>
            )}
            <button
              type="submit"
              className="bg-[#2b2d77] align-center flex justify-center text-sm items-center rounded-[9px] px-[40px] text-white p-3 hover:scale-105 duration-300 h-[35px]"
              // onClick={(e) => {
              //   e.preventDefault();
              //   handleSubmit(onSubmit)();
              //   // reset();
              // }}
            >
              Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FillForms;

export function Tasks({
  name,
  subTasks,
  index,
  bg,
  endDate,
  startDate,
  addToDateList,
  itemId,
  list,
  updateAt,
}) {
  const [rotation, setRotation] = useState(-45);
  const [height, setHeight] = useState(0);

  return (
    <div
      className={`flex  ${
        index === 0 && ""
      } cursor-pointer  relative items-center gap-3 border border-[#585d61] p-3 rounded-2xl bg-[#081c2f]`}
    >
      <div
        style={{
          color: colorTextPairs[index % colorTextPairs.length].text,
          backgroundColor: colorTextPairs[index % colorTextPairs.length].color,
        }}
        className={` text-sm h-[30px] flex justify-center items-center w-[30px] rounded-full font-bold`}
      >
        {index + 1}
      </div>
      <div className="text-white text-xs w-5/12">{name}</div>
      <div
        onClick={() => {
          if (rotation === 45) {
            setRotation(-45);
            setHeight(0);
          } else {
            setHeight(40);
            setRotation(45);
          }
        }}
        className=" flex justify-end  w-4/12 py-3 px-auto"
      >
        <FaArrowRight
          className="transition-all"
          style={{ transform: `rotateZ(${rotation}deg)` }}
        />
      </div>

      {rotation == 45 && (
        <div
          style={{
            marginBottom: `${subTasks.length * height + 65}px`,
            transition: "margin 0.5s",
          }}
          className={`  rounded-bl-2xl rounded-br-2xl absolute overflow-visible  transition-all group p-3 w-full left-0 top-[48px] border-b border-l border-r  border-[#585d61] z-20  bg-[#081c2f]`}
        >
          <div className="flex-col overflow-visible justify-between">
            <div className="mb-3 ">
              <DateInput
                addToDateList={addToDateList}
                startDate={startDate}
                endDate={endDate}
                itemId={itemId}
                list={list}
                updateAt={updateAt}
              />
            </div>
            <div className="pl-3">
              <div className="divider mt-1 mb-0"></div>

              {subTasks?.map((item, i) => {
                return (
                  <>
                    <TaskRender
                      title={item.name}
                      index={i + 1}
                      key={`${item.name}-${name}-${item.id}-${i}`}
                    />
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function TaskRender({ title, index }) {
  return (
    <div className="text-xs pt-1 truncate overflow-visible">
      <div className="pl-3">
        {index}. {title}
      </div>
      <div className="divider mt-1 mb-0"></div>
    </div>
  );
}

