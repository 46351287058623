import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import InputLabel from "./InputLabel";
import SelectField from "./SelectField";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { IoMdAddCircle } from "react-icons/io";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function IndividualForm({
  step1Complete,
  step2Complete,
  setStep1Complete,
  setStep2Complete,
  party1Data,
  setParty1Data,
  party2Data,
  setParty2Data,
  data,
}) {
  const history = useHistory();

  const schema = yup
    .object()
    .shape({
      name: yup.string().required(),
      // surname: yup.string().required(),
      email: yup.string().email().required(),
      maritalStatus: yup.string().required(),
      idNumber: yup.string().required("Id number is required"),
      idPassportFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),

      decreeAbsoluteFile: yup
        .mixed()
        .test("file", "You need to upload a file", function (value) {
          // Access the field values using `this.parent`
          const maritalStatus = this.parent.maritalStatus;
          if (maritalStatus === "Divorced") {
            if (value.length > 0) {
              return true;
            }
            return false;
          }
          return true;
        }),
      deathCertificateFile: yup
        .mixed()
        .test("file", "You need to upload a file", function (value) {
          // Access the field values using `this.parent`
          const maritalStatus = this.parent.maritalStatus;
          if (maritalStatus === "Widowed") {
            if (value.length > 0) {
              return true;
            }
            return false;
          }
          return true;
        }),
      spounseConsentFile: yup
        .mixed()
        .test("file", "You need to upload a file", function (value) {
          // Access the field values using `this.parent`
          const maritalStatus = this.parent.maritalStatus;
          if (maritalStatus === "Married") {
            if (value.length > 0) {
              return true;
            }
            return false;
          }
          return true;
        }),
      phoneNumber: yup.string().matches(/^\+?\d+$/, "Must be a number"),
      kycFile: yup
        .mixed()
        .test("file", "You need to upload a file", (value) => {
          if (value.length > 0) {
            return true;
          }
          return false;
        }),
      marriageCertificateFile: yup
        .mixed()
        .test("file", "You need to upload a file", function (value) {
          // Access the field values using `this.parent`
          const maritalStatus = this.parent.maritalStatus;
          if (maritalStatus === "Married") {
            if (value.length > 0) {
              return true;
            }
            return false;
          }
          return true;
        }),

      isCitizen: yup.boolean().required("Please select a value"),
    })
    .required();
  const {
    register,
    reset,
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitAndStay = (data) => {
    console.log(data);
    if (step1Complete == false) {
      const form_data = data; // dataToFormData(data, "FIRST");
      setParty1Data((prev) => [...prev, form_data]);
    } else {
      // reset();
      const form_data = data; //dataToFormData(data, "SECOND");
      setParty2Data((prev) => [...prev, form_data]);
    }
  };
  const onSubmitAndNext = (data) => {
    console.log(data);
    if (step1Complete == false) {
      // const form_data = dataToFormData(data, "FIRST");
      // setParty1Data((prev) => [...prev, form_data]);
      setStep1Complete(true);
    } else {
      // const form_data = dataToFormData(data, "SECOND");
      // setParty2Data((prev) => [...prev, form_data]);
      setStep2Complete(true);
      // reset();
    }
  };

  function dataToFormData(data, party) {
    var form_data = new window.FormData();
    form_data.append("name", data.name);
    form_data.append("email", data.email);
    form_data.append("phone", data.phoneNumber);
    form_data.append("id_passport", data.idNumber);
    form_data.append(
      "marriageCertificate",
      data.marriageCertificateFile[0] || null
    );
    form_data.append("party", party);
    form_data.append("decreeAbsolute", data.decreeAbsoluteFile[0]);
    form_data.append(
      "deathCertificate",
      data.deathCertificateFile ? data.deathCertificateFile[0] : null
    );
    form_data.append("citizenshipStatus", data.isCitizen);
    form_data.append("kycDocument", data.kycFile[0]);
    form_data.append("idPassportDocument", data.idPassportFile[0]);
    // form_data.append("type", data.userType);
    // form_data.append("transactionType", data.transactionType);

    return form_data;
  }

  // if case id exists, then
  return (
    <form>
      <div className="border-b bg-red border-gray-900/10 mb-10 w-screen container">
        <div className=" grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mx-10 xl:mx-80 bg-[#102232]  rounded-3xl  px-10 pt-10 pb-6 shadow-lg  border border-[#585d61]">
          <>
            <InputField
              isErrored={errors.name}
              message={errors.name?.message}
              label="Fullname(s) *"
              placeholder="Enter the party`s full names here..."
              register={register}
              name="name"
            />
            {/* <InputField
              label="Surname *"
              isErrored={errors.surname}
              message={errors.surname?.message}
              placeholder="Enter the party`s surname here..."
              register={register}
              name="surname"
            /> */}
            <InputField
              label="Email *"
              isErrored={errors.email}
              message={errors.email?.message}
              placeholder="Enter the party`s email here..."
              register={register}
              name="email"
            />
            <InputField
              label="Id Number *"
              register={register}
              name="idNumber"
              placeholder="Enter the party`s id here..."
              isErrored={errors.idNumber}
              message={errors.idNumber?.message}
            />
            <InputField
              id={"idPassportFile"}
              type="file"
              register={register}
              name={"idPassportFile"}
            />
            <InputLabel
              htmlFor={"idPassportFile"}
              title={"Attach ID/Passport *"}
              message={errors.idPassportFile?.message}
              hasFile={watch("idPassportFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the id or passport ,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              isErrored={errors.idPassportFile}
              subtitle={
                watch("idPassportFile")?.length > 0
                  ? watch("idPassportFile")[0].name
                  : "click to upload id/passport here..."
              }
            />
            <SelectField
              label="Marital Status *"
              register={register}
              setValue={setValue}
              clearErrors={clearErrors}
              name="maritalStatus"
              placeholder="Select marital status.."
              isErrored={errors.maritalStatus}
              message={"Please select a value"}
              toolTip={["Single", "Married", "Divorced", "Widowed"]}
            />
            {/* 
            <InputField
              label="Type of Transaction *"
              register={register}
              name="transactionType"
              placeholder="Enter the type of transaction here.."
              isErrored={errors.transactionType}
              message={errors.transactionType?.message}
            /> */}

            <InputField
              label="Phone Number *"
              register={register}
              name="phoneNumber"
              placeholder="Enter the party's phone number here..."
              type="text"
              isErrored={errors.phoneNumber}
              message={errors.phoneNumber?.message}
            />
            <InputLabel
              htmlFor={"kycFile"}
              title={"Attach KYC Documentation *"}
              message={errors.kycFile?.message}
              isErrored={errors.kycFile}
              hasFile={watch("kycFile")?.length > 0}
              toolTip={
                <p>
                  Please attach an electronic copy of the KYC documentation,
                  preferably in <span className="font-bold">PDF</span> or{" "}
                  <span className="font-bold"> word document </span>
                </p>
              }
              subtitle={
                watch("kycFile")?.length > 0
                  ? watch("kycFile")[0].name
                  : "click to upload KYC Documentation here..."
              }
            />
            <InputField
              register={register}
              type="file"
              name="kycFile"
              id="kycFile"
            />
            {watch("maritalStatus") === "Divorced" && (
              <>
                <InputLabel
                  htmlFor={"decreeAbsoluteFile"}
                  title={"Attach Decree Absolute *"}
                  toolTip={
                    <p>
                      Please attach an electronic copy of the decree absolute ,
                      preferably in <span className="font-bold">PDF</span> or{" "}
                      <span className="font-bold"> word document </span>
                    </p>
                  }
                  message={errors.decreeAbsoluteFile?.message}
                  isErrored={errors.decreeAbsoluteFile}
                  hasFile={watch("decreeAbsoluteFile")?.length > 0}
                  subtitle={
                    watch("decreeAbsoluteFile")?.length > 0
                      ? watch("decreeAbsoluteFile")[0].name
                      : "click to upload decree absolute here..."
                  }
                />
                <InputField
                  id={"decreeAbsoluteFile"}
                  type="file"
                  register={register}
                  name={"decreeAbsoluteFile"}
                />
              </>
            )}
            {watch("maritalStatus") === "Widowed" && (
              <>
                <InputLabel
                  htmlFor={"deathCertificateFile"}
                  title={"Attach Death Certificate "}
                  message={errors.deathCertificateFile?.message}
                  isErrored={errors.deathCertificateFile}
                  hasFile={watch("deathCertificateFile")?.length > 0}
                  toolTip={
                    <p>
                      Please attach an electronic copy of the death certificate,
                      preferably in <span className="font-bold">PDF</span> or{" "}
                      <span className="font-bold"> word document </span>
                    </p>
                  }
                  subtitle={
                    watch("deathCertificateFile")?.length > 0
                      ? watch("deathCertificateFile")[0].name
                      : "click to upload death cerfiticate here..."
                  }
                />

                <InputField
                  id={"deathCertificateFile"}
                  type="file"
                  register={register}
                  name={"deathCertificateFile"}
                />
              </>
            )}
            {watch("maritalStatus") === "Married" && (
              <>
                <InputLabel
                  id
                  htmlFor={"marriageCertificateFile"}
                  title={"Marriage Certificate"}
                  toolTip={
                    <p>
                      Please attach an electronic copy of the marriage
                      certificate certificate, preferably in{" "}
                      <span className="font-bold">PDF</span> or{" "}
                      <span className="font-bold"> word document </span>
                    </p>
                  }
                  message={errors.marriageCertificateFile?.message}
                  isErrored={errors.marriageCertificateFile}
                  hasFile={watch("marriageCertificateFile")?.length > 0}
                  subtitle={
                    watch("marriageCertificateFile")?.length > 0
                      ? watch("marriageCertificateFile")[0].name
                      : "click to upload Marriage certificate here..."
                  }
                />
                <InputField
                  type="file"
                  register={register}
                  id="marriageCertificateFile"
                  name="marriageCertificateFile"
                />

                <InputLabel
                  id
                  htmlFor={"spounseConsentFile"}
                  title={"Spouse Consent"}
                  toolTip={
                    <p>
                      Please attach an electronic copy of the Spouse Consent,
                      preferably in <span className="font-bold">PDF</span> or{" "}
                      <span className="font-bold"> word document </span>
                    </p>
                  }
                  message={errors.spounseConsentFile?.message}
                  isErrored={errors.spounseConsentFile}
                  hasFile={watch("spounseConsentFile")?.length > 0}
                  subtitle={
                    watch("spounseConsentFile")?.length > 0
                      ? watch("spounseConsentFile")[0].name
                      : "click to upload Spouse Consent here..."
                  }
                />
                <InputField
                  type="file"
                  register={register}
                  id="spounseConsentFile"
                  name="spounseConsentFile"
                />
              </>
            )}
            {/* <div
              className={`flex flex-col px-3 py-[15px] bg-[#081c2f] rounded-2xl  border ${
                errors.userType ? " border-[red]" : " border-[#585d61]"
              }`}
            >
              <label className="text-xs text-white">Select User Type *</label>
              <div>
                <div className="flex text-white  h-6 items-center justify-between">
                  <label className="flex items-center text-xs gap-2">
                    <p>Buyer</p>

                    <input
                      id="userType"
                      name="userType"
                      type="radio"
                      {...register("userType")}
                      value={"BUYER"}
                      // value={""}
                      className="h-3 w-3 rounded radio: border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>

                  <label className="flex items-center text-xs gap-2">
                    <p>Seller</p>

                    <input
                      id="userType1"
                      name="userType"
                      value={"SELLER"}
                      {...register("userType")}
                      type="radio"
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>
                </div>
                {errors.userType && (
                  <div className="text-red-600 text-xs">
                    {errors.userType?.message}
                  </div>
                )}
              </div>
            </div> */}

            <div
              className={`flex flex-col px-3 py-[15px] bg-[#081c2f] rounded-2xl  border ${
                errors.isCitizen ? " border-[red]" : " border-[#585d61]"
              }`}
            >
              <label className="text-xs text-white">Citizenship Status *</label>
              <div>
                <div className="flex h-6 text-white items-center justify-between">
                  <label htmlFor="" className="flex items-center text-xs gap-2">
                    <p>Citizen</p>
                    <input
                      id="isCitizen1"
                      name="isCitizen"
                      {...register("isCitizen")}
                      type="radio"
                      value={true}
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>

                  <label className="flex items-center text-xs gap-2">
                    <p>Non-Citizen</p>
                    <input
                      id="isCitizen"
                      name="isCitizen"
                      type="radio"
                      value={false}
                      {...register("isCitizen")}
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>
                </div>
                {errors.isCitizen && (
                  <div className="text-red-600 text-xs">
                    {errors.isCitizen?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center justify-between sm:col-span-1 mt- md:col-span-1 xl:col-span-2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmitAndStay)();
                }}
                className="px-7 text-[#43ace1] gap-1 text-xs font-semibold flex items-center"
              >
                <IoMdAddCircle size={19} /> Add More (
                {step1Complete ? party2Data.length : party1Data.length})
              </button>
              <input
                onClick={() => {
                  handleSubmit(onSubmitAndNext)();
                }}
                value={"Next Step"}
                type="button"
                className="cursor-pointer rounded-lg bg-[#2b2d77] text-xs text-white px-12 py-1.5"
              />
            </div>
          </>
          <div className="flex items-center justify-center sm:col-span-1 md:col-span-1 xl:col-span-2">
            <p className="text-center tracking-wide text-xs  text-white font-thin">
              <span className="text-[#3b97c6] font-bold">Attention</span>:
              Please note that by using to this platform you agree to
              <br />
              the
              <span className="text-[#3b97c6] font-medium underline">
                {" "}
                Terms & Conditions
              </span>{" "}
              and{" "}
              <span className="text-[#3b97c6] font-medium underline">
                Privacy Policies
              </span>
            </p>{" "}
          </div>
        </div>
      </div>
    </form>
  );
}

export default IndividualForm;
