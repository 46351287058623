import React, { useEffect, useState } from "react";
import InputField from "../InputField";
import InputLabel from "../InputLabel";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCookie } from "react-use";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { IoMdAddCircle } from "react-icons/io";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useCaseFormData } from "../../hooks/useCaseFormData";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useQueryParams } from "../../hooks/useQueryParams";
import { toast } from "sonner";
// import { get, set } from "js-cookie";
// import { log } from "console";
// import { log } from "console";
const api = process.env.REACT_APP_BACKEND_API_URL;

function EditIndividualForm({ data, setPartyIndex, showNext }) {
  const history = useHistory();

  const { id, party } = data;
  console.log(data);

  const [values] = useCookie("user");

  const userCookie = JSON.parse(values);

  const schema = yup
    .object()
    .shape({
      name: yup.string().required(),

      // surname: yup.string().required(),
      email: yup.string().email().required(),
      isCitizen: yup.boolean().required().default(false),
      id_passport: yup.string().required("Id number is required"),
      idPassportFile: yup.mixed(),
      transactionType: yup.string().required("Transaction type is required"),
      decreeAbsoluteFile: yup.mixed(),
      deathCertificateFile: yup.mixed(),
      phoneNumber: yup.string().matches(/^\+?\d+$/, "Must be a number"),
      kycFile: yup.mixed(),
      marriageCertificateFile: yup.mixed(),
      userType: yup.string().required("Please select a value"),
      isCitizen: yup.boolean().required("Please select a value"),
    })
    .required();
  const {
    register,
    reset,
    getValues,

    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: data?.email,
      id_passport: data?.id_passport,
      transactionType: data?.transaction_type,
      name: data?.name,
      phoneNumber: data?.phone,
      decreeAbsolute: data?.decree_absolute,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    let form_data = dataToFormData(data, "INDIVIDUAL");
    console.log(data.id);
    axios
      .patch(`${api}/api/v1/individuals/${id}`, form_data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res) => {
        console.log(res);
        await axios.post(
          `"https://collins-chilisa.testlive.space"/api/v1/logs`,
          {
            activity: "Case updated",
            userId: userCookie.id,
            timestamp: new Date().toISOString(), // Get current date and time in ISO format
          }
        );
        toast.success("Party updated successfully");
        queryClient.invalidateQueries({ queryKey: ["individual-cases"] });
        queryClient.invalidateQueries({
          queryKey: ["individuals-" + id],
        });
        history.goBack();
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occured", { description: JSON.stringify(err) });
      });
  };
  useEffect(() => {
    // Fetch the remote file
    fetch(data.kyc_document)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a temporary File object from the blob
        const file = new File([blob], "filename.ext", {
          type: "file/mime-type",
        });

        // Set the default value of the file input field
        // setValue("fieldName", file);
      })
      .catch((error) => {
        console.error("Error fetching remote file:", error);
      });
  }, []);

  const queryClient = useQueryClient();
  function dataToFormData(data) {
    console.log(data);
    var form_data = new window.FormData();
    form_data.append("name", data.name);
    form_data.append("email", data.email);
    form_data.append("phone", data.phoneNumber);
    form_data.append("id_passport", data.id_passport);
    form_data.append(
      "marriageCertificate",
      data.marriageCertificateFile[0] || null
    );

    form_data.append("party", party);
    form_data.append("id", id);
    form_data.append("decreeAbsolute", data.decreeAbsoluteFile[0]);
    form_data.append("deathCertificate", data.deathCertificateFile[0] || null);
    form_data.append("citizenshipStatus", data.isCitizen);
    form_data.append("kycDocument", data.kycFile[0]);
    form_data.append("idPassportDocument", data.idPassportFile[0]);
    form_data.append("type", data.userType);
    form_data.append("transactionType", data.transactionType);

    return form_data;
  }

  // if case id exists, then call api here and there
  return (
    <form>
      <div className="border-b bg-red border-gray-900/10 mb-10 w-screen container">
        <div className=" grid gap-6 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xl:grid-cols-2 mx-10 xl:mx-80 bg-[#102232]  rounded-3xl  px-10 pt-10 pb-6 shadow-lg  border border-[#585d61]">
          <>
            <InputField
              isErrored={errors.name}
              message={errors.name?.message}
              label="Fullname(s) *"
              placeholder="Enter the party`s full names here..."
              register={register}
              name="name"
            />
            {/* <InputField
              label="Surname *"
              isErrored={errors.surname}
              message={errors.surname?.message}
              placeholder="Enter the party`s surname here..."
              register={register}
              name="surname"
            /> */}
            <InputField
              label="Email *"
              isErrored={errors.email}
              message={errors.email?.message}
              placeholder="Enter the party`s email here..."
              register={register}
              name="email"
            />
            <InputField
              label="Id Number *"
              register={register}
              name="id_passport"
              placeholder="Enter the party`s id here..."
              isErrored={errors.idNumber}
              message={errors.idNumber?.message}
            />
            <InputField
              id={"idPassportFile"}
              type="file"
              register={register}
              name={"idPassportFile"}
            />
            <InputLabel
              htmlFor={"idPassportFile"}
              title={"Upload/Attach ID/Passport *"}
              message={errors.idPassportFile?.message}
              isErrored={errors.idPassportFile}
              hasFile={true}
              subtitle={"id_passport.txt"}
            />

            <InputField
              label="Type of Transaction *"
              register={register}
              name="transactionType"
              placeholder="Enter your type of transaction here.."
              isErrored={errors.transactionType}
              message={errors.transactionType?.message}
            />

            <InputLabel
              htmlFor={"decreeAbsoluteFile"}
              title={"Upload/Attach Decree Absolute *"}
              message={errors.decreeAbsoluteFile?.message}
              isErrored={errors.decreeAbsoluteFile}
              hasFile={true}
              subtitle={"degree_absolute.txt"}
            />
            <InputField
              id={"decreeAbsoluteFile"}
              type="file"
              register={register}
              name={"decreeAbsoluteFile"}
            />

            <InputLabel
              htmlFor={"deathCertificateFile"}
              title={"Upload/Attach Death Certificate "}
              message={errors.deathCertificateFile?.message}
              isErrored={errors.deathCertificateFile}
              hasFile={true}
              subtitle={"death_certificate.txt"}
            />
            <InputField
              id={"deathCertificateFile"}
              type="file"
              register={register}
              name={"deathCertificateFile"}
            />
            <InputField
              label="Phone Number *"
              register={register}
              name="phoneNumber"
              placeholder="Enter the party's phone number here..."
              type="text"
              isErrored={errors.phoneNumber}
              message={errors.phoneNumber?.message}
            />
            <InputLabel
              htmlFor={"kycFile"}
              title={"Upload/Attach KYC Documentation *"}
              message={errors.kycFile?.message}
              isErrored={errors.kycFile}
              hasFile={true}
              subtitle={"kyc_.txt"}
            />
            <InputField
              register={register}
              type="file"
              name="kycFile"
              id="kycFile"
            />
            <InputLabel
              id
              htmlFor={"marriageCertificateFile"}
              title={"Marriage Certificate"}
              message={errors.marriageCertificateFile?.message}
              isErrored={errors.marriageCertificateFile}
              hasFile={true}
              subtitle={"marriageCerficate.txt"}
            />
            <InputField
              type="file"
              register={register}
              id="marriageCertificateFile"
              name="marriageCertificateFile"
            />
            <div
              className={`flex flex-col px-3 py-[15px] bg-[#081c2f] rounded-2xl  border ${
                errors.userType ? " border-[red]" : " border-[#585d61]"
              }`}
            >
              <label className="text-xs text-white">Select User Type *</label>
              <div>
                <div className="flex text-white  h-6 items-center justify-between">
                  <label className="flex items-center text-xs gap-2">
                    <p>Buyer</p>

                    <input
                      id="userType"
                      name="userType"
                      type="radio"
                      defaultChecked={data?.type === "BUYER"}
                      {...register("userType")}
                      value={"BUYER"}
                      // value={""}
                      className="h-3 w-3 rounded radio: border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>

                  <label className="flex items-center text-xs gap-2">
                    <p>Seller</p>

                    <input
                      id="userType1"
                      name="userType"
                      value={"SELLER"}
                      defaultChecked={data?.type === "SELLER"}
                      {...register("userType")}
                      type="radio"
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>
                </div>
                {errors.userType && (
                  <div className="text-red-600 text-xs">
                    {errors.userType?.message}
                  </div>
                )}
              </div>
            </div>

            <div
              className={`flex flex-col px-3 py-[15px] bg-[#081c2f] rounded-2xl  border ${
                errors.isCitizen ? " border-[red]" : " border-[#585d61]"
              }`}
            >
              <label className="text-xs text-white">Citizenship Status *</label>
              <div>
                <div className="flex h-6 text-white items-center justify-between">
                  <label htmlFor="" className="flex items-center text-xs gap-2">
                    <p>Citizen</p>
                    <input
                      id="isCitizen1"
                      name="isCitizen"
                      {...register("isCitizen")}
                      type="radio"
                      defaultChecked={data?.citizenhip_status == true}
                      value={true}
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>

                  <label className="flex items-center text-xs gap-2">
                    <p>Non-Citizen</p>
                    <input
                      id="isCitizen"
                      name="isCitizen"
                      type="radio"
                      defaultChecked={data?.citizenhip_status == false}
                      value={false}
                      {...register("isCitizen")}
                      className="h-3 w-3 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </label>
                </div>
                {errors.isCitizen && (
                  <div className="text-red-600 text-xs">
                    {errors.isCitizen?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center justify-end gap-5 sm:col-span-1 mt- md:col-span-1 xl:col-span-2">
              {showNext && (
                <input
                  onClick={() => {
                    setPartyIndex((prev) => {
                      console.log(prev);
                      return prev + 1;
                    });
                  }}
                  value={"Next"}
                  type="button"
                  className="rounded-lg cursor-pointer border-[#2b2d77] text-xs text-white px-12 py-1.5"
                />
              )}
              <input
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
                value={"Save"}
                type="button"
                className="rounded-lg cursor-pointer bg-[#2b2d77] text-xs text-white px-12 py-1.5"
              />
            </div>
          </>
          <div className="flex items-center justify-center sm:col-span-1 md:col-span-1 xl:col-span-2">
            <p className="text-center tracking-wide text-xs  text-white font-thin">
              <span className="text-[#3b97c6] font-bold">Attention</span>:
              Please note that by using to this platform you agree to
              <br />
              the
              <span className="text-[#3b97c6] font-medium underline">
                {" "}
                Terms & Conditions
              </span>{" "}
              and{" "}
              <span className="text-[#3b97c6] font-medium underline">
                Privacy Policies
              </span>
            </p>{" "}
          </div>
        </div>
      </div>
    </form>
  );
}

export default EditIndividualForm;
