import React, { useEffect, useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { GoPaperclip } from "react-icons/go";

function InputLabel({
  title,
  subtitle,
  htmlFor,
  isErrored,
  message,
  hasFile,
  leftArrow,
  toolTip,
}) {
  const [hasError, setHasError] = useState(isErrored);
  // useStartTyping(() => alert("You started typing!"));

  useEffect(() => {
    setHasError(isErrored);
  }, [isErrored]);

  const handleClick = () => {
    const elem = document.activeElement;
    if (elem) {
      console.log(elem);
      elem?.blur();
    }
  };
  useEffect(() => {
    console.log(title);
  }, [title]);
  const isURL = (str) => {
    // Regular expression pattern for URL validation
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    // Test if the string matches the URL pattern
    return urlPattern.test(str);
  };
  return (
    <div
      key={subtitle}
      className={`flex  items-center justify-between px-3 py-[15px] bg-[#081c2f] cursor-pointer  rounded-2xl border ${
        hasError ? "border-[red]" : "border-[#585d61]"
      }`}
    >
      <label className="flex flex-col" htmlFor={htmlFor}>
        <div className="flex justify-between items-center">
          <div>
            <div className="text-xs flex flex-row gap-2 text-white">
              {title} <GoPaperclip size={13} />
            </div>
            <div
              className={
                "mt-1 text-xs bg-transparent text-[#475663] active:border-transparent focus:outline-none"
              }
            >
              {hasFile ? (
                <span className=" bg-[#3b97c6] text-white py-[0.1rem] pl-2 rounded-md pr-5">
                  <div className="flex">
                    <GoPaperclip size={13} />{" "}
                  </div>
                </span>
              ) : isURL(subtitle) ? (
                <div className="flex gap-1 items-center">
                  <GoPaperclip size={13} />
                  {title}{" "}
                  <span className="text-[#3b97c6]"> Document uploaded</span>{" "}
                </div>
              ) : (
                subtitle
              )}
            </div>
            {hasError && (
              <div className="text-[red] text-xs mt-1">
                {message || "Required"}
              </div>
            )}
          </div>
          {leftArrow}
        </div>
      </label>
      {toolTip && (
        <div>
          <div className="dropdown dropdown-end dropdown-hover">
            <div
              tabIndex="0"
              role="button"
              className=" btn-link mt-2 text-white p-0 m-0"
            >
              <BiInfoCircle size={22} />
            </div>
            <ul
              tabIndex="0"
              className="   dropdown-content z-[1] p-0 menu shadow  rounded-box border border-1 w-60 bg-[#102232]"
            >
              <div className="flex pt-3 px-3  justify-between   items-center">
                <div className="font-semibold">Information</div>
                <div>
                  <IoMdClose onClick={handleClick} />
                </div>
              </div>
              <div className="divider p-0 m-0 border-[#fff]"></div>
              <div className="px-3 py-2 text-xs mb-2 text-justify">
                {toolTip}
              </div>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default InputLabel;
