import React, { lazy, useContext, Suspense, useEffect, useState } from "react";
import {
  Route,
  Switch,
  redirect,
  Redirect,
  withRouter,
} from "react-router-dom";
import Profile from "../app-views/Profile";
import Cases from "../app-views/Cases";
import CasesList from "../app-views/CasesList";
import Home from "../app-views/Home";
import SystemUsers from "../app-views/super-admin/SystemUsers";
import SystemLogs from "../app-views/super-admin/SystemLogs";
import SuperAdminDasboard from "../app-views/super-admin/superAdminDasboard";
import { useDispatch, useSelector } from "react-redux";
import { useCookie } from "react-use";
import { loginSuccess } from "../../store/authSlice";
import SingleCase from "./SingleCase";
// import SingleCase from "./SingleCase";

// import SingleCase from "../../components/SingleCase";
// const dispatch = useDispatch();

// import SystemLogs from "../app-views/super-admin/SystemLogs";

// Assume you have a context or state to manage user authentication
// and user type
const AuthContext = React.createContext();

export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const AppViews = () => {
  const { role } = useSelector((state) => state.authState);
  const dispatch = useDispatch();

  const [value, updateCookie, deleteCookie] = useCookie("user");
  const user = JSON.parse(value);

  console.log(user);
  if (user?.jwt) {
    dispatch(loginSuccess(user));
  }
  // Sample user type
  // console.log(user.role);
  const userType = user?.role || ""; // Sample value, replace with your actual user type logic
  function RouteProtector({ children, allowedUserTypes, ...rest }) {
    console.log(allowedUserTypes);
    if (!allowedUserTypes.includes(role)) {
      return <Redirect to={AUTH_PREFIX_PATH} />;
    }

    return <Route {...rest} />;
  }

  // console.log(userType);
  return (
    // to be implemented here
    // <AuthContext.Provider value={{ isAuthenticated, userType }}>
    <Switch>
      <Route exact path="/app">
        {userType === "admin" ? (
          <Redirect to="/app/home" />
        ) : userType === "superAdmin" ? (
          <Redirect to="/app/dashboard" />
        ) : (
          ""
        )}
      </Route>

      {/* Admin/Eployee Routes */}

      <RouteProtector
        path={`${APP_PREFIX_PATH}/home`}
        allowedUserTypes={["admin"]}
        component={Home}
      />
      <RouteProtector
        path={`${APP_PREFIX_PATH}/profile`}
        allowedUserTypes={["admin"]}
        component={Profile}
      />

      <RouteProtector
        path={`${APP_PREFIX_PATH}/cases/:id`}
        allowedUserTypes={["admin"]}
        component={SingleCase}
      />
      <RouteProtector
        path={`${APP_PREFIX_PATH}/cases`}
        allowedUserTypes={["admin"]}
        component={Cases}
      />

<RouteProtector
        path={`${APP_PREFIX_PATH}/cases_list`}
        allowedUserTypes={["admin"]}
        component={CasesList}
      />

      {/* superAdmin/Routes */}
      <RouteProtector
        path={`${APP_PREFIX_PATH}/dashboard`}
        allowedUserTypes={["superAdmin"]}
        component={SuperAdminDasboard}
      />
      <RouteProtector
        path={`${APP_PREFIX_PATH}/users`}
        allowedUserTypes={["superAdmin"]}
        component={SystemUsers}
      />
      <RouteProtector
        path={`${APP_PREFIX_PATH}/logs`}
        allowedUserTypes={["superAdmin"]}
        component={SystemLogs}
      />
    </Switch>
    // </AuthContext.Provider>
  );
};

export default AppViews;
