import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "../layouts/auth-layout";
import AppLayout from "../layouts/app-layout";
import OnBoardViews from "./onboarding-views";
import TransactionViews from "./transactions-views";
import TemplatesViews from "./templates-views";
import { useDialog, useModal } from "../hooks/useModal";
import { BeatLoader, ClipLoader } from "react-spinners";
import { BsCheckCircleFill } from "react-icons/bs";
const Views = () => {
  const APP_PREFIX_PATH = "/app";
  const AUTH_PREFIX_PATH = "/auth";
  const ON_BOARD_PATH = "/onboard";
  const TRANSACTIONS_PATH = "/transactions";
  const TEMPLATES_PATH = "/templates";

  //   function RouteProtector({ children, isAuthenticated, ...rest }) {
  //     return (
  //       <Route
  //         {...rest}
  //         render={({ location }) =>
  //           isAuthenticated ? (
  //             children
  //           ) : (
  //             <Redirect
  //               to={{
  //                 pathname: AUTH_PREFIX_PATH,
  //                 state: { from: location }
  //               }}
  //             />
  //           )
  //         }
  //       />
  //     );
  // }
  // a custom hook to show a modal, a non dismissable dialog
  const modal = useModal();
  useEffect(() => {
    if (modal.isOpen) {
      document.getElementById("modal").showModal();
    } else {
      document.getElementById("modal").close();
    }
  }, [modal.isOpen]);

  // a custom hook to show a dismissable dialog
  const dialog = useDialog();
  useEffect(() => {
    if (dialog.isOpen) {
      document.getElementById("dialog").showModal();
    } else {
      document.getElementById("dialog").close();
    }
  }, [dialog.isOpen]);
  return (
    <>
      <dialog id="modal" className="modal  modal-bottom sm:modal-middle">
        <div className="modal-box bg-[#081c2f]">
          <h3 className="font-bold text-lg">{modal.title} </h3>
          <p className="py-4">
            {modal.description}

            <BeatLoader color="#a6adbb" />
          </p>
        </div>
      </dialog>
      <dialog
        onClose={() => {
          dialog.closeModal();
        }}
        id="dialog"
        className="modal bg-[#0c1a27cc]"
      >
        <div className="modal-box bg-[#102232]  flex justify-center flex-col items-center shadow-sm border-none">
          <div className="modal-box grid grid-cols-3 p-0  bg-transparent shadow-none ">
            <div className="col-span-3 ">
              <div className="  text-lg text-white mb-4 mt-10">
                {dialog.title}
              </div>
              <div className="flex items-center flex-col px-3 py-[15px] bg-[#081c2f]  rounded-2xl border border-[#585d61]">
                <label className="text-md gap-3 my-2 text-white flex justify-center items-center">
                  {dialog.description}
                  <BsCheckCircleFill size={25} color="#2b7734" />
                </label>
              </div>
            </div>
          </div>
          <div className="flex w-full justify-end my-4">
            <button
              onClick={() => {
                dialog.closeModal();
              }}
              className="bg-[#302a74] px-8   py-3 text-white  text-sm font-medium float-right mx-4 rounded-lg"
            >
              Dismiss
            </button>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
      <Switch>
        <Route exact path="/">
          <Redirect to={AUTH_PREFIX_PATH} />
        </Route>

        <Route path={AUTH_PREFIX_PATH}>
          <AuthLayout />
        </Route>

        <Route path={APP_PREFIX_PATH}>
          <AppLayout />
        </Route>

        <Route path={ON_BOARD_PATH}>
          <OnBoardViews />
        </Route>

        <Route path={TRANSACTIONS_PATH}>
          <TransactionViews />
        </Route>

        <Route path={TEMPLATES_PATH}>
          <TemplatesViews />
        </Route>

        {/* <RouteProtector path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location}/>
          </RouteProtector> */}
      </Switch>
    </>
  );
};

export default Views;
