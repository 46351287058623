import { create } from "zustand";

export const useCaseFormData = create((set) => ({
  party1: [],
  party2: [],
  // case: { parties: { party1: [], party2: [] } },
  addParty1: (party) =>
    set((state) => {
      let data = party;
      console.log(data);
      return { party1: [...state.party1, data] };
    }),
  addParty2: (party) => set((state) => ({ party1: [...state.party2, party] })),
  setCase: (caseData) => set({ case: caseData }),
  // addPartyToCase: (party, partyType) =>
  //   set((state) => ({
  //     case: {
  //       ...state.case,
  //       parties: {
  //         ...state.case.parties,
  //         [partyType]: [...state.case.parties[partyType], party],
  //       },
  //     },
  //   })),
}));
