import React, { useState } from "react";
// import UserProvider from "./provider/UserProvider";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { Provider } from "react-redux";
// import { store } from "./app/store";
import Views from "./views";
import AuthViews from "./views/auth-views";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster, toast } from "sonner";
const queryClient = new QueryClient();
function App() {
  return (
    <div className="App bg-[#081c2f] min-h-[100vh]">
      <Toaster />
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
