import React, { useEffect } from "react";

import { useState } from "react";
import { DateRange } from "react-date-range";
function DateInput({
  id,
  startDate,
  endDate,
  key,
  addToDateList, //function to add to date list
  itemId, // id of task
  list, // list of dates
  updateAt, // function to add add to date list at a given index
}) {
  // cons;
  const [state, setState] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: "selection",
      color: "#43ace1",
    },
  ]);

  // useEffect(() => {
  //   // if date exists on the list of dates
  //   let item = list.find((el) => (el.itemId = itemId));
  //   console.log(item);
  //   // setState({...state,startDate:item.startDate,endDate:item/})
  // }, [list]);
  if (!state[0]) {
    return null;
  }
 
  return (
    <div className="dropdown w-full ">
      <div className="flex border border-[#585d61] items-center justify-between pr-7 rounded-lg  py-2 px-2 bg-[#102232] w-full">
        <div tabIndex={0} role="button" className=" w-full text-xs">
          {state[0].startDate.toDateString()} -{" "}
          {state[0].endDate?.toDateString()}
        </div>
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content border border-[#585d61] z-[1] menu p-2 shadow rounded-box bg-[#081c2f]"
      >
        {state?.length > 0 && (
          <DateRange
            classNames={["test"]}
            color="red"
            editableDateInputs={true}
            // onPreviewChange={}
            onChange={(item) => {
              console.log(item);
              console.log(itemId);

              let index = list?.findIndex((el) => el.itemId === itemId);
              console.log("llllll")
              console.log(list)
              console.log("llllll")
              console.log(index);
              if (index > -1) {
                updateAt(index, {
                  itemId,
                  startDate: item.selection.startDate,
                  endDate: item.selection.endDate,
                });
              } else {
                addToDateList({
                  itemId,
                  startDate: item.selection.startDate,
                  endDate: item.selection.endDate,
                });
                console.log("llllll")
              console.log( {
                  itemId,
                  startDate: item.selection.startDate,
                  endDate: item.selection.endDate,
                }) 
                console.log("llllll") }

              setState([item.selection]);
            }}
            moveRangeOnFirstSelection={false}
            onRangeFocusChange={(item) => console.log("Item")}
            ranges={state}
          />
        )}
      </ul>
    </div>
  );
}

export default DateInput;
