import React, { useEffect,useState } from "react";
import backgroundImg from "../../images/home-img.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BiAlarm, BiSolidChevronDown } from "react-icons/bi";
import AdminBanner from "../../components/AdminBanner";
import Tabs from "../../components/Tabs";
import { GiBackwardTime } from "react-icons/gi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { PiSuitcaseSimple } from "react-icons/pi";
import ExportToExcel from "./Excel";
import axios from "axios";

function Home() {


  const [query, setQuery] = useState("");
  const [matchedSearch, setMatchedSearch] = React.useState([]);

  const onHandleSearch = (e) => {
    axios.get("https://collins-chilisa.testlive.space/api/v1/report-templates").then((res)=>{
    const reports = res.data.data;
    const results =  matchedSearch.filter((item) => item.reportType.toLowerCase().includes(query.toLowerCase()));

    if(query === ""){
      setMatchedSearch(reports);
    }
    else{
    setMatchedSearch(results? results : results );
  }
  });

  
  };

  useEffect(()=>{
  onHandleSearch()
  },[query])

  const inputRef = React.useRef(null);


  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);
  const history = useHistory();
  return (
    <div className="container mx-auto pt-7 md:px-10 px-5 ">
      <div
        style={{
          backgroundImage: `url(${backgroundImg})`,
        }}
        className="mb-5  h-[26vh] md:h-[40vh] md:shrink-0 rounded-xl md:rounded-3xl shadow-md overflow-hidden mt-[110px] lg:mt-10 bg-no-repeat bg-cover"
      >
        <div className="md:flex">
          <div className=" flex flex-col md:justify-center md:w-1/2  pt-10 md:pt-0 pl-10 md:pl-10">
            <p
              className=" tracking-wide font-display text-xl md:text-4xl md:mb-4 mb-2 text-white
           font-semibold"
            >
              Thank you <br></br>for<br></br>Choosing us
            </p>
            <p className="block leading-tight font-sm text-white ">
              Giving you peace of mind by providing you with the tools{" "}
              <br className="hidden md:flex"></br>
              you need when you need them
            </p>
          </div>

          <div className="md:w-1/2 md:pt-10  flex-col items-end pr-3 md:pr-10 flex justify-center">
            <div className="bg-[#243f57] w-1/2 md:w-[240px] lg:w-[300px] px-10 md:flex justify-center flex-col items-center hidden  relative h-[180px] rounded-3xl">
              <GiBackwardTime
                color="white"
                size={30}
                className="absolute top-4 left-4 "
              />
              <div className="space-y-2 ">
                {" "}
                <p className="underline  font-display text-white">
                  New Case File?
                </p>
                <p className="text-white text-sm">
                  Click the 'Create Case <br></br>File' button below
                </p>
              </div>
            </div>
            <>
              <dialog
                id="my_modal_5"
                className="modal modal-bottom sm:modal-middle "
              >
                <div className="modal-box  overflow-visible bg-[#102232]">
                  <h3 className="font-bold  text-lg">
                    {" "}
                    Select Report Type
                  </h3>

                  <div className="w-full bg-[#071b2e] mt-6 rounded-box  border-none">
                    <div className="dropdown w-full dropdown-end">
                      <div
                        tabIndex={0}
                        role="button"
                        className="min-h-12 gap-5 justify-between  pl-5 pr-2 items-center flex text-start btn-ghost rounded-box border-[#2f3740] w-full"
                      >
                        <div className=" w-11/12 flex items-center gap-5 cursor-pointer">
                          <PiSuitcaseSimple />
                          <input
                            value={query}
                            name="query"
                            className="bg-transparent cursor-pointer focus:outline-none w-11/12"
                            onChange={(e)=>setQuery(e.target.value)}
                            placeholder="Type to search report type"
                          />
                        </div>
                       <BiSolidChevronDown size={25} /> 
                      </div>
                      <ul
                        tabIndex={0}
                        className="menu dropdown-content border bg-[#102232] z-[1] p-2 w-full shadow bg-bae-100 rounded-box border-[#2f3740] mt-4"
                      >

                        {matchedSearch?.slice(0, 2).map((el) => (
                          <li className="b" key={el._id}>
                           <ExportToExcel reportType={el} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="modal-action">
                    <form method="dialog">
                      {/* if there is a button in form, it will close the modal */}
                      <button className="border-[#2b2d77] border align-center flex justify-center text-sm items-center rounded-[9px] px-[60px] mt-0 text-white p-3 hover:scale-105 duration-300 h-[35px]">
                        Close
                      </button>
                    </form>
                  </div>
                </div>
              </dialog>
            </>
            <Link
             
              to={"/transactions/select"}
              className="md:w-[240px] lg:w-[300px] bg-[#2b2d77] md:h-[45px]  px-4 py-2 md:px-0 md:py-0 flex justify-center items-center mt-2 text-white text-md rounded-xl md:rounded-xl"
            >
              Create Case File
            </Link>

            {/* <button
              onClick={() => document.getElementById("my_modal_5").showModal()}
              className="md:w-[240px] lg:w-[300px] bg-[#2b2d77] md:h-[45px]  px-4 py-2 md:px-0 md:py-0 flex justify-center items-center mt-2 text-white text-md rounded-xl md:rounded-xl"
            >
              Download Reports
            </button> */}
           
          </div>
        </div>
      </div>
      <Tabs />
    </div>
  );
}

export default Home;
