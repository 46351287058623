import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useLocalStorage } from "react-use";

const api = process.env.REACT_APP_BACKEND_API_URL;
export function useTasksQuery() {
  // const [user] = useLocalStorage("user", null);
  return useQuery({
    queryKey: ["cases"],
    queryFn: async () => {
      let response = await axios.get(`${api}/api/v1/tasks`, {
        // headers: {
        //   Authorization: `Bearer ${user.jwt}`,
        // },
      });

      let data = await response.data;

      return data.data;
    },
  });
}
