import React from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { toast } from "sonner";

export const exportToExcel = async (reportType, dates) => {
  
  try {
    const res = await axios.get("https://collins-chilisa.testlive.space/api/v1/transactions");
    const trans = res.data.data.filter(item => item.reportType === reportType.reportType);
    const transactionData = trans;

    const filteredTransactions = transactionData.filter(transaction => {
      const tranDate = new Date(transaction.estimatedEndDate);
      return tranDate >= new Date(dates.startDate) && tranDate <= new Date(dates.endDate);
    });

    if (filteredTransactions.length === 0) {
      toast.error("There are no transactions in this period");
    } 
    else {
      const attributeNames = reportType.templateSchema?.map(schema => schema.name);

      const newObjects = [];

      filteredTransactions.forEach(transaction => {
        const loanObject = {};

        transaction.transactionData.formSchema.forEach(field => {
          if (attributeNames.includes(field.name)) {
            loanObject[field.name] = field.value || null;
          }
        });

        if (Object.keys(loanObject).length !== 0) {
          newObjects.push(loanObject);
        }
      });

      const dataToSheet = (newObjects) => {
        const ws = XLSX.utils.json_to_sheet(newObjects);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        return wb;
      };

      const workbook = dataToSheet(newObjects);
      XLSX.writeFile(workbook, `${reportType.reportName}.xlsx`);
    }
  } catch (error) {
    console.error("Error exporting to Excel:", error);
    toast.error("An error occurred while exporting to Excel");
  }
};

const ExportToExcel = () => {
  return null; // This component doesn't render anything, as it's now just exporting the method
};

export default ExportToExcel;
