import React from "react";
import {
  BiDotsHorizontalRounded,
  BiDotsVertical,
  BiVerticalBottom,
} from "react-icons/bi";

const CasesHeader = ({ title }) => {
  return (
    <div className="flex flex-1 select-none w-full gap-16 px-3 py-[8px] justify-between items-center bg-[#081c2f] rounded-xl border border-[#585d61]">
      <div className="text-sm">{title}</div>
      <button onClick={() => console.log(title)}>
        <BiDotsHorizontalRounded size={"25"} />
      </button>
    </div>
  );
};

export default CasesHeader;
