import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../images/logo.png";
import loginImage from "../../images/loginImage.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import InputField from "../../components/InputField";
import { Link } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "sonner";
import { useCookie } from "react-use";
import axios from "axios";
const navigation = [
  //   { name: "Product", href: "#" },
  //   { name: "Features", href: "#" },
  //   { name: "Marketplace", href: "#" },
  //   { name: "Company", href: "#" },
];
const API = process.env.REACT_APP_BACKEND_API_URL;
export default function ForgotPassword() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const history = useHistory();

  const schema = yup
    .object()
    .shape({
      email: yup.string().required(),
      // password: yup.string().required(),
    })
    .required();
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const submit = (data) => {
    const email = data.email;
    setLoading(true);
    axios
      .post(`${API}/api/v1/auth/reset-password`, { email })
      .then(() => {
        history.push("/auth/login");
        setLoading(false);
        toast.success("You will receive an email to reset your password");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("An error occured", { description: "Email not found" });
      });
  };
  return (
    <div className="flex flex-col h-[100vh]  relative  items-center">
      <header className="absolute container mb-10 lg:mb-10 border-b border-b-[#636c70] mx-auto  inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-12 w-auto" src={logo} alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>

          <div className="hidden lg:items-center lg:gap-4 lg:flex lg:flex-1 lg:justify-end">
            <Link
              to="/auth/login"
              className="rounded-xl bg-[#2B2D77] px-8 py-1.5  text-sm font-  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              SIGN IN
            </Link>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      {/*container */}

      <div className="container flex flex-col justify-center  items-center h-[100vh]">
        <label className="text-white  md:mb-4 text-2xl md:text-3xl ">
          Forgot Password ?
        </label>

        <div className="text-white   md:w-1/2 lg:w-1/3 mx-6 gap-4 md:gap-4  flex-col flex ">
          <p className="text-center text-[1rem]  my-2">
            Please enter the email address associated with your account and to
            recieve a link to reset your password.
          </p>
          <InputField
            label="Email Address"
            type="email"
            isErrored={errors.email}
            message={errors.email?.message}
            register={register}
            name="email"
            placeholder="please enter your email address here..."
          />

          {emailVerified ? (
            <>
              <InputField
                label="New Password"
                type="password"
                register={register}
                name="email"
                placeholder="please enter your new password here..."
              />

              <InputField
                label="Confirm Password"
                register={register}
                type="password"
                name="email"
                placeholder="please enter password here..."
              />
            </>
          ) : (
            ""
          )}
          <button
            onClick={handleSubmit(submit)}
            className="bg-[#0e518b] py-3 rounded-xl text-md"
          >
            {loading ? (
              <span className="loading loading-bars loading-sm"></span>
            ) : (
              "Send"
            )}
          </button>

          <Link
            to="/auth/login"
            className="text-center my-4 text-md  cursor-pointer hover:underline"
          >
            Return to login
          </Link>
        </div>

        {/* form
        <div className=" px-8 py-16 lg:pt-10 md:pt-10 md:px-4">
          <form action="" className="flex flex-col gap-4">
            <div className="flex flex-col p-2 rounded-xl border border-[#585d61]">
              <label className="text-xs text-white">
                Full names / Email Address*
              </label>
              <input
                className="mt-1 text-xs bg-transparent"
                type="email"
                name="email"
                placeholder="Enter your details belwo"
              />
            </div>
            <div className="flex flex-col p-2 rounded-2xl border border-[#585d61]">
              <label className="text-xs text-white">Fullnames *</label>
              <input
                className="mt-1 text-xs bg-transparent"
                type="email"
                name="email"
                placeholder="Enter your password here..."
              />
            </div>
            <div className="mt-3 items-center flex flex-col text-gray-400 px-">
              <p className="text-center text-xs font-thin text-white ">
                <span className="text-[#3b97c6] font-medium">Attention</span>:
                Please note that by using to this platform <br /> you agree to{" "}
                <br />
                the
                <span className="text-[#3b97c6] font-medium underline">
                  {" "}
                  Terms & Conditions
                </span>{" "}
                and{" "}
                <span className="text-[#3b97c6] font-medium underline">
                  Privacy Policies
                </span>
              </p>
              <button
                className="bg-[#002D74] align-center flex justify-center text-sm items-center rounded-[9px] px-[75px] mt-5 text-white p-3 hover:scale-105 duration-300 h-[35px]"
                onClick={() => {
                  history.push("/app");
                }}
              >
                Reset
              </button>
            </div>
          </form>
        </div> */}
      </div>
    </div>
  );
}
