import React from 'react'

export default function Pagination ({ postsPerPage,currentPage, totalPosts, paginate }) {
  const pageNumbers = [];


  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (

<div className="flex justify-between w-full text-xs pr-4 mb-1"> 

<span className="text-[#bbbbbb] ">
  {currentPage} of  {(Math.round(totalPosts/postsPerPage)) === 0  ? 1 : Math.round(totalPosts/postsPerPage)} Page
</span>




<div className="flex gap-2">

<button onClick={() => paginate(currentPage - 1)} className={`text-[#bbbbbb] shadow-lg ${currentPage === 1 && "hidden"} `} >
  Previous
</button>
<button onClick={() => paginate(currentPage + 1)} className={`text-[#bbbbbb]  ${currentPage === Math.round(totalPosts/postsPerPage)&& "hidden"} `} >
  Next
</button>
</div>
</div>

  );
};
