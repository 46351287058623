import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./authSlice";
import { reportReducer } from "./reportSlice";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { persistReducer } from 'redux-persist'
import { combineReducers } from "@reduxjs/toolkit";
import { transactionReducer } from "./transactionSlice";


const persistConfig = {
  key: 'root',
  storage,
  version:1
}

const reducer = combineReducers({
  authState: authReducer,
  reportState: reportReducer,
  transactionState:transactionReducer,
})

const persistedReducer = persistReducer(persistConfig,reducer)

const store = configureStore({
  reducer:persistedReducer,
});

export { store };
