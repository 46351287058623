import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function TransactionData() {
  return (
    <div>
      TransactionData
      <Link to="/edit-form">Home</Link>
    </div>
  );
}

export default TransactionData;
