import { create } from "zustand";
// import { data } from "../data";
export const useFormEditData = create((set) => ({
  editData: [],
  resetEditData() {
    set({ editData: [] });
  },
  setEditData(data) {
    set((state) => {
      return { editData: data };
    });
  },
}));
